import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { Location } from '@angular/common';
import Utils from '../../utils/utils';
import { UserService } from '../../service/user.service';
import { Router, ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { TeamBottomSheetComponent } from '../team-bottom-sheet/team-bottom-sheet.component';
import { LayoutService } from '../../service/layout.service';
import { ImageStorageService } from '../../../shared/service/img-storage.service';

@Component({
  selector: 'egypt-app-multi-node',
  templateUrl: './egypt-multi-node.component.html',
  styleUrls: ['./egypt-multi-node.component.scss']
})

export class EgyptMultiNodeComponent implements OnInit, OnDestroy {
  searchText = { general_name: '' };
  public loader: any;

  @Input('arrCategories') arrCategories;
  @Input('displayback') displayback;
  @Output() onClickCategory = new EventEmitter();
  @Input('menuBarTitle') menuBarTitle;
  @Input('commodity_parent_name') commodity_parent_name;
  @Input('filterStatus') filterStatus;
  @Input('filterTypeGroup') filterTypeGroup;
  @Output() selectedCategory = new EventEmitter();
  @Input('arrDefaultCat') arrDefaultCat;
  public isMobileView: boolean = false;

  // public arrApplications: any;
  // public arrPageTitles: any;
  // public pageTitle: any;


  constructor(
    private location: Location,
    public uService: UserService,
    public router: Router,
    private bottomSheet: MatBottomSheet,
    public layoutService: LayoutService,
    private imageStorageService: ImageStorageService
  ) {
    // layoutService.refresh();
  }

  ngOnInit() {
    this.loader = true;
  }

  nav(catDetails) {
    this.onClickCategory.emit(catDetails);
  }

  back() {
    this.location.back();
  }

  setMyStyles() {
    return Utils.reverseContent();
  }

  onImgError(event) {
    event.target.src = Utils.offlineImage("");
  }

  filterCategories(filterType) {
    this.selectedCategory.emit(filterType);
  }
  openBottomSheet(data) {
    this.bottomSheet.open(TeamBottomSheetComponent, { data: data });
  }
  ngOnDestroy() {
    this.bottomSheet.dismiss();
  }
  getx48Image(inputUrl: string): string {
    return this.imageStorageService.getx48Image(inputUrl);
  }
}
