import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { WeatherComponent } from "./weather";
import { CropComponent } from "./crop/crop.component";
import { AuthGuard } from "../shared/guards/auth.guard";
import { ResultsPWAComponent } from "./crops/crop-calendar/components/results-pwa/results-pwa.component";
import { WeatherDetailComponent } from "./weatherDetail/weatherDetail.component";

const meteoroutes: Routes = [
  { path: 'weathercropcalendar', redirectTo: 'weather' },
  { path: "weather", component: WeatherComponent, canActivate: [AuthGuard] },
  { path: "crop/:crop_id", component: CropComponent, canActivate: [AuthGuard] },
  { path: "cropCalendar/:country/:crop/:zone", component: ResultsPWAComponent },
  { path: "weatherDetail/:date", component: WeatherDetailComponent, canActivate: [AuthGuard] }
];

@NgModule({
  imports: [RouterModule.forChild(meteoroutes)],
  exports: [RouterModule]
})
export class WeatherRoutingModule { }
