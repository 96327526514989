
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanDeactivate } from '@angular/router';
import { Observable } from 'rxjs';
import { UserProfileComponent } from '../../user-profile/user-profile.component';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable()
export class UserProfileDeactivateGuard implements CanDeactivate<UserProfileComponent> {
  component: Object;
  route: ActivatedRouteSnapshot;

  constructor(public toast: MatSnackBar) {
  }

  canDeactivate(component: UserProfileComponent,
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
    nextState: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    let user = JSON.parse(localStorage.getItem('user'));
    if (user) {
      if (!user.nickName) {
        this.toast.open('Please update your profile!', 'OK', { duration: 10000 });
        return false;
      } else {
        return true;
      }
    }
    return true;//component.canExit();

  }

}
