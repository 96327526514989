import { AngularFireDatabase } from '@angular/fire/compat/database';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { UserService } from './../shared/service/user.service';
import { NotificationService } from './../shared/service/notification.service';
@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit, OnDestroy {
  //list: any;
  public loader: boolean = false;
  public arrApplications: any;
  private arrThemes: any;
  private themesSerObj: any;
  loaded: boolean = false;
  searchText = { general_name: '' };
  //isNotificationActive: boolean = false;
  private filteredApplicationArr = [];
  constructor(
    public router: Router,
    public uService: UserService,
    public notificationService: NotificationService
  ) {
  }
  ngOnInit() {
    this.loader = true;
    this.getApplicationNames();
  }

  getApplicationNames() {
    this.filteredApplicationArr = [];
    // this.notificationService.getNotificationsApplications().take(1).subscribe(res => {
    //   if (res !== undefined) {
    //     this.arrApplications = res.applications;
    //     this.themesSerObj = this.uService.getDataUsingCountry('themes').take(1).subscribe(res => {
    //       this.loader = true;
    //       this.arrThemes = res;
    //       for (var apps in this.arrApplications) {
    //         for (var themes in this.arrThemes) {
    //           if (this.arrThemes[themes]['template_id'] == this.arrApplications[apps]['appId']) {
    //             this.filteredApplicationArr.push(this.arrThemes[themes]);
    //             break;
    //           }
    //         }
    //       }
    //     });
    //   }

    //   this.loader = false;
    //   this.loaded = true;
    // });

    this.themesSerObj = this.uService.getDataUsingCountry('themes').take(1).subscribe(res => {
      this.loader = true;
      this.arrThemes = res;
      // for (var apps in this.arrApplications) {
      for (var themes in this.arrThemes) {
        // if (this.arrThemes[themes]['template_id'] == this.arrApplications[apps]['appId']) {
        console.log("this.arrThemes[themes]['template_id']====");
        console.log(this.arrThemes[themes]['template_id']);
        if (this.arrThemes[themes]['template_id'] !== 'team' && this.arrThemes[themes]['template_id'] !== 'forum') {
          this.filteredApplicationArr.push(this.arrThemes[themes]);
        }
        // break;
        // }
      }
      // }
      this.loader = false;
      this.loaded = true;
    });

  }

  nav(app) {
    if (app) {
      this.router.navigate(['/notification-history', app]);
    }
  }

  goBack() {
    window.history.back();
  }
  ngOnDestroy() {
    this.themesSerObj.unsubscribe();
  }

}
