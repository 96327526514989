import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import Utils from '../../utils/utils';

@Component({
  selector: 'app-team-bottom-sheet',
  templateUrl: './team-bottom-sheet.component.html',
  styleUrls: ['./team-bottom-sheet.component.scss']
})
export class TeamBottomSheetComponent implements OnInit {
  constructor(@Inject(MAT_BOTTOM_SHEET_DATA) public data: { data: any }) {
  }
  ngOnInit() {
  }
  onImgError(event) {
    event.target.src = Utils.offlineImage("");
  }
}
