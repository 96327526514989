import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { Covid19FoodSafetyRoutingModule } from './covid19-food-safety-routing.module';
import { CategoriesComponent } from './categories/categories.component';
import { SubjectsComponent } from './subjects/subjects.component';

import { FilterPipe } from './../shared/pipes/filter.pipe';
import { FormsModule } from '@angular/forms';
import { SharedModule } from './../shared/shared.module';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { Covid19FoodSafetyService } from '../shared/service/covid19-food-safety.service';
import { Covid19FoodSafetyComponent } from './covid19-food-safety.component';


@NgModule({
  declarations: [CategoriesComponent, SubjectsComponent, Covid19FoodSafetyComponent],
  imports: [
    CommonModule,
    Covid19FoodSafetyRoutingModule,
    FormsModule,
    SharedModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  exports: [
    Covid19FoodSafetyRoutingModule
  ],
  providers: [
    Covid19FoodSafetyService
  ]
})




export class Covid19FoodSafetyModule { }
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}