import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from '../../../../src/environments/environment';
import { WeatherService } from '../../shared/service/weather.service';
import { GeoLocationService } from './../../shared/service/location';
import { Subscription } from 'rxjs';
import { NgxSpinnerService } from "ngx-spinner";
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-weatherDetail',
  templateUrl: './weatherDetail.component.html',
  styleUrls: ['./weatherDetail.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class WeatherDetailComponent implements OnInit, OnDestroy {
  showData: boolean;
  location: any;
  loader: boolean;
  timeseries: any;
  dailyWeather = [];
  public weatherData: any = { data: [], xAxisData: [], title: '', yAxisLabel: 'Temperature (°C)', seriesLabel: 'Temperature (°C)' };
  private day = {
    0: 'Weather.Night',
    6: 'Weather.Morning',
    12: 'Weather.Afternoon',
    18: 'Weather.Evening'
  }
  lang_code: any;
  subscriptions: Subscription[] = [];
  constructor(
    public weather: WeatherService,
    private locService: GeoLocationService,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService) {
  }

  ngOnInit() {
    this.spinner.show();
    if (!this.locService.coords.length) {
      this.locService.getLatestLocation();
    }
    this.subscriptions.push(this.locService.locality$.pipe(take(1)).subscribe(res => {
      if (res.originalCoords) {
        this.weatherData.title = res.formatted_address;
        this.getWeekWeather();
      }
    }));
    this.lang_code = JSON.parse(localStorage.getItem(environment.userLanguageStorageKey)).code;
    const country = JSON.parse(localStorage.getItem("countryData"));
    this.lang_code = country['country'] == 'VEN' && this.lang_code == 'es' ? 'en' : this.lang_code;
  }
  private getWeekWeather() {
    this.showData = false;
    let startDate = this.route.snapshot.params.date;
    let endDate: any = new Date();
    endDate.setDate(new Date(endDate).getDate() + 9);
    endDate = this.formatDate(endDate);
    this.subscriptions.push(this.weather.getDetailForecast(this.locService.coords, startDate, endDate).subscribe(res => {
      this.loader = true;
      this.spinner.hide();
      if (res) {
        this.timeseries = res.timeseries[startDate];
        this.timeseries.forEach(element => {
          const time = new Date(element.time)
          if ([0, 6, 12, 18].includes(time.getUTCHours())) {
            element.day = this.day[time.getUTCHours()];
            this.dailyWeather.push(element);
          }
        });
        for (var key in res.timeseries) {
          res.timeseries[key].forEach(element => {
            const time = new Date(element.time)
            if ([0, 6, 12, 18].includes(time.getUTCHours())) {
              this.weatherData.data.push(element.details.air_temperature);
              this.weatherData.xAxisData.push(key + ' ' + this.formatAMPM(element.time));
            }
          });
        }
      }
    }));
  }
  private formatAMPM(date) {
    date = new Date(date);
    var hours = date.getUTCHours();
    var ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    var strTime = hours + ' ' + ampm;
    return strTime;
  }
  private formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();
    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;
    return [year, month, day].join('-');
  }
  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe())
  }
}
