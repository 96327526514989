import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, Inject } from '@angular/core';

@Component({
    selector: 'app-feedback',
    template: `
    <h2 mat-dialog-title> Feedbacks on
    {{data.commodity_info.commodity_name}} -
    {{data.market_info.market_name}}
    </h2>


    <mat-dialog-content>
    <div class="margin-center">
    <mat-form-field style='width:75%'>
        <input matInput placeholder="{{'type_here' | localTranslate }}" 
        required type='number' [(ngModel)]='price'>
    </mat-form-field>
</div>
</mat-dialog-content>
    <mat-dialog-actions>
    <button mat-button mat-dialog-close>No</button>

    <button mat-button [mat-dialog-close]="price">Yes</button>
  </mat-dialog-actions>
    `
})

export class FeedbackComponent implements OnInit {
    price = this.data.price_info.last_avg_price;
    constructor( @Inject(MAT_DIALOG_DATA) public data: any) { }

    ngOnInit() { }
}
