import { Location } from '@angular/common';
import { Subscription, Observable } from 'rxjs';
import { LayoutService } from './../../shared/service/layout.service';
import {
  LivestockService,
  VaccinationData
} from './../../shared/service/livestock.service';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, OnDestroy } from '@angular/core';
import Utils from './../../shared/utils/utils';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { environment } from '../../../environments/environment';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-vaccination-details',
  templateUrl: './vaccination-details.component.html',
  styleUrls: ['./vaccination-details.component.scss']
})

export class VaccinationDetailsComponent implements OnInit, OnDestroy {
  r: Subscription;
  l: Subscription;
  rowH: string;
  cols: number;
  details: any;
  public livestockSpeAndDisList = {
    species: '',
    disease: ''
  };
  public loader: boolean = false;
  lang_code: any;
  appTheme: any;

  constructor(
    private location: Location,
    private route: ActivatedRoute,
    private livesctock: LivestockService,
    private layout: LayoutService,
    public gaService: GoogleAnalyticsService,
    private spinner: NgxSpinnerService
  ) {
    this.spinner.show();
  }

  public back() {
    this.location.back();
  }

  ngOnInit() {
    const themes = JSON.parse(localStorage.getItem('appThemes'));
    this.lang_code = JSON.parse(localStorage.getItem(environment.userLanguageStorageKey)).code;
    this.r = this.route.params.subscribe(res => {
      this.appTheme = themes.filter(_ => _.id === res.themeId)[0];
      this.livesctock.getDimensionDocument(res.id).subscribe((data: any) => {
        this.spinner.hide();
        this.details = data;
        this.livestockSpeAndDisList = data;
        this.loader = true;
      });
      this.l = this.layout.layout$.subscribe(r => {
        this.cols = r.cols;
        this.rowH = r.rowH;
      });
    });
  }

  ngOnDestroy() {
    this.r.unsubscribe();
    this.l.unsubscribe();
  }

  setMyStyles() {
    return Utils.reverseContent();
  }

  onImgError(event) {
    event.target.src = Utils.offlineImage("");
  }

  getDefImage(event) {
    event.target.src = Utils.defaultImage();
  }
  tabChange(i) {
    this.gaService.event('Vaccination' + this.details.vaccination, 'Tab_Change', i.tab.textLabel);
  }
}
