import { AnimalFeedComponent } from './animal-feed';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../shared/guards/auth.guard';
import { AnimalFeedCategoriesComponent } from './animal-feed-categories/animal-feed-categories.component';
import { AnimalFeedSubjectsComponent } from './animal-feed-subjects/animal-feed-subjects.component';

const AnimalFeed: Routes = [
  { path: 'animal_feed', redirectTo: 'animal-feed' },
  {
    path: 'animal-feed',
    component: AnimalFeedComponent,
    canActivate: [AuthGuard],
    children: [
       { path: '', redirectTo: 'categories', pathMatch: 'full' },
       { path: 'categories', component: AnimalFeedCategoriesComponent, canActivate: [AuthGuard] },
       { path: 'categories/:id', component: AnimalFeedCategoriesComponent, canActivate: [AuthGuard] },
       { path: 'subject/:themeId/:id', component: AnimalFeedSubjectsComponent, canActivate: [AuthGuard] }
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(AnimalFeed)],
  exports: [RouterModule],
})
export class AnimalFeedRoutingModule { }
