import { AngularFireDatabase } from '@angular/fire/compat/database';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { UserService } from './../../shared/service/user.service';
import { NotificationService } from './../../shared/service/notification.service';
import { Subscription } from 'rxjs';
import { NgxSpinnerService } from "ngx-spinner";
@Component({
  selector: 'app-notifications',
  templateUrl: './notification-details.component.html',
  styleUrls: ['./notification-details.component.scss']
})
export class NotificationDetailsComponent implements OnInit, OnDestroy {
  //list: any;
  public loader: boolean = false;
  public notificationArr = [];
  loaded: boolean = false;
  searchText = { general_name: '' };
  subscriptions: Subscription[] = [];

  constructor(
    public router: Router,
    public uService: UserService,
    public notificationService: NotificationService,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService
  ) {
    this.spinner.show();
  }
  ngOnInit() {
    this.getNotifications(this.route.snapshot.params.id);
    this.loader = true;
  }

  getNotifications(applicationId: string) {
    this.subscriptions.push(this.notificationService.getNotificationsHistory(applicationId).subscribe(res => {
      this.spinner.hide();
      this.notificationArr = res;
      this.notificationArr.sort((a, b) => b.created_at.toMillis() - a.created_at.toMillis());
      this.loader = false;
      this.loaded = true;
    }));
  }

  getNotificationText(notification) {
    return notification.body[notification.language_code];
  }

  goBack() {
    window.history.back();
  }
  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

}
