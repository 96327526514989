import { Component, OnInit, OnDestroy } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { LayoutService } from './../shared/service/layout.service';

@Component({
  selector: 'app-citrus',
  templateUrl: './citrus.component.html'
})
export class CitrusComponent implements OnInit, OnDestroy {
  p: Subscription;
  rowH: string;
  cols = 1;
  isLoading = true;
  
  constructor(private layout: LayoutService) { }

  ngOnInit() {
    this.isLoading = false;
    this.p = this.layout.layout$.subscribe(res => {
      this.cols = res.cols;
      this.rowH = res.rowH;
    });
  }

  ngOnDestroy() {
    this.p.unsubscribe();
  }
}
