import {
  throwError as observableThrowError,
  BehaviorSubject,
  Observable
} from 'rxjs';
import { tap, catchError, map } from 'rxjs/operators';
import { UserService } from './user.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { VaccinationData } from './livestock.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GeoLocationService } from './location';
import * as isOnline from 'is-online';
import { Parametre } from './parametre';
const PARAMETRE = Parametre.getInstance();
import { LivestockService } from './livestock.service';
import { environment } from '../../../environments/environment';
import { FirestoreDataService } from "./firestore-data.service";
import Utils from "./../utils/utils";

@Injectable()
export class HouseholdPoultryService {
  constructor(
    private UService: UserService,
    public location: GeoLocationService,
    private http: HttpClient,
    public toast: MatSnackBar,
    private livestockService: LivestockService,
    private fireStoreDataService: FirestoreDataService
  ) { }

  public handleError = (err: Response) => {
    if (err.status === 500 || err.status === 503) {
      this.toast.open(
        'Service is temporarily unavailable, please try again later',
        'OK',
        { duration: 3000 }
      );
    }
    return observableThrowError(err.json());
  }

  getAllCommodities(commodity_parent: string) {
    let apiType = "dimensions-api";
    let sheetType = "household_poultry";
    let filterArr: string[] = [commodity_parent];
    const country = this.location.getCountry();
    let boolStatus = true;
    return this.fireStoreDataService.getDocument(apiType, sheetType, filterArr, country, '', boolStatus);
  }

  getCommodity(strCommodity) {
    let apiType = "key-messages-api";
    let sheetType = "household_poultry_final";
    let filterArr: string[] = [strCommodity];
    const country = this.location.getCountry();
    return this.fireStoreDataService.getDocument(apiType, sheetType, filterArr, country);
  }
}
