import { LayoutService } from '../shared/service/layout.service';
import { Subscription } from 'rxjs';
import { Component, OnInit, OnDestroy } from '@angular/core';

@Component({
  selector: 'app-family-practices',
  templateUrl: 'family-practices.html'
})

export class FamilyPracticesComponent implements OnInit, OnDestroy {
  p: Subscription;
  rowH: string;
  cols = 1;
  isLoading = true;

  constructor(
    private layout: LayoutService
  ) { }

  ngOnInit() {
    this.isLoading = false;
    this.p = this.layout.layout$.subscribe(res => {
      this.cols = res.cols;
      this.rowH = res.rowH;
    });
  }

  ngOnDestroy() {
    this.p.unsubscribe();
  }
}
