import { CitrusCategoriesComponent } from './citrus-categories/citrus-categories.component';
import { CitrusComponent } from './citrus.component';
import { CitrusSubjectsComponent } from './citrus-subjects/citrus-subjects.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../shared/guards/auth.guard';

const Citrus: Routes = [
    {
        path: 'citrus-production',
        component: CitrusComponent,
        canActivate: [AuthGuard],
        children: [
            { path: '', redirectTo: 'categories', pathMatch: 'full' },
            { path: 'categories', component: CitrusCategoriesComponent, canActivate: [AuthGuard] },
            { path: 'categories/:commodity_parent', component: CitrusCategoriesComponent, canActivate: [AuthGuard] },
            { path: 'subject/:commodity_parent/:commodity_name', component: CitrusSubjectsComponent, canActivate: [AuthGuard] }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(Citrus)],
    exports: [RouterModule],
})
export class CitrusRoutingModule { }
