export class Question {
    public uid: string;
    public photoUrl: string;
    public firstName: string;
    public lastName: string;
    public questionCreatedDate: any;
    public questionTitle: string;
    public questionDetails: string;
    public questionImageUrl: string;
    public questionTag: string;
    public publish = true;
    // public Question() { };

    // QuestinJOSN = function (): any {
    //     let qjsion: Array<any>;
    //     qjsion.push({ "firstName": this.questionTitle })
    //     qjsion.push({ "lastName": this.questionTitle })

    //     return qjsion;
    // }
}
