import { LivestockService, VaccinationData, DeseaseData } from './../../shared/service/livestock.service';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import Utils from "./../../shared/utils/utils";
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { environment } from '../../../environments/environment';
import { NgxSpinnerService } from "ngx-spinner";
@Component({
  selector: 'app-disease-details',
  templateUrl: './disease-details.component.html',
  styleUrls: ['./disease-details.component.scss']
})

export class DeseaseDetailsComponent implements OnInit, OnDestroy {
  public details: any = [];
  public livestockSpeAndVacList = {
    species: '',
    vaccination: ''
  };
  public loader: boolean = false;
  lang_code: any;
  appTheme: any;
  sub: any;
  constructor(
    private location: Location,
    private route: ActivatedRoute,
    private livesctock: LivestockService,
    public gaService: GoogleAnalyticsService,
    private spinner: NgxSpinnerService
  ) {
    this.spinner.show();
  }

  public back() {
    this.location.back();
  }

  ngOnInit() {
    const themes = JSON.parse(localStorage.getItem('appThemes'));
    this.lang_code = JSON.parse(localStorage.getItem(environment.userLanguageStorageKey)).code;
    this.route.params.subscribe(res => {
      this.appTheme = themes.filter(_ => _.id === res.themeId)[0];
      this.sub = this.livesctock.getDimensionDocument(res.id).subscribe((data: any) => {
        this.spinner.hide();
        this.details = data;
        this.livestockSpeAndVacList = data;
        this.loader = true;
      });
    });
  }

  setMyStyles() {
    return Utils.reverseContent();
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  onImgError(event) {
    event.target.src = Utils.offlineImage("");
  }

  getDefImage(event) {
    event.target.src = Utils.defaultImage();
  }
  tabChange(i) {
    this.gaService.event('Disease' + this.details.disease, 'Tab_Change', i.tab.textLabel);
  }
}
