import { throwError as observableThrowError, Observable } from 'rxjs';
import { retry, first, tap, catchError, map } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as isOnline from 'is-online';
import { GeoLocationService } from './location';
import { Parametre } from './parametre';
const PARAMETRE = Parametre.getInstance();
import { environment } from '../../../environments/environment';
import { FirestoreDataService } from "./firestore-data.service";

@Injectable()
export class AgrimarketService {
  res: any;

  constructor(
    public location: GeoLocationService,
    private http: HttpClient,
    public toast: MatSnackBar,
    private fireStoreDataService: FirestoreDataService
  ) { }

  getMarketForCountry(id) {
    return this.fireStoreDataService.getDimensionsDocument('dimensions', 'tab_id', id, 'order');
  }

  getMarketForCountryBQ() {
    const country = this.location.getCountry();
    return this.http.post(environment.bigQueryApi, {
      "query": `SELECT * FROM \`fao-maps.${environment.bigTableName}.vw_markets\` where iso3 ='${country.country}'`,
      "output_format": "json"
    });
  }

  getCommoditiesForCountry(id) {
    return this.fireStoreDataService.getDimensionsDocument('dimensions', 'tab_id', id, 'order');
  }

  getCommoditiesForCountryBQ() {
    const country = this.location.getCountry();

    //TODO _ query hardcoded for sri lanka (LKA)
    const query = country.country.toLowerCase() === 'lka'
      ? `SELECT * FROM \`fao-maps.${environment.bigTableName}.vw_markets\` where iso3 = '${country.country}'`//query without order by dates
      : `select commodity_name, max(referenceDate) as referenceDate from \`fao-maps.${environment.bigTableName}.vw_dsp_agrimarket\` where iso3 ='${country.country}' group by commodity_name order by referenceDate DESC`; //query WITH order by dates

    return this.http.post(environment.bigQueryApi, {
      "query": query,
      "output_format": "json"
    });
  }

  getCommoditiesDetails(value) {
    return this.fireStoreDataService.getDimensionsDocument('dimensions', 'commodity_id', value);
  }

  getDetailsForMarket(value) {
    return this.fireStoreDataService.getDimensionsDocument('dimensions', 'market_id', value);
  }
  getCommoditiesDetailsForBBQ(commodityName) {
    let apiType = "agrimarket-api";
    let sheetType = "commodity_final";
    let filterArr: string[] = [commodityName];
    const country = this.location.getCountry();
    return this.fireStoreDataService.getDocument(apiType, sheetType, filterArr, country, 'commodity_name');
  }
  getAdvices(id, theme_id) {
    return this.fireStoreDataService.getAdvices(id, theme_id);
  }
}
