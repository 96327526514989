import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, Subscription } from 'rxjs';
import { AgrimarketService } from '../shared/service/agrimarket.service';
import { AuthService } from '../shared/service/auth.service';
import { FirestoreDataService } from '../shared/service/firestore-data.service';
import { ForumService } from '../shared/service/forum.service';
import { FpmaService } from '../shared/service/fpma.service';
import { LivestockService } from '../shared/service/livestock.service';
import { GeoLocationService } from '../shared/service/location';
import { SectionService } from '../shared/service/section.service';
import { SubForumService } from '../shared/service/subforum.service';
import { UserService } from '../shared/service/user.service';
import { EnutrifoodService } from '../shared/service/nutrifood.service';
import { CovidService } from '../shared/service/covid.service';

@Injectable({
  providedIn: 'root'
})
export class OfflineSupportService implements OnDestroy {

  subscriptions: Subscription[] = [];
  loader = new BehaviorSubject(false);
  downloaded = new BehaviorSubject(false);
  counter = 0;
  ResponseArray = new BehaviorSubject([]);
  receivedForumPosts: boolean;
  EgySpecialThems = {
    'citrus-production': 'citrus',
    'date-production': 'date_production',
    'healthy-nutrition': 'healthy_nutrition',
    'household-poultry': 'household_poultry',
    'covid19_vegetables_fruits': 'covid19_vegetables_fruits'
  };
  EgySpecialThemsKM = {
    'citrus-production': 'citrus_final',
    'date-production': 'date_production_final',
    'healthy-nutrition': 'healthy_nutrition_final',
    'household-poultry': 'household_poultry_final',
    'covid19_vegetables_fruits': 'covid19_vegetables_fruits_final'
  };
  constructor(private _livestochService: LivestockService,
    public _agrimarketService: AgrimarketService,
    public _geoLocationService: GeoLocationService,
    private _fireStoreDataService: FirestoreDataService,
    public _userService: UserService,
    public _fpmaService: FpmaService,
    public _forumService: ForumService,
    private _subForumService: SubForumService,
    private _sectionService: SectionService,
    public _authService: AuthService,
    private enutrifoodService: EnutrifoodService,
    private covidService: CovidService) { }

  setLoader(value) {
    this.loader.next(value);
  }

  setDownloaded(value) {
    this.downloaded.next(value);
  }

  pushToResponseArray(value) {
    this.ResponseArray.next([...this.ResponseArray.getValue(), value]);
    this.disableTheLoader();
  }

  disableTheLoader() {
    if (this.ResponseArray.value.length == this.counter) {
      setTimeout(() => {
        this.setLoader(false);
        this.setDownloaded(true);
      }, 3000);
    }
    if (this.ResponseArray.value.length == 1) {
      setTimeout(() => {
        this.setLoader(false);
        this.setDownloaded(true);
      }, 180000);
    }
  }

  isItemFound(value, receivedForumPosts?): boolean {
    let itemFound = 0;
    let responseArray = this.ResponseArray.value;
    responseArray.forEach(element => {
      if (receivedForumPosts)
        this.receivedForumPosts = true;
      try {
        if (JSON.stringify(element) === JSON.stringify(value))
          itemFound += 1;
      } catch (e) {
        if (!this.receivedForumPosts)
          this.counter -= 1;
        return true;
      }
    });
    if (itemFound > 0)
      return true;
    else
      return false;
  }

  //Livestock Data
  getLivestockData(theme) {
    this.counter += 1;
    this.subscriptions.push(this._livestochService.getAllDimensions(theme.id, 'theme_id', 'order').subscribe(res => {
      if (res && res.length > 0) {
        if (!this.isItemFound(res)) {
          this.pushToResponseArray(res);
          this.getSingleSpecieData(res);
        }
      } else {
        this.counter -= 1;
        this.disableTheLoader();
      }
    }));
  }

  getSingleSpecieData(species) {
    let observables: Observable<any>[] = [];
    observables.push(this._livestochService.getMessageGroup());
    observables.push(this._livestochService.getProviders());
    species.forEach(element => {
      observables.push(this._livestochService.getDimensionDocument(element.id));
      observables.push(this._livestochService.getMessageForSpecie('dimension_id', element.id));
    });
    this.getSubscribe(observables);
  }
  // Livestock data ends

  // Agrimarket data
  // getAgrimarketData(data) {
  //   this.counter += 1;
  //   let apiPath = data.tabs[0].api_path;
  //   // this.subscriptions.push(this._userService.getApplicationList().subscribe((data) => {
  //   //   data['applications'].forEach(element => {
  //   //     if (element.application_id == 'agrimarket') {
  //   //       apiPath = element.api_path;
  //   //     }
  //   //   });

  //   if (apiPath != '') {
  //     if (apiPath == 'BIG_QUERY') {
  //       this.getCommoditiesForCountryBQ();
  //       this.getMarketsBQ();
  //     } else
  //       this.getFaoCountryList(apiPath);
  //   } else {
  //     this.getCommodities();
  //     this.getMarkets();
  //   }
  //   this.counter -= 1;
  //   this.disableTheLoader();
  //   // }));
  // }

  // getMarketsBQ() {
  //   this.counter += 1;
  //   this.subscriptions.push(this._agrimarketService.getMarketForCountryBQ().subscribe(res => {
  //     if (res) {
  //       if (!this.isItemFound(res)) {
  //         this.getMarkets(true);
  //         this.pushToResponseArray(res);
  //       }
  //     } else {
  //       this.counter -= 1;
  //       this.disableTheLoader();
  //     }
  //   }));
  // }

  // getCommoditiesForCountryBQ() {
  //   this.counter += 1;
  //   this.subscriptions.push(this._agrimarketService.getCommoditiesForCountryBQ().subscribe(res => {
  //     if (res) {
  //       if (!this.isItemFound(res)) {
  //         this.getCommodities(true);
  //         this.pushToResponseArray(res);
  //       }
  //     } else {
  //       this.counter -= 1;
  //       this.disableTheLoader();
  //     }
  //   }));
  // }

  // getFaoCountryList(apiPath) {
  //   this.counter += 1;
  //   this.subscriptions.push(this._fpmaService.getFaoCountryList(apiPath).subscribe(res => {
  //     if (res) {
  //       if (!this.isItemFound(res)) {
  //         this.getMarketDetails(res);
  //         this.pushToResponseArray(res);
  //       }
  //     } else {
  //       this.counter -= 1;
  //       this.disableTheLoader();
  //     }
  //   }));
  // }
  // getCommodities(isBigQuery?) {
  //   this.counter += 1;
  //   this.subscriptions.push(this._agrimarketService.getCommoditiesForCountry('commodities').subscribe(res => {
  //     if (res && res.length > 0) {
  //       if (!this.isItemFound(res)) {
  //         this.getCommodityDetails(res);
  //         this.pushToResponseArray(res);
  //       }
  //     } else {
  //       this.counter -= 1;
  //       this.disableTheLoader();
  //     }
  //   }));
  // }

  // getMarkets(isBigQuery?) {
  //   this.counter += 1;
  //   this.subscriptions.push(this._agrimarketService.getMarketForCountry('markets').subscribe(res => {
  //     if (res && res.length > 0) {
  //       if (!this.isItemFound(res)) {
  //         this.getMarketDetails(res);
  //         this.pushToResponseArray(res);
  //         // if (isBigQuery)
  //         //   this.getMarketDetails(res, true);
  //         // else
  //       }
  //     } else {
  //       this.counter -= 1;
  //       this.disableTheLoader();
  //     }
  //   }));
  // }

  // getCommodityDetails(commodities, isBigQuery?) {
  //   let observables: Observable<any>[] = [];
  //   const country = this._geoLocationService.getCountry();
  //   commodities.forEach(element => {
  //     observables.push(this._agrimarketService.getCommoditiesDetails(element.commodity_name));
  //     // if (isBigQuery)
  //     //   observables.push(this._fpmaService.getDetailsForCommoditytBQ(element, country));
  //   });
  //   this.getSubscribe(observables);
  // }

  // getMarketDetails(markets, isBigQuery?) {
  //   let observables: Observable<any>[] = [];
  //   const country = this._geoLocationService.getCountry();
  //   markets.forEach(element => {
  //     observables.push(this._agrimarketService.getDetailsForMarket(element.market_name));
  //     // if (isBigQuery)
  //     //   observables.push(this._fpmaService.getDetailsForMarketBQ(element, country));
  //   });
  //   this.getSubscribe(observables);
  // }
  // Agrimarket data ends

  // e-nutifood data
  getENutrifoodData(theme) {

    this.getAllCommodities(theme);
  }

  getAllCommodities(theme) {
    this.counter += 1;
    this.subscriptions.push(this._livestochService.getAllDimensions(theme.id, 'theme_id', 'order').subscribe((res: any[]) => {
      if (res && res.length > 0) {
        if (!this.isItemFound(res)) {
          this.getENutriDetails(res);
          this.pushToResponseArray(res);
        }
      } else {
        this.counter -= 1;
        this.disableTheLoader();
      }
    }));
  }

  getENutriDetails(commodities) {
    let observables: Observable<any>[] = [];
    observables.push(this._livestochService.getMessageGroup());
    observables.push(this._livestochService.getProviders());
    commodities.forEach(element => {
      // if (element.has_child == 'no') {
      //   let mainFilterArr: string[] = [element.commodity_parent];
      //   let subFilterArr: string[] = [element.commodity_name];
      observables.push(this._livestochService.getDimensionDocument(element.id));
      observables.push(this._livestochService.getMessageForSpecie('dimension_id', element.id));
      //   observables.push(this._fireStoreDataService.getAllCommodities("key-messages-api", "enutrifood_final", subFilterArr, country, '', true));
      // } else {
      //  this.getAllCommodities(element.commodity_name);
      // }
    });
    this.getSubscribe(observables);
  }
  // e-nutifood data ends

  // forum data
  getForumData() {
    this.counter += 1;
    this.subscriptions.push(
      this._forumService.getPosts(0, 0).subscribe(actionArray => {
        if (actionArray && !this.receivedForumPosts) {
          let temp = { 'forum_data': 'received' };
          if (!this.isItemFound(temp, true)) {
            let arrDocs = actionArray.map(item => {
              const docid = item.payload.doc['id'];
              let _post = item.payload.doc.data() as Post;
              _post.docid = docid;
              if (!_post.uphotoUrl) {
                _post.uphotoUrl = '../../assets/forum/userprofile.svg';
              }
              return _post;
            });

            let observables: Observable<any>[] = [];
            arrDocs.forEach(items => {
              observables.push(this._forumService.getUserDetails(items.uid));
            });
            this.getSubscribe(observables);
            this.pushToResponseArray(temp);
          }
          else {
            this.counter -= 1;
            this.disableTheLoader();
          }
        } else {
          this.counter -= 1;
          this.disableTheLoader();
        }
      })
    );

    this.counter += 1;
    this.subscriptions.push(
      this._authService.afAuth.authState.subscribe(user => {
        if (user) {
          let temp = { 'forum_auth': 'received' };
          if (!this.isItemFound(temp)) {
            this.pushToResponseArray(temp);
            this.IsAdmin(user.uid);
          } else {
            this.counter -= 1;
            this.disableTheLoader();
          }
        } else {
          this.counter -= 1;
          this.disableTheLoader();
        }
      })
    );

    // let observables: Observable<any>[] = [];
    // observables.push(this._subForumService.getSubForums());
    // observables.push(this._sectionService.getSections());
    // this.getSubscribe(observables);
    this.getSubForums();
    this.getSections();
  }

  getSubForums() {
    this.counter += 1;
    this._subForumService.getSubForums().subscribe(res => {
      if (res && res.length > 0) {
        let temp = { 'forum_sub_forms': 'received' };
        if (!this.isItemFound(temp)) {
          this.pushToResponseArray(temp);
        }
      } else {
        this.counter -= 1;
        this.disableTheLoader();
      }
    })
  }

  getSections() {
    this.counter += 1;
    this._sectionService.getSections().subscribe(res => {
      if (res) {
        let temp = { 'forum_sections': 'received' };
        if (!this.isItemFound(temp)) {
          this.pushToResponseArray(temp);
        }
      } else {
        this.counter -= 1;
        this.disableTheLoader();
      }
    })
  }

  IsAdmin(UserId) {
    this.counter += 1;
    this.subscriptions.push(this._forumService.getUserDetails(UserId).subscribe((res: any[]) => {
      if (res) {
        let temp = { 'forum_isAdmin': 'received' };
        if (!this.isItemFound(temp)) {
          this.pushToResponseArray(temp);
        } else {
          this.counter -= 1;
          this.disableTheLoader();
        }
      } else {
        this.counter -= 1;
        this.disableTheLoader();
      }
    }));
  }
  // forum data ends


  //animal feed starts
  getAnimalFeedData(theme) {
    this.counter += 1;
    this.subscriptions.push(this._livestochService.getAllDimensions(theme.id, 'theme_id', 'order').subscribe(res => {
      if (res && res.length > 0) {
        if (!this.isItemFound(res)) {
          this.pushToResponseArray(res);
          this.getSingleAnimalData(res, theme);
        }
      } else {
        this.counter -= 1;
        this.disableTheLoader();
      }
    }));
  }

  getSingleAnimalData(species, theme) {
    let observables: Observable<any>[] = [];
    let lang_code = JSON.parse(localStorage.getItem('SAIDA_language')).code;
    observables.push(this._livestochService.getMessageGroup());
    observables.push(this._livestochService.getProviders());
    observables.push(this._livestochService.getCalculatorGuide(theme.id, lang_code));
    species.forEach(element => {
      observables.push(this._livestochService.getDimensionDocument(element.id));
      // observables.push(this._livestochService.getFeedAnimals(element.id));
      this.getSingleAnimalType(element);
      observables.push(this._livestochService.getFeedDatabase(element.id));
      observables.push(this._livestochService.getMessageForSpecie('dimension_id', element.id));
    });
    this.getSubscribe(observables);
  }
  getSingleAnimalType(element) {
    this.subscriptions.push(this._livestochService.getFeedAnimals(element.id).subscribe(res => {
      if (res && res.length > 0) {
        if (!this.isItemFound(res)) {
          this.pushToResponseArray(res);
          this.getSingleAnimalWeight(element, res);
        }
      }
    }));
  }
  getSingleAnimalWeight(species, animal) {
    let observables: Observable<any>[] = [];
    animal.forEach(element => {
      observables.push(this._livestochService.getFeedAnimalsWeight(species.id, element.id));
    });
    this.getSubscribe(observables);
  }
  //animal feed end


  //POI starts
  getPOIData(theme) {
    this.counter += 1;
    this._geoLocationService.localise();
    this.subscriptions.push(this._livestochService.getAllDimensions(theme.id, 'theme_id', 'order').subscribe(res => {
      if (res && res.length > 0) {
        if (!this.isItemFound(res)) {
          this.pushToResponseArray(res);
          this.getSinglePOIData(res);
          this.getPOICategories(theme);
        }
      } else {
        this.counter -= 1;
        this.disableTheLoader();
      }
    }));
  }
  getSinglePOIData(data) {
    let observables: Observable<any>[] = [];
    this._geoLocationService.getLatestLocation();
    observables.push(this._livestochService.getMessageGroup());
    observables.push(this._livestochService.getProviders());
    data.forEach(element => {
      observables.push(this._livestochService.getDimensionDocument(element.id));
      observables.push(this._livestochService.getMessageForSpecie('dimension_id', element.id));
    });
    this.getSubscribe(observables);
  }
  getPOICategories(theme) {
    this.subscriptions.push(this.enutrifoodService.getNutrifoodCategories(theme.id).subscribe(res => {
      if (res && res.length > 0) {
        if (!this.isItemFound(res)) {
          this.pushToResponseArray(res);
          this.getPOIFilters(theme.id, res);
        }
      }
    }));
  }
  getPOIFilters(id, cate) {
    let observables: Observable<any>[] = [];
    cate.forEach(element => {
      observables.push(this.enutrifoodService.getNutrifoodFilter(id, element.docId));
    });
    this.getSubscribe(observables);
  }
  //POI Ends



  // EGY Special themes data
  getEGYSpecialData(theme) {
    this.getAllEGYCommodities(theme, 'root');
  }

  getAllEGYCommodities(theme, parentId) {
    // if (parentId == 'root')
    this.counter += 1;
    let filterArr: string[] = [parentId];
    const country = this._geoLocationService.getCountry();
    this.subscriptions.push(this._fireStoreDataService.getAllCommodities("dimensions-api", this.EgySpecialThems[theme.template_id], filterArr, country, '', true).take(1).subscribe((res: any[]) => {
      if (res && res.length > 0) {
        if (!this.isItemFound(res)) {
          this.getSpecialThemeDetails(res, theme);
          this.pushToResponseArray(res);
        }
      } else {
        this.counter -= 1;
        this.disableTheLoader();
      }
    }));
  }

  getSpecialThemeDetails(commodities, theme) {
    let observables: Observable<any>[] = [];
    const country = this._geoLocationService.getCountry();
    commodities.forEach(element => {
      if (element.has_child == 'no') {
        let mainFilterArr: string[] = [element.commodity_parent];
        let subFilterArr: string[] = [element.commodity_name];
        observables.push(this._fireStoreDataService.getAllCommodities("dimensions-api", this.EgySpecialThems[theme.template_id], mainFilterArr, country, '', true));
        observables.push(this._fireStoreDataService.getAllCommodities("key-messages-api", this.EgySpecialThemsKM[theme.template_id], subFilterArr, country, '', true));
      } else {
        this.getAllEGYCommodities(theme, element.commodity_name);
      }
    });
    this.getSubscribe(observables);
  }
  // EGY Special themes ends

  // Team data
  getTeamData(theme) {
    this.getTeamList(theme);
  }

  getTeamList(theme) {
    this.counter += 1;
    this.subscriptions.push(this._livestochService.getAllTeams(theme.id, 'theme_id').subscribe((res: any[]) => {
      if (res && res.length > 0) {
        if (!this.isItemFound(res)) {
          this.getSingleTeam(res);
          this.pushToResponseArray(res);
        }
      } else {
        this.counter -= 1;
        this.disableTheLoader();
      }
    }));
  }

  getSingleTeam(teamList) {
    let observables: Observable<any>[] = [];
    teamList.forEach(element => {
      observables.push(this._livestochService.getTeamDetail(element.id));
    });
    this.getSubscribe(observables);
  }
  // Team ends

  //Covid advice start
  getCovidAdviceData(theme) {
    let observables: Observable<any>[] = [];
    observables.push(this._livestochService.getMessageGroup());
    observables.push(this._livestochService.getProviders());
    observables.push(this.covidService.getCovidAdvices('theme_id', theme.id));
    this.getSubscribe(observables);
  }
  //Covid advice end

  getSubscribe(observables: Observable<any>[]) {
    this.counter += 1;
    this.subscriptions.push(combineLatest(observables).subscribe(dataArray => {
      if (!this.isItemFound(dataArray)) {
        this.pushToResponseArray(dataArray);
      }
    }));
  }
  unsubscribe() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    this.subscriptions.splice(0, this.subscriptions.length);
  }
  ngOnDestroy() {
    this.unsubscribe();
  }

}

interface Post {
  uphotoUrl: any;
  ufirstName: any;
  ulastName: any;
  pDetails: string;
  pTitle: string;
  pCreatedDate: any;
  numLikes: any;
  numComments: any;
  docid: any;
  publish: any;
  uid: any;
}
