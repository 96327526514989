import { FormsModule } from '@angular/forms';
import { FamilyPracitesRoutingModule } from './family-practices.routing';
import { SharedModule } from '../shared/shared.module';
import { FamilyPracticesComponent } from './family-practices';
import { NgModule } from '@angular/core';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { FamilyPracticesCategoriesComponent } from './family-practices-categories/family-practices-categories.component';
import { FamilyPracticesSubjectsComponent } from './family-practices-subjects/family-practices-subjects.component';
import { MatTableModule } from "@angular/material/table";
@NgModule({
    imports: [
        SharedModule,
        FormsModule,
        FamilyPracitesRoutingModule,
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        MatTableModule
    ],
    declarations: [
        FamilyPracticesComponent,
        FamilyPracticesCategoriesComponent,
        FamilyPracticesSubjectsComponent
    ],
    exports: [
        FamilyPracticesComponent,
        FamilyPracitesRoutingModule
    ]
})
export class FamilyPracitesModule { }
// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}
