
import { forkJoin as observableForkJoin, Observable, Subscription } from 'rxjs';
import { Component, OnInit, Input, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { GeoLocationService } from './../shared/service/location';
import { WeatherService } from '../shared/service/weather.service';

@Component({
  selector: 'app-weather-index',
  templateUrl: 'weather-index.html',
  styleUrls: ['weather.scss']
})
export class WeatherIndexComponent implements OnInit, OnDestroy {
  appData: any;
  constructor(
    public weather: WeatherService,
    public router: Router,
    public location: GeoLocationService,
    private currenturl: Location,
    private route: ActivatedRoute,
  ) { }

  indexInfo: { [key: string]: any } = {};
  xAxis = true;
  yAxis = true;
  showLegend = true;

  data = [];
  public loader: boolean = false;
  subscriptions: Subscription[] = [];
  refreshData(event: any) {
  }

  ngOnInit() {
    this.location.localise();
    const indexCode = this.route.snapshot.params.indexCode;
    const responseMapper = r => r.response.items || [];
    const themes = JSON.parse(localStorage.getItem('appThemes'));
    this.appData = themes.filter(_ => _.template_id === 'weathercropcalendar')[0];
    this.subscriptions.push(this.weather.getCubeInfo(indexCode).map(r => r.response).subscribe(info => {
      this.indexInfo = info;
    }));

    this.subscriptions.push(this.weather.getCubeMeasures(indexCode).map(responseMapper).subscribe(cubeMeasures => {
      const indexProps: { [key: string]: any } = {
        dimensions: {},
        dimensionTypes: {},
      };
      indexProps.measures = cubeMeasures;

      this.subscriptions.push(this.weather.getCubeDimensions(indexCode).map(responseMapper).subscribe(cubeDimensions => {
        const memberObservables = [];

        cubeDimensions.forEach(dimension => {
          indexProps.dimensionTypes[dimension.code] = dimension.type;

          memberObservables.push(this.weather.getCubeDimensionMembers(indexCode, dimension.code)
            .map(r => r.response)
            .map(members => {
              if (['DAY', 'DEKAD', 'YEAR'].includes(dimension.code)) {
                const thirdToLastMember = members[members.length - 8];
                const lastMember = members[members.length - 1];

                const rangeStart = thirdToLastMember.code.split(',')[0].replace('[', '').replace('(', '');
                const rangeEnd = lastMember.code.split(',')[1].replace(')', '').replace(']', '');

                indexProps.dimensions[dimension.code] = [{ code: dimension.code, range: `[${rangeStart},${rangeEnd})` }];
              } else {
                indexProps.dimensions[dimension.code] = [members[members.length - 1]];
              }
            }));
        });

        observableForkJoin(...memberObservables).subscribe(results => {
          this.subscriptions.push(this.location.locality$.subscribe(location => {
            if (!location.originalCoords.lat || !location.originalCoords.long) {
              return;
            }
            const coords = [location.originalCoords.lat, location.originalCoords.long];
            this.subscriptions.push(this.weather.getIndexValues(indexCode, coords, indexProps)
              .map(r => r.response)
              .subscribe(valueRes => {
                const mappedValues = valueRes.items.length ? valueRes.items.map(([date, value]) => {
                  return {
                    value,
                    name: date,
                  };
                }) : [];

                this.data = [{ name: indexCode, series: mappedValues }];
                this.loader = true;
              }));
          }));
        });
      }));
    }));
  }
  public goBack() {
    this.currenturl.back();
  }
  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe())
  }
}
