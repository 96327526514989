import { Component, Input, OnInit } from '@angular/core';
import Utils from '../../../app/shared/utils/utils';

@Component({
  selector: 'app-app-covid-advice-preview',
  templateUrl: './app-covid-advice-preview.component.html',
  styleUrls: ['./app-covid-advice-preview.component.scss']
})
export class AppCovidAdvicePreviewComponent implements OnInit {
  @Input() data: any;
  @Input() appName: any;
  public searchText: string;
  public objectKeys = Object.keys;
  advices: any = [];
  constructor() { }

  ngOnInit(): void {
    this.advices = this.data.messages.reduce((acc, message) => {
      const kindof = message.group_id;
      if (acc[kindof]) {
        acc[kindof].push(message);
        return acc;
      }
      acc[kindof] = [message];
      return acc;
    }, {});
  }
  setMyStyles() {
    return {
      'direction': 'ltr',
      'text-align': 'left'
    };
  }
  getGroupName(id) {
    return id ? id.split('_').join(' ') : '';
  }
}
