import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AgrimarketComponent } from './home/agrimarket.component';
import { SharedModule } from '../shared/shared.module';
import { AgrimarketRoutingModule } from './agrimarket.routing';
import { AgrimarketDetailsComponent } from './agrimarket-details/agrimarket-details.component';
import { MarketDetailsComponent } from './market-details/market-details.component';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { HighChartJsModule } from "./../shared/component/charts/highcharts-js-chart/highcharts-js-chart.module";
import { ChartModule } from 'angular-highcharts';
import { MatAutocompleteModule } from '@angular/material/autocomplete';

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        FormsModule,
        ReactiveFormsModule,
        MatAutocompleteModule,
        HighChartJsModule,
        ChartModule,
        AgrimarketRoutingModule,
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        })
    ],
    declarations: [
        AgrimarketComponent,
        AgrimarketDetailsComponent,
        MarketDetailsComponent
    ],
    exports: [
        AgrimarketComponent,
        AgrimarketRoutingModule,
    ]
})
export class AgrimarketModule { }
// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}