import { HealthyNutritionService } from './../shared/service/healthynutrition.service';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HealthyNutritionCategoriesComponent } from './healthy-nutrition-categories/healthy-nutrition-categories.component';
import { HealthyNutritionComponent } from './healthy-nutrition.component';
import { HealthyNutritionRoutingModule } from './healthy-nutrition.routing';
import { FilterPipe } from './../shared/pipes/filter.pipe';
import { FormsModule } from '@angular/forms';
import { SharedModule } from './../shared/shared.module';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HealthyNutritionSubjectsComponent } from './healthy-nutrition-subjects/healthy-nutrition-subjects.component';
import { DatePipe } from '@angular/common';
import {MatDatepickerModule} from '@angular/material/datepicker';
@NgModule({
  declarations: [HealthyNutritionCategoriesComponent, HealthyNutritionComponent, HealthyNutritionSubjectsComponent],
  imports: [
    CommonModule,
    HealthyNutritionRoutingModule,
    FormsModule,
    SharedModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  exports: [
    HealthyNutritionRoutingModule,
    MatDatepickerModule,
  ],
  providers: [
    HealthyNutritionService,
    DatePipe
  ]
})
export class HealthyNutritionModule { }
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
