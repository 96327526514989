import { CovidAdvicesComponent } from './covid-advices/covid-advices.component';
import { CovidComponent } from './covid';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../shared/guards/auth.guard';

const Covidroutes: Routes = [
  {
    path: 'covid',
    component: CovidComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', redirectTo: 'advices', pathMatch: 'full' },
      { path: 'advices', component: CovidAdvicesComponent, canActivate: [AuthGuard] },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(Covidroutes)],
  exports: [RouterModule],
})
export class CovidRoutingModule { }
