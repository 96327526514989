import { Router } from '@angular/router';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { EnutrifoodService } from '../../shared/service/nutrifood.service';
import Utils from "../../shared/utils/utils";
import { ActivatedRoute } from '@angular/router';
import { FirestoreDataService } from "../../shared/service/firestore-data.service";
import { GeoLocationService } from '../../shared/service/location';
import { UserService } from '../../shared/service/user.service';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { LivestockService } from '../../../../src/app/shared/service/livestock.service';
import { Subscription } from 'rxjs';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-nutrifood-categories',
  templateUrl: './nutrifood-categories.component.html',
  styleUrls: ['./nutrifood-categories.component.scss']
})
export class NutrifoodCategoriesComponent implements OnInit, OnDestroy {
  public menuBarTitle = "Home.EnutrifoodTitle";
  searchText = { general_name: '' };
  public arrCategories: any = [];
  public loader: any;
  public commodity_parent_name = '';
  public displayback = 'none';
  isFilterExist: boolean = false;
  public arrApplications: any;
  filterTypeGroup: any = [];
  groupSelectedCategories = [];
  appData: any;
  template_id: string;
  subscriptions: Subscription[] = [];
  constructor(
    public enutrifoodService: EnutrifoodService,
    private router: Router,
    public route: ActivatedRoute,
    private fireStoreDataService: FirestoreDataService,
    public geoLocationService: GeoLocationService,
    public livestock: LivestockService,
    private gaService: GoogleAnalyticsService,
    private spinner: NgxSpinnerService
  ) {
    this.spinner.show();
  }

  ngOnInit() {
    let parentid: string;
    this.loader = false;
    const themes = JSON.parse(localStorage.getItem('appThemes'));
    this.template_id = location.pathname.includes('administrative') ? 'administrative' : 'enutrifood';
    this.route.params.subscribe(param => {
      if (param.id)
        parentid = param.id.split('?')[0];
      if (parentid)
        this.appData = themes.filter(_ => _.id === parentid)[0];
      else {
        this.appData = themes.filter(_ => _.template_id === this.template_id)[0];
      }
      this.menuBarTitle = this.appData.name;
      if (this.template_id == 'enutrifood')
        this.getCategoryData(this.appData.id);
      else
        this.spinner.hide();
    });
  }

  getCategoryData(parentid) {
    this.subscriptions.push(this.livestock.getAllDimensions(parentid, 'theme_id', 'order').subscribe(res => {
      this.arrCategories = res.filter(_ => _['status'] == undefined || _['status']);
      this.loader = true;
      this.spinner.hide();
    }));
  }

  nav(data) {
    this.gaService.event('E-nutrifood_Details', 'Page_Navigation', data.id)
    localStorage.setItem('commodityInfo', JSON.stringify(data));
    if (this.appData.template_id == 'enutrifood')
      this.router.navigate([`/e-nutrifood/subject`, `${data.id}`, `${this.appData.id}`]);
    else
      this.router.navigate([`/administrative/subject`, `${data.id}`, `${this.appData.id}`]);
  }
  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
