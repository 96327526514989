import { SectionService } from './../shared/service/section.service';
import { SubForumService } from './../shared/service/subforum.service';
import { SubForum } from './subforum.model';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../shared/service/user.service';
import { AuthService } from '../shared/service/auth.service';
import { ForumService } from '../shared/service/forum.service';
import { environment } from '../../environments/environment';
import Utils from '../shared/utils/utils';
import { Section } from './section.model';
import { MatDialog } from '@angular/material/dialog';
import { CaveatComponent } from './caveat/caveat.component';
import { TranslationService } from '../shared/service/translation.service';
import { GeoLocationService } from '../shared/service/location';
import { Subscription } from 'rxjs';
import { NgxSpinnerService } from "ngx-spinner";

interface Post {
  uphotoUrl: any;
  ufirstName: any;
  ulastName: any;
  pDetails: string;
  pTitle: string;
  pCreatedDate: any;
  numLikes: any;
  numComments: any;
  docid: any;
  publish: any;
}

@Component({
  selector: 'app-forum',
  templateUrl: 'forum.html',
  styleUrls: ['./forum.scss']
})

export class ForumComponent implements OnInit, OnDestroy {

  public loader: boolean = false;
  public arrApplications: any;
  searchText = '';
  public userdoc: any = [];
  public IsforumAdmin: boolean = false;
  public langCode = UserService.langCode;
  config: any;
  page = 1;
  itemsPerPage = 25;
  country: any;
  forum: any;
  subscriptions: Subscription[] = [];

  constructor(
    public location: GeoLocationService,
    private TranslateS: TranslationService,
    private router: Router,
    public userService: UserService,
    public authService: AuthService,
    public forumService: ForumService,
    private subForumService: SubForumService,
    private sectionService: SectionService, public Caveatdialog: MatDialog,
    private spinner: NgxSpinnerService
  ) {
    let user = JSON.parse(localStorage.getItem('user'));
    if (user) {
      if (!user.nickName) {
        this.router.navigate(['profile']);
      }
    }
  }
  public arrDocs: any;
  public arrDocs0: any;
  public arrExperiences: any;
  public arrTopics: any;
  public selectedSubForum = '0';
  public selectedSection = '0';

  public SubForumsList: SubForum[];
  public SectionsList: Section[];

  public IsAdmin(UserId) {
    this.subscriptions.push(this.forumService.getUserDetails(UserId).subscribe((res: any[]) => {
      if (res) {
        this.userdoc = res;
        let countryData = localStorage.getItem('countryData') ? JSON.parse(localStorage.getItem('countryData')) : {};
        if (this.userdoc.roles["dsp"].includes("admin") || (this.userdoc.roles["dsp"].indexOf("forum_admin") > -1 && this.userdoc['country_iso3'].toLowerCase() == countryData['country'].toLowerCase())) {
          this.IsforumAdmin = true;
        }
        this.loader = true;
      }
    }));
  }
  loadForumData() {
    this.spinner.show();
    this.subscriptions.push(this.forumService.getPosts(this.selectedSection, this.selectedSubForum).subscribe(actionArray => {
      this.spinner.hide();
      this.arrDocs = actionArray.map(item => {
        const docid = item.payload.doc.id;
        let _post = item.payload.doc.data() as Post;
        _post.docid = docid;
        if (!_post.uphotoUrl) {
          _post.uphotoUrl = '../../assets/forum/userprofile.svg';
        }
        return _post;

      });

      this.arrDocs0 = this.arrDocs;
      this.config = {
        itemsPerPage: this.itemsPerPage,
        currentPage: this.page,
        totalItems: this.arrDocs.length
      };
    }));

    window.scrollTo(10, 15);
  }

  Search() {
    this.searchText = this.searchText.trim();
    if (this.searchText == '') {
      this.arrDocs = this.arrDocs0;
    }
    else {
      this.arrDocs = [];
      this.arrDocs0.forEach(_post => {
        if (_post.pTitle.includes(this.searchText) || _post.pDetails.includes(this.searchText)) {
          this.arrDocs.push(_post);
        }
      });
    }
    this.config = {
      itemsPerPage: this.itemsPerPage,
      currentPage: this.page,
      totalItems: this.arrDocs.length
    };


  }
  allF: SubForum;
  allS: Section;
  SectionsListOptions: any;
  SubForumsListOptions: any;

  loadListsData() {

    this.SectionsListOptions = [];
    // this.SectionsListOptions.push({ name: ' ', value: '0' });

    this.SubForumsListOptions = [];
    // this.SubForumsListOptions.push({ name: ' ', value: '0' });

    this.subscriptions.push(this.subForumService.getSubForums().subscribe(actionArray => {
      this.SubForumsList = actionArray.map(item => {

        var sub = {
          id: item.payload.doc.id,
          ...item.payload.doc.data() as {}
        } as SubForum;
        for (let x = 0; x < sub.names.length; x++) {
          if (sub.names[x].language_code == this.langCode) {
            if (!this.SubForumsListOptions.filter(_ => _.value == sub.value).length)
              this.SubForumsListOptions.push({ name: '' + sub.names[x].value, value: '' + sub.value });
          }
        }

        return sub;



        return {
          id: item.payload.doc.id,
          ...item.payload.doc.data() as {}
        } as SubForum;
      });

      this.allF = new SubForum();
      this.allF.id = '0';
      this.allF.names = [];
      this.allF.order = 0;
      this.allF.value = '0';
      this.SubForumsList.splice(0, 0, this.allF);
    }));

    this.subscriptions.push(this.sectionService.getSections().subscribe(actionArray => {
      this.SectionsList = actionArray.map(item => {
        var sec = {
          id: item.payload.doc.id,
          ...item.payload.doc.data() as {}
        } as Section;
        for (var x = 0; x < sec.names.length; x++) {
          if (sec.names[x].language_code == this.langCode) {
            if (!this.SectionsListOptions.filter(_ => _.value == sec.value).length)
              this.SectionsListOptions.push({ name: '' + sec.names[x].value, value: '' + sec.value });
          }
        }
        return sec;
      });
      this.allS = new Section();
      this.allS.id = '0';
      this.allS.names = [];
      this.allS.order = 0;
      this.allS.value = '0';
      this.SectionsList.splice(0, 0, this.allS);
    }));

  }

  ngOnInit() {
    this.spinner.show();
    this.country = JSON.parse(localStorage.getItem("countryData"));
    this.loader = true;
    UserService.langCode = JSON.parse(localStorage.getItem(environment.userLanguageStorageKey)).code;
    this.langCode = UserService.langCode;
    localStorage.setItem('strDeepLink', '/forum');
    const themes = JSON.parse(localStorage.getItem('appThemes'));
    this.forum = themes.filter(_ => _.template_id === 'forum')[0];
    if (this.forum.initialPopupVisible) {
      var firstopen = this.getCookie('firstopen');
      if (firstopen == undefined) {
        this.setCookie('firstopen', 'true');
        this.openCaveatDialog();
      }
    }
    // });

    this.loadListsData();
    this.loadForumData();

    this.config = {
      itemsPerPage: this.itemsPerPage,
      currentPage: this.page,
      totalItems: this.itemsPerPage
    };

    this.subscriptions.push(this.authService.afAuth.authState.subscribe(async user => {
      if (user) {
        this.IsAdmin(user.uid);
      }
    }));

  }

  nav(link) {
    if (link == this.selectedSection) {
      if (link == '0') {
        link = 'question';
      }
      this.router.navigate([`forum/post`, `${link}`]);
    }
    else {
      this.router.navigate([`forum/post-details`, `${link}`]);
    }
  }
  navAddPost() {
    if (this.authService.isLoggedIn) {
      this.router.navigate([`forum/post/add`]);
    } else {
      this.router.navigate(['login/forum*post*add']);
    }
  }
  navUser(link) {
    this.router.navigate([`${link}`]);
  }

  navAdmin(link) {
    this.router.navigate([`${link}`]);
  }



  pageChanged(event) {
    this.page = event;
    this.config.currentPage = this.page;
  }

  setMyStyles() {
    return Utils.reverseContent();
  }

  setCookie(name: string, val: string) {
    const date = new Date();
    const value = val;

    // Set it expire in 7 days
    date.setTime(date.getTime() + (7 * 24 * 60 * 60 * 1000));

    // Set it
    document.cookie = name + "=" + value + "; expires=" + date.toUTCString() + "; path=/";
  }

  getCookie(name: string) {
    const value = "; " + document.cookie;
    const parts = value.split("; " + name + "=");

    if (parts.length == 2) {
      return parts.pop().split(";").shift();
    }
  }

  deleteCookie(name: string) {
    const date = new Date();

    // Set it expire in -1 days
    date.setTime(date.getTime() + (-1 * 24 * 60 * 60 * 1000));

    // Set it
    document.cookie = name + "=; expires=" + date.toUTCString() + "; path=/";
  }


  openCaveatDialog(): void {
    const dialogRef = this.Caveatdialog.open(CaveatComponent, {
      width: '300px',
      data: { title: this.TranslateS.translate("Post.CaveatTitle"), subtitle: this.TranslateS.translate('post.caveatdetails') }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result == null || result.Details == "") {
        return;
      }

    });
  }
  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
  selectForumsListOptions(filter) {
    this.spinner.show();
    if (filter.selected) {
      this.SubForumsListOptions.forEach(ele => {
        if (filter.value != ele.value)
          ele.selected = false;
      });
      this.selectedSubForum = filter.value;
    } else {
      this.selectedSubForum = '0';
    }
    this.loadForumData();
  }
  selectSectionsListOptions(filter) {
    this.spinner.show();
    if (filter.selected) {
      this.SectionsListOptions.forEach(ele => {
        if (filter.value != ele.value)
          ele.selected = false;
      });
      this.selectedSection = filter.value;
    } else {
      this.selectedSection = '0';
    }
    this.loadForumData();
  }
}

