import { LayoutService } from './../shared/service/layout.service';
import { Observable, Subscription } from 'rxjs';
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { Component, OnInit, OnDestroy } from '@angular/core';

@Component({
  selector: 'app-nutrifood',
  templateUrl: 'nutrifood.html'
})

export class NutrifoodComponent implements OnInit, OnDestroy {
  p: Subscription;
  rowH: string;
  cols = 1;
  isLoading = true;
  
  constructor(
    private layout: LayoutService
  ) {}

  ngOnInit() {
    this.isLoading = false;
    this.p = this.layout.layout$.subscribe(res => {
      this.cols = res.cols;
      this.rowH = res.rowH;
    });
  }

  ngOnDestroy() {
    this.p.unsubscribe();
  }
}
