import { ImageStorageService } from './../shared/service/img-storage.service';
import { DomSanitizer } from '@angular/platform-browser';
import { AuthService } from './../shared/service/auth.service';
import { Observable, Subscription } from 'rxjs';
import { LayoutService } from './../shared/service/layout.service';
import { MatDialog } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserService } from './../shared/service/user.service';
import { Router } from '@angular/router';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Injectable } from '@angular/core';
import { GeoLocationService } from '../shared/service/location';
import { NotificationService } from '../shared/service/notification.service';
import { environment } from '../../environments/environment';
import Utils from '../shared/utils/utils';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
@Injectable()
export class HomeComponent implements OnInit, OnDestroy {
  isXs: boolean;
  user: any;
  isLogged: Boolean;
  public phoneNumber: number;
  public arrApplications: any;
  public loader: boolean = false;
  public arrData: any;
  lgCode = '';
  countries$: Observable<any>;
  sub: any;
  appSettings: any;
  subscriptions: Subscription[] = [];
  constructor(
    public location: GeoLocationService,
    private layout: LayoutService,
    public toast: MatSnackBar, public router: Router,
    public uService: UserService,
    public notificationService: NotificationService,
    public dialog: MatDialog,
    public authService: AuthService,
    public iconRegistry: MatIconRegistry,
    public sanitizer: DomSanitizer,
    public imgService: ImageStorageService,
    private spinner: NgxSpinnerService

  ) {
    localStorage.removeItem("selWeatherTabIndex");
    localStorage.removeItem("selLivestockTabIndex");
    localStorage.removeItem("selAgmTabIndex");

    this.imgService.weatherImg$.subscribe(res => {
      iconRegistry.addSvgIcon(
        'weather',
        sanitizer.bypassSecurityTrustResourceUrl(res.icon));

    });

    this.imgService.agriImg$.subscribe(res => {
      iconRegistry.addSvgIcon(
        'agrimarket',
        sanitizer.bypassSecurityTrustResourceUrl(res.icon));

    });

    this.imgService.livestockImg$.subscribe(res => {
      iconRegistry.addSvgIcon(
        'livestock',
        sanitizer.bypassSecurityTrustResourceUrl(res.icon));

    });

    this.imgService.nutriImg$.subscribe(res => {
      iconRegistry.addSvgIcon(
        'nutrifood',
        sanitizer.bypassSecurityTrustResourceUrl(res.icon));

    });

    this.imgService.bugImg$.subscribe(res => {
      iconRegistry.addSvgIcon(
        'bug',
        sanitizer.bypassSecurityTrustResourceUrl(res.icon));

    });
  }

  ngOnInit() {
    this.spinner.show();
    let countryData = localStorage.getItem('countryData') ? JSON.parse(localStorage.getItem('countryData')) : {};
    this.subscriptions.push(this.location.countryData$.subscribe(data => {
      if (data && data.country_code_2 !== countryData.country_code_2) {
        countryData = data;
        this.sub.unsubscribe();
        this.getData();
      }
    }));
    this.getData();
  }

  private getData() {
    this.sub = this.uService.getDataUsingCountry('themes', 'order').subscribe(res => {
      this.loader = true;
      this.spinner.hide();
      this.arrApplications = res.filter(_ => _.template_id != "team");;
      this.arrData = res.filter(_ => _.template_id != "team");
      this.arrData = this.arrData.filter(_ => _['status'] == undefined || _['status']);
      if (this.uService.districtId) {
        this.arrData.forEach(element => {
          element.status = element.district_list && element.district_list.length ?
            element.district_list.includes(this.uService.districtId) ? true : false : true;
        });
        return
      }
      this.subscriptions.push(this.uService.district.subscribe(dist => {
        if (dist) {
          this.arrData.forEach(element => {
            element.status = element.district_list && element.district_list.length ?
              element.district_list.includes(dist) ? true : false : true;
          });
        }
      }));
    });
    this.subscriptions.push(this.sub);
    //End
    this.isLogged = this.uService.isLogged();
    if (this.isLogged) {
      this.user = JSON.parse(this.uService.getUser());
    }
    this.isXs = this.layout.media.isActive('xs');
    this.notificationService.registerNotification("home", 'fao_user');
    this.subscriptions.push(this.location.getCountryLanguages(Utils.modifyCountryString(localStorage.getItem('gpsCountry'))).subscribe(res => {
      this.appSettings = res;
    }));
  }

  async login(phone) {
    const encodedPhone = encodeURIComponent(phone);
  }

  logout() {
    this.authService.SignOut();
  }

  reset() {
    localStorage.clear();
    location.reload();
  }
  changeCountryCode(event) {

  }

  setMyStyles() {
    return Utils.reverseContent();
  }

  onImgError(event, img) {
    event.target.src = Utils.offlineImage(img);
  }
  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
