import { NutrifoodSubjectsComponent } from './nutrifood-subjects/nutrifood-subjects.component';
import { NutrifoodCategoriesComponent } from './nutrifood-categories/nutrifood-categories.component';
import { NutrifoodComponent } from './nutrifood';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../shared/guards/auth.guard';

const Nutriroutes: Routes = [
  { path: 'enutrifood', redirectTo: 'e-nutrifood' },
  {
    path: 'e-nutrifood',
    component: NutrifoodComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', redirectTo: 'categories', pathMatch: 'full' },
      { path: 'categories', component: NutrifoodCategoriesComponent, canActivate: [AuthGuard] },
      { path: 'categories/:id', component: NutrifoodCategoriesComponent, canActivate: [AuthGuard] },
      { path: 'subject/:commodity_name/:id', component: NutrifoodSubjectsComponent, canActivate: [AuthGuard] }
    ]
  },

  { path: 'administrative', redirectTo: 'administrative' },
  {
    path: 'administrative',
    component: NutrifoodComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', redirectTo: 'categories', pathMatch: 'full' },
      { path: 'categories', component: NutrifoodCategoriesComponent, canActivate: [AuthGuard] },
      { path: 'categories/:id', component: NutrifoodCategoriesComponent, canActivate: [AuthGuard] },
      { path: 'subject/:commodity_name/:id', component: NutrifoodSubjectsComponent, canActivate: [AuthGuard] }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(Nutriroutes)],
  exports: [RouterModule],
})
export class NutrifoodRoutingModule { }
