import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from '../../../shared/service/user.service';
import { AuthService } from '../../../shared/service/auth.service';
import { ForumService } from '../../../shared/service/forum.service';
import { MatListModule } from '@angular/material/list';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { Parametre } from '../../../shared/service/parametre';
import { GeoLocationService } from '../../../shared/service/location';
import Utils from '../../../shared/utils/utils';
import { Subscription } from 'rxjs';
interface ForumSettings {
  directPublsh: any;
}

@Component({
  selector: 'app-forumsettings',
  templateUrl: './forumsettings.component.html',
  styleUrls: ['./forumsettings.component.css']
})
export class ForumsettingsComponent implements OnInit, OnDestroy {
  public loader = false;
  public forumSettingsDoc: any = [];
  public userdoc: any = [];
  public IsforumAdmin: boolean = false;
  forum: any;
  subscriptions: Subscription[] = [];
  constructor(
    private router: Router,
    public forumService: ForumService,
    public authService: AuthService,
    public route: ActivatedRoute,
    private location: Location,
    private geoLocation: GeoLocationService
  ) { }

  ngOnInit() {
    const themes = JSON.parse(localStorage.getItem('appThemes'));
    this.forum = themes.filter(_ => _.template_id === 'forum')[0];
    if (!this.authService.isLoggedIn) {
      this.router.navigate(['login/forum*admin']);
    }

    this.subscriptions.push(this.authService.afAuth.authState.subscribe(async user => {
      if (user) {
        this.IsforumAdmin = this.IsAdmin(user.uid);
      }
    }));


    this.subscriptions.push(this.forumService.getForumSettings().subscribe((res) => {
      if (res)
        res.directPublish = res.directPublish ? res.directPublish : false
      else {
        res = { directPublish: false };
        this.forumService.addForumSettings('ForumSettings', 'settings', res);
      }
      this.forumSettingsDoc = res as ForumSettings;
    }));
  }


  public IsAdmin(UserId) {
    this.subscriptions.push(this.forumService.getUserDetails(UserId).subscribe((res: any[]) => {
      if (res) {
        this.userdoc = res;
        const country = this.geoLocation.getCountry();
        if (this.userdoc.roles["dsp"].indexOf("admin") > -1) {
          this.loader = true;
        } else if (!(this.userdoc.roles["dsp"].includes("admin") || this.userdoc.roles["dsp"].includes("forum_admin")) || this.userdoc['country_iso3'].toLowerCase() != country.country.toLowerCase()) {
          this.router.navigate([`forum`]);
        }
        this.loader = true;
      }
    }));
    return true;
  }

  update() {
    this.forumService.updateForumSettings(this.forumSettingsDoc);
  }

  back() {
    this.location.back();
  }
  setMyStyles() {
    return Utils.reverseContent();
  }
  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}

