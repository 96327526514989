 import { FilterPipe } from './../shared/pipes/filter.pipe';
import { FormsModule } from '@angular/forms';
import { CovidRoutingModule } from './covid.routing';
import { SharedModule } from './../shared/shared.module';
import { CovidComponent } from './covid';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {CapitalizePipe}  from '../shared/pipes/capitalize.pipe';
import { CovidAdvicesComponent } from './covid-advices/covid-advices.component'

@NgModule({
  imports: [
    SharedModule,
    FormsModule,
    CovidRoutingModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      }
  })

  ],
  declarations: [
    CovidComponent,
    CovidAdvicesComponent,
  ],
  exports: [
    CovidComponent,
    CovidRoutingModule
  ]
})
export class CovidModule { }

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
