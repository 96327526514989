import { Component, OnInit } from '@angular/core';
import { AuthService } from "./../shared/service/auth.service";
import { GeoLocationService } from './../shared/service/location';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-email-login',
  templateUrl: './email-login.component.html',
  styleUrls: ['./email-login.component.scss']
})

export class EmailLoginComponent implements OnInit {
  public invokeURL: string = "";
  constructor(
    public route: ActivatedRoute,
    public authService: AuthService,
    public router: Router,
    public location: GeoLocationService
  ) { }

  ngOnInit(): void {
    if (this.authService.isLoggedIn) {
      this.router.navigate(['forum']);
    }
    this.route.params.subscribe(param => {
      //  alert(param.id);
      if (param.invokeURL != null) {
        this.invokeURL = param.invokeURL;
        this.invokeURL = this.invokeURL.replace(/\*/g, "/")
        // alert(this.invokeURL);
      }


    });
  }
}
