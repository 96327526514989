import { Observable } from 'rxjs';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { LivestockService } from '../shared/service/livestock.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'plant-pests',
  templateUrl: './plant-pests.component.html',
  styleUrls: ['./plant-pests.component.css'],
})

export class PlantPestsComponent implements OnInit, OnDestroy {
  public data: any;
  appData: any;
  subscriptions: Subscription[] = [];
  constructor(private service: LivestockService,
    public gaService: GoogleAnalyticsService) { }
  ngOnInit() {
    const themes = JSON.parse(localStorage.getItem('appThemes'));
    this.appData = themes.filter(_ => _.template_id === 'plantPests')[0];
    this.subscriptions.push(this.service.getDimensionsDocument('theme_id', this.appData.id).subscribe((data) => {
      this.data = data.length ? data[0].general : '';
    }));
  }
  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
