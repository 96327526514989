import { Observable, BehaviorSubject, forkJoin, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpClientModule } from '@angular/common/http';
import { retry, first, tap, catchError, map } from 'rxjs/operators';
import { Parametre } from './parametre';
import Utils from '../utils/utils';
import { environment } from '../../../environments/environment';
const PARAMETRE = Parametre.getInstance();

@Injectable()
export class FpmaService {
  urlList: any = [];

  constructor(private http: HttpClient) { }

  getFaoCountryList(apiPath) {
    if (apiPath != '') {
      return this.http.get(apiPath);
    }

    //return this.http.get(PARAMETRE.fpmaAPI);
  }

  getMarketPrices(obj) {
    return this.http.get(obj.link);
  }

  getAllMarketData(marketData, country, api?) {
    country.country_name = Utils.getCountryData(country.country, 'name');
    this.urlList = [];
    //Use forkjoin to call multiple api
    marketData.forEach(element => {
      let strUrl = api ? api.replace('{uuid}', element.uuid) : environment.fpmaPriceSeries.replace('{uuid}', element.uuid);
      this.urlList.push(this.http.get(strUrl).pipe(catchError(error => of(error))))
    });
    return forkJoin(this.urlList);
  }

  getDetailsForCommoditytBQ(commoditydata, country) {
    return this.http.post(environment.bigQueryApi,
      {
        "query": `SELECT * FROM \`fao-maps.${environment.bigTableName}.vw_prices\` where iso3 ='${country.country}' and commodity_name ='${commoditydata.commodity_name}'`
      });
  }

  getDetailsForMarketBQ(marketdata, country) {
    return this.http.post(environment.bigQueryApi,
      {
        "query": `SELECT * FROM \`fao-maps.${environment.bigTableName}.vw_prices\` where iso3 ='${country.country}' and market_name ='${marketdata.market_name}'`,
      });
  }
  getfpmaMarket(id, api?) {
    return this.http.get((api ? api : environment.fpmaMarketDetails) + id);
  }
}
