import { share, distinctUntilChanged } from 'rxjs/operators';
import { Observable, BehaviorSubject } from 'rxjs';
import { MediaObserver } from '@angular/flex-layout';
import { Injectable } from '@angular/core';
import * as $ from 'jquery';


export interface Layout {
  cols: number;
  rowH: string;
  mode: string;
  isMobileView: boolean;
}

const INITIAL_STATE: Layout = {
  cols: 1,
  rowH: '3:2',
  mode: 'xs',
  isMobileView: false
};
@Injectable()
export class LayoutService {
  cols = 1;
  rowH = '3:2';
  mode = 'xs'
  isMobileView = false;
  chartWidth = 220;
  chartHeight = 200;
  private layoutSubject: BehaviorSubject<Layout> = new BehaviorSubject(INITIAL_STATE);
  public readonly layout$: Observable<Layout> = this.layoutSubject.asObservable().pipe(
    share());
  constructor(public media: MediaObserver) {
    this.refresh();
  }

  refresh() {
    var ref = this;
    this.media.media$.subscribe((v) => {
      var isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
      if (isMobile) {
        isMobile = true;
      } else {
        isMobile = false;
      }
      //ref.setMatTabsOnMobile(isMobile);
      this.isMobileView = isMobile;
      if (ref.media.isActive('xs')) {
        ref.layoutSubject.next({ cols: 1, rowH: '3:2', mode: 'xs', isMobileView: isMobile });
      } else if (ref.media.isActive('sm')) {
        ref.layoutSubject.next({ cols: 2, rowH: '2:2', mode: 'sm', isMobileView: isMobile });
        ref.cols = 2;
        ref.rowH = '2:2';
        ref.mode = 'sm';
        ref.chartWidth = 360;
        ref.chartHeight = 280;
      } else if (ref.media.isActive('md')) {
        ref.layoutSubject.next({ cols: 3, rowH: '4:3', mode: 'md', isMobileView: isMobile });
        ref.cols = 3;
        ref.rowH = '4:3';
        ref.mode = 'md';
        ref.chartWidth = 280;
        ref.chartHeight = 600;
      } else if (ref.media.isActive('lg')) {
        ref.layoutSubject.next({ cols: 4, rowH: '4:3', mode: 'lg', isMobileView: isMobile });
        ref.cols = 4;
        ref.rowH = '4:3';
        ref.chartWidth = 280;
        ref.chartHeight = 800;
      } else if (ref.media.isActive('xl')) {
        ref.layoutSubject.next({ cols: 5, rowH: '4:3', mode: 'xl', isMobileView: isMobile });
        ref.cols = 5;
        ref.rowH = '4:3';
        ref.chartWidth = 280;
        ref.chartHeight = 1200;
      } else {
        console.error('[LAYOUT-SERVICE] This browser might not support flexbox..TODO: Put a bootstrap wrapper');
      }
    })
  }

  setMatTabsOnMobile(isMobile: boolean) {
    if (isMobile) {
      $('.mat-tab-header-pagination').removeClass('displayFlex').addClass('displayNone');
      $('.mat-tab-header').removeClass('overFlowHidden').addClass('overFlowScroll');
      $('.mat-tab-header::-webkit-scrollbar').removeClass('displayFlex').addClass('displayNone');
      $('.mat-tab-label-container').removeClass('overFlowHidden').addClass('overFlowInitial');
    }
    else {
      $('.mat-tab-header-pagination').removeClass('displayNone').addClass('displayFlex');
      $('.mat-tab-header').removeClass('overFlowScroll').addClass('overFlowHidden');
      $('.mat-tab-header::-webkit-scrollbar').removeClass('displayNone').addClass('displayFlex');
      $('.mat-tab-label-container').removeClass('overFlowInitial').addClass('overFlowHidden');
    }
  }
}
