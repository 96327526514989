import { Component, Input, OnChanges, OnInit } from '@angular/core';
import Utils from '../../shared/utils/utils';

@Component({
  selector: 'app-app-species-preview',
  templateUrl: './app-species-preview.component.html',
  styleUrls: ['./app-species-preview.component.scss']
})
export class AppSpeciesPreviewComponent implements OnInit, OnChanges {
  @Input() data: any;
  @Input() appName: any;
  public objectKeys = Object.keys;
  public arrTabInfo: any = [];
  constructor() { }

  ngOnInit(): void {
    if (this.data) {
      this.arrTabInfo = this.data.keyMessages ? this.data.keyMessages.reduce(this.messagesTabs, {}) : {};
    }
  }
  ngOnChanges() {
  }
  setMyStyles() {
    return {
      'direction': 'ltr',
      'text-align': 'left'
    };
  }
  messagesTabs(acc, msg) {
    if (msg.topic_id !== "") {
      if (!acc[msg.topic_id]) {
        acc[msg.topic_id] = {};
      }
      if (!acc[msg.topic_id][msg.group_id]) {
        acc[msg.topic_id][msg.group_id] = [msg];
        return acc;
      }
      acc[msg.topic_id][msg.group_id].push(msg);
    }
    return acc;
  }
  getGroupName(id) {
    return id ? id.split('_').join(' ') : '';
  }
  getDefImage(event) {
    event.target.src = Utils.defaultImage();
  }
}
