import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { Location } from '@angular/common';
import Utils from '../../utils/utils';
import { UserService } from '../../service/user.service';
import { Router, ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { TeamBottomSheetComponent } from '../team-bottom-sheet/team-bottom-sheet.component';
import { LayoutService } from '../../service/layout.service';
import { environment } from '../../../../../src/environments/environment';
import { EnutrifoodService } from '../../service/nutrifood.service';
import { GeoLocationService } from '../../service/location';
import { LivestockService } from '../../service/livestock.service';
import { NgxSpinnerService } from "ngx-spinner";
import { ImageStorageService } from '../../service/img-storage.service';

@Component({
  selector: 'app-multi-node',
  templateUrl: './multi-node.component.html',
  styleUrls: ['./multi-node.component.scss']
})

export class MultiNodeComponent implements OnInit, OnDestroy {
  searchText: any = {
    name: {}
  };
  public loader: any;
  @Input('arrCategories') arrCategories;
  @Input('displayback') displayback;
  @Output() onClickCategory = new EventEmitter();
  @Input('menuBarTitle') menuBarTitle;
  @Input('commodity_parent_name') commodity_parent_name;
  @Input('filterStatus') filterStatus;
  @Input('filterTypeGroup') filterTypeGroup;
  @Output() selectedCategory = new EventEmitter();
  @Input('arrDefaultCat') arrDefaultCat;
  @Input('themeData') themeData;
  public isMobileView: boolean = false;
  mapData: any = {};
  lang_code: any;
  appData: any;
  croods: any;
  template_id: string;
  filters: any[] = [];
  orignalArrCategories: any = [];
  subscriptions: Subscription[] = [];

  // public arrApplications: any;
  // public arrPageTitles: any;
  // public pageTitle: any;


  constructor(
    private location: Location,
    public uService: UserService,
    public router: Router,
    private bottomSheet: MatBottomSheet,
    public layoutService: LayoutService,
    public route: ActivatedRoute,
    private geoLocation: GeoLocationService,
    private livestock: LivestockService,
    private enutrifoodService: EnutrifoodService,
    private spinner: NgxSpinnerService,
    private imageStorageService: ImageStorageService
  ) {
    // layoutService.refresh();
    //remove useless GEOINFORMATION refresh
    this.detect();
    this.getUserLocation();
  }

  ngOnInit() {
    this.loader = true;
    this.lang_code = JSON.parse(localStorage.getItem(environment.userLanguageStorageKey)).code
    this.searchText.name[this.lang_code] = '';
    const themes = JSON.parse(localStorage.getItem('appThemes'));
    this.template_id = location.pathname.includes('administrative') ? 'administrative' : 'enutrifood';
    this.route.params.subscribe(param => {
      if (param.id)
        var parentid = param.id.split('?')[0];
      if (parentid)
        this.appData = themes.filter(_ => _.id === parentid)[0];
      else
        this.appData = themes.filter(_ => _.template_id === this.template_id)[0];
      if (this.appData && (this.appData.isMapEnabled || this.appData.template_id == 'administrative')) {
        this.spinner.show();
        // this.mapData.lat = this.appData.MapSettings ? this.appData.MapSettings.CentralLat : '';
        // this.mapData.long = this.appData.MapSettings ? this.appData.MapSettings.CentralLong : '';
        this.mapData.zoom = 12;
        // this.mapData.nearBy = 3;
        this.getCategoryData(parentid);
        this.getFilters(parentid);
      }
    });
    // this.searchText.filters = {};
  }

  getCategoryData(parentid) {
    this.subscriptions.push(this.livestock.getAllDimensions(parentid, 'theme_id', 'order').subscribe(res => {
      this.orignalArrCategories = res.filter(_ => _['status'] == undefined || _['status']);
      let countryData = JSON.parse(localStorage.getItem('countryData'));
      let defaultLang = countryData.languages.find(_ => _.default).code;
      this.orignalArrCategories.map(element => {
        element.name[this.lang_code] = !element.name[this.lang_code] ? element.name[defaultLang] : element.name[this.lang_code];
        element.searchString = element.name[this.lang_code] + ' ' + (element.neighborhood ? (element.neighborhood[this.lang_code] || element.neighborhood[defaultLang]) : '');
        return element;
      });
      if (this.filters.length && this.filters.filter((_: any) => _.type == 'location').length) {
        this.filters.filter((_: any) => _.type == 'location')[0]['selected'] = true;
        this.changeNearby(this.filters.filter((_: any) => _.type == 'location')[0]);
        this.mapData.zoom = 12;
      } else if (this.filters.length && !this.filters.filter((_: any) => _.type == 'location').length) {
        this.arrCategories = this.orignalArrCategories
        this.spinner.hide();
      }
      this.loader = true;
    }));
  }
  nav(catDetails) {
    this.onClickCategory.emit(catDetails);
  }

  back() {
    this.location.back();
  }

  setMyStyles() {
    return Utils.reverseContent();
  }

  onImgError(event) {
    event.target.src = Utils.offlineImage("");
  }

  filterCategories(filterType) {
    this.selectedCategory.emit(filterType);
  }
  openBottomSheet(data) {
    this.bottomSheet.open(TeamBottomSheetComponent, { data: data });
  }
  ngOnDestroy() {
    this.bottomSheet.dismiss();
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
  filterChanged(e) {
    if (Object.keys(e).length)
      this.searchText.filters = e;
    else
      delete this.searchText.filters;
  }
  filterMarket(e) {
    if (e) {
      if (this.filters.filter((_: any) => _.type == 'location').length && this.orignalArrCategories.length) {
        this.filters.filter((_: any) => _.type == 'location')[0]['selected'] = false;
        this.changeNearby(this.filters.filter((_: any) => _.type == 'location')[0]);
        this.mapData.zoom = 8;
      } else {
        this.spinner.hide();
      }
      this.searchText.levels = e;
    } else {
      if (this.filters.filter((_: any) => _.type == 'location').length && this.orignalArrCategories.length) {
        this.filters.filter((_: any) => _.type == 'location')[0]['selected'] = true;
        this.changeNearby(this.filters.filter((_: any) => _.type == 'location')[0]);
        this.mapData.zoom = 12;
      }
      this.searchText.levels = {};
    }
  }
  nearByChanged(data) {
    this.mapData.nearBy = data.value;
    this.orignalArrCategories.forEach(element => {
      if (data.selected) {
        var marketPoint = { lat: element.lat, long: element.long };
        var center = data.centerLocation ? data.centerLocation : this.croods;
        element.status = this.ePointsNear(marketPoint, center, data.value);
      }
      else
        element.status = true;
    });
    this.arrCategories = this.orignalArrCategories.filter(_ => _['status']);
    this.spinner.hide();
  }
  ePointsNear(checkPoint, centerPoint, km) {
    var ky = 40000 / 360;
    var kx = Math.cos(Math.PI * centerPoint.lat / 180.0) * ky;
    var dx = Math.abs(centerPoint.long - checkPoint.long) * kx;
    var dy = Math.abs(centerPoint.lat - checkPoint.lat) * ky;
    return Math.sqrt(dx * dx + dy * dy) <= km;
  }
  getUserLocation() {
    this.subscriptions.push(this.geoLocation.locality$.subscribe(location => {
      if (location.originalCoords && location.originalCoords.lat && location.originalCoords.long) {
        this.croods = location.originalCoords;
        this.mapData.lat = location.originalCoords.lat;
        this.mapData.long = location.originalCoords.long;
      }
    }));
  }
  detect() {
    this.geoLocation.localise();
  }
  getFilters(id) {
    this.filters = [];
    this.subscriptions.push(this.enutrifoodService.getNutrifoodCategories(id).take(1).subscribe((result: any) => {
      if (result && result.length) {
        let filter = [];
        let req = [];
        result.forEach((element: any) => {
          req.push(this.enutrifoodService.getNutrifoodFilter(id, element.docId).take(1));
        });
        this.subscriptions.push(forkJoin(req).subscribe((data) => {
          data.forEach((res: any, index) => {
            if (res && res.length) {
              filter = filter.concat(res);
              var location = filter.filter(_ => _.type == 'location');
              var other = filter.filter(_ => _.type != 'location' && _.isFilter);
              this.filters = location.concat(other);
              this.filters.forEach(res => {
                res['selected'] = false;
              });
            }
            if (result[index].docId == result[result.length - 1].docId) {
              if (this.filters.filter((_: any) => _.type == 'location').length && this.orignalArrCategories.length) {
                this.filters.filter((_: any) => _.type == 'location')[0]['selected'] = true;
                this.changeNearby(this.filters.filter((_: any) => _.type == 'location')[0]);
                this.mapData.zoom = 12;
              } else if (!this.filters.filter((_: any) => _.type == 'location').length && this.orignalArrCategories.length) {
                this.arrCategories = this.orignalArrCategories
              }
            }
          });
        }));
      }
    }));
  }
  changeNearby(filter) {
    if (filter.selected) {
      this.filters.forEach((element: any) => {
        if (element.type == "location" && element.id != filter.id)
          element.selected = "";
      });
      this.mapData.zoom = 12;
    } else {
      this.mapData.zoom = 8;
    }
    this.nearByChanged(filter);
  }
  identify(index, item) {
    return item.docId;
  }
  newSearch(e) {
    if (e.length >= 3) {
      if (this.filters.filter((_: any) => _.type == 'location').length && this.orignalArrCategories.length && this.filters.filter((_: any) => _.type == 'location')[0]['selected']) {
        this.filters.filter((_: any) => _.type == 'location')[0]['selected'] = false;
        this.changeNearby(this.filters.filter((_: any) => _.type == 'location')[0]);
        this.mapData.zoom = 8;
      }
    } else {
      if (this.filters.filter((_: any) => _.type == 'location').length && this.orignalArrCategories.length && !this.filters.filter((_: any) => _.type == 'location')[0]['selected']) {
        this.filters.filter((_: any) => _.type == 'location')[0]['selected'] = true;
        this.changeNearby(this.filters.filter((_: any) => _.type == 'location')[0]);
        this.mapData.zoom = 12;
      }
    }
  }
  getx48Image(inputUrl: string): string {
    return this.imageStorageService.getx48Image(inputUrl);
  }
}
