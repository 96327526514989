import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { EnutrifoodService } from '../../shared/service/nutrifood.service';
import { Parametre } from '../../shared/service/parametre';
const PARAMETRE = Parametre.getInstance();
import { UserService } from '../../shared/service/user.service';
import { environment } from '../../../environments/environment';
import { FirestoreDataService } from "../../shared/service/firestore-data.service";
import { GeoLocationService } from '../../shared/service/location';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { LivestockService } from '../../../../src/app/shared/service/livestock.service';
import { Subscription } from 'rxjs';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-nutrifood-subjects',
  templateUrl: './nutrifood-subjects.component.html',
  styleUrls: ['./nutrifood-subjects.component.scss']
})
export class NutrifoodSubjectsComponent implements OnInit, OnDestroy {
  public menuBarTitle = "Home.EnutrifoodTitle";
  objectKeys = Object.keys;
  arrCommodity: any = {};
  strCommodity: any;
  public loader: Boolean = false;
  public generalTabData: any;
  public txtSpeech: any;
  public commodity$: any;
  public messages = {};
  public messages2: any;
  public contentStyle: any;
  appData: any;
  subscriptions: Subscription[] = [];

  constructor(
    public enutrifoodService: EnutrifoodService,
    public route: ActivatedRoute,
    private livestockService: LivestockService,
    private fireStoreDataService: FirestoreDataService,
    public geoLocationService: GeoLocationService,
    private gaService: GoogleAnalyticsService,
    private spinner: NgxSpinnerService
  ) {
    this.spinner.show();
  }

  ngOnInit() {
    UserService.langCodeTextSpeech = JSON.parse(localStorage.getItem(environment.userLanguageStorageKey)).text_to_speech_code;
    this.txtSpeech = UserService.langCodeTextSpeech;
    this.contentStyle = UserService.langCode == 'ar' ? "{'direction':'rtl','text-align': 'right'}" : "'direction':'ltr','text-align': 'left'}";
    this.route.params.subscribe(param => {
      const themes = JSON.parse(localStorage.getItem('appThemes'));
      this.appData = themes.filter(_ => _.id === param.id)[0];
      this.menuBarTitle = this.appData.name;
      this.loader = false;
      this.subscriptions.push(this.livestockService.getDimensionDocument(param.commodity_name).subscribe(res => {
        this.generalTabData = res;
        this.subscriptions.push(this.livestockService.getMessageForSpecie('dimension_id', this.generalTabData.id).subscribe(data => {
          this.spinner.hide();
          data = data.filter(_ => _.status == undefined || _.status);
          this.arrCommodity = data["reduce"]((acc, message) => {
            const kindof = message.topic_id;
            if (acc[kindof]) {
              acc[kindof].push(message);
              return acc;
            }
            acc[kindof] = [message];
            return acc;
          }, {});
          this.loader = true;
        }));
        this.loader = true;
      }));
    });
  }
  public ga(event) {
    const name = 'E-nutrifood_' + this.route.snapshot.params.commodity_name;
    const action = event.action ? event.action + name : name;
    this.gaService.event(action, event.category, event.label);
  }
  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
