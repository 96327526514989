import { DeseasesComponent } from './diseases/diseases.component';
import { VaccinationsComponent } from './vaccinations/vaccinations.component'
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LivestockComponent } from './livestock';
import { SharedModule } from '../shared/shared.module';
import { SpeciesComponent } from './species/species.component';
import { LivestockRoutingModule } from './livestock.routing';
import { LivestockDetailsComponent } from './livestock-details/livestock-details.component';
import { DeseaseDetailsComponent } from './disease-details/disease-details.component';
import { VaccinationDetailsComponent } from './vaccination-details/vaccination-details.component';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';
//import { SelectAutocompleteModule } from "mat-select-autocomplete";

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        FormsModule,
        LivestockRoutingModule,
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        /* SelectAutocompleteModule, */
        ReactiveFormsModule
    ],
    declarations: [
        LivestockComponent,
        SpeciesComponent,
        DeseasesComponent,
        VaccinationsComponent,
        LivestockDetailsComponent,
        VaccinationDetailsComponent,
        DeseaseDetailsComponent
    ],
    exports: [
        LivestockComponent,
        LivestockRoutingModule,
        LivestockDetailsComponent
    ]
})
export class LivestockModule { }
// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}