import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  ViewEncapsulation, OnDestroy
} from "@angular/core";

import { BehaviorSubject } from "rxjs";
import { ActivatedRoute } from "@angular/router";
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from "@angular/material/dialog";
import { ModalAllZonesComponent } from "../filters-pwa/modal-all-zones/modal-all-zones.component";
import { FilterService } from "../../services/filter.service";
import { CropCalendarService } from "../../services/api/cropcalendar-api.service";
import { AudioService } from "../../../../../shared/component/audio/audio.service";
import { VideoService } from "./../../../../../shared/component/video/video.service";
import { ImageService } from "./../../../../../shared/component/image-modal/image.service";
import { environment } from "../../../../../../environments/environment";
import { LayoutService } from "../../../../../shared/service/layout.service";
import { WeatherService } from '../../../../../shared/service/weather.service';
import { Parametre } from '../../../../../shared/service/parametre';
const PARAMETRE = Parametre.getInstance();
import { UserService } from '../../../../../shared/service/user.service';
import { GoogleAnalyticsService } from "ngx-google-analytics";
import { LivestockService } from "../../../../../shared/service/livestock.service";
import Utils from "../../../../../shared/utils/utils";
import { Subscription } from 'rxjs';
import { NgxSpinnerService } from "ngx-spinner";
import { LinkService } from '../../../../../shared/component/link/link.service';
// const dummyData = [
//   {
//     "lastUpdated": "01/03/2023",
//     "crop": {
//       "id": "0303",
//       "name": "Rice",
//       "link_image": "https://firebasestorage.googleapis.com/v0/b/fao-cropcalendar.appspot.com/o/rice.jpg?alt=media",
//       "copyright": "CIAT/NeilPalmer",
//       "cropParent": {}
//     },
//     "aez": {
//       "id": "003",
//       "name": "Wet Zone"
//     },
//     "sessions": [
//       {
//         "additional_information": "",
//         "comments": "",
//         "all_year": "no",
//         "early_harvest": {
//           "month": 2,
//           "day": 1
//         },
//         "early_land_preparation": {
//           "month": 1,
//           "day": 10
//         },
//         "early_sowing": {
//           "month": 10,
//           "day": 15
//         },
//         "early_fertilization": {
//           "month": 1,
//           "day": 10
//         },
//         "growing_period": {
//           "period": "days",
//           "value": 120
//         },
//         "late_harvest": {
//           "month": 2,
//           "day": 15
//         },
//         "later_sowing": {
//           "month": 11,
//           "day": 21
//         },
//         "late_fertilization": {
//           "month": 11,
//           "day": 21
//         },
//         "later_land_preparation": {
//           "month": 11,
//           "day": 21
//         },
//         "sowing_rate": {
//           "unit": "kg/ha",
//           "value": "30-40"
//         }
//       }
//     ]
//   }
// ];
@Component({
  selector: "app-results-pwa",
  templateUrl: "./results-pwa.component.html",
  styleUrls: ["./results-pwa.component.scss"],
  encapsulation: ViewEncapsulation.None
})
export class ResultsPWAComponent implements OnInit, OnDestroy {
  @ViewChild("chartBars", { static: false }) div: ElementRef;
  @ViewChild("section", { read: ElementRef, static: false })
  section: ElementRef;

  windowResize$ = new BehaviorSubject<boolean>(false);

  filter;

  dataSource;
  dataZones = [];
  dataCountry;
  datazone;
  datacrop;
  infoZones;

  heightFisrtRow = 60;
  heightYear = 604;
  widthFirstColumn = 120;
  widthBar = 113;

  widthFirstColumnar = 113; //345;
  widthBarar = -113;

  country: string;
  zone: string;
  crop: string;
  name;

  loaded: boolean = false;

  isXs: boolean;
  public selLangCode: any;
  strCommodityName: any;
  public objectKeys = Object.keys;
  arrCropDetails: { [key: string]: any } = {};
  public txtSpeech: any;
  countryName: string;
  //cropName: string;
  allCountry: any;
  strDeepLink: any;
  lang_code: any;
  dimension_id: any;
  groups: any;
  providers: any;
  subscriptions: Subscription[] = [];
  hasNewRows = false;
  supportedLang = ['en', 'ar', 'es', 'ru', 'fr', 'zh'];
  constructor(
    private cropcalendarService: CropCalendarService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private livestockService: LivestockService,
    private audio: AudioService,
    private videoService: VideoService,
    private imageService: ImageService,
    private imageCopy: MatSnackBar,
    private layout: LayoutService,
    public weather: WeatherService,
    public gaService: GoogleAnalyticsService,
    private spinner: NgxSpinnerService,
    private linkService: LinkService
  ) {
    this.crop = this.route.snapshot.params.crop;
    this.zone = this.route.snapshot.params.zone;
    this.country = this.route.snapshot.params.country;
    //this.countryName = this.route.snapshot.params.countryName;
    //this.cropName = this.route.snapshot.params.cropName;
    this.spinner.show();
  }

  ngOnInit() {
    this.lang_code = JSON.parse(localStorage.getItem(environment.userLanguageStorageKey)).code;
    this.strDeepLink = localStorage.getItem('strDeepLink');
    UserService.langCodeTextSpeech = JSON.parse(localStorage.getItem(environment.userLanguageStorageKey)).text_to_speech_code;
    this.txtSpeech = UserService.langCodeTextSpeech;

    this.isXs = this.layout.media.isActive("xs");

    this.selLangCode = JSON.parse(
      localStorage.getItem(environment.userLanguageStorageKey)
    ).code;
    this.subscriptions.push(this.cropcalendarService
      .getAllCropCalendar(this.country, [this.zone], [this.crop])
      .subscribe(data => {
        //data = dummyData;
        this.infoZones = data;
        this.subscriptions.push(this.cropcalendarService
          .getAllAEZByCountry(this.country)
          .subscribe(zone => {
            this.datazone = zone.filter(zone => {
              if (zone.zone_id === this.infoZones[0].aez.id) {
                this.cropcalendarService.getZonesFromStorage().subscribe(response => {
                  if (response.length > 0) {
                    //this.infoZones[0].aez.name = response.filter(zone => zone.zone_id === this.infoZones[0].aez.id)[0].name;
                    var aezFinalArr = response.filter(zone => {
                      if (zone.zone_id === this.infoZones[0].aez.id) {
                        this.infoZones[0].aez.name = (zone.name) ? (zone.name[this.lang_code]) : this.infoZones[0].aez.name;
                        this.datazone[0]["name"] = (zone.name) ? (zone.name[this.lang_code]) : this.infoZones[0].aez.name;
                        this.datazone[0]["main_agricultural_practices"] = (zone.main_agricultural_practices) ? (zone.main_agricultural_practices[this.lang_code]) : this.datazone[0]["main_agricultural_practices"];
                        this.datazone[0]["geographical_description"] = (zone.geographical_description) ? (zone.geographical_description[this.lang_code]) : this.datazone[0]["geographical_description"];
                        this.datazone[0]["administratrative_units"] = (zone.administratrative_units) ? (zone.administratrative_units) : this.datazone[0]["administratrative_units"];
                        //this.datazone[0]["main_agricultural_practices"] = "testing jgkklgjdlk"
                      }
                    })
                  }
                  if (!this.supportedLang.includes(this.lang_code)) {
                    this.subscriptions.push(this.livestockService.getTranslation({
                      "q": [this.datazone[0]["name"], this.datazone[0]["main_agricultural_practices"], this.datazone[0]["geographical_description"], this.datazone[0]["administratrative_units"]],
                      "target": this.lang_code
                    }).subscribe(res => {
                      this.infoZones[0].aez.name = res['data']['translations'][0].translatedText;
                      this.datazone[0]["name"] = res['data']['translations'][0].translatedText;
                      this.datazone[0]["main_agricultural_practices"] = res['data']['translations'][1].translatedText;
                      this.datazone[0]["geographical_description"] = res['data']['translations'][2].translatedText;
                      this.datazone[0]["administratrative_units"] = res['data']['translations'][3].translatedText;
                      this.spinner.hide();
                    },
                      err => {
                        this.spinner.hide();
                      }));
                  }
                  return true;
                },
                  (err) => {
                  })
                return true;
              }
            });
            //this.datazone[0]["name"] = 'test';
            return this.datazone;
          }));
        const orignalCountry = JSON.parse(localStorage.getItem('countryData'));
        const country = orignalCountry.country == "FAO" ? Utils.getCountryData(this.country, 'code3') : orignalCountry.country;
        this.subscriptions.push(this.cropcalendarService.getCropsFromStorage(country).subscribe(response => {
          if (this.supportedLang.includes(this.lang_code))
            this.spinner.hide();
          const crop = response.filter(crop => crop.crop_id === this.infoZones[0].crop.id);
          this.dimension_id = crop.length ? crop[0].id : '';
          this.infoZones[0].crop.name = crop.length && crop[0].name ? crop[0].name[this.lang_code] : this.infoZones[0].crop.name;
          this.infoZones[0].crop.link_image = crop.length && crop[0].img ? crop[0].img : this.infoZones[0].crop.link_image;
          if (this.dimension_id) {
            this.subscriptions.push(this.livestockService.getMessageGroup(country).subscribe(res => {
              this.groups = res;
            }));
            this.subscriptions.push(this.livestockService.getProviders(country).subscribe(res => {
              this.providers = res;
            }));
            this.subscriptions.push(this.weather.getCropAdvices(this.dimension_id, country).subscribe(data => {
              data = data.filter(_ => _.status == undefined || _.status);
              this.arrCropDetails = data["reduce"](
                (acc, message) => {
                  const kindof = message.group_id;
                  if (acc[kindof]) {
                    acc[kindof].push(message);
                    return acc;
                  }
                  if (kindof != undefined) {
                    acc[kindof] = [message];
                  }
                  return acc;
                },
                {}
              );
              var obj = {};
              var filteredGroups = this.groups.filter(_ => _.group_type == "group" && Object.keys(this.arrCropDetails).includes(_.id));
              filteredGroups.forEach(element => {
                obj[element.id] = this.arrCropDetails[element.id];
              });
              this.arrCropDetails = obj;
            }));
          }
        }));
        this.subscriptions.push(this.cropcalendarService
          .getAllCropsByCountry(this.country)
          .subscribe(crop => {
            this.datacrop = crop.filter(crop => {
              return crop.crop_id === this.infoZones[0].crop.id;
            });
            return this.datacrop;
          }));

        this.loadCalendarData(data);
        this.loadChartData();
        this.loaded = true;
      }));

    if (this.crop) {
      // Get country from country code
      this.subscriptions.push(this.weather.getCountryFromCountryCode(this.country).subscribe(response => {
        this.allCountry = response;
        if (this.allCountry.length > 0) {
          var arrCountry = this.allCountry.filter(item => {
            return (this.country == item.code);
          });
        }
        if (arrCountry.length > 0) {
          // If 'arrCountry' not mtching with api url country
          let cntryName = arrCountry[0]['name'].toLowerCase();
          let gpsCountry = localStorage.getItem('gpsCountry').toLowerCase();
          if (gpsCountry != 'fao') {
            if (cntryName != gpsCountry) {
              cntryName = gpsCountry;
            }
          }
          //End
        }
      }));
    }
  }

  loadCalendarData(data): void {
    const zones = {};
    const dataZones = [];

    data.forEach(item => {
      const id = item.aez.id;
      let index = dataZones.length;
      if (!zones.hasOwnProperty(id)) {
        const aez = {
          aez: item.aez,
          chartBars: [],
          dataSource: []
        };
        dataZones.push(aez);
        zones[id] = index;
      } else {
        index = zones[id];
      }

      dataZones[index].dataSource.push(item);
    });

    this.dataZones = dataZones;
  }

  loadChartData(): void {
    let chartBars = [];

    for (let i = 0; i < this.dataZones.length; i++) {
      const zone = this.dataZones[i];
      chartBars = [];
      for (let j = 0; j < zone.dataSource.length; j++) {
        const crop = zone.dataSource[i];
        for (let k = 0; k < crop.sessions.length; k++) {
          const session = crop.sessions[k];
          const bars = this.getBarsFromSession(session, j);
          chartBars.push(...bars);
        }
      }
      this.dataZones[i].chartBars = chartBars;
    }
  }

  getBarsFromSession(session, indexRow): Array<any> {
    const bars = [];
    let bar = null;
    this.hasNewRows = Object.keys(session).includes('early_land_preparation') && Object.keys(session).includes('later_land_preparation') &&
      Object.keys(session).includes('early_fertilization') && Object.keys(session).includes('late_fertilization');
    if (this.hasNewRows) {
      this.widthFirstColumnar = 345;
    }
    if (this.hasNewRows) {
      if (
        session.early_land_preparation.month <= +session.later_land_preparation.month ||
        session.all_year === "yes"
      ) {
        bar = this.getBar(
          session.early_land_preparation.day,
          session.early_land_preparation.month,
          session.later_land_preparation.day,
          session.later_land_preparation.month,
          session.all_year,
          indexRow,
          '#c28858',
          0
        );

        if (bar) {
          bars.push(bar);
        }
      } else {
        bar = this.getBar(
          session.early_land_preparation.day,
          session.early_land_preparation.month,
          31,
          12,
          session.all_year,
          indexRow,
          '#c28858',
          0
        );

        if (bar) {
          bars.push(bar);
        }

        bar = this.getBar(
          1,
          1,
          session.later_land_preparation.day,
          session.later_land_preparation.month,
          session.all_year,
          indexRow,
          '#c28858',
          0
        );

        if (bar) {
          bars.push(bar);
        }
      }
    }
    if (
      session.early_sowing.month <= +session.later_sowing.month ||
      session.all_year === "yes"
    ) {
      bar = this.getBar(
        session.early_sowing.day,
        session.early_sowing.month,
        session.later_sowing.day,
        session.later_sowing.month,
        session.all_year,
        indexRow,
        '#6db753',
        this.hasNewRows ? 1 : 0
      );

      if (bar) {
        bars.push(bar);
      }
    } else {
      bar = this.getBar(
        session.early_sowing.day,
        session.early_sowing.month,
        31,
        12,
        session.all_year,
        indexRow,
        '#6db753',
        this.hasNewRows ? 1 : 0
      );

      if (bar) {
        bars.push(bar);
      }

      bar = this.getBar(
        1,
        1,
        session.later_sowing.day,
        session.later_sowing.month,
        session.all_year,
        indexRow,
        '#6db753',
        this.hasNewRows ? 1 : 0
      );

      if (bar) {
        bars.push(bar);
      }
    }

    if (this.hasNewRows) {

      if (
        session.early_fertilization.month <= +session.late_fertilization.month ||
        session.all_year === "yes"
      ) {
        bar = this.getBar(
          session.early_fertilization.day,
          session.early_fertilization.month,
          session.late_fertilization.day,
          session.late_fertilization.month,
          session.all_year,
          indexRow,
          '#f7c04a',
          2
        );

        if (bar) {
          bars.push(bar);
        }
      } else {
        bar = this.getBar(
          session.early_fertilization.day,
          session.early_fertilization.month,
          31,
          12,
          session.all_year,
          indexRow,
          '#f7c04a',
          2
        );

        if (bar) {
          bars.push(bar);
        }

        bar = this.getBar(
          1,
          1,
          session.late_fertilization.day,
          session.late_fertilization.month,
          session.all_year,
          indexRow,
          '#f7c04a',
          2
        );

        if (bar) {
          bars.push(bar);
        }
      }
    }

    if (
      session.early_harvest.month <= +session.late_harvest.month ||
      session.all_year === "yes"
    ) {
      bar = this.getBar(
        session.early_harvest.day,
        session.early_harvest.month,
        session.late_harvest.day,
        session.late_harvest.month,
        "no",
        indexRow,
        '#314a5e',
        this.hasNewRows ? 3 : 1
      );

      if (bar) {
        bars.push(bar);
      }
    } else {
      bar = this.getBar(
        session.early_harvest.day,
        session.early_harvest.month,
        31,
        12,
        "no",
        indexRow,
        '#314a5e',
        this.hasNewRows ? 3 : 1
      );

      if (bar) {
        bars.push(bar);
      }

      bar = this.getBar(
        1,
        1,
        session.late_harvest.day,
        session.late_harvest.month,
        "no",
        indexRow,
        '#314a5e',
        this.hasNewRows ? 3 : 1
      );

      if (bar) {
        bars.push(bar);
      }
    }
    return bars;
  }

  getBar(dia1, mes1, dia2, mes2, allYear, indexRow, background, index) {
    if (allYear === "yes") {
      dia1 = 1;
      mes1 = 1;
      dia2 = 31;
      mes2 = 12;
    }

    const height = this.getSizeDateRange(dia1, mes1, dia2, mes2);
    if (height === 0) {
      return null;
    }

    //previous day of dia1/mes1
    const dia3 = dia1 > 1 ? dia1 : 30;
    const mes3 = dia1 > 1 ? mes1 : mes1 - 1;
    const top = this.getSizeDateRange(1, 1, dia3, mes3);

    if (this.selLangCode !== "ar") {
      const bar = {
        indexRow: indexRow,
        left: this.widthFirstColumn,
        top: this.heightFisrtRow + top,
        height: height,
        dia1: dia1,
        mes1: mes1,
        dia2: dia2,
        mes2: mes2,
        // sowing: sowing,
        background: background
      };

      if (index) {
        bar.left += (this.widthBar * index) - index;
      }
      return bar;
    }

    if (this.selLangCode === "ar") {
      const bar = {
        indexRow: indexRow,
        left: this.widthFirstColumnar,
        top: this.heightFisrtRow + top,
        height: height,
        dia1: dia1,
        mes1: mes1,
        dia2: dia2,
        mes2: mes2,
        // sowing: sowing,
        background: background
      };

      if (index && this.selLangCode === "ar") {
        bar.left -= (this.widthBar * index) - index;
      }
      return bar;
    }
  }

  getSizeDateRange(dia1, mes1, dia2, mes2): number {
    if (!dia1 || !mes1 || !dia2 || !mes2) {
      return 0;
    }

    const days = this.getDaysRange30(+dia1, +mes1, +dia2, +mes2);
    const size = (days / 360) * this.heightYear;
    return Math.min(this.heightYear, size);
  }

  getDaysRange30(dia1, mes1, dia2, mes2): number {
    let days = 0;

    if (mes1 === 2 && dia1 >= 28) {
      dia1 = 30;
    }
    if (mes2 === 2 && dia2 >= 28) {
      dia2 = 30;
    }
    if (dia1 > 30) {
      dia1 = 30;
    }
    if (dia2 > 30) {
      dia2 = 30;
    }

    let incr = 0;
    for (let i = 1; i <= 12; i++) {
      if (i < mes1) {
        incr = 0;
      } else if (i === mes1 && i === mes2) {
        incr = dia2 - dia1 + 1;
      } else if (i === mes1 && i < mes2) {
        incr = dia1 === 1 ? 30 : 30 - dia1;
      } else if (i > mes1 && i < mes2) {
        incr = 30;
      } else if (i > mes1 && i === mes2) {
        incr = dia2;
      } else {
        incr = 0;
      }
      days += incr;
    }

    return days;
  }

  getCurrentDay() {
    //current month
    let now = new Date();
    //Mes
    let month = now.getMonth();
    //Día
    let day = now.getDate();
    const currentmonth = month * 50;
    const currentday = currentmonth + day + this.heightFisrtRow;
    return currentday;
  }

  openDialogAllZones(data): void {
    this.dialog.open(ModalAllZonesComponent, {
      panelClass: "custom-dialog-container",
      data: {
        zone: data[0]
      }
    });
  }

  public openMediaPlayer(src) {
    this.audio.openMediaPlayer(src);
  }

  openMediaPlayerForVideo(src) {
    this.videoService.openMediaPlayer(src);
  }

  openMediaForImage(src) {
    this.imageService.openMediaPlayer(src);
  }

  openMediaForSpeech(content) {
    const strUrl = PARAMETRE.strTextToSpeechUrl + encodeURIComponent(content) + "&language=" + this.txtSpeech;
    this.audio.openMediaPlayer(strUrl);
  }

  exportXlsx(data: Response) {
    this.subscriptions.push(this.cropcalendarService
      .downloadCropCalendar(this.country, [this.crop], [this.zone])
      .subscribe((data: Blob) => {
        this.cropcalendarService.downloadFile(
          data,
          null,
          "Crop_Calendar_Data.xlsx"
        );
      }));
  }

  openChartInfo(message: string, action: string) {
    this.imageCopy.open(message, action, {
      duration: 2500
    });
  }

  isNumber(o): boolean {
    return !isNaN(o - 0) && o !== null && o !== "" && o !== false;
  }

  get isCommentVisible() {
    return ['en', 'fr', 'zh', 'ru', 'ar', 'es'].includes(this.selLangCode.toLowerCase());
  }
  tabChange(i) {
    this.gaService.event('Crop_' + this.crop, 'Tab_Change', i.tab.textLabel);
  }
  ga(action, category, label) {
    this.gaService.event(action, category, label);
  }

  getGroupDetails(id) {
    if (id == '') return 'system.information_tab';
    if (this.groups && this.groups.length) {
      var data = this.groups.filter(_ => _.id == id);
      return data.length ? data[0].name : id;
    } else return id
  }
  getProviderDetaiils(id, returnValue) {
    if (this.providers && this.providers.length) {
      var data = this.providers.filter(_ => _.id == id);
      return data.length ? data[0][returnValue] : id;
    } else return id
  }
  getProviders(keymessages) {
    return this.groupByKey(keymessages, 'provider_id');
  }
  groupByKey(data, key) {
    return data.reduce(function (rv, x) {
      x[key] = !x[key] ? '' : x[key];
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  };
  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe())
  }
}
