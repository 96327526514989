import { Component, Inject, Input } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import Utils from '../../shared/utils/utils';

export interface DialogData {
  title: string;
  subtitle: string;
  hint: string;
  Details: string;
  name: string;
}

@Component({
  selector: 'app-weather-alert',
  templateUrl: './weather-alert.component.html',
  styleUrls: ['./weather-alert.component.css']
})
export class WeatherAlertComponent {
  @Input() showWeatherAlert: boolean;

  constructor(
    public dialogRef: MatDialogRef<WeatherAlertComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  onNoClick(): void {
    this.dismissAlert();
  }

  setMyStyles() {
    return Utils.reverseContent();
  }

  dismissAlert(): void {
    this.dialogRef.close();
  }

}
