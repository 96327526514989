import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-plant-pests-preview',
  templateUrl: './app-plant-pests-preview.component.html',
  styleUrls: ['./app-plant-pests-preview.component.css']
})
export class AppPlantPestsPreviewComponent implements OnInit {
  @Input() data: any;
  @Input() appName: any;
  constructor() { }

  ngOnInit(): void {
  }

}
