import { Component, Input, OnInit } from '@angular/core';
import Utils from '../../../app/shared/utils/utils';

@Component({
  selector: 'app-app-vaccination-preview',
  templateUrl: './app-vaccination-preview.component.html',
  styleUrls: ['./app-vaccination-preview.component.scss']
})
export class AppVaccinationPreviewComponent implements OnInit {
  @Input() data: any;
  @Input() appName: any;
  constructor() { }

  ngOnInit(): void {
  }
  setMyStyles() {
    return {
      'direction': 'ltr',
      'text-align': 'left'
    };
  }
  getDefImage(event) {
    event.target.src = Utils.defaultImage();
  }
}
