import { AgrimarketService } from './../../shared/service/agrimarket.service';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { LayoutService } from './../../shared/service/layout.service';
import { Component, OnInit, AfterViewInit, ElementRef, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { Chart } from 'angular-highcharts';
import { GeoLocationService } from './../../shared/service/location';
import { FpmaService } from '../../shared/service/fpma.service';
import * as moment from 'moment';
import { UserService } from './../../shared/service/user.service';
import { environment } from '../../../environments/environment';
import Utils from '../../shared/utils/utils';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { Subscription } from 'rxjs';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-market-details',
  templateUrl: './market-details.component.html',
  styleUrls: ['./market-details.component.scss']
})
export class MarketDetailsComponent implements OnInit, OnDestroy {
  cols: number;
  rowH: string;
  searchText = { commodity_label: '' };
  searchTextBQ = { commodity_label: '' };
  public loader: boolean = false;
  details: any = [];
  sparklines = {
    down: {
      path: 'assets/down-arrow.svg',
      color: '#ef3935'
    },
    up: {
      path: 'assets/up-arrow.svg',
      color: '#7cb342'
    },
    equal: {
      path: 'assets/equal-arrow.svg',
      color: '#f57c00'
    }
  }

  boolCountry: boolean = false;
  isBigQuery: boolean = false;
  arrData: any;
  marketData: any = [];
  arrSelCountryData: any;
  arrSelComMarkets: any = [];
  lgCode: any;
  appData: any;
  fpmaApi: any;
  fpmaPeriods = [{ name: 'Agrimarket.Daily', value: 'daily', selected: false }, { name: 'Agrimarket.weekly', value: 'weekly', selected: false }, { name: 'Agrimarket.monthly', value: 'monthly', selected: true }];
  croods: any;
  subscriptions: Subscription[] = [];
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private layout: LayoutService,
    private market: AgrimarketService,
    private location: Location,
    public locService: GeoLocationService,
    public fpmaService: FpmaService,
    public userService: UserService,
    public gaService: GoogleAnalyticsService,
    private spinner: NgxSpinnerService
  ) {
    this.spinner.show();
    this.lgCode = JSON.parse(localStorage.getItem(environment.userLanguageStorageKey)).code;
    this.getUserLocation();
  }

  public back() {
    this.location.back();
  }

  public showChart($event) {
    $event.stopPropagation();
  }

  ngOnInit() {
    const themes = JSON.parse(localStorage.getItem('appThemes'));
    this.appData = themes.filter(_ => _.template_id === 'agrimarketplace')[0];
    let apiPath = this.appData.tabs[0].source_type == 'api' ? this.appData.tabs[0].api_path : this.appData.tabs[0].source_type;
    if (apiPath != '') {
      if (apiPath == 'BIG_QUERY') {
        this.boolCountry = false;
        this.isBigQuery = true;
        const country = this.locService.getCountry();
        this.marketData = JSON.parse(localStorage.getItem('selListMarketBQ'));
        this.subscriptions.push(this.fpmaService.getDetailsForMarketBQ(this.marketData, country).subscribe((res: any) => {
          this.loader = true;
          res.forEach(element => {
            element.referenceDate = (typeof (element.referenceDate) == 'string') ? element.referenceDate : moment(element.referenceDate).format('DD/MM/YYYY');
          });
          const marketsList = new Set(res.map(item => item.market_name));
          const commoditiesList = new Set(res.map(item => item.commodity_name));
          let finalList = [];
          commoditiesList.forEach(commodityName => {
            let temp = [];
            let temp2 = [];
            marketsList.forEach(marketName => {
              res.forEach(element => {
                if (element.market_name == marketName && element.commodity_name == commodityName) {
                  temp.push(element);
                }
              });
              if (temp.length > 0) {
                temp2 = temp;
                if (temp2.length > 0) {
                  temp2.forEach(element => {
                    element.referenceDate = (typeof (element.referenceDate) == 'string') ? element.referenceDate : moment(element.referenceDate).format('DD/MM/YYYY');
                    var d = element.referenceDate.substring(6, 10) + '/' + element.referenceDate.substring(3, 6) + element.referenceDate.substring(0, 2);
                    element.referenceDate = new Date(d);
                    element.upload_timestamp = new Date(element.upload_timestamp);
                  });
                  temp2 = temp2.filter(_ => (typeof (_.referenceDate) == 'string') ? new Date(_.referenceDate) : _.referenceDate <= new Date());
                  temp2 = this.getFilteredData(temp2);
                  temp2 = temp2.sort(function (a, b) {
                    return (a.referenceDate) - (b.referenceDate);
                  });
                  if (temp2.length > 1) {
                    let maxlen = temp2.length;
                    temp2[temp2.length - 1].dateRange = temp2.slice(Math.max(maxlen - 5, 0));
                    temp2[temp2.length - 1].dateRange.forEach((ele, index) => {
                      ele['date'] = (typeof (ele.referenceDate) == 'string') ? ele.referenceDate : moment(ele.referenceDate).format('DD/MM/YYYY');
                      ele['price'] = ele.priceValue_avg;
                    });
                    let len = temp2[temp2.length - 1].dateRange.length - 1;
                    let seclastLen = temp2[temp2.length - 1].dateRange.length - 2;
                    temp2[temp2.length - 1].referenceDate = temp2[temp2.length - 1].dateRange[len]['date'];
                    temp2[temp2.length - 1].priceValue = temp2[temp2.length - 1].dateRange[len]['price'];
                    let lastPriceValue = temp2[temp2.length - 1].dateRange[seclastLen]['price'];
                    temp2[temp2.length - 1].priceChangeRaito = (temp2[temp2.length - 1].priceValue - lastPriceValue) / temp2[temp2.length - 1].priceValue * 100;
                    temp2[temp2.length - 1].chartObject = this.createChart(temp2[temp2.length - 1].dateRange);
                    temp2[temp2.length - 1].chartDateObject = this.createChartDates(temp2[temp2.length - 1].dateRange);
                  }
                  if (temp2.length)
                    finalList.push(temp2[temp2.length - 1]);
                }
              }
            });
          });
          finalList.forEach(element => {
            element.referenceDate = (typeof (element.referenceDate) == 'string') ? element.referenceDate : moment(element.referenceDate).format('DD/MM/YYYY');
          });
          let details: any = finalList;
          this.subscriptions.push(this.market.getCommoditiesForCountry('commodities').subscribe((res: any[]) => {
            this.loader = true;
            this.spinner.hide();
            if (res.length > 0) {
              details.map((l) => {
                let item = l?.commodity_name ? res.find((commodity) => commodity?.commodity_id.toLowerCase() == l?.commodity_name.toLowerCase()) : null;
                if (item) {
                  l.commodity_label = item.name[this.lgCode] ? item.name[this.lgCode] : l.commodity_label;
                  l.image_link = item.img ? item.img : l.image_link;
                  l['order'] = item.order;
                  l.status = item.status;
                } else {
                  l['order'] = 9999;
                  l.status = false;
                }
              });
              this.subscriptions.push(this.market.getMarketForCountry('markets').subscribe((response: any[]) => {
                this.loader = true;
                this.spinner.hide();
                if (response.length > 0) {
                  details.map((l) => {
                    let item = l?.market_name ? response.find((market) => market?.market_id.toLowerCase() == l?.market_name.toLowerCase()) : null;
                    if (item) {
                      l.market_label = item.name[this.lgCode] ? item.name[this.lgCode] : l.market_label;
                      l.latitude = parseFloat(item.lat);
                      l.longitude = parseFloat(item.long);
                    }
                  });
                }
                this.details = Utils.sort(details, 'order');
              }));

            }
          }));
        }));
      } else {
        this.boolCountry = false;
        this.isBigQuery = false;
        this.marketData = JSON.parse(localStorage.getItem('arrSelMarketCommodities'));
        if (this.marketData.length) {
          this.fpmaApi = apiPath ? apiPath.split('FpmaSerie') : '';
          this.getFPMA('monthly');
        } else {
          this.router.navigate(['agrimarket']);
        }
      }
    }
  }

  createChart(data) {
    let priceArr = [];
    data.forEach(element => {
      priceArr.push(element.price);
    });
    let priceSlicedArr = priceArr.slice(Math.max(priceArr.length - 5, 0))
    return priceSlicedArr;
  }

  createChartDates(data) {
    let dateArr = [];
    data.forEach(ele => {
      let date = ele.referenceDate ? ele.referenceDate : ele.date;
      dateArr.push((typeof (date) == 'string') ? date : moment(date).format('DD/MM/YYYY'));
    });
    dateArr = dateArr.slice(Math.max(dateArr.length - 5, 0))
    return dateArr;
  }

  setMyStyles() {
    return Utils.reverseContent();
  }

  openMarket(commName, commodityId) {
    var url = environment.firebaseCommImg;
    var imgName = url + "" + this.modifyCommodity(commName);
    //Check selected commodity in country markets
    this.arrSelCountryData = JSON.parse(localStorage.getItem('arrCountryData'));
    var l = this.arrSelCountryData.length, i;
    for (i = 0; i < l; i++) {
      if (this.arrSelCountryData[i].commodityId === commodityId) {
        var data = this.arrSelComMarkets.filter(_ => _.market_code == this.arrSelCountryData[i].market_code);
        if (!data.length) {
          this.arrSelComMarkets.push({
            "ISO3_country_code": this.arrSelCountryData[i]['ISO3_country_code'],
            "commodity_name": this.arrSelCountryData[i]['commodity_name'],
            "commodity_label": this.arrSelCountryData[i]['commodity_label'],
            "image_link": imgName,
            "commodity_code": this.arrSelCountryData[i]['commodity_code'],
            "market_season_start": this.arrSelCountryData[i]['market_season_start'],
            "commodity_info": this.arrSelCountryData[i]['commodity_info'],
            "no_of_markets": "",
            "priceTypeId": this.arrSelCountryData[i]['priceTypeId'],
            "periodicityId": this.arrSelCountryData[i]['periodicityId'],
            "measureUnitId": this.arrSelCountryData[i]['measureUnitId'],
            "commodityId": this.arrSelCountryData[i]['commodityId'],
            "marketId": this.arrSelCountryData[i]['marketId'],
            "currencyId": this.arrSelCountryData[i]['currencyId'],
            "currency_variant": this.arrSelCountryData[i]['currency_variant'],
            "measureUnit": this.arrSelCountryData[i]['measureUnit'],
            "market_name": this.arrSelCountryData[i]['market_name'],
            "market_label": this.arrSelCountryData[i]['market_label'],
            "currency": this.arrSelCountryData[i]['currency'],
            "priceValue": this.arrSelCountryData[i]['priceValue'],
            "priceChangeRaito": this.arrSelCountryData[i]['priceChangeRaito'],
            "dateRange": this.arrSelCountryData[i]['dateRange'],
            "referenceDate": this.arrSelCountryData[i]['referenceDate'],
            "chartObject": this.arrSelCountryData[i]['chartObject'],
            "link": this.arrSelCountryData[i]['link'],
            "market_code": this.arrSelCountryData[i]['market_code'],
            "uuid": this.arrSelCountryData[i]['uuid']
          });
        }
      }
    }
    localStorage.setItem('arrSelComMarkets', JSON.stringify(this.arrSelComMarkets));
    this.router.navigate(['/agrimarket', commName]);
  }

  getComMarketsBQ(commName) {
    localStorage.removeItem('selListBQ');
    let selListBQ;
    this.details.forEach(cn => {
      if (cn.commodity_name == commName) {
        selListBQ = cn;
      }
    });
    delete selListBQ.chartObject;
    delete selListBQ.dateRange;
    localStorage.setItem('selListBQ', JSON.stringify(selListBQ));
    this.router.navigate(['/agrimarket', commName]);
  }

  modifyCommodity(str) {
    var txt = str.replace(/[(),]/g, "");
    return txt.replace(/\s/g, "_").toLowerCase() + ".jpg?alt=media";
  }
  onTabChange(i) {
    this.gaService.event('Markets', 'Tab_Change', i.tab.textLabel);
  }
  getFilteredData(data) {
    data = data.sort(function (a, b) {
      return (b.upload_timestamp) - (a.upload_timestamp);
    });
    data = this.getUniqueData(data);
    return data;
  }
  getUniqueData(data) {
    const uniqueValuesSet = new Set();
    const filteredArr = data.filter((obj) => {
      const ref = typeof obj.referenceDate == 'string' ? obj.referenceDate : moment(obj.referenceDate).format('DD/MM/YYYY');
      const string = obj.commodity_name + '_' + ref;
      const isPresentInSet = uniqueValuesSet.has(string);
      uniqueValuesSet.add(string);
      return !isPresentInSet;
    });
    return filteredArr;
  }
  getFPMA(periodicity) {
    this.spinner.show();
    this.details = [];
    this.loader = false;
    const country = this.locService.getCountry();
    var api = this.fpmaApi[0] + 'Market/';
    this.subscriptions.push(this.fpmaService.getfpmaMarket(this.marketData[0].market_code, api || '').subscribe((market: any) => {
      this.marketData.forEach(ele => {
        ele.latitude = parseFloat(market.latitude);
        ele.longitude = parseFloat(market.longitude);
      });
      if (!this.marketData || !this.marketData.length) {
        this.loader = true;
        this.spinner.hide();
      }
      api = this.fpmaApi;
      api = api[0] + 'FpmaSeriePrice/{uuid}/?periodicity=' + periodicity;
      this.subscriptions.push(this.fpmaService.getAllMarketData(this.marketData, country, api || '').subscribe(res => {
        this.loader = true;
        this.spinner.hide();
        this.marketData.forEach((element, index) => {
          if (!res[index]['error']) {
            let maxlen = res[index]['datapoints'].length;
            this.marketData[index].dateRange = res[index]['datapoints'].slice(0, res[index]['datapoints'].length > 5 ? 5 : res[index]['datapoints'].length).reverse();
            this.marketData[index].dateRange.forEach((ele, index) => {
              ele['date'] = moment(ele.date).format(periodicity == 'monthly' ? 'MM/YYYY' : 'DD/MM/YYYY');
              ele['price'] = ele.price_value;
            });
            let len = this.marketData[index].dateRange.length - 1;
            let seclastLen = this.marketData[index].dateRange.length - 2;
            this.marketData[index].referenceDate = this.marketData[index].dateRange.length ? this.marketData[index].dateRange[len]['date'] : '';
            this.marketData[index].priceValue = this.marketData[index].dateRange.length ? this.marketData[index].dateRange[len]['price'] : '';
            let lastPriceValue = this.marketData[index].dateRange.length ? this.marketData[index].dateRange[seclastLen]['price'] : '';
            this.marketData[index].priceChangeRaito = (this.marketData[index].priceValue - lastPriceValue) / this.marketData[index].priceValue * 100;
            this.marketData[index].chartObject = this.createChart(this.marketData[index].dateRange);
            this.marketData[index].chartDateObject = this.createChartDates(this.marketData[index].dateRange);
          } else {
            element.status = false;
          }
        });
        this.details = this.marketData;
        this.subscriptions.push(this.market.getMarketForCountry('markets').subscribe((response: any[]) => {
          this.loader = true;
          this.spinner.hide();
          if (response.length > 0) {
            let item = this.details[0].market_name ? response.find((market) => market?.market_id.toLowerCase() == this.details[0].market_name.toLowerCase()) : null;
            if (item) {
              this.details[0].market_label = item.name[this.lgCode] ? item.name[this.lgCode] : this.details[0].market_label;
              this.details[0].latitude = item.lat ? parseFloat(item.lat) : this.details[0].latitude;
              this.details[0].longitude = item.long ? parseFloat(item.long) : this.details[0].longitude;
            }
          }
        }));
        this.subscriptions.push(this.market.getCommoditiesForCountry('commodities').subscribe((res: any[]) => {
          if (res.length > 0) {
            this.details.map((l) => {
              let item = l?.commodity_name ? res.find((commodity) => commodity?.commodity_id.toLowerCase() == l?.commodity_name.toLowerCase()) : null;
              if (item) {
                l.commodity_label = item.name[this.lgCode] ? item.name[this.lgCode] : l.commodity_label;
                l.image_link = item.img ? item.img : l.image_link;
                l['order'] = item.order;
                l.status = item.status;
              } else {
                l['order'] = 9999;
                // l.status = false;
              }
            });
            this.details = Utils.sort(this.details, 'order');
          }
        }));
      },
        (error) => console.log(error)
      ));
    }));
  }
  getUserLocation() {
    this.subscriptions.push(this.locService.locality$.subscribe(location => {
      if (location.originalCoords && location.originalCoords.lat && location.originalCoords.long) {
        this.croods = location.originalCoords;
      }
    }));
  }
  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
