import { Component, OnInit, OnDestroy } from '@angular/core';
import Utils from '../../../shared/utils/utils';
import { Router } from '@angular/router';
import { SectionService } from '../../../shared/service/section.service';
import { Location } from '@angular/common';
import { NgForm, FormBuilder, FormArray } from '@angular/forms';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AuthService } from '../../../shared/service/auth.service';
import { GeoLocationService } from '../../../shared/service/location';
import { ForumService } from '../../../shared/service/forum.service';
import { BreakpointObserver } from '@angular/cdk/layout';
import { Section } from '../../section.model';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-sections',
  templateUrl: './sections.component.html',
  styleUrls: ['./sections.component.css']
})
export class SectionsComponent implements OnInit, OnDestroy {

  public loader = false;
  public userdoc: any = [];
  public IsforumAdmin: boolean = false;
  public country: any;
  isMobile: boolean = false;
  list: Section[];
  forum: any;
  subscriptions: Subscription[] = [];
  // public geoLocationService: GeoLocationService;
  constructor(private router: Router,
    public geoLocationService: GeoLocationService,
    private location: Location,
    private authService: AuthService,
    private forumService: ForumService,
    private sectionService: SectionService,
    private firestore: AngularFirestore,
    private breakpointObserver: BreakpointObserver) { }

  ngOnInit() {
    const themes = JSON.parse(localStorage.getItem('appThemes'));
    this.forum = themes.filter(_ => _.template_id === 'forum')[0];
    if (!this.authService.isLoggedIn) {
      this.router.navigate(['login/forum*admin']);
    }

    this.subscriptions.push(this.authService.afAuth.authState.subscribe(async user => {
      if (user) {
        this.IsforumAdmin = this.IsAdmin(user.uid);
      }
    }));

    this.country = this.geoLocationService.getCountry();
    this.resetForm();
    this.breakpointObserver.observe([
      '(max-width: 768px)'
    ]).subscribe(result => {
      this.isMobile = result.matches;
    });

    this.subscriptions.push(this.sectionService.getSections().subscribe(actionArray => {
      this.list = actionArray.map(item => {
        return {
          id: item.payload.doc.id,
          ...item.payload.doc.data() as {}
        } as Section;
      });
    }));
  }

  resetForm(form?: NgForm) {
    if (form != null) {
      form.resetForm();
    }
    this.sectionService.formData = {
      id: null,
      value: '',
      // nameAr: '',
      // nameEn: '',
      order: null,
      names: []
    };

    for (var i = 0; i < this.country.languages.length; i++) {
      this.sectionService.formData.names.push({ language_code: '' + this.country.languages[i].code, value: '' });
    }

  }

  public IsAdmin(UserId) {
    this.subscriptions.push(this.forumService.getUserDetails(UserId).subscribe((res: any[]) => {
      if (res) {
        this.userdoc = res;
        const country = this.geoLocationService.getCountry();
        if (this.userdoc.roles["dsp"].indexOf("admin") > -1) {
          this.loader = true;
        } else if (!(this.userdoc.roles["dsp"].includes("admin") || this.userdoc.roles["dsp"].includes("forum_admin")) || this.userdoc['country_iso3'].toLowerCase() != country.country.toLowerCase()) {
          this.router.navigate([`forum`]);
        }
        this.loader = true;
      }
    }));
    return true;
  }
  onSubmit(form: NgForm) {
    let data0 = Object.assign({}, form.value);
    let data = {
      value: data0.value,
      order: data0.order,
      id: data0.id,
      names: []
    };
    for (var attr in data0) {
      if (attr == 'id' || attr == 'order' || attr == 'value') {
        continue;
      }
      data.names.push({ 'language_code': attr, 'value': data0[attr] });
    }
    delete data.id;
    if (form.value.id == null) {
      this.sectionService.addSection(data);
    }
    else {
      this.sectionService.updateSection(data, form.value.id);
    }
    this.resetForm();
  }

  setMyStyles() {
    return Utils.reverseContent();
  }

  back() {
    this.location.back();
  }
  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
