import { AudioService } from './../audio/audio.service';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';


@Component({
    selector: 'app-snackbar',
    template: `
        <button class="close" mat-button (click)="snackBarRef.dismiss()">X</button>
        <app-audio fxFlex='95%' [audioSrc]='data' ></app-audio>
    `,
    styles: [
        `
    .close.mat-button {
        position: absolute;
        top: 0;
        right: 0;
        padding: 10px;
        line-height: 14px;
        min-width: auto;
    }`
    ]
})

export class SnackbarComponent implements OnInit {

    constructor(
        @Inject(MAT_SNACK_BAR_DATA)
        public data: string,
        public snackBarRef: MatSnackBarRef<SnackbarComponent>
    ) { }

    ngOnInit() {
    }

}

