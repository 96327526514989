import { Component, Input, OnInit } from '@angular/core';
import Utils from '../../shared/utils/utils';

@Component({
  selector: 'app-app-agrimarket-detail-preview',
  templateUrl: './app-agrimarket-detail-preview.component.html',
  styleUrls: ['./app-agrimarket-detail-preview.component.scss']
})
export class AppAgrimarketDetailPreviewComponent implements OnInit {
  @Input() data: any;
  @Input() appName: any;
  searchText = { market_label: '' };
  sparklines = {
    down: {
      path: 'assets/down-arrow.svg',
      color: '#ef3935'
    },
    up: {
      path: 'assets/up-arrow.svg',
      color: '#7cb342'
    },
    equal: {
      path: 'assets/equal-arrow.svg',
      color: '#f57c00'
    }
  };
  constructor() { }

  ngOnInit(): void {
  }
  setMyStyles() {
    return {
      'direction': 'ltr',
      'text-align': 'left'
    };
  }
}
