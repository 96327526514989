import { throwError as observableThrowError, Observable } from 'rxjs';
import { catchError, tap, retry, map } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { HttpClientModule } from '@angular/common/http';
import { UserService } from './user.service';
import { GeoLocationService } from './location';
import { Parametre } from './parametre';
import { DatePipe } from '@angular/common';

const PARAMETRE = Parametre.getInstance();
import { LivestockService } from './livestock.service';

export const APIKEY = 'b2b462dd6c35ebd10a88ffac6f7f6d05';
import { environment } from '../../../environments/environment';
import { FirestoreDataService } from './firestore-data.service';
import Utils from './../utils/utils';

@Injectable()
export class WeatherService {
  headers = new Headers({ 'Content-Type': 'application/json' });
  datas: any;
  weathers: any;
  seasonalForcasts: any;
  meteoForecasts: any;
  crops: any;
  meteoFeedbacks: any;
  meteoWarnings: any;
  meteoAdvices: any;
  // old params
  cityInfo: City;
  ForecastList: List;
  RegionList: List;
  res: any;
  lat: any = 13.75389;
  lon: any = -13.75861;
  APIKEY: String = 'b2b462dd6c35ebd10a88ffac6f7f6d05';
  todayData: any = {};
  private accessTokenMeteoRwanda: any = null;
  private isTokenExpires = true;

  constructor(
    private fireStoreDataService: FirestoreDataService,
    public location: GeoLocationService,
    private http: HttpClient,
    public toast: MatSnackBar,
    private UService: UserService,
    private livestockService: LivestockService
  ) {
  }

  // tslint:disable-next-line:indent
  public handleServerError = (err: any) => {
    // tslint:disable-next-line:indent
    if (err.status === 500 || err.status === 503) {
      // TODO: Once the status get back to normal bring the toast back here
      this.toast.open(
        'Service is temporarily unavailable, please try again later',
        'OK',
        { duration: 7000 }
      );
    }
    return observableThrowError(
      'Error while loading the weather handleServerError'
    );
  };
  public handleServerErrorWeather = (err: any) => {
    if (err.status === 500 || err.status === 503) {
      // TODO: Once the status get back to normal bring the toast back here
      this.toast.open(
        'Service is temporarily unavailable, please try again later',
        'OK',
        { duration: 7000 }
      );
    } else {
      this.toast.open(
        'There are no valid forecast for this localization',
        'OK',
        { duration: 7000 }
      );
    }
    return observableThrowError(
      'Erroe while loading the weather handleServerErrorWeather'
    );
  };
  public handleServerErrorAdvices = (err: any) => {
    if (err.status === 500 || err.status === 503) {
      // TODO: Once the status get back to normal bring the toast back here
      this.toast.open(
        'Service is temporarily unavailable, please try again later',
        'OK',
        { duration: 7000 }
      );
    } else {
      this.toast.open('There are no  advices this localization', 'OK', {
        duration: 7000
      });
    }
    return observableThrowError(
      'Erroe while loading the weather handleServerErrorAdvices'
    );
  };
  public handleServerErrorCrop = (err: any) => {
    if (err.status === 500 || err.status === 503) {
      // TODO: Once the status get back to normal bring the toast back here
      this.toast.open(
        'Service is temporarily unavailable, please try again later',
        'OK',
        { duration: 7000 }
      );
    } else {
      this.toast.open('There are no  crop for this localization', 'OK', {
        duration: 7000
      });
    }
    return observableThrowError(
      'Erroe while loading the weather handleServerErrorCrop'
    );
  };

  getCropDetails(cropName) {
    const commodityName = Utils.modifyString(cropName);
    const apiType = 'key-messages-api';
    const sheetType = 'weather_crop_calendar_final';
    const filterArr: string[] = [commodityName];
    const country = this.location.getCountry();

    return this.fireStoreDataService.getDocument(
      apiType,
      sheetType,
      filterArr,
      country
    );
  }

  getCropAdvices(dimension_id, country?) {
    return this.fireStoreDataService.getDimensionsDocument('key_messages', 'dimension_id', dimension_id, null, country);
  }

  getAllCrops() {
    let apiType = "dimensions-api";
    let sheetType = "weather_crop_calendar";
    let filterArr: string[] = [];
    const country = this.location.getCountry();
    return this.fireStoreDataService.getDocument(apiType, sheetType, filterArr, country);
  }

  getWeatherFromCIO(country_id, region_id, district_id): Observable<any> {
    return this.http
      .get(
        `${PARAMETRE.sync_url}weather/${country_id}/${region_id}/${district_id}/`
      )
      .pipe(
        tap(res =>
          res
        ),
        catchError(this.handleServerErrorWeather)
      );
  }

  getForecastFromMeteoRwanda(currentDistrict: any): Observable<any> {
    const country = this.location.getCountry();
    const countryName = localStorage.getItem('originalCountry');
    const districtName: string = currentDistrict.district_name;
    return this.http.get(`${PARAMETRE.meteo}${countryName}/cities/${currentDistrict.CityId}/forecasts`).pipe(
      tap(res => {
      }), catchError(this.handleServerError));
  }

  getWeatheRegions(): Observable<any> {
    if (this.UService.isLogged() && this.UService.hasActiveCountry()) {
      const user = JSON.parse(this.UService.getUser());
      return this.http.get('/assets/getRegions.json')
        .map(res => res['DistrictsFlatInfo'].DistrictsFlatList);
    } else {
      const countryName = localStorage.getItem('originalCountry');
      return this.http.get(`${PARAMETRE.meteo}${countryName}/cities`).pipe(
        tap(res => {
        }),
        catchError(this.handleServerError));
    }
  }

  getWeatheAdvices(id, theme_id): Observable<any> {
    return this.fireStoreDataService.getAdvices(id, theme_id);
  }

  isRwanda() {
    const country = this.location.getCountry();
    return country.country.toLowerCase() === 'rwa' ? true : false;
  }

  isSenegal() {
    const country = this.location.getCountry();
    return country.country.toLowerCase() === 'sen' ? true : false;
  }

  getIndices(): Observable<any> {
    return this.http.get(
      `${PARAMETRE.WAPOR_CATALOG_API}/workspaces/WAPOR_2/cubes?language=en&overview=false`
    );
  }

  getCubeInfo(cubeCode): Observable<any> {
    return this.http.get(
      `${PARAMETRE.WAPOR_CATALOG_API}/workspaces/WAPOR_2/cubes/${cubeCode}?language=en&overview=false`
    );
  }

  getIndexValues(indexCode, location, reqParams): Observable<any> {
    const body: { [key: string]: any } = {
      type: 'PixelTimeSeries',
      params: {
        cube: {
          code: indexCode,
          workspaceCode: 'WAPOR_2',
          language: 'en'
        },
        dimensions: [],
        measures: [],
        point: {
          crs: 'EPSG:4326',
          x: location[1],
          y: location[0]
        }
      }
    };

    for (let i = 0; i < reqParams.measures.length; i++) {
      body.params.measures.push(reqParams.measures[i].code);
    }

    for (const dimension of Object.keys(reqParams.dimensions)) {
      const item: {
        code: string;
        range?: string;
        values?: string[];
      } = {
        code: dimension
      };

      // If there's no corresponding dimension type
      // for the dimension, just ignore it.
      if (!reqParams.dimensionTypes[dimension]) {
        continue;
      }

      if (
        ['TIME', 'YEAR'].includes(reqParams.dimensionTypes[dimension])
      ) {
        if (reqParams.dimensions[dimension][0].range) {
          item.range = reqParams.dimensions[dimension][0].range;
        } else {
          item.range = reqParams.dimensions[dimension][0].code;
        }
      } else {
        item.values = [reqParams.dimensions[dimension][0].code];
      }

      body.params.dimensions.push(item);
    }
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    const options = { headers: headers };

    return this.http.post(
      `${PARAMETRE.WAPOR_QUERY_API}`,
      JSON.stringify(body),
      options
    );
  }

  getCubeMeasures(code: string): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    const url = `${PARAMETRE.WAPOR_CATALOG_API}/workspaces/WAPOR_2/cubes/${code}/measures`;
    const params = '?language=en&overview=false';

    return this.http.get(`${url}${params}`, { headers });
  }

  getCubeDimensions(code: string): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');

    const url = `${PARAMETRE.WAPOR_CATALOG_API}/workspaces/WAPOR_2/cubes/${code}/dimensions`;
    const params = '?language=en&overview=false';

    return this.http.get(`${url}${params}`, { headers });
  }

  getCubeDimensionMembers(cube: string, code: string): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');

    const url = `${PARAMETRE.WAPOR_CATALOG_API}/workspaces/WAPOR_2/cubes/${cube}/dimensions/${code}/members`;
    const params = '?language=en&overview=false&paged=false&sort=code';

    return this.http.get(`${url}${params}`, { headers });
  }

  getCountryFromCountryCode(countryCode) {
    return this.http.get('assets/country.json').pipe(
      map(res => res));
  }

  getForecast(crood, apiPath): Observable<any> {
    apiPath = apiPath.replace('latitude', crood[0]);
    apiPath = apiPath.replace('longitude', crood[1]);
    return this.http.get(apiPath).pipe(
      tap(res => {
      }), catchError(this.handleServerError));
  }
  getDetailForecast(coord, startDate, endDate): Observable<any> {
    let apiPath = environment.weatherDetail;
    apiPath = apiPath.replace('latitude', coord[0]);
    apiPath = apiPath.replace('longitude', coord[1]);
    apiPath = apiPath.replace('startDate', startDate);
    apiPath = apiPath.replace('endDate', endDate);
    return this.http.get(apiPath).pipe(
      tap(res => {
      }), catchError(this.handleServerError));
  }
}

// Interface are types on typescript. They represent an object structure
// Its very good for type checking and code completion

export interface Coord {
  lat: Number;
  lon: Number;
}

export interface City {
  coord: Coord;
  country: String;
  id: Number;
  name: String;
  population: Number;
}

export interface Forecast {
  city: City;
  list: List[];
  message: Number;
}

export interface List {
  clouds: any;
  dt: Number;
  dt_txt: String;
  main: Temperature;
  rain?: any;
  sys: any;
  weather: WeatherDescription[];
}

export interface WeatherDescription {
  description: String;
  icon: string;
  id: Number;
  main: any;
  wind: any;
}

export interface Temperature {
  grnd_level: Number;
  humidity: Number;
  pressure: Number;
  sea_level: Number;
  temp: Number;
  temp_kf: Number;
  temp_max: Number;
  temp_min: Number;
}
