import { Component, Input, OnChanges, OnInit } from '@angular/core';
import Utils from '../../shared/utils/utils';

@Component({
  selector: 'app-crop-detail-preview',
  templateUrl: './app-crop-detail-preview.component.html',
  styleUrls: ['./app-crop-detail-preview.component.scss']
})
export class AppCropDetailPreviewComponent implements OnInit, OnChanges {
  @Input() data: any;
  @Input() appName: any;
  public objectKeys = Object.keys;
  public advices: any = [];
  constructor() { }

  ngOnInit(): void {
    if (this.data) {
      this.advices = this.data.messages ? this.data.messages.reduce((acc, message) => {
        const kindof = message.group_id;
        if (acc[kindof]) {
          acc[kindof].push(message);
          return acc;
        }
        acc[kindof] = [message];
        return acc;
      }, {}) : {};
    }
  }
  ngOnChanges() {
  }
  setMyStyles() {
    return {
      'direction': 'ltr',
      'text-align': 'left'
    };
  }
  getGroupName(id) {
    return id ? id.split('_').join(' ') : '';
  }
  getDefImage(event) {
    event.target.src = Utils.defaultImage();
  }
}
