import { Component, Input, OnChanges } from '@angular/core';
import { Chart } from 'angular-highcharts';

declare var $: any;

@Component({
  selector: 'line-chart',
  template: `
  <div [chart]="chart"></div>
  `,
  styles: []
})
export class LineChartComponent implements OnChanges {
  @Input() public weatherData: any;
  @Input() public title: any;
  @Input() public seriesLabel: any;
  @Input() public xAxisData: any;
  @Input() public yAxisLabel: any;
  public chart: any;
  ngOnChanges() {
    if (this.weatherData) {
      this.chart = new Chart({
        chart: {
          type: 'line'
        },
        title: {
          text: this.title
        },
        credits: {
          enabled: false
        },
        series: [
          {
            name: this.seriesLabel,
            type: 'line',
            data: this.weatherData,
          }
        ],
        xAxis: {
          categories: this.xAxisData
        },
        yAxis: {
          title: {
            text: this.yAxisLabel
          }
        }
      });
    }
  }


  // add point to chart serie
  add() {
    this.chart.addPoint(Math.floor(Math.random() * 10));
  }
}
