import { throwError as observableThrowError, BehaviorSubject, Observable } from 'rxjs';
import { tap, catchError, map } from 'rxjs/operators';
import { UserService } from './user.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { VaccinationData } from './livestock.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GeoLocationService } from './location';
import * as isOnline from 'is-online';
import { Parametre } from './parametre';
const PARAMETRE = Parametre.getInstance();
import { LivestockService } from './livestock.service';
import { environment } from '../../../environments/environment';
import Utils from "./../utils/utils";
import { FirestoreDataService } from "./firestore-data.service";

@Injectable()
export class EnutrifoodService {

    constructor(private UService: UserService, public location: GeoLocationService, private http: HttpClient,
        public toast: MatSnackBar, private livestockService: LivestockService, private fireStoreDataService: FirestoreDataService) {
    }
    public handleError = (err: Response) => {
        if ((err.status === 500) || (err.status === 503)) {
            this.toast.open('Service is temporarily unavailable, please try again later', 'OK', { duration: 3000 });
        }
        return observableThrowError(err.json());
    }

    public getMessageForEnutrifood(commodity_id): Observable<any> {
        if (this.UService.isLogged() && this.UService.hasActiveCountry()) {
            const user = JSON.parse(this.UService.getUser());
            const country_id = user.userData.country_id;
            return this.http.get(`${PARAMETRE.sync_url}enutrifood/advices/${commodity_id}/?lang=${user.langId}`).pipe(
                map(res => res),
                catchError(this.handleError),
                tap(res => res));
        } else {
            let country_id = this.location.getCountryID();
            return this.http.get(`${PARAMETRE.sync_url}enutrifood/advices/${commodity_id}/?lang=${UserService.langId}`).pipe(
                map(res => res),
                catchError(this.handleError),
                tap(res => res));
        }
    }

    getNutrifoodCategories(id) {
        let url = 'themes/' + id + '/categories';
        return this.fireStoreDataService.getNutrifoodFilter(url);
    }
    getNutrifoodFilter(themeId, id) {
        let url = 'themes/' + themeId + '/categories/' + id + '/filters';
        return this.fireStoreDataService.getNutrifoodFilter(url);
    }
    getNutrifoodDivisions(chaildUrl?, administrative_divisions_type?) {
        const country = this.location.getCountry();
        return this.fireStoreDataService.getNutrifoodDivisions(country.country, 'divisions', chaildUrl, administrative_divisions_type);
    }
    getNutrifoodDivisionSetting(administrative_divisions_type) {
        const country = this.location.getCountry();
        return this.fireStoreDataService.getNutrifoodDivisions(country.country, 'settings', null, administrative_divisions_type);
    }
}
