import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { Location } from '@angular/common';
import { AudioService } from '../audio/audio.service';
import { VideoService } from '../video/video.service';
import { ImageService } from '../image-modal/image.service';

import { Parametre } from '../../service/parametre';
const PARAMETRE = Parametre.getInstance();
import { UserService } from '../../service/user.service';
import { environment } from '../../../../environments/environment';
import Utils from '../../utils/utils';
import { GeoLocationService } from '../../service/location';


@Component({
    selector: 'egypt-app-single-node',
    templateUrl: './egypt-single-node.component.html',
    styleUrls: ['./egypt-single-node.component.scss']
})

export class EgyptSingleNodeComponent implements OnInit {
    @Input('menuBarTitle') menuBarTitle;
    @Input('arrCommodity') arrCommodity;
    @Input('generalTabData') generalTabData;
    @Input('loader') loader;
    @Output('ga') ga = new EventEmitter<any>();;

    public txtSpeech: any;
    objectKeys = Object.keys;
    public selCountry: any;

    constructor(
        private location: Location,
        private audio: AudioService,
        private videoService: VideoService,
        private imageService: ImageService,
        public geoLocationService: GeoLocationService
    ) { }

    ngOnInit() {
        UserService.langCodeTextSpeech = JSON.parse(localStorage.getItem(environment.userLanguageStorageKey)).language_code_text_to_speech;
        this.txtSpeech = UserService.langCodeTextSpeech;
        this.selCountry = (this.geoLocationService.getCountry().country_name).toLowerCase();
    }

    back() {
        this.location.back();
    }
    public openMediaPlayer(src) {
        this.audio.openMediaPlayer(src);
    }

    openMediaForImage(src) {

        this.imageService.openMediaPlayer(src);

    }

    openMediaPlayerForVideo(src) {
        this.videoService.openMediaPlayer(src);
    }

    openMediaForSpeech(content) {
        const strippedString = content.replace(/(<([^>]+)>)/g, "");
        const strippedString1 = strippedString.replace(/&nbsp;/g, "");
        const strUrl = PARAMETRE.strTextToSpeechUrl + encodeURIComponent(strippedString1) + '&language=' + this.txtSpeech;
        this.audio.openMediaPlayer(strUrl);
    }

    setMyStyles() {
        return Utils.reverseContent();
    }

    getDefImage(event) {
        event.target.src = Utils.defaultImage();
    }

    textJustify(txt) {
        return txt.toString().replace(/(?:\r\n|\r|\n)/g, '<br>');
    }

    show_general_tab_or_not() {
        return (!this.generalTabData.general_description && !this.generalTabData.proteins && !this.generalTabData.vitamins) ? false : true;
    }
    onTabChange(event) {
        let obj = { action: '', category: 'Tab_Change', label: event.tab.textLabel }
        this.ga.emit(obj);
    }
    keyMessages(action, label) {
        let obj = { action: action, category: 'KEY_MESSAGES', label: label }
        this.ga.emit(obj);
    }
    checkContent(data) {
        return data.filter(_ => _.content).length ? true : false;
    }
}
