import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-app-enutrifood-subject-preview',
  templateUrl: './app-enutrifood-subject-preview.component.html',
  styleUrls: ['./app-enutrifood-subject-preview.component.css']
})
export class AppEnutrifoodSubjectPreviewComponent implements OnInit {
  @Input() data: any;
  @Input() appName: any;
  public title = 'Home.EnutrifoodTitle';
  constructor(private route: ActivatedRoute) { }
  public keyMessages = [];
  ngOnInit(): void {
    const section = this.route.snapshot.params.section;
    if (section === 'nutrifoodDetail')
      this.title = 'Home.EnutrifoodTitle';
    else if (section === 'citrusDetail')
      this.title = 'Home.CitrusProductionTitle';
    else if (section === 'dateProductionDetail')
      this.title = 'Home.DateProductionTitle';
    else if (section === 'householdPoultryDetail')
      this.title = 'Home.HouseholdPoultryTitle';
    else if (section === 'healthyNutritionDetail')
      this.title = 'Home.HealthyNutritionTitle';
    else if (section === 'covidFoodSafetyDetail')
      this.title = 'Home.covid19_vegetables_fruitsTitle';
    this.keyMessages = this.data.keyMessages["reduce"]((acc, message) => {
      const kindof = message.topic_id;
      if (acc[kindof]) {
        acc[kindof].push(message);
        return acc;
      }
      acc[kindof] = [message];
      return acc;
    }, {});
  }

}
