import { Component, OnInit, NgZone, OnDestroy } from '@angular/core';
import { AuthService } from "./../shared/service/auth.service";
import { Router } from "@angular/router";
import { UserService } from './../shared/service/user.service';
import { UntypedFormGroup, UntypedFormControl, Validators, FormBuilder, AbstractControl } from '@angular/forms';
import { DatePipe } from "@angular/common";
import { RegistrationService } from './../shared/service/registration.service';
import * as moment from 'moment';
import { MatSnackBar } from '@angular/material/snack-bar';
import { GeoLocationService } from './../shared/service/location';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.css']
})
export class UserProfileComponent implements OnInit, OnDestroy {
  userEmailId: string = "";
  user: any;
  id: any;
  noUser: boolean = true;
  backendUser;
  lang;
  arrKeys;
  arrValues;
  profilePage: UntypedFormGroup;
  arrOptions = ["", "Male", "Female"];
  arrCountry = ["", "India", "Rawanda"];
  formTemplate: any = [];
  boolStatus: boolean = false;
  suggetion: any;
  oldNickName: any;
  subscriptions: Subscription[] = [];
  // hasUnsavedChanges = false;
  constructor(
    public authService: AuthService,
    public router: Router,
    public ngZone: NgZone,
    public uS: UserService,
    private datePipe: DatePipe,
    public registrationService: RegistrationService,
    public toast: MatSnackBar,
    public location: GeoLocationService
  ) { }

  buildForm() {
    var group = {};
    this.arrKeys = Object.keys(JSON.parse(this.uS.getUser()));
    this.arrValues = Object.values(JSON.parse(this.uS.getUser()));

    this.subscriptions.push(this.registrationService.getRegistrationConfiguration().take(1).subscribe((res: any) => {
      this.formTemplate = [];
      let options = [
        {
          "key": "1",
          "value": "Male"
        },
        {
          "key": "2",
          "value": "Female"
        }
      ];

      // Assign country field
      this.arrKeys.forEach((element, index) => {
        if (element == "countryName") {
          group['country'] = new UntypedFormControl({ value: this.arrValues[index], disabled: true });
        }
      });
      // End

      res.forEach((element, index) => {
        if (res[index]['field_id'] == 'gender' && res[index]['type'] == 'select') {
          res[index]['options'] = options;
        }
        /* if (res[index]['field_id'] == 'country' && res[index]['type'] == 'select') {
          res[index]['options'] = country;
        } */
      });

      res.forEach((element, index) => {
        if (element['type'] != 'password') {
          this.arrKeys.forEach((e, i) => {
            if (element['field_id'] == e) {
              if (element['type'] == 'datepicker') {
                res[index]['value'] = this.arrValues[i]['seconds'];
              } else {
                res[index]['value'] = this.arrValues[i];
              }
            }
          });
          this.formTemplate.push(element);
          let dt = new Date(moment(res[index]['value'] * 1000).format('M/DD/YYYY'));
          if (element['type'] == 'datepicker' && element.required) {
            group[element['field_id']] = new UntypedFormControl(dt, [Validators.required]);
          } else if (element['type'] == 'datepicker' && !element.required) {
            group[element['field_id']] = new UntypedFormControl(dt);
          } else if (element['field_id'] == 'email' && element.required) {
            this.userEmailId = element['value'];
            group[element['field_id']] = new UntypedFormControl({ value: element['value'], disabled: true });
          } else if (element.field_id == 'nickName') {
            this.oldNickName = element.value;
            group[element.field_id] = new UntypedFormControl({ value: element.value || '', disabled: false }, [Validators.required, this.validateNickName().bind(this), Validators.minLength(5)]);
          } else if (element.required && element['field_id'] != 'email' && element['type'] != 'datepicker') {
            group[element['field_id']] = new UntypedFormControl(element['value'], [Validators.required]);
          } else if ((element.field_id == 'firstName' || element.field_id == 'lastName') && element.required) {
            group[element['field_id']] = new UntypedFormControl(element['value'], [Validators.required]);
          } else if ((element.field_id == 'firstName' || element.field_id == 'lastName') && !element.required) {
            group[element['field_id']] = new UntypedFormControl(element['value']);
          } else {
            group[element['field_id']] = new UntypedFormControl(element['value']);
            group['countryName'] = new UntypedFormControl({ value: element['countryName'], disabled: true });
          }
          this.profilePage = new UntypedFormGroup(group);
        }
      });
      this.boolStatus = true;
      //this.hasUnsavedChanges = this.checkIfMandoryFieldAvailble;
    }));
  }

  ngOnInit() {
    if (this.uS.isLogged()) {
      this.buildForm();
    } else {
      this.router.navigate(['/register']);
    }
  }

  onSubmit(obj) {
    this.boolStatus = false;
    let user = JSON.parse(localStorage.getItem('user'));
    if (this.profilePage.invalid) {
      return;
    } else {
      obj.roles = user.roles;
      this.oldNickName = obj.nickName;
      let res = this.authService.UpdateUserData(user, obj);
      this.boolStatus = true;
      //this.hasUnsavedChanges = this.checkIfMandoryFieldAvailble;
      this.toast.open('User profile updated successfuly.', 'OK', { duration: 10000 });
    }
  }

  getErrorMessage(controlName) {
    if (controlName == 'nickName') {
      return this.profilePage.get(controlName).hasError('required') ? 'Field is required' :
        this.profilePage.get(controlName).hasError('duplicateNickName') ? 'This nickname is taken.' :
          this.profilePage.get(controlName).hasError('minlength') ? 'Minimum 5 characters required' : '';
    } else
      return this.profilePage.get(controlName).hasError('required') ? 'Field is required' : '';
  }

  navigateToHome() {
    // if (!this.hasUnsavedChanges) {
    //   this.toast.open('Please update your profile!', 'OK', { duration: 10000 });
    //   return;
    // }
    this.router.navigate(['/']);
  }
  validateNickName() {
    return (control: AbstractControl) => {
      if (control.value && control.value !== control.value.toLowerCase())
        this.profilePage.get('nickName')?.setValue(control.value.toLowerCase());
      if (control.value && control.value.length >= 5 && this.oldNickName !== control.value.toLowerCase()) {
        this.profilePage.controls['nickName']?.setErrors(null);
        this.suggetion = null;
        this.subscriptions.push(this.registrationService.get_UserByField(control.value).subscribe((res: any) => {
          if (res.length && this.oldNickName !== control.value.toLowerCase()) {
            this.profilePage.controls['nickName']?.setErrors({ 'duplicateNickName': true });
            this.getNickNameSuggetion(control.value);
          }
          else {
            this.profilePage.controls['nickName']?.setErrors(null);
            this.suggetion = null;
          }
        }));
      }
    }
  }
  getNickNameSuggetion(value: string) {
    const suggestion = value.concat(Math.floor(10 + Math.random() * 90).toString())
    this.subscriptions.push(this.registrationService.get_UserByField(suggestion).subscribe((res: any) => {
      if (res.length)
        this.getNickNameSuggetion(value);
      else
        this.suggetion = ' Try another like ' + suggestion;
    }));
  }
  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
  // get checkIfMandoryFieldAvailble() {
  //   if (this.profilePage.get('nickName').value && this.profilePage.get("firstName").value && this.profilePage.get("lastName").value)
  //     return true;
  //   else
  //     return false;
  // }
}


