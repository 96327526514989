
import { first } from 'rxjs/operators';
import { Subscription, Observable, BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/compat/storage';

export interface Imgs {
    icon: string;
    image: string;
    image2?: string;
}

export interface Imgs$ {
    icon: Observable<string | null>;
    image: Observable<string | null>;
    image2?: Observable<string | null>;
}

const MARKET_WHITE = 'assets/market.svg';
const MARKET_PURPLE = 'assets/market-purple.svg';
const MARKET_PURPLE_2X = 'assets/market-purple-2x.svg';
const CLOUD_WHITE = 'assets/cloud.svg';
const CLOUD_BLUE = 'assets/cloud-blue.svg';
const COW_SILHOUETTE_GREEN = 'assets/cow-silhouette-green.svg';
const COW_SILHOUETTE_WHITE = 'assets/cow-silhouette.svg';
const NUTRIFOOD_ORANGE = 'assets/nutrifood-orange.svg';
const NUTRIFOOD_WHITE = 'assets/nutrifood-white.svg';
const BUG_RED = 'assets/bug-red.svg';
const BUG_WHITE = 'assets/bug-white.svg';
const LOGO = 'assets/fao-logo-white.svg';
const weatherIconPath = "img/icons/meteo/weather/";
const googlePlayPngPath = "assets/google-play.png";
const AGRI_IMGS = {
    icon: MARKET_PURPLE,
    image: MARKET_PURPLE_2X,
    image2: MARKET_WHITE
};

const WEATHER_IMGS = {
    icon: CLOUD_BLUE,
    image: CLOUD_WHITE
};

const LIVESTOCK_IMGS = {
    icon: COW_SILHOUETTE_GREEN,
    image: COW_SILHOUETTE_WHITE
};

const NUTRIFOOD_IMGS = {
    icon: NUTRIFOOD_ORANGE,
    image: NUTRIFOOD_WHITE
};

const BUG_IMGS = {
    icon: BUG_RED,
    image: BUG_WHITE
};

@Injectable()
export class ImageStorageService {
    private agriImgSubject: BehaviorSubject<Imgs> = new BehaviorSubject(AGRI_IMGS);
    private weatherImgSubject: BehaviorSubject<Imgs> = new BehaviorSubject(WEATHER_IMGS);
    private livestockImgSubject: BehaviorSubject<Imgs> = new BehaviorSubject(LIVESTOCK_IMGS);
    private nutriImgSubject: BehaviorSubject<Imgs> = new BehaviorSubject(NUTRIFOOD_IMGS);
    private bugImgSubject: BehaviorSubject<Imgs> = new BehaviorSubject(BUG_IMGS);
    private logoSubject: BehaviorSubject<string> = new BehaviorSubject(LOGO);

    public agriImg$: Observable<Imgs> = this.agriImgSubject.asObservable();
    public weatherImg$: Observable<Imgs> = this.weatherImgSubject.asObservable();
    public livestockImg$: Observable<Imgs> = this.livestockImgSubject.asObservable();
    public nutriImg$: Observable<Imgs> = this.nutriImgSubject.asObservable();
    public bugImg$: Observable<Imgs> = this.bugImgSubject.asObservable();
    public logoImg$: Observable<string> = this.logoSubject.asObservable();

    constructor(private storage: AngularFireStorage) { }


    loadLogo(): void {
        this.storage.ref(LOGO).getDownloadURL().pipe(first()).subscribe(
            res => {
                const logo = res;
                this.logoSubject.next(logo);
            },
            err => {
            });
    }

    // To improve using an array of assets and default wrapped in an object
    loadAsset(asset1: string, asset2: string): Imgs {
        let Img = asset1;
        let Icon = asset2;
        this.storage.ref(asset1).getDownloadURL().pipe(first()).subscribe(
            res => {
                Img = res;
            },
            err => {

            });

        this.storage.ref(asset2).getDownloadURL().pipe(first()).subscribe(
            res => {
                Icon = res;
            },
            err => {

            });
        return { image: Img, icon: Icon };
    }

    loadAgrimarketImgs(): void {
        let dls = this.loadAsset(AGRI_IMGS.image, AGRI_IMGS.icon);
        this.storage.ref(AGRI_IMGS.image2).getDownloadURL().pipe(first()).subscribe(
            res => {
                dls.image2 = res;
            },
            err => {

            });
        this.agriImgSubject.next(dls);
    }

    loadWeatherImgs(): void {
        const dls = this.loadAsset(WEATHER_IMGS.image, WEATHER_IMGS.icon)
        this.weatherImgSubject.next(dls);
    }

    loadLivestockImgs(): void {
        const dls = this.loadAsset(LIVESTOCK_IMGS.image, LIVESTOCK_IMGS.icon);
        this.livestockImgSubject.next(dls);
    }

    loadNutrifoodImgs(): void {
        const dls = this.loadAsset(NUTRIFOOD_IMGS.image, NUTRIFOOD_IMGS.icon);
        this.nutriImgSubject.next(dls);
    }

    loadBugImgs(): void {
        const dls = this.loadAsset(BUG_IMGS.image, BUG_IMGS.icon);
        this.bugImgSubject.next(dls);
    }

    public loadWheaterIconUrl(name: string): Observable<string> {
        return this.storage.ref(weatherIconPath + name + ".png").getDownloadURL();
    }

    getx48Image(inputUrl: string): string {
        // Parse the input URL
        if (!inputUrl || !inputUrl.includes("firebasestorage.googleapis.com")) {
            return inputUrl;
        }

        const uri = new URL(decodeURIComponent(inputUrl));

        // Get the original filename and extension
        const pathSegments = uri.pathname.split('/');
        const originalFileName = pathSegments[pathSegments.length - 1];
        const originalFileExtension = originalFileName.split('.').pop() || "";

        const namex48 = `${originalFileName.substring(0, originalFileName.lastIndexOf(originalFileExtension) - 1)}_48x48.${originalFileExtension}`;

        const newUrl = inputUrl.replace(originalFileName, `thumbnails%2F${namex48}`);

        return newUrl;
    }

}
