import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UserService } from './user.service';
import 'rxjs/add/operator/map'

@Injectable()
export class GoogleService {
  constructor(private http: HttpClient) { }

  translate(obj: GoogleObj) {
    return this.http.post(url + 'AIzaSyCOXsnmPkkYNbQ-83pBhlgce0hgS3rVsro', obj);
  }
}

const url = 'https://translation.googleapis.com/language/translate/v2?key=';

export class GoogleObj {
  q: string;
  target: string;
  readonly format: string = 'text';

  constructor() { }
}
