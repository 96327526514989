import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { EnutrifoodService } from '../../service/nutrifood.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import Utils from '../../utils/utils';
import { environment } from '../../../../../src/environments/environment';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-division-filters',
  templateUrl: './division-filters.component.html',
  styleUrls: ['./division-filters.component.css']
})
export class DivisionFiltersComponent implements OnInit, OnDestroy {
  setting: any;
  lang_code: any;
  subscriptions: Subscription[] = [];
  constructor(public dialogRef: MatDialogRef<DivisionFiltersComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private enutrifoodService: EnutrifoodService,
    public route: ActivatedRoute) {
    this.lang_code = JSON.parse(localStorage.getItem(environment.userLanguageStorageKey)).code;
  }

  ngOnInit(): void {
    if (!this.data.filter)
      this.getDivisionSetting();
    else
      this.setting = this.data.filter;
  }
  getDivisionSetting() {
    this.subscriptions.push(this.enutrifoodService.getNutrifoodDivisionSetting(this.data.themeData.administrative_divisions_type).subscribe((setting: any) => {
      if (setting.length) {
        this.setting = Utils.sort(setting, 'level');
        this.setting.forEach(element => {
          element.value = '';
          element.options = [];
        });
        this.getFreashMarketFilters(this.setting[0]);
      }
    }));
  }
  getNestedData(nextIndex) {
    let url = ''
    for (let index = 0; index < nextIndex; index++) {
      let docId = this.setting[index].options.filter(_ => _.level_id == this.setting[index].value)[0].docId;
      url = url + '/' + docId + '/subdivision'
    }
    this.getFreashMarketFilters(this.setting[nextIndex], url);
  }
  getFreashMarketFilters(setting, url?) {
    this.subscriptions.push(this.enutrifoodService.getNutrifoodDivisions(url, this.data.themeData.administrative_divisions_type).subscribe((data: any) => {
      if (data && data.length) {
        setting.options = data;
      }
    }));
  }
  filterData() {
    this.dialogRef.close(this.setting);
  }

  filteredData(list, searchText) {
    if (searchText && list.length)
      return list.filter(_ => _.name[this.lang_code].toString().toLowerCase().includes(searchText.toLowerCase()))
    return list;
  }
  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
