import { TeamListComponent } from './team-list.component';
//import { TeamMembersComponent } from './team-members/team-members.component';
import { TeamComponent } from './team/team.component';
// import { SecureInnerPagesGuard } from './../shared/guards/secure-inner-pages.guard';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../shared/guards/auth.guard';

const teamlist: Routes = [
    {
        path: 'team-list',
        component: TeamListComponent,
        canActivate: [AuthGuard],
        children: [
            { path: '', redirectTo: 'team', pathMatch: 'full' },
            { path: 'team', component: TeamComponent, canActivate: [AuthGuard] },
            { path: 'team/:commodity_parent', component: TeamComponent, canActivate: [AuthGuard] }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(teamlist)],
    exports: [RouterModule],
})
export class TeamListRoutingModule { }
