import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import Utils from '../../shared/utils/utils';
export interface DialogData {
  title: string;
  subtitle: string;
  hint: string;
  Details: string;
  name: string;
  HideInput: string;
}
@Component({
  selector: 'app-rply-dialog',
  templateUrl: './rply-dialog.component.html',
  styleUrls: ['./rply-dialog.component.scss']
})
export class RplyDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<RplyDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  onNoClick(): void {
    this.dialogRef.close();
  }
  setMyStyles() {
    return Utils.reverseContent();
  }
}