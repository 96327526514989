import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { AuthService } from './../shared/service/auth.service';
import { UserService } from '../shared/service/user.service';
import { RegistrationService } from './../shared/service/registration.service';
import { User } from '../shared/models/user';
import { UntypedFormGroup, UntypedFormControl, Validators, UntypedFormBuilder, AbstractControl, ValidatorFn } from '@angular/forms';
import { Router } from '@angular/router';
import { GeoLocationService } from './../shared/service/location';
import { LoginComponent } from "../login/login.component";
import * as moment from 'moment';
import Utils from '../shared/utils/utils';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.css']
})

export class RegistrationComponent implements OnInit, OnDestroy {

  regConfigDetails: any;
  myFormGroup: UntypedFormGroup;
  formTemplate: any = [];
  status: boolean = false;
  cmpPass: any;
  post: any;
  suggetion: any = null;
  subscriptions: Subscription[] = [];
  constructor(
    public authService: AuthService,
    public registrationService: RegistrationService,
    private fb: UntypedFormBuilder,
    public router: Router,
    public location: GeoLocationService
  ) {
    this.createForm();
  }

  ngOnInit() { }

  createForm() {
    let emailregex: RegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    this.subscriptions.push(this.registrationService.getRegistrationConfiguration().subscribe((res: any) => {
      let group = {};
      if (res) {
        const country = JSON.parse(localStorage.getItem("countryData"));
        // if (country.country == 'VEN')
        res = Utils.sort(res, 'order');
        let options = [
          {
            "key": "1",
            "value": "Male"
          },
          {
            "key": "2",
            "value": "Female"
          }
        ];

        /* let country = [
          {
            "key": "1",
            "value": "India"
          },
          {
            "key": "2",
            "value": "Rawanda"
          }
        ]; */

        res.forEach((element, index) => {
          if (res[index]['field_id'] == 'gender' && res[index]['type'] == 'select') {
            res[index]['options'] = options;
          }
          /* if (res[index]['field_id'] == 'country' && res[index]['type'] == 'select') {
            res[index]['options'] = country;
          } */
        });
        this.formTemplate = res;
        this.formTemplate.forEach(f => {
          if (f.field_id == 'email' && f.required) {
            group[f.field_id] = new UntypedFormControl(f.value || '', [Validators.required, Validators.pattern(emailregex)]);
          } else if (f.field_id == 'password' && f.required) {
            group[f.field_id] = new UntypedFormControl(f.value || '', [Validators.required, this.checkPassword]);
          } else if (f.field_id == 'confirmpassword' && f.required) {
            group[f.field_id] = new UntypedFormControl(f.value || '', [Validators.required, this.checkPassword]);
          } else if (f.field_id == 'nickName') {
            group[f.field_id] = new UntypedFormControl(f.value || '', [Validators.required, this.validateNickName().bind(this), Validators.minLength(5)]);
          } else if (f.required && f.field_id != 'email' && f.field_id != 'password' && f.field_id != 'confirmpassword' && f.field_id != 'nickName') {
            group[f.field_id] = new UntypedFormControl(f.value || '', Validators.required);
          } else {
            group[f.field_id] = new UntypedFormControl(f.value || '');
          }

        })
        this.myFormGroup = new UntypedFormGroup(group, this.pwdMatchValidator);
        this.status = true;
      }
    }));
  }

  pwdMatchValidator(FormGroup) {
    let sts = (FormGroup.get('password').value != FormGroup.get('confirmpassword').value) ? { 'mismatch': true } : null;
    return sts;
  }

  getErrorMessage(controlName) {
    if (controlName == 'email') {
      return this.myFormGroup.get(controlName).hasError('required') ? 'Field is required' :
        this.myFormGroup.get(controlName).hasError('pattern') ? 'Not a valid email' : '';
    } else if (controlName == 'nickName') {
      return this.myFormGroup.get(controlName).hasError('required') ? 'Field is required' :
        this.myFormGroup.get(controlName).hasError('duplicateNickName') ? 'This nickname is taken.' :
          this.myFormGroup.get(controlName).hasError('minlength') ? 'Minimum 5 characters required' : '';
    } else {
      return this.myFormGroup.get(controlName).hasError('required') ? 'Field is required' : '';
    }
  }

  getErrorPassword(controlName) {
    return this.myFormGroup.get(controlName).hasError('required')
      ? 'Field is required (at least eight characters, one uppercase letter and one number)' :
      this.myFormGroup.get(controlName).hasError('requirements')
        ? 'Password needs to be at least eight characters, one uppercase letter and one number' : '';
  }

  cmpPassword(controlName) {
    this.cmpPass = (this.myFormGroup.get('password').value != this.myFormGroup.get('confirmpassword').value)
      ? 'Password not match' : '';
    return this.cmpPass;
  }

  checkPassword(control) {
    let enteredPassword = control.value
    let passwordCheck = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/;
    return (!passwordCheck.test(enteredPassword) && enteredPassword) ? { 'requirements': true } : null;
  }

  onSubmit(obj) {
    if (this.myFormGroup.invalid) {
      return;
    } else {
      const country = this.location.getCountry();
      obj.countryName = country.country_name;
      obj.country_iso3 = country.country;
      obj.roles = { dsp: ["user"] };
      obj.regDate = moment().format('DD/MM/YYYY');
      obj.created_by = 'forum';
      obj.isActive = true;
      this.authService.SignUp(obj);
    }
  }
  validateNickName() {
    return (control: AbstractControl) => {
      if (control.value && control.value !== control.value.toLowerCase())
        this.myFormGroup.get('nickName').setValue(control.value.toLowerCase());
      if (control.value && control.value.length >= 5) {
        this.myFormGroup.get('nickName').setErrors(null);
        this.suggetion = null;
        this.subscriptions.push(this.registrationService.get_UserByField(control.value).subscribe((res: any) => {
          if (res.length) {
            this.myFormGroup.get('nickName').setErrors({ 'duplicateNickName': true });
            this.getNickNameSuggetion(control.value);
          }
          else {
            this.myFormGroup.get('nickName').setErrors(null);
            this.suggetion = null;
          }
        }));
      }
    }
  }
  getNickNameSuggetion(value: string) {
    const suggestion = value.concat(Math.floor(10 + Math.random() * 90).toString())
    this.subscriptions.push(this.registrationService.get_UserByField(suggestion).subscribe((res: any) => {
      if (res.length)
        this.getNickNameSuggetion(value);
      else
        this.suggetion = ' Try another like ' + suggestion;
    }));
  }
  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
