import { DashboardComponent } from './admin/dashboard/dashboard.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { ForumRoutingModule } from './forum.routing';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { ForumComponent } from './forum';
import { AddQuestionComponent } from './add-question/add-question.component';
import { QuestionDetailsComponent } from './question-details/question-details.component';
import { ForumhomeComponent } from './forumhome/forumhome.component';
import { RplyDialogComponent } from './rply-dialog/rply-dialog.component';
import { CommentComponent } from './comment/comment.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { ReportsComponent } from './admin/reports/reports.component';
import { UsersComponent } from './admin/users/users.component';
import { SubforumsComponent } from './admin/subforums/subforums.component';
import { SectionsComponent } from './admin/sections/sections.component';
import { ForumsettingsComponent } from './admin/forumsettings/forumsettings.component';
import { AdminComponent } from './admin/admin.component';
import { UserDetailsComponent } from './admin/user-details/user-details.component';
import { RichtextareaComponent } from './richtextarea/richtextarea.component';
import { SubforumsListComponent } from './admin/subforums-list/subforums-list.component';
import { SectionsListComponent } from './admin/sections-list/sections-list.component';
import { CaveatDialogComponent } from './caveat-dialog/caveat-dialog.component';
import { CaveatComponent } from './caveat/caveat.component';
import { UserpostComponent } from './userpost/userpost.component';
import { LoginRequestDialogComponent } from './login-request-dialog/login-request-dialog.component';


@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        FormsModule,
        ForumRoutingModule,
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        ReactiveFormsModule,
        NgxPaginationModule,
        Ng2SearchPipeModule
    ],
    bootstrap: [LoginRequestDialogComponent, RplyDialogComponent, CaveatComponent, CaveatDialogComponent],
    declarations: [
        ForumComponent,
        AddQuestionComponent,
        QuestionDetailsComponent,
        ForumhomeComponent,
        RplyDialogComponent,
        CommentComponent,
        ReportsComponent,
        UsersComponent,
        SubforumsComponent,
        SectionsComponent,
        ForumsettingsComponent,
        AdminComponent,
        DashboardComponent,
        UserDetailsComponent,
        CommentComponent, RichtextareaComponent, SubforumsListComponent, SectionsListComponent, CaveatDialogComponent, CaveatComponent, UserpostComponent, LoginRequestDialogComponent
    ],
    exports: [
        ForumComponent,
        AddQuestionComponent,
        QuestionDetailsComponent,
        ForumhomeComponent,
        ForumRoutingModule,
        ReportsComponent,
        UsersComponent,
        SubforumsComponent,
        SectionsComponent,
        ForumsettingsComponent,
        AdminComponent,
        DashboardComponent
    ]
})
export class ForumModule { }
// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
