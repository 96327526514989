import { forkJoin as observableForkJoin, Subscription, Observable, Subject, forkJoin } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { GeoLocationService } from '../shared/service/location';
import { LayoutService } from '../shared/service/layout.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { WeatherService } from '../shared/service/weather.service';
import { AudioService } from '../shared/component/audio/audio.service';
import { ViewEncapsulation } from '@angular/core';
import 'rxjs/add/operator/catch';
import { UserService } from '../shared/service/user.service';
import { UntypedFormControl } from '@angular/forms';
import { startWith, map, filter } from 'rxjs/operators';
import { ImageStorageService } from '../shared/service/img-storage.service';
import { LivestockService } from '../shared/service/livestock.service';
import { VideoService } from '../shared/component/video/video.service';
import { ImageService } from '../shared/component/image-modal/image.service';
import { Parametre } from '../shared/service/parametre';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
const PARAMETRE = Parametre.getInstance();
import { environment } from '../../environments/environment';
import Utils from '../shared/utils/utils';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { NgxSpinnerService } from "ngx-spinner";
import { WeatherAlertComponent } from './weather-alert/weather-alert.component';
import { MatDialog } from '@angular/material/dialog';
import { inspect } from 'util';
import { TranslationService } from '../shared/service/translation.service';
import { FirestoreDataService } from '../shared/service/firestore-data.service';
import { LinkService } from '../shared/component/link/link.service';

@Component({
  selector: 'app-insect',
  templateUrl: 'insect.html',
  styleUrls: ['insect.scss'],
  encapsulation: ViewEncapsulation.None
})

export class InsectComponent implements OnInit, OnDestroy {
  parentSubject: Subject<any> = new Subject();
  public objectKeys = Object.keys;
  currentDistrict: any = null;
  l: Subscription;
  isDataLoading: Boolean;
  day$: Observable<any>;
  week$: any;
  cols: any;
  rowH: any;
  advices: { [key: string]: any } = {};
  weeklyAdv = [];
  datas;
  showLegend: boolean;
  view: any[];
  indices: any[];
  indexValues: { [key: string]: any } = {};
  myControl: UntypedFormControl = new UntypedFormControl();
  public showData: Boolean;
  showDataInsects: Boolean;
  options: any = [];
  filteredOptions: Observable<any[]>;
  public txtSpeech: any;
  selectedTab = 0;
  public addressName: string;
  norwayApidata: any = [];
  public apiPath: string;
  strOriginalCountry: string;
  appData: any;
  groups: any;
  providers: any;
  lang_code: any;
  groupList: any = [];
  VENlang_code: any;
  keyMessage = '';
  subscriptions: Subscription[] = [];
  selectedTabData: any;
  coords: any = [];
  tab: any = {};
  previousUrl: string;
  currentUrl: string;
  constructor(
    private dialog: MatDialog,
    private route: ActivatedRoute,
    public router: Router,
    public weather: WeatherService,
    private audio: AudioService,
    private TranslateS: TranslationService,
    private layout: LayoutService,
    private us: UserService,
    public location: GeoLocationService,
    public toast: MatSnackBar,
    public imageStorageService: ImageStorageService,
    private livestockService: LivestockService,
    private videoService: VideoService,
    private firestoreService: FirestoreDataService,
    private imageService: ImageService,
    private userService: UserService,
    private gaService: GoogleAnalyticsService,
    private spinner: NgxSpinnerService,
    private linkService: LinkService
  ) {
    this.currentUrl = localStorage.getItem("currentUrl");
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.previousUrl = this.currentUrl;
        this.currentUrl = event.url;
        if (this.previousUrl == 'insect' && this.currentUrl == '/insect') {
          localStorage.setItem('WeatherPopupStatus', 'false')
        }
      };
    });
    if (Utils.getWeatherTabIndex()) {
      this.selectedTab = Utils.getWeatherTabIndex();
    }
  }

  ngOnInit() {
    this.keyMessage = this.route.snapshot.queryParams['keyMessage'];
    if (this.keyMessage) {
      this.selectedTab = this.keyMessage == 'cropcalendar' ? 2 : 1;
      this.keyMessage = this.keyMessage == 'cropcalendar' ? '' : this.keyMessage;
    }
    this.strOriginalCountry = localStorage.getItem('originalCountry').toLowerCase();
    this.lang_code = JSON.parse(localStorage.getItem(environment.userLanguageStorageKey)).code;
    const themes = JSON.parse(localStorage.getItem('appThemes'));
    this.appData = themes.filter(_ => _.template_id === 'insect')[0];
    let enabledTabs = this.appData.tabs.filter(_ => _.status === undefined || _.status).sort((a, b) => a.order - b.order);
    this.selectedTabData = enabledTabs[this.selectedTab];
    UserService.langCodeTextSpeech = JSON.parse(localStorage.getItem(environment.userLanguageStorageKey)).text_to_speech_code;
    this.txtSpeech = UserService.langCodeTextSpeech;
    this.detect();
    this.showData = false;
    this.showDataInsects = false;
    this.view = [this.layout.chartHeight, this.layout.chartWidth];
    if (this.layout.mode === 'xs') {
      this.showLegend = false;
    } else {
      this.showLegend = true;
    }
    this.getForecastData();
    this.getInsects(this.appData.id);
    if (this.selectedTabData.id == 'advice')
      this.getAdvices();
  }

  // filter function for filter
  filter(val: string) {
    if (val && typeof val === 'object') {
      this.refreshDataWithInitLocation();
    }
    return this.options.filter(option => option.City);
  }

  displayFn(value: any): string {
    return value.City;
  }

  refreshDataWithInitLocation() {
    this.isDataLoading = true;
    this.subscriptions.push(this.weather.getWeatheAdvices("advice", this.appData.id).subscribe(e => {
      this.weeklyAdv = e;
      this.weeklyAdv = this.weeklyAdv.filter(_ => _.status == undefined || _.status);
      this.advices = this.weeklyAdv.reduce((acc, message) => {
        const kindof = message.group_id;// + "#" + message.provided_by;
        if (acc[kindof]) {
          acc[kindof].push(message);
          return acc;
        }
        acc[kindof] = [message];
        return acc;
      }, {});
      var groups = [];
      var filteredGroups = this.groups.filter(_ => _.group_type == "group" && Object.keys(this.advices).includes(_.id));
      filteredGroups.forEach(element => {
        groups.push(element.id);
      });
      this.groupList = groups;
    }));
    this.subscriptions.push(this.weather.getWeatheRegions().subscribe(e => {
      this.options = e;
    }));
    this.getWeekWeather();
  }

  refreshData(event: any) {
    this.notifyChildren();
    this.subscriptions.push(this.weather.getWeatheRegions().subscribe(e => {
      this.options = e;
      if (this.currentDistrict === null) {
        /*if (this.weather.isRwanda()) {
          this.currentDistrict = e[1];
        } else {
          this.currentDistrict = e[0];
        }*/
        this.currentDistrict = e[0];
      }
      this.getWeekWeather();
    }));
  }

  getWeekWeather() {
    this.showData = false;
    this.weather.getForecastFromMeteoRwanda(this.currentDistrict).forEach(res => {
      this.spinner.hide();
      if (res) {
        if (this.weather.isSenegal()) {
          const weatherObj: any = res.city.forecastDay;
          weatherObj.forEach((element) => {
            element['weatherMorning'] = Utils.modifyString(element['weatherMorning']);
            element['weatherAftn'] = Utils.modifyString(element['weatherAftn']);
          });
          weatherObj.data = weatherObj;
          this.week$ = weatherObj;
        } else {
          const weatherObj: any = res.city.forecast.forecastDay;
          weatherObj.forEach((element, index) => {
            const weather: string = element['weather'].replace(/ /g, '');
            element['weather'] = Utils.modifyString(element['weather']);
          });
          weatherObj.data = weatherObj;
          this.week$ = weatherObj;
        }
      }
    }).then(() => {
      this.showData = true;
    }).catch(() => {
      this.spinner.hide();
      this.week$ = null;
      this.showData = true;
    });
  }

  openMediaPlayer(src) {
    this.audio.openMediaPlayer(src);
  }

  openMediaForImage(src) {
    this.imageService.openMediaPlayer(src);
  }

  notifyChildren() {
    this.parentSubject.next(true);
  }

  detect() {
    this.location.localise();
  }

  openMediaPlayerForVideo(src) {
    this.videoService.openMediaPlayer(src);
  }

  openMediaForSpeech(content) {
    const strUrl = PARAMETRE.strTextToSpeechUrl + encodeURIComponent(content) + '&language=' + this.txtSpeech;
    this.audio.openMediaPlayer(strUrl);
  }

  setMyStyles() {
    return Utils.reverseContent();
  }

  onImgError(event) {
    event.target.src = Utils.offlineImage('');
  }

  tabChange(i) {
    this.gaService.event('Insect Recognition', 'Tab_Change', i.tab.textLabel);
    this.selectedTab = Utils.setWeatherTabIndex(i.index);
    let enabledTabs = this.appData.tabs.filter(_ => _.status === undefined || _.status).sort((a, b) => a.order - b.order);
    this.selectedTabData = enabledTabs[this.selectedTab];
    if (this.selectedTabData.id == 'insects')
      this.getInsects(this.appData.id);
    if (this.selectedTabData.id == 'forecast')
      this.getForecastData();
    if (this.selectedTabData.id == 'advice')
      this.getAdvices();
  }

  getnewWeekWeather(apiPath, coords) {
    this.showData = false;
    this.weather.getForecast(coords, apiPath).forEach(res => {
      this.spinner.hide();
      if (res) {
        this.showData = true;
        this.norwayApidata = res.city.forecastDay;
        this.inspectWeatherForRain();
      }
    }).then(() => {
      this.showData = true;
    }).catch(() => {
      this.spinner.hide();
      this.week$ = null;
      this.showData = true;
    });
  }

  inspectWeatherForRain() {
    const hasRainyWeather = this.norwayApidata.some(item => item.weather.toLowerCase().includes("rain"));
    // inspect(hasRainyWeather);
    let weatherPopup = JSON.parse(localStorage.getItem('WeatherPopupStatus'));
    if (hasRainyWeather && weatherPopup) {
      this.displayRainAlert();
    }
  }

  private displayRainAlert() {
    const country = JSON.parse(localStorage.getItem('countryData'));
    this.subscriptions.push(this.firestoreService.getWeatherAlertTranslation(country.country).subscribe((data) => {
      data = Utils.translationConvertor(data);
      localStorage.setItem('localLanguageData', JSON.stringify(data));
    }));
    const title: string = "insect_recognition.rain_alert_title";
    const subtitle: string = "insect_recognition.rain_alert";
    const dialogRef = this.dialog.open(WeatherAlertComponent, {
      width: '400px',
      data: { title: this.TranslateS.translate(title), subtitle: this.TranslateS.translate(subtitle) }
    });
  }

  ga(action, category, label) {
    this.gaService.event(action, category, label);
  }

  getTabData(id) {
    if (this.appData && this.appData.tabs.length)
      return this.appData.tabs.filter(_ => _.id == id && (_.status == undefined || _.status))[0];
    else return '';
  }
  getGroupDetails(id) {
    if (id == '') return 'system.information_tab';
    if (this.groups && this.groups.length) {
      var data = this.groups.filter(_ => _.id == id);
      return data.length ? data[0].name : id;
    } else return id
  }
  getProviderDetaiils(id, returnValue) {
    if (this.providers && this.providers.length) {
      var data = this.providers.filter(_ => _.id == id);
      return data.length ? data[0][returnValue] : id;
    } else return id
  }
  isTabOpened(keyMessages) {
    return this.keyMessage && keyMessages.filter(_ => _.id == this.keyMessage).length ? true : false;
  }
  getProviders(keymessages) {
    return this.groupByKey(keymessages, 'provider_id');
  }
  groupByKey(data, key) {
    return data.reduce(function (rv, x) {
      x[key] = !x[key] ? '' : x[key];
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  };
  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe())
  }

  getInsects(parentid) {
    this.subscriptions.push(this.livestockService.getAllInsects(parentid).subscribe(res => {
      if (res) {
        this.showDataInsects = true;
        this.tab.data = res
        this.tab.themeId = parentid;
      }
    }));
  }

  getDimentions(parentid) {
    this.subscriptions.push(this.livestockService.getAllDimensions(parentid, 'theme_id', 'order').subscribe(res => {
      this.showData = true;
      if (res) {
        res = res.filter(_ => _.status == undefined || _.status && _.tab_id == 'species');
        this.tab.data = res
        this.tab.themeId = parentid;
      }
    }));
  }

  getForecastData() {
    if ((this.week$ && this.week$.length) || (this.norwayApidata && this.norwayApidata.length)) {
      return;
    }
    if (this.getTabData('forecast')) {
      this.spinner.show();
      this.apiPath = this.getTabData('forecast').api_path ? this.getTabData('forecast').api_path : '';
      if (this.apiPath === '') {
        // for filter
        this.filteredOptions = this.myControl.valueChanges.pipe(
          startWith(''),
          map(val => this.filter(val))
        );
        this.subscriptions.push(this.weather.getWeatheRegions().subscribe(e => {
          this.options = e;
          if (this.currentDistrict === null) {
            this.currentDistrict = e[0];
          }
          this.day$ = this.weather
            .getWeatherFromCIO(
              this.currentDistrict.country_id,
              this.currentDistrict.region_id,
              this.currentDistrict.district_id
            )
            .map(res => res.forecast[0])
            .catch(this.weather.handleServerError);

          // Original code end
        }));
      } else {
        this.subscriptions.push(this.location.locality$.subscribe(location => {
          if (location.originalCoords && location.originalCoords.lat && location.originalCoords.long && !this.coords.length) {
            this.coords = [
              location.originalCoords.lat,
              location.originalCoords.long
            ];
            if (!this.norwayApidata.length)
              this.getnewWeekWeather(this.apiPath, this.coords);
          }
        }));
      }
    }
  }
  getAdvices() {
    if (this.groupList && this.groupList.length) {
      return;
    }
    this.spinner.show();
    this.subscriptions.push(this.livestockService.getMessageGroup().subscribe(res => {
      this.groups = res;
    }));
    this.subscriptions.push(this.livestockService.getProviders().subscribe(res => {
      this.providers = res;
    }));
    this.subscriptions.push(this.weather.getWeatheAdvices("advice", this.appData.id).subscribe(e => {
      this.weeklyAdv = e;
      this.weeklyAdv = this.weeklyAdv.filter(_ => _.status == undefined || _.status);
      this.advices = this.weeklyAdv.reduce((acc, message) => {
        const kindof = message.group_id;// + "#" + message.provided_by;
        if (acc[kindof]) {
          acc[kindof].push(message);
          return acc;
        }
        acc[kindof] = [message];
        return acc;
      }, {});
      var groups = [];
      var filteredGroups = this.groups.filter(_ => _.group_type == "group" && Object.keys(this.advices).includes(_.id));
      filteredGroups.forEach(element => {
        groups.push(element.id);
      });
      this.groupList = groups;
      this.spinner.hide();
    }));

  }

  recogniseInsects() {
    this.router.navigate(['/recognise-insect/']);
  }
}
