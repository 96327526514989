import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'formatBubble'
})
export class FormatBubblePipe implements PipeTransform {

    transform(value: string, ...args: unknown[]): unknown {

        if (value.includes('DSP: ')) {
            return value.replace('DSP:', '')
        } else if (value.includes('Utente: ')) {
            return value.replace('User:', '')
        } else {
            return value
        }


    }

}
