import { Section } from './../../forum/section.model';
import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { GeoLocationService } from './location';
@Injectable({
  providedIn: 'root'
})
export class SectionService {

  formData: Section;

  constructor(public location: GeoLocationService, private firestore: AngularFirestore) { }

  getSections() {
    const country = this.location.getCountry();
    return this.firestore.collection("forumData/" + country.country + "/ForumSettings/settings/sections", ref => ref.orderBy('order')).snapshotChanges();
  }

  addSection(data) {
    const country = this.location.getCountry();
    this.firestore.collection("forumData/" + country.country + "/ForumSettings/settings/sections").add(data);
  }
  updateSection(data, sectionId) {
    const country = this.location.getCountry();
    this.firestore.doc("forumData/" + country.country + "/ForumSettings/settings/sections/" + sectionId).update(data);
  }
  deleteSection(sectionId) {
    const country = this.location.getCountry();
    this.firestore.doc("forumData/" + country.country + "/ForumSettings/settings/sections/" + sectionId).delete();
  }

}
