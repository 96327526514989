import { Component, OnInit, ViewEncapsulation } from "@angular/core";

@Component({
  selector: "app-help-pwa",
  templateUrl: "./help-pwa.component.html",
  styleUrls: ["./help-pwa.component.scss"],
  encapsulation: ViewEncapsulation.None
})
export class HelpPwaComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
