import { Component, OnInit, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { Covid19FoodSafetyService } from '../../shared/service/covid19-food-safety.service';
import { ActivatedRoute } from '@angular/router';
import Utils from '../../shared/utils/utils';
import { FirestoreDataService } from "../../shared/service/firestore-data.service";
import { GeoLocationService } from '../../shared/service/location';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { Subscription } from 'rxjs';
import { NgxSpinnerService } from "ngx-spinner";
@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss']
})
export class CategoriesComponent implements OnInit, OnDestroy {
  public menuBarTitle = "Home.covid19_vegetables_fruitsTitle";
  searchText = { general_name: '' };
  public arrCategories: any = [];
  public loader: any;
  public commodity_parent: string;
  public commodity_parent_name = '';
  public displayback: string = 'none';
  subscriptions: Subscription[] = [];

  constructor(
    public Covid19FoodSafetyService: Covid19FoodSafetyService,
    private location: Location,
    private router: Router,
    public route: ActivatedRoute,
    private fireStoreDataService: FirestoreDataService,
    public geoLocationService: GeoLocationService,
    private gaService: GoogleAnalyticsService,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit() {
    let parentid: string;
    this.loader = false;
    this.route.params.subscribe(param => {
      parentid = param.commodity_parent;
      this.getCategoryData(parentid);
    });
  }

  getCategoryData(parentid) {
    this.spinner.show();
    if (parentid == null) {
      parentid = 'root';
    } else {
      this.displayback = 'inline';
    }
    const country = this.geoLocationService.getCountry();
    let filterArr: string[] = [parentid];
    this.subscriptions.push(this.fireStoreDataService.getAllCommodities("dimensions-api", "covid19_vegetables_fruits", filterArr, country, '', true).subscribe((res: any[]) => {
      this.loader = true;
      this.spinner.hide();
      if (res) {
        this.arrCategories = res;
      }
    }));
  }

  nav(data) {
    if (data.has_child == 'yes') {
      this.gaService.event('Covid19_Food_Safety_Child_Category', 'Page_Navigation', data.commodity_name);
      this.router.navigate([`covid19_vegetables_fruits/categories`, `${data.commodity_name}`]);
    } else {
      this.gaService.event('Covid19_Food_Safety_Details', 'Page_Navigation', data.commodity_name);
      localStorage.setItem('commodityInfo', JSON.stringify(data));
      this.router.navigate([`covid19_vegetables_fruits/subject`, `${data.commodity_parent}`, `${data.commodity_name}`]);
    }
  }
  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
