import { Component, AfterContentInit, ElementRef, Input, ViewChild } from '@angular/core';
import { StockChart, Chart } from 'angular-highcharts';
import * as Highcharts from 'highcharts';
import { LayoutService } from '../../../service/layout.service';

declare var $: any;

@Component({
  selector: 'sa-highcharts-js-chart',
  template: `
  <div [chart]="stocKchart"></div>
  <div #chart></div>
  `,
  styles: []
})
export class HighChartJsComponent implements AfterContentInit {

  @Input() public data: any;
  @Input() public xAxisData: any;
  @Input() public yAxisTitle: string;
  @Input() public options: any;
  @Input() public chartType: string;
  @Input() public type: string;
  @Input() public fileName: string;

  @Input() public stocKchart: any;
  @Input() public chart: any;
  @Input() public dates: any;

  @ViewChild('chart', { static: false }) public chartEl: ElementRef;
  isXs: boolean;
  wd: any;
  hgt: any;
  constructor(private el: ElementRef, private layout: LayoutService) {
  }

  ngAfterContentInit() {
    let ref = this;
    let options = this.options;

    switch (this.type) {
      case 'singleline':
        options.series[0].data = this.data;
        options.chart.renderTo = this.chartEl.nativeElement;
        this.stocKchart = new StockChart(options);
        break;
      case 'multiline':
        options.series = this.data;
        options.chart.renderTo = this.chartEl.nativeElement;
        this.stocKchart = new StockChart(options);
        break;
      case 'simplechart':
        options.series = this.data;
        options.chart.reflow = true;
        options.chart.type = this.chartType;
        options.xAxis.categories = this.xAxisData;
        if (this.yAxisTitle != null) options.yAxis[0].title.text = this.yAxisTitle;
        options.chart.renderTo = this.chartEl.nativeElement;
        this.stocKchart = new Highcharts.Chart(options);
        break;
      case 'mixedchart':
        options.series = this.data;
        options.chart.reflow = true;
        options.xAxis.categories = this.xAxisData;
        options.chart.renderTo = this.chartEl.nativeElement;
        this.stocKchart = new Highcharts.Chart(options);
        break;
      case 'sparkline':
        this.stocKchart = this.returnSparkLineChart(this.data, this.dates);
        break;
      default:
        this.stocKchart = new StockChart(options);
        break;
    }
  }

  printChart() {
    this.stocKchart.print();
  }

  exportChart(fileName: string, exportType: string) {
    if (exportType == 'pdf') {
      this.stocKchart.exportChart({ type: "application/pdf", filename: fileName });
    }
    else if (exportType == 'jpeg') {
      this.stocKchart.exportChart({ type: "image/jpeg", filename: fileName });
    }
    else if (exportType == 'png') {
      this.stocKchart.exportChart({ type: "image/png", filename: fileName });
    }
    else if (exportType == 'svg') {
      this.stocKchart.exportChart({ type: "image/svg+xml", filename: fileName });
    }
  }

  returnSparkLineChart(seriesData: any, dates) {
    this.isXs = this.layout.media.isActive('xs');
    if (this.isXs) {
      this.wd = 130;
      this.hgt = 70;
    } else {
      this.wd = 170;
      this.hgt = 130;
    }
    return new Chart(<any>{
      title: {
        text: ''
      },
      credits: false,
      chart: {
        renderTo: 'container',
        width: this.wd,
        height: this.hgt,
        type: 'line',
        skipClone: true,
        backgroundColor: "rgba(255,0,0,0)"
      },
      yAxis: {
        endOnTick: false,
        startOnTick: false,
        labels: {
          enabled: false
        },
        title: {
          text: null
        },
        tickPositions: [0]
      },
      xAxis: {
        labels: {
          enabled: false
        },
        title: {
          text: null
        },
        startOnTick: false,
        endOnTick: false,
        tickPositions: []
      },
      legend: {
        enabled: false
      },
      plotOptions: {
        series: {
          marker: {
            enabled: true,
            radius: 2
          }
        }
      },
      tooltip: {
        shadow: false,
        enabled: true,
        formatter: function () {
          return '<span style="font-size: 10px">' + dates[this.x] + '</span><br/><b>' + (this.y).toFixed(2) + '</b>';//(this.x).toFixed(4);
        }
      },
      exporting: {
        enabled: false
      },
      series: [{
        data: seriesData
      }]
    });
  }

  render() {

  }
}
