import { Subscription, Observable } from 'rxjs';
import { LayoutService } from './../../shared/service/layout.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { AgrimarketService } from '../../shared/service/agrimarket.service';
import { GeoLocationService } from './../../shared/service/location';
import { FpmaService } from '../../shared/service/fpma.service';
import { UntypedFormControl } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';
import { Router } from "@angular/router";
import { environment } from '../../../environments/environment';
import Utils from '../../shared/utils/utils';
import { UserService } from '../../shared/service/user.service';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { NgxSpinnerService } from "ngx-spinner";
import { LivestockService } from '../../shared/service/livestock.service';
import { AudioService } from '../../shared/component/audio/audio.service';
import { VideoService } from '../../shared/component/video/video.service';
import { ImageService } from '../../shared/component/image-modal/image.service';
import { Parametre } from '../../shared/service/parametre';
import { ImageStorageService } from '../../shared/service/img-storage.service';
import { LinkService } from '../../shared/component/link/link.service';

const PARAMETRE = Parametre.getInstance();
@Component({
  selector: 'app-agrimarket',
  templateUrl: './agrimarket.component.html',
  styleUrls: ['./agrimarket.component.scss']
})

export class AgrimarketComponent implements OnInit, OnDestroy {
  myControl: UntypedFormControl = new UntypedFormControl();
  l: Subscription;
  list: any = [];
  listmarket: any = [];
  listBQ: any = [];
  listmarketBQ: any = [];
  public loader: boolean = false;
  cols = 1;
  rowH: any;
  bqSearchText = { commodity_label: '' };
  bqSearchTextMarket = { market_name: '' };
  boolCountry: boolean = false;
  isBigQuery: boolean = false;
  arrFaoCountries: any = [];
  arrFaoCountryData: any = [];
  arrSelCountryData: any = [];
  filteredOptions: Observable<string[]>;
  arrSelComMarkets: any = [];
  arrSelMarketCommodities: any = [];
  arrCountryWithCode: any = [];
  selectedCountry: string;
  strGPSCountry: string;
  strOriginalCountry: string;
  geoCountryCode: string;
  selectedTab: number = 0;
  hasInCountry: Observable<any>;
  showDwn: boolean;
  hasInCountryGps: any;
  arrcounryCodes = [];
  hasInCountryCode: any[];
  apiPath: string;
  public arrApplications: any;
  isFilterExist: boolean = false;
  groupSelectedMarkets = [];
  filterRegions: any = [];
  appData: any;
  public lang_Code: string;
  subscriptions: Subscription[] = [];
  groups: any;
  advices: { [key: string]: any } = {};
  weeklyAdv = [];
  groupList: any = [];
  public txtSpeech: any;
  providers: any;
  public objectKeys = Object.keys;
  selectedTabData: any;
  constructor(
    private layout: LayoutService,
    public agrimarket: AgrimarketService,
    public location: GeoLocationService,
    public fpmaService: FpmaService,
    public router: Router,
    public userService: UserService,
    public gaService: GoogleAnalyticsService,
    private spinner: NgxSpinnerService,
    private livestockService: LivestockService,
    private audio: AudioService,
    private videoService: VideoService,
    private imageService: ImageService,
    private imageStorageService: ImageStorageService,
    private linkService: LinkService
  ) {
    UserService.langCodeTextSpeech = JSON.parse(localStorage.getItem(environment.userLanguageStorageKey)).text_to_speech_code;
    this.txtSpeech = UserService.langCodeTextSpeech;
    this.spinner.show();
    this.lang_Code = JSON.parse(localStorage.getItem(environment.userLanguageStorageKey)).code;
    localStorage.removeItem("selWeatherTabIndex");
    localStorage.removeItem("selLivestockTabIndex");
    if (Utils.getAgmTabIndex()) {
      this.selectedTab = Utils.getAgmTabIndex();
    }
    const themes = JSON.parse(localStorage.getItem('appThemes'));
    this.appData = themes.filter(_ => _.template_id === 'agrimarketplace')[0];
    let enabledTabs = this.appData.tabs.filter(_ => _.status == undefined || _.status);
    this.selectedTabData = enabledTabs[this.selectedTab];
    if (this.getTabData('advice')) {
      this.getAdvice();
    }
    this.l = this.layout.layout$.subscribe(res => {
      this.cols = res.cols;
      this.rowH = res.rowH;
    });
    this.subscriptions.push(this.l);
    //If the country is FAO then fetch commodity data from fpma apis otherwise from GAS apis
    const country = this.location.getCountry();
    this.geoCountryCode = Utils.getCountryData(country.country, 'code').toLowerCase();
    this.geoCountryCode = Utils.getCountryData(this.geoCountryCode, 'code3').toLowerCase();
    this.strGPSCountry = localStorage.getItem('gpsCountry').toLowerCase();
    this.strOriginalCountry = localStorage.getItem('originalCountry').toLowerCase()
    // Fetch all country of FAO
    this.apiPath = this.appData.tabs[0].source_type == 'api' ? this.appData.tabs[0].api_path : this.appData.tabs[0].source_type;
    if (this.apiPath != '') {
      if (this.apiPath == 'BIG_QUERY') {
        this.boolCountry = false;
        this.isBigQuery = true;
        if (this.selectedTabData.id == 'commodities')
          this.getCommoditiesBQ();
        else if (this.selectedTabData.id == 'markets')
          this.getMarketsBQ();
      } else {
        this.subscriptions.push(this.fpmaService.getFaoCountryList(this.apiPath).subscribe((res: any) => {
          //Get GPS country
          let k = res.results.length
          for (var j = 0; j < k; j++) {
            let faoCountry = res.results[j]['country_name'].toLowerCase();
            let faoCountryCode = res.results[j]['iso3_country_code'].toLowerCase();
            if (this.strGPSCountry == faoCountry || faoCountryCode == this.geoCountryCode) {
              this.selectedCountry = res.results[j]['country_name'];
              break;
            } else {
              this.selectedCountry = res.results[0]['country_name'];
            }
          }
          this.arrFaoCountryData = res.results;
          var flags = [], output = [], l = res.results.length, i;
          for (i = 0; i < l; i++) {
            if (flags[res.results[i].iso3_country_code]) continue;
            flags[res.results[i].iso3_country_code] = true;
            this.arrFaoCountries.push(res.results[i].country_name);
            this.arrCountryWithCode[res.results[i].country_name] = res.results[i].iso3_country_code;
            this.arrcounryCodes.push(res.results[i].iso3_country_code)
            this.filteredOptions = this.myControl.valueChanges
              .pipe(
                startWith(''),
                map(value => value ? this.filterOption(value) : this.arrFaoCountries.slice())
              );
          }
          var hasInCountryCode1 = '';
          this.hasInCountryCode = this.arrcounryCodes.filter((item, i) => {
            if (item.toLowerCase() == this.geoCountryCode.toLowerCase()) {
              hasInCountryCode1 = this.arrFaoCountries[i]
              return this.arrFaoCountries[i]
            }
          });

          this.hasInCountry = this.arrFaoCountries.filter((item) => {
            if (item.toLowerCase() == this.strOriginalCountry) {
              return item.toLowerCase()
            }
          });

          this.hasInCountryGps = this.arrFaoCountries.filter((item) => {
            if (item.toLowerCase() == this.strGPSCountry) {
              return item.toLowerCase()
            }
          });

          if (this.strGPSCountry == 'fao') {
            if (this.hasInCountry && this.hasInCountry[0]) {
              this.showDwn = true;
              this.selectedCountry = this.hasInCountry[0];
            } else {
              this.showDwn = true;
              this.selectedCountry = this.arrFaoCountries[0];
            }
          } else {
            if (this.hasInCountry && (this.strGPSCountry == this.hasInCountry[0])) {
              this.showDwn = false;
              this.selectedCountry = this.hasInCountry[0];
            }
            else if (this.hasInCountryCode && this.hasInCountryCode[0]) {
              this.showDwn = false
              this.selectedCountry = hasInCountryCode1
            }
            else {
              this.showDwn = true
              this.selectedCountry = this.arrFaoCountries[0];
            }
          }
          this.loader = true;
          this.spinner.hide();
          this.boolCountry = false;
          this.isBigQuery = false;
          this.getCntryCommAndMar(this.selectedCountry);
        }));
      }
    }
  }

  private filterOption(value: string) {
    const filterValue = value.toLowerCase();
    return this.arrFaoCountries.filter(value => value.toLowerCase().indexOf(filterValue) === 0);
  }

  ngOnInit() {
    //Filter exist or not
    this.subscriptions.push(this.userService.getApplicationList().subscribe(res => {
      if (res) {
        this.arrApplications = res['applications'];
        this.arrApplications.forEach(elements => {
          if (elements.application_id == 'agrimarket') {
            this.isFilterExist = elements['is_category_filter_exist'];
          }
        });
      }
    }));
    //Get regions
    this.subscriptions.push(this.agrimarket.getMarketForCountry(this.getTabData('markets').id).subscribe(res => {
      this.filterRegions = Utils.getMarketRegions(res);
    }));
  }

  getMarketsBQ() {
    this.subscriptions.push(this.agrimarket.getMarketForCountryBQ().subscribe(res => {
      if (res) {
        let listmarketBQ: any = res;
        // this.loader = true;
        this.subscriptions.push(this.agrimarket.getMarketForCountry(this.getTabData('markets').id).subscribe(res => {
          if (res) {
            listmarketBQ.map((l) => {
              let item = l?.market_name ? res.find((market) => market?.market_id.toLowerCase() == l?.market_name.toLowerCase()) : null;
              if (item) {
                l.market_label = item.name[this.lang_Code] ? item.name[this.lang_Code] : l.market_label;
                l['status'] = item.status == undefined ? true : item.status;
                l['order'] = item.order
                l.image_link = item.img;
              } else {
                l.market_label = l.market_name;
                l['order'] = 999;
              }
            });
            listmarketBQ = Utils.sort(listmarketBQ, 'order');
            this.listmarketBQ = listmarketBQ;
            this.loader = true;
            this.spinner.hide();
          }
        }));
      }
    }));
  }

  getCommoditiesBQ() {
    const id = this.getTabData('commodities').id;
    this.subscriptions.push(this.agrimarket.getCommoditiesForCountryBQ().subscribe(res => {
      if (res) {
        let listBQ: any = res;
        this.subscriptions.push(this.agrimarket.getCommoditiesForCountry(id).subscribe(res => {
          if (res) {
            listBQ.map((l) => {
              let item = l?.commodity_name ? res.find((commodity) => commodity?.commodity_id.toLowerCase() == l?.commodity_name.toLowerCase()) : null;
              if (item) {
                l.commodity_label = item.name[this.lang_Code] ? item.name[this.lang_Code] : l.commodity_label;
                l.image_link = item.img ? item.img : l.image_link;
                l['status'] = item.status == undefined ? true : item.status;
                l['order'] = item.order;
              } else {
                l['order'] = 999;
              }
            });
            //listBQ = Utils.sort(listBQ, 'order');
            this.listBQ = listBQ;
            this.loader = true;
            this.spinner.hide();
          }
        }));
      }
    }));
  }


  getCntryCommAndMar(country) {
    this.selectedCountry = country;
    var countryCode = this.arrCountryWithCode[country];
    this.list = [];
    this.listmarket = [];
    this.arrSelCountryData = [];

    //Find out the commodity and markets from iso3code
    var l = this.arrFaoCountryData.length, i;
    for (i = 0; i < l; i++) {
      if (this.arrFaoCountryData[i].iso3_country_code === countryCode) {
        this.arrSelCountryData.push({
          "ISO3_country_code": this.arrFaoCountryData[i]['iso3_country_code'],
          "commodity_name": this.arrFaoCountryData[i]['commodity_name'],
          "commodity_label": this.arrFaoCountryData[i]['commodity_name'],
          "image_link": this.arrFaoCountryData[i]['commodity_image'],
          "commodity_code": this.arrFaoCountryData[i]['commodity_code'],
          // "market_season_start": this.arrFaoCountryData[i]['commodityMarketSeasonStart'],
          "commodity_info": this.arrFaoCountryData[i]['commodity_info'],
          "no_of_markets": "",
          "priceTypeId": this.arrFaoCountryData[i]['price_type_id'],
          // "periodicityId": this.arrFaoCountryData[i]['periodicityId'],
          "measureUnitId": this.arrFaoCountryData[i]['measure_unit_label'],
          "commodityId": this.arrFaoCountryData[i]['commodity'],
          "marketId": this.arrFaoCountryData[i]['marketId'],
          "currencyId": this.arrFaoCountryData[i]['currency'],
          "currency_variant": 'nominal',
          "measureUnit": this.arrFaoCountryData[i]['measure_unit_label'],
          "market_name": this.arrFaoCountryData[i]['market_name'],
          "market_label": this.arrFaoCountryData[i]['market_name'],
          "currency": this.arrFaoCountryData[i]['currency'],
          "priceValue": 0,
          "priceChangeRaito": 0,
          "dateRange": null,
          "referenceDate": "",
          "chartObject": "",
          // "link": this.arrFaoCountryData[i]['links'][0]['href'],
          "market_code": this.arrFaoCountryData[i]['market'],
          "latitude": this.arrFaoCountryData[i]['marketLatitude'],
          "longitude": this.arrFaoCountryData[i]['marketLongitude'],
          "source": this.arrFaoCountryData[i]['source_name'],
          "countryName": this.arrFaoCountryData[i]['commodity_name'],
          "uuid": this.arrFaoCountryData[i]['uuid']
        });
      }
    }

    if (this.arrSelCountryData.length) {
      var tempArray = [], tempMarketArr = [], l = this.arrSelCountryData.length, i;
      // Get selected country commodities
      var url = environment.firebaseCommImg;

      for (i = 0; i < l; i++) {
        if (tempArray[this.arrSelCountryData[i]['commodity_name']]) continue;
        tempArray[this.arrSelCountryData[i]['commodity_name']] = true;

        //Modify string to get the image
        let imgName;
        if (this.arrSelCountryData[i]['commodity_name']) {
          imgName = url + "" + this.modifyCommodity(this.arrSelCountryData[i]['commodity_name']);
        }
        this.list.push({
          "ISO3-country-code": this.arrSelCountryData[i]['ISO3_country_code'],
          "commodity_name": this.arrSelCountryData[i]['commodity_name'],
          "commodity_label": this.arrSelCountryData[i]['commodity_name'],
          "image_link": imgName,
          "commodity_code": this.arrSelCountryData[i]['commodity_code'],
          "market_season_start": this.arrSelCountryData[i]['market_season_start'],
          "commodity_info": this.arrSelCountryData[i]['commodity_info'],
          "no_of_markets": "5",
          "priceTypeId": this.arrSelCountryData[i]['priceTypeId'],
          "periodicityId": this.arrSelCountryData[i]['periodicityId'],
          "measureUnitId": this.arrSelCountryData[i]['measureUnitId'],
          "commodityId": this.arrSelCountryData[i]['commodityId'],
          "marketId": this.arrSelCountryData[i]['marketId'],
          "currencyId": this.arrSelCountryData[i]['currencyId'],
          "currency_variant": this.arrSelCountryData[i]['currency_variant'],
          "measureUnit": this.arrSelCountryData[i]['measureUnit'],
          "link": this.arrSelCountryData[i]['link']
        });
      }

      this.subscriptions.push(this.agrimarket.getCommoditiesForCountry('commodities').subscribe((res: any[]) => {
        if (res.length > 0) {
          this.list.map((l) => {
            let item = l?.commodity_name ? res.find((commodity) => commodity?.commodity_id.toLowerCase() == l?.commodity_name.toLowerCase()) : null;
            if (item) {
              l.commodity_label = item.name[this.lang_Code] ? item.name[this.lang_Code] : l.commodity_label;
              l.image_link = item.img ? item.img : l.image_link;
              l['status'] = item.status == undefined ? true : item.status;
              l['order'] = item.order
            } else {
              l['order'] = 999;
              l['status'] = true;
            }
          });
          this.list = Utils.sort(this.list, 'order');
        }
      }));
      // Get selected country markets
      for (i = 0; i < l; i++) {
        if (tempMarketArr[this.arrSelCountryData[i]['market_name']]) continue;
        tempMarketArr[this.arrSelCountryData[i]['market_name']] = true;
        this.listmarket.push({
          "ISO3-country-code": this.arrSelCountryData[i]['ISO3_country_code'],
          "market_name": this.arrSelCountryData[i]['market_name'],
          "market_code": this.arrSelCountryData[i]['market_code'],
          "market_label": this.arrSelCountryData[i]['market_name'],
          "latitude": this.arrSelCountryData[i]['latitude'],
          "longitude": this.arrSelCountryData[i]['longitude'],
          "available_commodity_count": 10
        });
      }
      this.subscriptions.push(this.agrimarket.getMarketForCountry('markets').subscribe((response: any[]) => {
        this.loader = true;
        if (response.length > 0) {
          this.listmarket.map((l) => {
            let item = l?.market_name ? response.find((market) => market?.market_id.toLowerCase() == l?.market_name.toLowerCase()) : null;
            if (item) {
              l.market_label = item.name[this.lang_Code] ? item.name[this.lang_Code] : l.market_label;
              l.latitude = item.lat;
              l.longitude = item.long;
              l['status'] = item.status == undefined ? true : item.status;
              l['order'] = item.order
              l.image_link = item.img;
            } else {
              l['order'] = 999;
              l['status'] = true;
            }
          });
          this.listmarket = Utils.sort(this.listmarket, 'order');
        }
      }));
      // Set all the selected country data to use for further process
      localStorage.setItem('arrCountryData', JSON.stringify(this.arrSelCountryData));
    }
  }

  getComMarkets(commName, commodityId) {
    localStorage.removeItem('arrSelComMarkets');
    var url = environment.firebaseCommImg;
    var imgName = url + "" + this.modifyCommodity(commName);
    //Check selected commodity in country markets
    var l = this.arrSelCountryData.length, i;
    for (i = 0; i < l; i++) {
      if (this.arrSelCountryData[i].commodityId === commodityId) {
        var data = this.arrSelComMarkets.filter(_ => _.market_code == this.arrSelCountryData[i].market_code);
        if (!data.length) {
          this.arrSelComMarkets.push({
            "ISO3_country_code": this.arrSelCountryData[i]['ISO3_country_code'],
            "commodity_name": this.arrSelCountryData[i]['commodity_name'],
            "commodity_label": this.arrSelCountryData[i]['commodity_label'],
            "image_link": imgName,
            "commodity_code": this.arrSelCountryData[i]['commodity_code'],
            "market_season_start": this.arrSelCountryData[i]['market_season_start'],
            "commodity_info": this.arrSelCountryData[i]['commodity_info'],
            "no_of_markets": "",
            "priceTypeId": this.arrSelCountryData[i]['priceTypeId'],
            "periodicityId": this.arrSelCountryData[i]['periodicityId'],
            "measureUnitId": this.arrSelCountryData[i]['measureUnitId'],
            "commodityId": this.arrSelCountryData[i]['commodityId'],
            "marketId": this.arrSelCountryData[i]['marketId'],
            "currencyId": this.arrSelCountryData[i]['currencyId'],
            "currency_variant": this.arrSelCountryData[i]['currency_variant'],
            "measureUnit": this.arrSelCountryData[i]['measureUnit'],
            "market_name": this.arrSelCountryData[i]['market_name'],
            "market_label": this.arrSelCountryData[i]['market_label'],
            "currency": this.arrSelCountryData[i]['currency'],
            "priceValue": this.arrSelCountryData[i]['priceValue'],
            "priceChangeRaito": this.arrSelCountryData[i]['priceChangeRaito'],
            "dateRange": this.arrSelCountryData[i]['dateRange'],
            "referenceDate": this.arrSelCountryData[i]['referenceDate'],
            "chartObject": this.arrSelCountryData[i]['chartObject'],
            "link": this.arrSelCountryData[i]['link'],
            "market_code": this.arrSelCountryData[i]['market_code'],
            "countryName": this.arrSelCountryData[i]['countryName'],
            "uuid": this.arrSelCountryData[i]['uuid']
          });
        }
      }
    }
    localStorage.setItem('arrSelComMarkets', JSON.stringify(this.arrSelComMarkets));
    this.router.navigate(['/agrimarket', commName]);
  }

  getComMarketsBQ(commName) {
    localStorage.removeItem('selListBQ');
    let selListBQ;
    this.listBQ.forEach(cn => {
      if (cn.commodity_name == commName) {
        selListBQ = cn;
      }
    });
    localStorage.setItem('selListBQ', JSON.stringify(selListBQ));
    this.router.navigate(['/agrimarket', commName]);
  }

  getMarketCommodityBQ(marketName) {
    localStorage.removeItem('selListMarketBQ');
    let selListMarketBQ;
    this.listmarketBQ.forEach(m => {
      if (m.market_name == marketName) {
        selListMarketBQ = m;
      }
    });
    localStorage.setItem('selListMarketBQ', JSON.stringify(selListMarketBQ));
    this.router.navigate(['/market', marketName]);
  }

  getMarketCommodity(marketName, marketCode) {
    localStorage.removeItem('arrSelMarketCommodities');
    //Check selected market in country markets
    var l = this.arrSelCountryData.length, i;
    for (i = 0; i < l; i++) {
      if (this.arrSelCountryData[i].market_code === marketCode) {
        var data = this.arrSelMarketCommodities.filter(_ => _.commodity_code == this.arrSelCountryData[i].commodity_code);
        if (!data.length) {
          this.arrSelMarketCommodities.push({
            "ISO3_country_code": this.arrSelCountryData[i]['ISO3_country_code'],
            "commodity_name": this.arrSelCountryData[i]['commodity_name'],
            "commodity_label": this.arrSelCountryData[i]['commodity_label'],
            "image_link": this.arrSelCountryData[i]['image_link'],
            "commodity_code": this.arrSelCountryData[i]['commodity_code'],
            "market_season_start": this.arrSelCountryData[i]['market_season_start'],
            "commodity_info": this.arrSelCountryData[i]['commodity_info'],
            "no_of_markets": "",
            "priceTypeId": this.arrSelCountryData[i]['priceTypeId'],
            "periodicityId": this.arrSelCountryData[i]['periodicityId'],
            "measureUnitId": this.arrSelCountryData[i]['measureUnitId'],
            "commodityId": this.arrSelCountryData[i]['commodityId'],
            "marketId": this.arrSelCountryData[i]['marketId'],
            "currencyId": this.arrSelCountryData[i]['currencyId'],
            "currency_variant": this.arrSelCountryData[i]['currency_variant'],
            "measureUnit": this.arrSelCountryData[i]['measureUnit'],
            "market_name": this.arrSelCountryData[i]['market_name'],
            "market_label": this.arrSelCountryData[i]['market_label'],
            "currency": this.arrSelCountryData[i]['currency'],
            "priceValue": this.arrSelCountryData[i]['priceValue'],
            "priceChangeRaito": this.arrSelCountryData[i]['priceChangeRaito'],
            "dateRange": this.arrSelCountryData[i]['dateRange'],
            "referenceDate": this.arrSelCountryData[i]['referenceDate'],
            "chartObject": this.arrSelCountryData[i]['chartObject'],
            "link": this.arrSelCountryData[i]['link'],
            "latitude": parseFloat(this.arrSelCountryData[i]['latitude']),
            "longitude": parseFloat(this.arrSelCountryData[i]['longitude']),
            "source": this.arrSelCountryData[i]['source'],
            "market_code": this.arrSelCountryData[i]['market_code'],
            "countryName": this.arrSelCountryData[i]['countryName'],
            "uuid": this.arrSelCountryData[i]['uuid']
          });
        }
      }
    }
    localStorage.setItem('arrSelMarketCommodities', JSON.stringify(this.arrSelMarketCommodities));
    this.router.navigate(['/market', marketName]);
  }
  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  setMyStyles() {
    return Utils.reverseContent();
  }

  onImgError(event) {
    event.target.src = Utils.offlineImage("");
  }

  modifyCommodity(str) {
    var txt = str.replace(/[(),]/g, "");
    return txt.replace(/\s/g, "_").toLowerCase() + ".jpg?alt=media";
  }

  tabChange(i) {
    this.gaService.event('AgriMarketplace', 'Tab_Change', i.tab.textLabel);
    this.selectedTab = Utils.setAgmTabIndex(i.index);
    let enabledTabs = this.appData.tabs.filter(_ => _.status == undefined || _.status);
    this.selectedTabData = enabledTabs[this.selectedTab];
    if (this.apiPath == 'BIG_QUERY') {
      if (this.selectedTabData.id == 'commodities') {
        if (this.listBQ && this.listBQ.length)
          return;
        this.spinner.show();
        this.getCommoditiesBQ();
      } else if (this.selectedTabData.id == 'markets') {
        if (this.listmarketBQ && this.listmarketBQ.length)
          return;
        this.spinner.show();
        this.getMarketsBQ();
      }
    }
  }
  getTabData(id) {
    if (this.appData && this.appData.tabs.length)
      return this.appData.tabs.filter(_ => _.id == id)[0];
    else return '';
  }
  getAdvice() {
    this.subscriptions.push(this.livestockService.getProviders().subscribe(res => {
      this.providers = res;
    }));
    this.subscriptions.push(this.livestockService.getMessageGroup().subscribe(res => {
      this.groups = res;
      this.subscriptions.push(this.agrimarket.getAdvices("advice", this.appData.id).subscribe(e => {
        this.weeklyAdv = e;
        this.weeklyAdv = this.weeklyAdv.filter(_ => _.status == undefined || _.status);
        this.advices = this.weeklyAdv.reduce((acc, message) => {
          const kindof = message.group_id;// + "#" + message.provided_by;
          if (acc[kindof]) {
            acc[kindof].push(message);
            return acc;
          }
          acc[kindof] = [message];
          return acc;
        }, {});
        var groups = [];
        var filteredGroups = this.groups.filter(_ => _.group_type == "group" && Object.keys(this.advices).includes(_.id));
        filteredGroups.forEach(element => {
          groups.push(element.id);
        });
        this.groupList = groups;
      }));
    }));
  }
  ga(action, category, label) {
    this.gaService.event(action, category, label);
  }
  openMediaPlayer(src) {
    this.audio.openMediaPlayer(src);
  }
  openMediaForImage(src) {
    this.imageService.openMediaPlayer(src);
  }
  openMediaPlayerForVideo(src) {
    this.videoService.openMediaPlayer(src);
  }
  openMediaForSpeech(content) {
    const strUrl = PARAMETRE.strTextToSpeechUrl + encodeURIComponent(content) + '&language=' + this.txtSpeech;
    this.audio.openMediaPlayer(strUrl);
  }
  getGroupDetails(id) {
    if (id == '') return 'system.information_tab';
    if (this.groups && this.groups.length) {
      var data = this.groups.filter(_ => _.id == id);
      return data.length ? data[0].name : id;
    } else return id
  }
  getProviderDetaiils(id, returnValue) {
    if (this.providers && this.providers.length) {
      var data = this.providers.filter(_ => _.id == id);
      return data.length ? data[0][returnValue] : id;
    } else return id
  }
  getProviders(keymessages) {
    return this.groupByKey(keymessages, 'provider_id');
  }
  groupByKey(data, key) {
    return data.reduce(function (rv, x) {
      x[key] = !x[key] ? '' : x[key];
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  };

  getx48Image(inputUrl: string): string {
    return this.imageStorageService.getx48Image(inputUrl);
  }
}
