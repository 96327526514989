import { ImageStorageService } from './service/img-storage.service';
import { GeoLocationService } from './service/location';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FeedbackComponent } from './component/feedback/feedback.dialog';
import { FeedbackService } from './service/feedback.service';
import { WindowService } from './service/phone-auth/window.service';
import { FireDBService } from './service/firedb.service';
import { FirestoreDataService } from './service/firestore-data.service';
import { DetailsComponent } from './component/details/details.component';
import { AuthService } from './service/auth.service';
import { AuthGuard } from './guards/auth.guard';
import { NotificationService } from './service/notification.service';
import { MessagingService } from './service/messaging.service';
import { LayoutService } from './service/layout.service';
import { FilterPipe } from './pipes/filter.pipe';
import { CapitalizePipe } from './pipes/capitalize.pipe';
import { SnackbarComponent } from './component/snackbar/snackbar.component';
import { PopupComponent } from './component/popup/ok-popup/popup.component';
import { CommonModule } from '@angular/common';
import { AudioComponent } from './component/audio/audio-player';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { RegistrationService } from './service/registration.service';
import { AudioService } from './component/audio/audio.service';
import { WeatherService } from './service/weather.service';
import { LivestockService } from './service/livestock.service';
import { UserService } from './service/user.service';
import { AgrimarketService } from './service/agrimarket.service';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MainMaterialModule } from '../material';
import { ToolbarComponent } from '../toolbar';
import { MenuComponent } from './component/menu/menu';
// Comment after check
import { VgCoreModule } from '@videogular/ngx-videogular/core';
import { VgControlsModule } from '@videogular/ngx-videogular/controls';
import { VgOverlayPlayModule } from '@videogular/ngx-videogular/overlay-play';
import { VgBufferingModule } from '@videogular/ngx-videogular/buffering';
// End
import { AgmCoreModule } from '@agm/core';
import { FilterPipeModule } from 'ngx-filter-pipe';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import { FooterComponent } from './component/footer/footer.component';
import { EnutrifoodService } from './service/nutrifood.service';
import { LanguagePickerComponent } from './component/language-picker/language-picker.component';
import { TranslationService } from './service/translation.service';
import { TranslatePipe } from './pipes/translate.pipe';
import { SortByPipe } from './pipes/sort.pipe';
import { GoogleService } from './service/google.services';
import { VideoService } from './component/video/video.service';
import { LinkService } from './component/link/link.service';
import { ImageService } from './component/image-modal/image.service'
import { FpmaService } from './service/fpma.service';
import { CustomDatePipe } from './pipes/custom.datepipe';
import { ForumService } from './service/forum.service';
import { CovidService } from './service/covid.service';
import { StartUpService } from './service/startup.service';
import { MultiNodeComponent } from './component/multi-node/multi-node.component';
import { SingleNodeComponent } from './component/single-node/single-node.component';
import { CustomFilterComponent } from './component/custom-filter/custom-filter.component';
import { environment } from '../../environments/environment';
import { NumberDirective } from './directive/numbers-only.directive';
import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';
import { TeamBottomSheetComponent } from './component/team-bottom-sheet/team-bottom-sheet.component';
import { EgyptMultiNodeComponent } from './component/egypt-multi-node/egypt-multi-node.component';
import { EgyptSingleNodeComponent } from './component/egypt-single-node/egypt-single-node.component';
import { FreshMarketComponent } from './component/fresh-market/fresh-market.component';
import { DivisionFiltersComponent } from './component/division-filters/division-filters.component';
import { InfoDialogComponent } from './component/info-dialog/info-dialog.component';
import { NgxSpinnerModule } from "ngx-spinner";
import { UserProfileDeactivateGuard } from './guards/user-profile.guard';
import { FormatBubblePipe } from './pipes/format-bubble.pipe'
import { InsectRecogniseService } from './service/insect-recognise.service';
import { PandemReportService } from './service/pandemic-report.service';
import { AnimalFeedService } from './service/animal-feed.service';


@NgModule({
  imports: [
    CommonModule,
    FlexLayoutModule,
    MainMaterialModule,
    // Comment after check
    VgCoreModule,
    VgControlsModule,
    VgOverlayPlayModule,
    VgBufferingModule,
    //End
    FilterPipeModule,
    FormsModule,
    ReactiveFormsModule,
    NgxChartsModule,
    AngularFireStorageModule,
    AgmCoreModule.forRoot({
      apiKey: environment.geoLocationApiKey
    }),
    NgxGoogleAnalyticsModule.forRoot(environment.googleAnalyticsId),
    NgxGoogleAnalyticsRouterModule.forRoot(),
    NgxSpinnerModule
  ],
  entryComponents: [SnackbarComponent, FeedbackComponent, MultiNodeComponent, SingleNodeComponent, CustomFilterComponent, EgyptMultiNodeComponent, EgyptSingleNodeComponent],
  exports: [
    CommonModule,
    FlexLayoutModule,
    MainMaterialModule,
    AngularFireStorageModule,
    ToolbarComponent,
    MenuComponent,
    // Comment after check
    VgCoreModule,
    VgControlsModule,
    VgOverlayPlayModule,
    VgBufferingModule,
    //End
    NgxChartsModule,
    AudioComponent,
    PopupComponent,
    FilterPipeModule,
    SnackbarComponent,
    AgmCoreModule,
    DetailsComponent,
    FeedbackComponent,
    CapitalizePipe,
    FooterComponent,
    FormsModule,
    LanguagePickerComponent,
    TranslatePipe,
    SortByPipe,
    FormatBubblePipe,
    CustomDatePipe,
    MultiNodeComponent,
    SingleNodeComponent,
    CustomFilterComponent,
    ReactiveFormsModule,
    NumberDirective,
    NgxGoogleAnalyticsModule,
    NgxGoogleAnalyticsRouterModule,
    TeamBottomSheetComponent,
    EgyptMultiNodeComponent,
    EgyptSingleNodeComponent,
    InfoDialogComponent,
    NgxSpinnerModule
  ],
  declarations: [
    ToolbarComponent,
    MenuComponent,
    AudioComponent,
    PopupComponent,
    SnackbarComponent,
    FilterPipe,
    DetailsComponent,
    FeedbackComponent,
    CapitalizePipe,
    FooterComponent,
    LanguagePickerComponent,
    TranslatePipe,
    SortByPipe,
    FormatBubblePipe,
    CustomDatePipe,
    MultiNodeComponent,
    SingleNodeComponent,
    CustomFilterComponent,
    NumberDirective,
    TeamBottomSheetComponent,
    EgyptMultiNodeComponent,
    EgyptSingleNodeComponent,
    FreshMarketComponent,
    DivisionFiltersComponent,
    InfoDialogComponent
  ],
  providers: [
    RegistrationService,
    AgrimarketService,
    FeedbackService,
    LayoutService,
    GeoLocationService,
    EnutrifoodService,
    GoogleService,
    FpmaService,
    StartUpService,
    InsectRecogniseService,
    PandemReportService
  ]
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
        AudioService,
        WeatherService,
        LivestockService,
        AnimalFeedService,
        UserService,
        AuthService,
        AuthGuard,
        FireDBService,
        NotificationService,
        FirestoreDataService,
        MessagingService,
        WindowService,
        ImageStorageService,
        TranslationService,
        GoogleService,
        VideoService,
        LinkService,
        ForumService,
        ImageService,
        CovidService,
        UserProfileDeactivateGuard
      ]
    };
  }
}

