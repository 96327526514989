import { Component, Input, OnInit, OnDestroy } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Subject } from "rxjs";
import { Filters } from "./crop-calendar/models/filters.dto";

@Component({
  selector: "app-crops",
  templateUrl: "./crops.component.html",
  styleUrls: ["./crops.component.scss"]
})
export class CropsComponent implements OnInit {
  @Input()
  parentSubject: Subject<any>;
  @Input() dataFilter: Filters;
  hiddenInfo = false;

  constructor(
    public dialog: MatDialog,
  ) { }

  ngOnInit() {
  }

  onImgError(event) {
    event.target.src = "assets/offline/icons/default-icon.svg";
  }

  showAEZinfo(filter) {
    this.dataFilter = filter;
    this.hiddenInfo = true;
  }
}
