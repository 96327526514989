
import { Injectable } from '@angular/core';

@Injectable()
export class LinkService {
  constructor() { }

  openExternalLink(url) {
    url = !url.toLowerCase().startsWith('http://') && !url.toLowerCase().startsWith('https://') ? 'http://' + url : url;
    window.open(url, '_blank');
  }

}

