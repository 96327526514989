import { Component, OnInit, OnDestroy } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { LayoutService } from './../shared/service/layout.service';


@Component({
  selector: 'app-covid19-food-safety',
  templateUrl: './covid19-food-safety.component.html',
  styleUrls: ['./covid19-food-safety.component.css']
})

export class Covid19FoodSafetyComponent implements OnInit, OnDestroy {
  p: Subscription;
  rowH: string;
  cols = 1;
  isLoading = true;

  constructor(private layout: LayoutService) { }

  ngOnInit() {
    this.isLoading = false;
    this.p = this.layout.layout$.subscribe(res => {
      this.cols = res.cols;
      this.rowH = res.rowH;
    });
  }

  ngOnDestroy() {
    this.p.unsubscribe();
  }
}

