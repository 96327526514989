import { Injectable } from "@angular/core";
import { CropCalendarDTO } from "../models/cropCalendar.dtos";
import { Filters } from "../models/filters.dto";
import { CropDTO } from "../models/crops.dtos";
import { Subject } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class FilterService {
  country: Filters;
  zone: string;
  crop: string;
  cropGroup: string;
  cropCalendar: CropCalendarDTO[];
  listCrops: CropDTO[];

  countryGeo$ = new Subject<any>();

  constructor() {}

  getCountry() {
    return this.country;
  }

  setCountry(country: Filters) {
    this.country = country;
  }

  getZone() {
    return this.zone;
  }

  setZone(zone: string) {
    this.zone = zone;
  }

  getCropList() {
    return this.listCrops;
  }

  setCropList(listCrops: CropDTO[]) {
    this.listCrops = listCrops;
  }
}
