import { Router } from '@angular/router';
import { Component, Input, OnInit, OnDestroy, OnChanges } from '@angular/core';
import { LivestockService, } from '../../shared/service/livestock.service';
import { Subject } from 'rxjs';
import { UserService } from '../../shared/service/user.service';
import Utils from './../../shared/utils/utils';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { environment } from '../../../../src/environments/environment';
import { Subscription } from 'rxjs';
import { ImageStorageService } from '../../shared/service/img-storage.service';

@Component({
  selector: 'app-species',
  templateUrl: './species.component.html',
  styleUrls: ['./species.component.scss']
})

export class SpeciesComponent implements OnInit, OnDestroy, OnChanges {
  @Input()
  parentSubject: Subject<any>;
  @Input() tabData: any;
  searchText = {
    name: {}
  };
  species: any;
  @Input() loaded: boolean;
  groupSelectedSpecies = [];
  public arrApplications: any;
  isFilterExist: boolean = false;
  filterTypeGroup: any = [];
  lang_code: any;
  subscriptions: Subscription[] = [];
  constructor(
    public livestock: LivestockService,
    private router: Router,
    public uService: UserService,
    private gaService: GoogleAnalyticsService,
    private imageStorageService: ImageStorageService
  ) { }

  ngOnInit() {
    this.lang_code = JSON.parse(localStorage.getItem(environment.userLanguageStorageKey)).code;
    this.searchText.name[this.lang_code] = '';
  }

  ngOnChanges() {
    if (this.tabData)
      this.species = this.tabData.data;
  }

  ngOnDestroy() {
    this.parentSubject.unsubscribe();
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  onImgError(event) {
    event.target.src = 'assets/offline/icons/default-icon.svg';
  }

  nav(spec) {
    if (spec) {
      this.router.navigate(['/livestock', this.tabData.themeId, spec.species]);
    }
  }


  filterSpecies(selectedFilterType) {
    this.subscriptions.push(this.livestock.getAllDimensions(this.tabData.id).subscribe(res => {
      this.species = res;
      this.groupSelectedSpecies.length = 0;
      if (selectedFilterType.value != 'ALL') {
        this.species.forEach(item => {
          const speciesType = item.filterType;
          if (speciesType === selectedFilterType.value) {
            this.groupSelectedSpecies.push(item);
          }
        });
        this.groupSelectedSpecies = this.groupSelectedSpecies;
        this.species = this.groupSelectedSpecies;
      }
    }));
  }
  ga(label) {
    this.gaService.event('Species_Details', 'Page_Navigation', label);
  }

  getx48Image(inputUrl: string): string {
    return this.imageStorageService.getx48Image(inputUrl);
  }

}
