import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import Utils from '../../shared/utils/utils';
export interface DialogData {
  title: string;
  subtitle: string;
  hint: string;
  Details: string;
  name: string;
}
@Component({
  selector: 'app-caveat',
  templateUrl: './caveat.component.html',
  styleUrls: ['./caveat.component.css']
})
export class CaveatComponent {

  constructor(
    public dialogRef: MatDialogRef<CaveatComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  onNoClick(): void {
    this.dialogRef.close();
  }
  setMyStyles() {
    return Utils.reverseContent();
  }
}