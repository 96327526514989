import { ActivatedRoute, Router } from '@angular/router';
import { ImageStorageService } from './../../shared/service/img-storage.service';
import { Layout, LayoutService } from './../../shared/service/layout.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { CovidService } from '../../shared/service/covid.service';
import { UserService } from '../../shared/service/user.service';
import Utils from "../../shared/utils/utils";
import { AudioService } from "../../shared/component/audio/audio.service";
import { VideoService } from "../../shared/component/video/video.service";
import { Parametre } from './../../shared/service/parametre';
import { environment } from "../../../environments/environment";
import { Observable, Subscription } from "rxjs";
import { Location } from "@angular/common";
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { LivestockService } from './../../shared/service/livestock.service';
const PARAMETRE = Parametre.getInstance();
import { NgxSpinnerService } from "ngx-spinner";
import { LinkService } from './../../shared/component/link/link.service';

@Component({
  selector: 'app-covid-advices',
  templateUrl: './covid-advices.component.html',
  styleUrls: ['./covid-advices.component.scss']
})
export class CovidAdvicesComponent implements OnInit, OnDestroy {
  r: Subscription;
  l: Subscription;
  rowH: string;
  cols: number;
  details: { [key: string]: any };
  public objectKeys = Object.keys;
  public loader: boolean = false;
  public arrTabInfo: any = [];
  public isCount: any;
  public txtSpeech: any;
  advices: { [key: string]: any } = {};
  covidAdv = [];
  public searchText: string;
  appData: any;
  groups: any;
  providers: any;
  lang_Code: any;
  subscriptions: Subscription[] = [];
  constructor(
    private audioService: AudioService,
    private location: Location,
    private route: ActivatedRoute,
    private covidService: CovidService,
    private livestockService: LivestockService,
    private videoService: VideoService,
    public gaService: GoogleAnalyticsService,
    private spinner: NgxSpinnerService,
    private linkService: LinkService
  ) {
    this.spinner.show();
  }

  ngOnInit() {
    this.loader = true;
    UserService.langCodeTextSpeech = JSON.parse(localStorage.getItem(environment.userLanguageStorageKey)).text_to_speech_code;
    this.txtSpeech = UserService.langCodeTextSpeech;
    this.lang_Code = JSON.parse(localStorage.getItem(environment.userLanguageStorageKey)).code;
    const themes = JSON.parse(localStorage.getItem('appThemes'));
    this.appData = themes.filter(_ => _.template_id === 'covid19')[0];
    this.subscriptions.push(this.livestockService.getMessageGroup().subscribe(res => {
      this.groups = res;
    }));
    this.subscriptions.push(this.livestockService.getProviders().subscribe(res => {
      this.providers = res;
    }));
    this.subscriptions.push(this.covidService.getCovidAdvices('theme_id', this.appData.id).subscribe(e => {
      this.covidAdv = e;
      this.covidAdv = this.covidAdv.filter(_ => _.status == undefined || _.status);
      this.advices = this.covidAdv.reduce((acc, message) => {
        const kindof = message.group_id;
        if (acc[kindof]) {
          acc[kindof].push(message);
          return acc;
        }
        acc[kindof] = [message];
        return acc;
      }, {});
      var obj = {};
      var filteredGroups = this.groups.filter(_ => _.group_type == "group" && Object.keys(this.advices).includes(_.id));
      filteredGroups.forEach(element => {
        obj[element.id] = this.advices[element.id];
      });
      this.advices = obj;
      this.spinner.hide();
    }));

  }

  setMyStyles() {
    return Utils.reverseContent();
  }

  openMediaPlayer(src) {
    this.audioService.openMediaPlayer(src);
  }

  openMediaPlayerForVideo(src) {
    this.videoService.openMediaPlayer(src);
  }

  openMediaForSpeech(content) {
    const strUrl = PARAMETRE.strTextToSpeechUrl + encodeURIComponent(content) + "&language=" + this.txtSpeech;
    this.audioService.openMediaPlayer(strUrl);
  }
  onImgError(event) {
    event.target.src = Utils.offlineImage("");
  }

  getDefImage(event) {
    event.target.src = Utils.defaultImage();
  }

  //filter array for search by topic_name, message and provider
  public filterArray(tags: any[]) {
    if (this.searchText && this.searchText !== '') {
      return tags.filter(tag => {
        const contenHasTextSeach = this.advices[tag].find(adv => {
          return adv.content[this.lang_Code].toLowerCase().includes(this.searchText.toLowerCase())
        })
        return this.getGroupDetails(this.advices[tag][0].group_id).toLowerCase().includes(this.searchText.toLowerCase()) ||
          this.getProviderDetaiils(this.advices[tag][0].provider_id, 'name').toLowerCase().includes(this.searchText.toLowerCase()) ||
          contenHasTextSeach
      })
    }
    else {
      return tags;
    }
  }
  checkContent(data) {
    return data.filter(_ => _.content).length ? true : false;
  }

  getGroupDetails(id) {
    if (id == '') return 'system.information_tab';
    if (this.groups && this.groups.length) {
      var data = this.groups.filter(_ => _.id == id);
      return data.length ? data[0].name[this.lang_Code] ? data[0].name[this.lang_Code] : data[0].name['en'] : id;
    } else return id
  }
  getProviderDetaiils(id, returnValue) {
    if (this.providers && this.providers.length) {
      var data = this.providers.filter(_ => _.id == id);
      return data.length ? data[0][returnValue] : id;
    } else return id
  }
  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
