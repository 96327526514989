import { NotificationDetailsComponent } from './notification-details/notification-details.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NotificationsComponent } from './notifications.component';
import { AuthGuard } from '../shared/guards/auth.guard';


const meteoroutes: Routes = [
  { path: 'notifications', component: NotificationsComponent },
  { path: 'notification-history/:id', component: NotificationDetailsComponent }
];

@NgModule({
  imports: [RouterModule.forChild(meteoroutes)],
  exports: [RouterModule],
})
export class NotificationsRoutingModule { }
