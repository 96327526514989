import { AgrimarketService } from './../../shared/service/agrimarket.service';
import { ActivatedRoute, Router, ParamMap } from '@angular/router';
import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { GeoLocationService } from './../../shared/service/location';
import { FpmaService } from '../../shared/service/fpma.service';
import * as moment from 'moment';
import { Location } from '@angular/common';
import { MatSnackBar } from '@angular/material/snack-bar';
import Utils from '../../shared/utils/utils';
import { environment } from './../../../environments/environment';
import { UserService } from '../../shared/service/user.service';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { Subscription } from 'rxjs';
import { NgxSpinnerService } from "ngx-spinner";
@Component({
  selector: 'app-agrimarket-details',
  templateUrl: './agrimarket-details.component.html',
  styleUrls: ['./agrimarket-details.component.scss']
})

export class AgrimarketDetailsComponent implements OnInit, OnDestroy {
  public loader: boolean = false;
  searchText = {
    market_label: ''
  };
  searchTextBQ = {
    market_name: ''
  };
  details: any = [];
  sparklines = {
    down: {
      path: 'assets/down-arrow.svg',
      color: '#ef3935'
    },
    up: {
      path: 'assets/up-arrow.svg',
      color: '#7cb342'
    },
    equal: {
      path: 'assets/equal-arrow.svg',
      color: '#f57c00'
    }
  };
  boolCountry: boolean = false;
  isBigQuery: boolean = false;
  arrData: any;
  marketData: any = [];
  commodityData: any = [];
  arrSelCountryData: any;
  arrSelMarketCommodities: any = [];
  appData: any;
  lang_Code: any;
  fpmaApi: any;
  fpmaPeriods = [{ name: 'Agrimarket.Daily', value: 'daily', selected: false }, { name: 'Agrimarket.weekly', value: 'weekly', selected: false }, { name: 'Agrimarket.monthly', value: 'monthly', selected: true }];
  subscriptions: Subscription[] = [];
  constructor(
    public _location: Location,
    public marketService: AgrimarketService,
    private route: ActivatedRoute,
    private router: Router,
    public locService: GeoLocationService,
    public fpmaService: FpmaService,
    public toast: MatSnackBar,
    public userService: UserService,
    public gaService: GoogleAnalyticsService,
    private spinner: NgxSpinnerService
  ) {
    this.spinner.show();
  }

  @Output()
  fav = new EventEmitter();

  ngOnInit() {
    let apiPath;
    this.lang_Code = JSON.parse(localStorage.getItem(environment.userLanguageStorageKey)).code;
    const themes = JSON.parse(localStorage.getItem('appThemes'));
    this.appData = themes.filter(_ => _.template_id === 'agrimarketplace')[0];
    apiPath = this.appData.tabs[0].source_type == 'api' ? this.appData.tabs[0].api_path : this.appData.tabs[0].source_type;
    if (apiPath != '') {
      if (apiPath == 'BIG_QUERY') {
        this.boolCountry = false;
        this.isBigQuery = true;
        const country = this.locService.getCountry();
        this.commodityData = JSON.parse(localStorage.getItem('selListBQ'));
        this.subscriptions.push(this.fpmaService.getDetailsForCommoditytBQ(this.commodityData, country).subscribe((res: any) => {
          // this.loader = true;
          res.forEach(element => {
            element.referenceDate = (typeof (element.referenceDate) == 'string') ? element.referenceDate : moment(element.referenceDate).format('DD/MM/YYYY');
          });
          const marketsList = new Set(res.map(item => item.market_name));
          const commoditiesList = new Set(res.map(item => item.commodity_name));
          let finalList = [];
          marketsList.forEach(marketName => {
            let temp = [];
            let temp2 = [];
            commoditiesList.forEach(commodityName => {
              res.forEach(element => {
                if (element.market_name == marketName && element.commodity_name == commodityName) {
                  temp.push(element);
                }
              });
              if (temp.length > 0) {
                // temp2 = Array.from(new Set(temp.map(a => a.referenceDate)))
                //   .map(referenceDate => {
                //     return temp.find(a => a.referenceDate === referenceDate)
                //   });
                temp2 = temp;
                if (temp2.length > 0) {
                  temp2.forEach(element => {
                    element.referenceDate = (typeof (element.referenceDate) == 'string') ? element.referenceDate : moment(element.referenceDate).format('DD/MM/YYYY');
                    var d = element.referenceDate//.substring(6, 10) + '/' + element.referenceDate.substring(3, 6) + element.referenceDate.substring(0, 2);
                    element.referenceDate = new Date(d);
                    element.upload_timestamp = new Date(element.upload_timestamp);
                  });
                  temp2 = temp2.filter(_ => (typeof (_.referenceDate) == 'string') ? new Date(_.referenceDate) : _.referenceDate <= new Date());
                  temp2 = this.getFilteredData(temp2);
                  temp2 = temp2.sort(function (a, b) {
                    return (a.referenceDate) - (b.referenceDate);
                  });
                  if (temp2.length > 1) {
                    let maxlen = temp2.length;
                    temp2[temp2.length - 1].dateRange = temp2.slice(Math.max(maxlen - 5, 0));
                    temp2[temp2.length - 1].dateRange.forEach((ele, index) => {
                      ele['date'] = (typeof (ele.referenceDate) == 'string') ? ele.referenceDate : moment(ele.referenceDate).format('DD/MM/YYYY');
                      ele['price'] = ele.priceValue_avg;
                    });
                    let len = temp2[temp2.length - 1].dateRange.length - 1;
                    let seclastLen = temp2[temp2.length - 1].dateRange.length - 2;
                    temp2[temp2.length - 1].referenceDate = temp2[temp2.length - 1].dateRange[len]['date'];
                    temp2[temp2.length - 1].priceValue = temp2[temp2.length - 1].dateRange[len]['price'];
                    let lastPriceValue = temp2[temp2.length - 1].dateRange[seclastLen]['price'];
                    temp2[temp2.length - 1].priceChangeRaito = (temp2[temp2.length - 1].priceValue - lastPriceValue) / temp2[temp2.length - 1].priceValue * 100;
                    temp2[temp2.length - 1].chartObject = this.createChart(temp2[temp2.length - 1].dateRange);
                    temp2[temp2.length - 1].chartDateObject = this.createChartDates(temp2[temp2.length - 1].dateRange);
                  }
                  if (temp2.length)
                    finalList.push(temp2[temp2.length - 1]);
                }
              }
            });
          });
          finalList.forEach(element => {
            element.referenceDate = (typeof (element.referenceDate) == 'string') ? element.referenceDate : moment(element.referenceDate).format('DD/MM/YYYY');
          });
          let details: any = finalList;
          this.subscriptions.push(this.marketService.getCommoditiesDetails(this.commodityData.commodity_name.toLowerCase()).subscribe((res: any[]) => {
            if (res.length > 0) {
              details.map((l) => {
                let item = l?.commodity_name ? res.find((commodity) => commodity?.commodity_id.toLowerCase() == l?.commodity_name.toLowerCase()) : null;
                if (item) {
                  l.commodity_label = item.name[this.lang_Code] ? item.name[this.lang_Code] : l.commodity_label;
                  l.image_link = item.img;
                }
              });
              this.subscriptions.push(this.marketService.getMarketForCountry('markets').subscribe((response: any[]) => {
                this.loader = true;
                this.spinner.hide();
                if (response.length > 0) {
                  details.map((l) => {
                    let item = l?.market_name ? response.find((market) => market?.market_id.toLowerCase() == l?.market_name.toLowerCase()) : null;
                    if (item) {
                      l.market_label = item.name[this.lang_Code] ? item.name[this.lang_Code] : l.market_label;
                      details[0].latitude = item.lat;
                      details[0].longitude = item.long;
                      l['order'] = item.order;
                      l.status = item.status;
                    } else {
                      l['order'] = 9999;
                      l.status = false;
                    }
                  });
                }
                this.loader = true;
                this.spinner.hide();
                this.details = Utils.sort(details, 'order');
              }));

              // this.details = details;
              // this.getMarketsCount();
            }
          }));
        }));
      } else {
        this.boolCountry = false;
        this.isBigQuery = false;
        // this.loader = true;
        this.marketData = JSON.parse(localStorage.getItem('arrSelComMarkets'));
        //List out the urls
        if (this.marketData.length) {
          this.fpmaApi = apiPath ? apiPath.split('FpmaSerie') : '';
          this.getFPMA('monthly');
        } else {
          this.router.navigate(['agrimarket']);
        }
      }

    }
  }

  getMarketsCount() {
    const uniqueNames = new Set(this.details.filter(_ => _.status != false).map(item => item.market_name)).size;
    return uniqueNames;
  }

  getMarketCommodityBQ(marketName) {
    localStorage.removeItem('selListMarketBQ');
    let selListMarketBQ;
    this.details.forEach(m => {
      if (m.market_name == marketName) {
        selListMarketBQ = m;
      }
    });
    delete selListMarketBQ.chartObject;
    delete selListMarketBQ.dateRange;
    localStorage.setItem('selListMarketBQ', JSON.stringify(selListMarketBQ));
    this.router.navigate(['/market', marketName]);
  }

  public showChart($event) {
    $event.stopPropagation();
  }

  createChart(data) {
    let priceArr = [];
    data.forEach(element => {
      priceArr.push(element.price);
    });
    let priceSlicedArr = priceArr;
    return priceSlicedArr;
  }

  createChartDates(data) {
    let dateArr = [];
    data.forEach(ele => {
      let date = ele.referenceDate ? ele.referenceDate : ele.date;
      dateArr.push((typeof (date) == 'string') ? date : moment(date).format('DD/MM/YYYY'));
    });
    dateArr = dateArr.slice(Math.max(dateArr.length - 5, 0))
    return dateArr;
  }

  back() {
    this._location.back();
  }

  openCommodity(marketName, marketCode) {
    this.arrSelCountryData = JSON.parse(localStorage.getItem('arrCountryData'));
    var l = this.arrSelCountryData.length, i;
    for (i = 0; i < l; i++) {
      if (this.arrSelCountryData[i].market_code === marketCode) {
        var data = this.arrSelMarketCommodities.filter(_ => _.commodity_code == this.arrSelCountryData[i].commodity_code);
        if (!data.length) {
          this.arrSelMarketCommodities.push({
            "ISO3_country_code": this.arrSelCountryData[i]['ISO3_country_code'],
            "commodity_name": this.arrSelCountryData[i]['commodity_name'],
            "commodity_label": this.arrSelCountryData[i]['commodity_label'],
            "image_link": this.arrSelCountryData[i]['image_link'],
            "commodity_code": this.arrSelCountryData[i]['commodity_code'],
            "market_season_start": this.arrSelCountryData[i]['market_season_start'],
            "commodity_info": this.arrSelCountryData[i]['commodity_info'],
            "no_of_markets": "",
            "priceTypeId": this.arrSelCountryData[i]['priceTypeId'],
            "periodicityId": this.arrSelCountryData[i]['periodicityId'],
            "measureUnitId": this.arrSelCountryData[i]['measureUnitId'],
            "commodityId": this.arrSelCountryData[i]['commodityId'],
            "marketId": this.arrSelCountryData[i]['marketId'],
            "currencyId": this.arrSelCountryData[i]['currencyId'],
            "currency_variant": this.arrSelCountryData[i]['currency_variant'],
            "measureUnit": this.arrSelCountryData[i]['measureUnit'],
            "market_name": this.arrSelCountryData[i]['market_name'],
            "market_label": this.arrSelCountryData[i]['market_label'],
            "currency": this.arrSelCountryData[i]['currency'],
            "priceValue": this.arrSelCountryData[i]['priceValue'],
            "priceChangeRaito": this.arrSelCountryData[i]['priceChangeRaito'],
            "dateRange": this.arrSelCountryData[i]['dateRange'],
            "referenceDate": this.arrSelCountryData[i]['referenceDate'],
            "chartObject": this.arrSelCountryData[i]['chartObject'],
            "link": this.arrSelCountryData[i]['link'],
            "latitude": parseFloat(this.arrSelCountryData[i]['latitude']),
            "longitude": parseFloat(this.arrSelCountryData[i]['longitude']),
            "source": this.arrSelCountryData[i]['source'],
            "uuid": this.arrSelCountryData[i]['uuid'],
            "market_code": this.arrSelCountryData[i]['market_code']
          });
        }
      }
    }
    localStorage.setItem('arrSelMarketCommodities', JSON.stringify(this.arrSelMarketCommodities));
    this.router.navigate(['/market', marketName]);
  }

  setMyStyles() {
    return Utils.reverseContent();
  }

  getDefImage(event) {
    event.target.src = Utils.defaultImage();
  }

  onTabChange(i) {
    this.gaService.event('Commodities', 'Tab_Change', i.tab.textLabel);
  }
  getFilteredData(data) {
    data = data.sort(function (a, b) {
      return (b.upload_timestamp) - (a.upload_timestamp);
    });
    data = this.getUniqueData(data);
    return data;
  }
  getUniqueData(data) {
    const uniqueValuesSet = new Set();
    const filteredArr = data.filter((obj) => {
      const ref = typeof obj.referenceDate == 'string' ? obj.referenceDate : moment(obj.referenceDate).format('DD/MM/YYYY');
      const string = obj.market_name + '_' + ref;
      const isPresentInSet = uniqueValuesSet.has(string);
      uniqueValuesSet.add(string);
      return !isPresentInSet;
    });
    return filteredArr;
  }
  getFPMA(period) {
    this.spinner.show();
    this.details = [];
    const country = this.locService.getCountry();
    var api = this.fpmaApi[0] + 'FpmaSeriePrice/{uuid}/?periodicity=' + period;
    this.loader = false;
    this.subscriptions.push(this.fpmaService.getAllMarketData(this.marketData, country, api || '').subscribe(res => {
      this.loader = true;
      this.spinner.hide();
      this.marketData.forEach((element, index) => {
        let maxlen = res[index]['datapoints'].length;
        this.marketData[index].dateRange = res[index]['datapoints'].slice(0, res[index]['datapoints'].length > 5 ? 5 : res[index]['datapoints'].length).reverse();
        this.marketData[index].dateRange.forEach((ele, index) => {
          ele['date'] = moment(ele.date).format(period == 'monthly' ? 'MM/YYYY' : 'DD/MM/YYYY');
          ele['price'] = ele.price_value;
        });
        let len = this.marketData[index].dateRange.length - 1;
        let seclastLen = this.marketData[index].dateRange.length - 2;
        this.marketData[index].referenceDate = this.marketData[index].dateRange.length ? this.marketData[index].dateRange[len]['date'] : '';
        this.marketData[index].priceValue = this.marketData[index].dateRange.length ? this.marketData[index].dateRange[len]['price'] : '';
        let lastPriceValue = this.marketData[index].dateRange.length ? this.marketData[index].dateRange[seclastLen]['price'] : '';
        this.marketData[index].priceChangeRaito = (this.marketData[index].priceValue - lastPriceValue) / this.marketData[index].priceValue * 100;
        this.marketData[index].chartObject = this.createChart(this.marketData[index].dateRange);
        this.marketData[index].chartDateObject = this.createChartDates(this.marketData[index].dateRange);
      });
      this.details = this.marketData;
      this.subscriptions.push(this.marketService.getMarketForCountry('markets').subscribe((response: any[]) => {
        if (response.length > 0) {
          this.details.map((l) => {
            let item = l.market_name ? response.find((market) => market?.market_id.toLowerCase() == l.market_name.toLowerCase()) : null;
            if (item) {
              l.market_label = item.name[this.lang_Code] ? item.name[this.lang_Code] : l.market_label;
              l.latitude = item.lat;
              l.longitude = item.long;
              l['order'] = item.order;
              l.status = item.status;
            } else {
              l['order'] = 9999;
              // l.status = false;
            }
          });
          this.details = Utils.sort(this.details, 'order');
        }
      }));
      this.subscriptions.push(this.marketService.getCommoditiesForCountry('commodities').subscribe((res: any[]) => {
        if (res.length > 0) {
          let item = this.details[0].commodity_name ? res.find((commodity) => commodity?.commodity_id.toLowerCase() == this.details[0].commodity_name.toLowerCase()) : null;
          if (item) {
            this.details[0].commodity_label = item.name[this.lang_Code];
            this.details[0].image_link = item.img ? item.img : this.details[0].image_link;
          }
        }
      }));
    }));
  }
  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
