import { DateProductionCategoriesComponent } from './date-production-categories/date-production-categories.component';
import { DateProductionComponent } from './date-production.component';
import { DateProductionSubjectsComponent } from './date-production-subjects/date-production-subjects.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../shared/guards/auth.guard';

const DateProduction: Routes = [
    {
        path: 'date-production',
        component: DateProductionComponent,
        canActivate: [AuthGuard],
        children: [
            { path: '', redirectTo: 'categories', pathMatch: 'full' },
            { path: 'categories', component: DateProductionCategoriesComponent, canActivate: [AuthGuard] },
            { path: 'categories/:commodity_parent', component: DateProductionCategoriesComponent, canActivate: [AuthGuard] },
            { path: 'subject/:commodity_parent/:commodity_name', component: DateProductionSubjectsComponent, canActivate: [AuthGuard] }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(DateProduction)],
    exports: [RouterModule],
})
export class DateProductionRoutingModule { }
