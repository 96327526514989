import { AngularFireDatabase } from '@angular/fire/compat/database';
import { Injectable } from '@angular/core';

@Injectable()
export class FireDBService {

    constructor(private database: AngularFireDatabase) {

    }

    writeUserToDb(uid, ids) {
        return this.database.object(`users/${uid}`).set(ids);
    }


}
