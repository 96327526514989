import { Component, OnInit, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { ImageStorageService } from './../../shared/service/img-storage.service';
import { Layout, LayoutService } from './../../shared/service/layout.service';
import { TeamListService } from '../../shared/service/teamlist.service';
import { LivestockService } from '../../shared/service/livestock.service';
import { HttpClientModule } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { Parametre } from '../../shared/service/parametre';
import { UserService } from '../../shared/service/user.service';
import Utils from '../../shared/utils/utils';
import { FirestoreDataService } from "../../shared/service/firestore-data.service";
import { GeoLocationService } from '../../shared/service/location';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { TeamBottomSheetComponent } from '../../shared/component/team-bottom-sheet/team-bottom-sheet.component';
import { environment } from '../../../../src/environments/environment';
import { Subscription } from 'rxjs';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-team-list',
  templateUrl: './team.component.html',
  styleUrls: ['./team.component.scss']
})
export class TeamComponent implements OnInit, OnDestroy {
  searchText = {
    name: {}
  };
  public arrCategories: any = [];
  public loader: any;
  public commodity_parent: string;
  public commodity_parent_name = '';
  public displayback = 'none';
  lang_code: any;
  team_data: any;
  subscriptions: Subscription[] = [];

  constructor(
    public teamListService: TeamListService,
    private location: Location,
    public img: ImageStorageService,
    private router: Router,
    public route: ActivatedRoute,
    private livestockService: LivestockService,
    private fireStoreDataService: FirestoreDataService,
    public geoLocationService: GeoLocationService,
    private bottomSheet: MatBottomSheet,
    public layoutService: LayoutService,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit() {
    this.lang_code = JSON.parse(localStorage.getItem(environment.userLanguageStorageKey)).code
    this.searchText.name[this.lang_code] = '';
    let parentid: string;
    this.route.params.subscribe(param => {
      parentid = param.commodity_parent;
      this.getCategoryData(parentid);
    });
  }

  getCategoryData(parentid) {
    this.spinner.show();
    if (parentid != null) {
      this.displayback = 'inline';
    }
    this.loader = false;
    this.team_data = JSON.parse(localStorage.getItem('team_data'));
    if (parentid == null) {
      this.subscriptions.push(this.livestockService.getAllTeams(this.team_data.id, 'theme_id').subscribe(res => {
        this.spinner.hide();
        this.arrCategories = res;
        this.loader = true;
      }));
    } else {
      this.loader = false;
      this.subscriptions.push(this.livestockService.getTeamDetail(parentid).subscribe(res => {
        this.spinner.hide();
        this.arrCategories = res;
        this.loader = true;
      }));
    }
  }

  nav(data) {
    if (data.enutri_id != undefined) {
      this.router.navigate([`/team-list/team`, `${data.id}`]);
    }
  }


  back() {
    this.location.back();
  }

  setMyStyles() {
    return Utils.reverseContent();
  }

  onImgError(event) {
    event.target.src = Utils.offlineImage("");
  }
  openBottomSheet(data) {
    this.bottomSheet.open(TeamBottomSheetComponent, { data: data });
  }
  ngOnDestroy() {
    this.bottomSheet.dismiss();
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
