import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotificationsComponent } from './notifications.component';
import { NotificationsRoutingModule } from './notifications.routing';
import { SharedModule } from '../shared/shared.module';
import { NotificationDetailsComponent } from './notification-details/notification-details.component';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';

@NgModule({
    imports: [
        CommonModule,
        //Ng2FloatBtnModule,
        SharedModule,
        FormsModule,
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        })
    ],
    declarations: [
        NotificationsComponent,
        NotificationDetailsComponent
    ],
    exports: [
        NotificationsComponent,
        NotificationDetailsComponent,
        NotificationsRoutingModule
    ]
})
export class NotificationsModule { }
// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}