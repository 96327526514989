import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';


// TODO : npm i --save hammerjs for mat-slide-toggle, mat-slider, mdTooltip
// Importing components from @angular/material
@NgModule({
  imports: [MatButtonModule, MatCheckboxModule, MatToolbarModule, MatTabsModule,
    MatGridListModule, MatCardModule, MatIconModule, MatDialogModule,
    MatProgressBarModule, MatProgressSpinnerModule, MatInputModule,
    MatMenuModule, MatRadioModule,
    MatButtonToggleModule, MatSelectModule,
    MatSnackBarModule, MatExpansionModule, MatStepperModule,
    MatSlideToggleModule, MatSliderModule, MatSidenavModule, MatListModule,
    MatDatepickerModule, MatNativeDateModule, MatChipsModule, MatAutocompleteModule

  ],
  exports: [MatButtonModule, MatCheckboxModule, MatToolbarModule, MatTabsModule,
    MatGridListModule, MatCardModule, MatIconModule, MatDialogModule,
    MatProgressBarModule, MatProgressSpinnerModule, MatInputModule,
    MatMenuModule, MatButtonToggleModule, MatSelectModule,
    MatSnackBarModule, MatExpansionModule, MatStepperModule,
    MatSlideToggleModule, MatRadioModule, MatSliderModule, MatSidenavModule,
    MatListModule, MatDatepickerModule, MatNativeDateModule,
    MatChipsModule, MatAutocompleteModule
  ],
  schemas: [NO_ERRORS_SCHEMA],
})
export class MainMaterialModule { }
