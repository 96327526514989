import { Observable, Subscription } from 'rxjs';
import { Component, OnInit, HostListener, OnDestroy } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { UntypedFormControl } from '@angular/forms';
import { MatSnackBar } from "@angular/material/snack-bar";
import { map, startWith } from 'rxjs/operators';
import { AppComponent } from '../app.component';
import { environment } from '../../environments/environment';
import { GeoLocationService } from '../shared/service/location';
import { NotificationService } from '../shared/service/notification.service';
import {
  SwiperComponent, SwiperDirective, SwiperConfigInterface,
  SwiperScrollbarInterface, SwiperPaginationInterface
} from 'ngx-swiper-wrapper';
import Utils from './../shared/utils/utils';
import { AllLanguageApiResponse } from '../shared/models/languages';
import { StartUpService } from '../shared/service/startup.service';
import { UserService } from '../shared/service/user.service';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss'],
})
export class MessageComponent implements OnInit, OnDestroy {
  myControl: UntypedFormControl = new UntypedFormControl();
  filteredOptions: Observable<string[]>;
  arrLanguages: any = [];
  selLanguage: any;
  public loader: boolean = false;
  public setPreferences: any = {
    "getNotification": false,
    "setGeolocation": false,
    "installApp": false,
    "startApplication": false
  };

  config: SwiperConfigInterface = {
    a11y: true,
    direction: 'horizontal',
    slidesPerView: 1,
    slideToClickedSlide: true,
    mousewheel: true,
    scrollbar: true,
    watchSlidesProgress: true,
    navigation: true,
    keyboard: true,
    pagination: true,
    centeredSlides: true,
    loop: false,
    roundLengths: true,
    slidesOffsetBefore: 0,
    slidesOffsetAfter: 0,
    spaceBetween: 0,
    breakpoints: {
      // when window width is >= 320px
      320: {
        slidesPerView: 1
      }
    }
  };
  optionsLanguages: AllLanguageApiResponse;

  deferredPrompt: any;
  @HostListener('window:beforeinstallprompt', ['$event'])
  onbeforeinstallprompt(e) {
    // Prevent Chrome 67 and earlier from automatically showing the prompt
    e.preventDefault();
    // Stash the event so it can be triggered later.
    this.deferredPrompt = e;
  }
  subscriptions: Subscription[] = [];

  constructor(
    private router: Router,
    private appComponent: AppComponent,
    public location: GeoLocationService,
    public toast: MatSnackBar,
    public notificationService: NotificationService,
    public startUpService: StartUpService,
    private us: UserService,
    private spinner: NgxSpinnerService
  ) {
    this.spinner.show();
    localStorage.setItem("setPreferences", JSON.stringify(this.setPreferences));
    this.getAllLanguages();
  }

  private filterOption(value) {
    const filterValue = value.toLowerCase();
    return this.arrLanguages.filter(value => value.toLowerCase().indexOf(filterValue) === 0);
  }

  chngOptionalLang(selLang) {
    let arrLang;
    let language;
    this.selLanguage = selLang;
    arrLang = this.arrLanguages.filter(function (item) {
      return item.code == selLang
    });
    language = arrLang[0];
    localStorage.setItem(environment.userLanguageStorageKey, JSON.stringify(language));
    this.subscriptions.push(this.us.getWizardTranslation().subscribe((data) => {
      data = Utils.translationConvertor(data);
      localStorage.setItem('localLanguageData', JSON.stringify(data));
    }));
    // this.appComponent.setDefaultLang(selLang);
  }

  ngOnInit() {
    if (localStorage.getItem('SAIDA_language') && localStorage.getItem('originalCountry'))
      this.router.navigate(['/home']);
  }

  onIndexChange(event) {
    switch (event) {
      case 0:
        break;
      case 1:
        this.enableLocation();
        break;
      case 2:
        this.getNotification();
        break;
      case 3:
        break;
      case 4:
        break;
    }
  }

  continue() {
    Utils.setWizardPreferences("startApplication");
    this.router.navigate(['/select-language/1']);
  }

  getNotification() {
    this.notificationService.registerNotification("message", 'fao_user');
    Utils.setWizardPreferences("getNotification");
  }

  enableLocation() {
    let userDeniedLocation = sessionStorage.getItem(
      "userDeniedGeoLocation"
    );
    if (userDeniedLocation == 'no') {
      this.toast.open('User already enabled Geolocation.', "OK");
    }
    else {
      this.location.getLatestLocation();
    }

    Utils.setWizardPreferences("setGeolocation");
  }

  installApp() {
    Utils.setWizardPreferences("installApp");
    // Show the prompt
    this.deferredPrompt.prompt();
    // Wait for the user to respond to the prompt
    this.deferredPrompt.userChoice
      .then((choiceResult) => {
        this.deferredPrompt = null;
      });

  }
  getDefault(dta) {
    return dta.filter((item) => {
      if (item.default_language == true) {
        return item
      }
    })
  }
  getAllLanguages() {
    this.subscriptions.push(this.startUpService.getAllLanguagesList().subscribe((data: AllLanguageApiResponse[]) => {
      this.spinner.hide();
      this.arrLanguages = Object.values(data);
      this.filteredOptions = this.myControl.valueChanges.pipe(startWith(''),
        map(value =>
          value ? this.filterOption(value) : this.arrLanguages.slice())
      );
      this.selLanguage = "en";
      this.loader = true;
    }));
  }
  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
