import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { WeatherComponent } from "./weather";
import { SharedModule } from "../shared/shared.module";
import { WeeklyComponent } from "./weekly/weekly.component";
import { WeatherIndexComponent } from "./weather-index.component";
import { WeatherRoutingModule } from "./weather.routing";
// import ngx-translate and the http loader
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { HttpClient, HttpClientModule } from "@angular/common/http";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { CropComponent } from "./crop/crop.component";
import { CropsComponent } from "./crops/crops.component";
import { CropCalendarModule } from "./crops/crop-calendar/crop-calendar.module";
import { CropCalendarRoutingModule } from "./crops/crop-calendar/crop-calendar-routing.module";
import { WeatherDetailComponent } from "./weatherDetail/weatherDetail.component";
import { HighChartJsModule } from "../shared/component/charts/highcharts-js-chart/highcharts-js-chart.module";
@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        WeatherRoutingModule,
        MatAutocompleteModule,
        CropCalendarModule,
        CropCalendarRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        HighChartJsModule
    ],
    declarations: [
        CropComponent,
        CropsComponent,
        WeatherComponent,
        WeatherIndexComponent,
        WeeklyComponent,
        WeatherDetailComponent
    ],
    exports: [
        WeatherComponent,
        CropComponent,
        CropsComponent,
        WeatherIndexComponent,
        WeatherRoutingModule,
        WeeklyComponent,
        WeatherDetailComponent
    ]
})
export class WeatherModule { }
// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
