import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { ResultsPWAComponent } from "./components/results-pwa/results-pwa.component";
import { CropsComponent } from "../crops.component";

const routes: Routes = [
  { path: "", redirectTo: "/home", pathMatch: "full" },
  { path: "crops", component: CropsComponent }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      // Tell the router to use the HashLocationStrategy.
      useHash: true,
      enableTracing: false,
      relativeLinkResolution: 'legacy'
    })
  ],
  exports: [RouterModule]
})
export class CropCalendarRoutingModule { }
