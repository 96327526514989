import { TwitterComponent } from './twitter/twitter.component';
import { DisclaimerComponent } from './disclaimer/disclaimer.component';
import { PlantPestsComponent } from './plant-pests/plant-pests.component';
import { MessageComponent } from './message/message.component';
import { LoginComponent } from './login/login.component';
import { AuthGuard } from './shared/guards/auth.guard';
import { NotificationsComponent } from './notifications/notifications.component';
import { NotificationDetailsComponent } from './notifications/notification-details/notification-details.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { VerifyEmailComponent } from './verify-email/verify-email.component';
import { RegistrationComponent } from './registration/registration.component';
import { WeatherIndexComponent } from './weather/weather-index.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { SelectLanguageComponent } from './select-language/select-language.component';
import { PhoneLoginComponent } from './phone-login/phone-login.component';
import { EmailLoginComponent } from './email-login/email-login.component';
import { AppPreviewComponent } from './app-preview/app-preview/app-preview.component';
import { UserProfileDeactivateGuard } from './shared/guards/user-profile.guard';
import { ChatComponent } from './chat/chat.component';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'select-language', component: SelectLanguageComponent },
  { path: 'home', component: HomeComponent, canActivate: [AuthGuard] },
  { path: 'agrimarket', redirectTo: 'agrimarket', pathMatch: 'full' },
  { path: 'admin', redirectTo: 'admin', pathMatch: 'full' },
  { path: 'weather/:indexCode', component: WeatherIndexComponent, pathMatch: 'full', canActivate: [AuthGuard] },
  { path: 'livestock', redirectTo: 'livestock', pathMatch: 'full' },
  { path: 'nutrifood', redirectTo: 'nutrifood', pathMatch: 'full' },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'verify-email-address', component: VerifyEmailComponent },
  { path: 'register', component: RegistrationComponent, canActivate: [AuthGuard] },
  { path: 'profile', component: UserProfileComponent, canActivate: [AuthGuard], canDeactivate: [UserProfileDeactivateGuard] },
  { path: 'notifications', component: NotificationsComponent },
  { path: 'login', component: LoginComponent, canActivate: [AuthGuard] },
  { path: 'login/:invokeURL', component: LoginComponent, canActivate: [AuthGuard] },
  { path: 'disclaimer', component: DisclaimerComponent },
  { path: 'plant-pests', component: PlantPestsComponent, canActivate: [AuthGuard] },
  { path: 'message', component: MessageComponent },
  { path: 'healthy-nutrition', redirectTo: 'healthy-nutrition', pathMatch: 'full' },
  { path: 'citrus-production', redirectTo: 'citrus-production', pathMatch: 'full' },
  { path: 'date-production', redirectTo: 'date-production', pathMatch: 'full' },
  { path: 'forum', redirectTo: 'forum', pathMatch: 'full' },
  { path: 'team-list', redirectTo: 'team-list', pathMatch: 'full' },
  { path: 'select-language/:location', component: SelectLanguageComponent },
  { path: 'covid', redirectTo: "covid", pathMatch: "full" },
  { path: 'twitter', component: TwitterComponent },
  { path: 'covid19_vegetables_fruits', redirectTo: 'covid19_vegetables_fruits', pathMatch: 'full' },
  { path: 'phone-number', component: PhoneLoginComponent },
  { path: 'email-login', component: EmailLoginComponent },
  { path: 'privacy-policy/:code', component: DisclaimerComponent },
  { path: 'animal_feed', redirectTo: 'animal-feed', pathMatch: 'full' },
  { path: 'chat', component: ChatComponent, canActivate: [AuthGuard] },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
