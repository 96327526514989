import { Router } from '@angular/router';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { EnutrifoodService } from '../../shared/service/nutrifood.service';
import Utils from "../../shared/utils/utils";
import { ActivatedRoute } from '@angular/router';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { LivestockService } from '../../shared/service/livestock.service';
import { environment } from '../../../environments/environment';
import { Subscription } from 'rxjs';
import { NgxSpinnerService } from "ngx-spinner";
import { ImageStorageService } from '../../shared/service/img-storage.service';

@Component({
  selector: 'app-family-practices-categories',
  templateUrl: './family-practices-categories.component.html',
  styleUrls: ['./family-practices-categories.component.scss']
})
export class FamilyPracticesCategoriesComponent implements OnInit, OnDestroy {
  searchText: any = {
    name: {}
  };
  public isDataLoading: any;
  lang_code: any;
  appData: any;
  selectedTab: number = 0;
  appTabs: any;
  species: any;
  subscriptions: Subscription[] = [];
  public arrTabInfo: any;
  topics: any = [];
  groups: any = [];
  providers: any;
  selectedDimension: any;
  constructor(
    public enutrifoodService: EnutrifoodService,
    private router: Router,
    public route: ActivatedRoute,
    public livestock: LivestockService,
    private gaService: GoogleAnalyticsService,
    private spinner: NgxSpinnerService,
    private imageStorageService: ImageStorageService
  ) {
    this.spinner.show();
  }

  ngOnInit() {
    this.isDataLoading = true;
    this.lang_code = JSON.parse(localStorage.getItem(environment.userLanguageStorageKey)).code
    this.searchText.name[this.lang_code] = '';
    const themes = JSON.parse(localStorage.getItem('appThemes'));
    this.route.params.subscribe(param => {
      if (param.id)
        var parentid = param.id.split('?')[0];
      if (parentid)
        this.appData = themes.filter(_ => _.id === parentid)[0];
      else
        this.appData = themes.filter(_ => _.template_id === 'animal_feed')[0];

      this.appTabs = this.appData.tabs;
      this.appTabs = this.appTabs.filter(_ => _.status == undefined || _.status);
      if (this.appData) {
        this.getCategoryData(parentid);
      }
    });
    this.subscriptions.push(this.livestock.getMessageGroup().subscribe(res => {
      this.groups = res;
    }));
  }
  getCategoryData(parentid) {
    this.subscriptions.push(this.livestock.getAllDimensions(parentid, 'theme_id', 'order').subscribe(res => {
      this.spinner.hide();
      this.species = res;
      this.isDataLoading = false;
      //this.arrCategories = res.filter(_ => _['status'] == undefined || _['status']);
      //this.loader = true;
    }));
  }

  tabChange(i) {
    // this.gaService.event('Livestock', 'Tab_Change', i.tab.textLabel);
    // this.selectedTab = Utils.setLivestockTabIndex(i.index);
  }
  setMyStyles() {
    return Utils.reverseContent();
  }
  onImgError(event) {
    event.target.src = Utils.offlineImage("");
  }
  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
  getx48Image(inputUrl: string): string {
    return this.imageStorageService.getx48Image(inputUrl);
  }
  selectDimension(item) {
    //open the key messages section if it is a topic
    if (item?.group_type == 'topic') {
      this.router.navigate(['/family-practices/subject', this.appData.id, this.selectedDimension.id, item?.id]);
    }
    this.subscriptions.push(this.livestock.getMessageForSpecie('dimension_id', item.id).subscribe(messgae => {
      messgae = messgae.filter(_ => _.status == undefined || _.status);
      this.arrTabInfo = messgae ? messgae.reduce(this.messagesTabs, {}) : {};

      this.topics = this.groups.filter(_ => _.group_type == 'topic' && Object.keys(this.arrTabInfo).includes(_.id));
      this.selectedDimension = item;

      //open the key messages section if there are no topics
      if (this.topics.length == 0) {
        this.router.navigate(['/family-practices/subject', this.appData.id, this.selectedDimension.id]);
      }
    }));
  }
  messagesTabs(acc, msg) {
    if (msg.topic_id !== "") {
      if (!acc[msg.topic_id]) {
        acc[msg.topic_id] = {};
      }
      if (!acc[msg.topic_id][msg.group_id]) {
        acc[msg.topic_id][msg.group_id] = [msg];
        return acc;
      }
      acc[msg.topic_id][msg.group_id].push(msg);
    }
    return acc;
  }
}
