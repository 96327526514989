import { TwitterComponent } from './twitter.component';
// import { SecureInnerPagesGuard } from './../shared/guards/secure-inner-pages.guard';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../shared/guards/auth.guard';

const twitter: Routes = [
    {
        path: 'twitter',
        component: TwitterComponent,
        canActivate: [AuthGuard]
    }
];

@NgModule({
    imports: [RouterModule.forChild(twitter)],
    exports: [RouterModule],
})
export class TwitterRoutingModule { }
