import { tap, map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { ImageStorageService } from './../../service/img-storage.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  version$: any;

  constructor(private router: Router, public fdb: AngularFireDatabase, public img: ImageStorageService) { }

  ngOnInit() {
    this.version$ = this.fdb
      .list('/changelog', ref => ref.limitToLast(1))
      .valueChanges().pipe(
        map(res => res[0]['name']))
  }

  nav(link) {
    this.router.navigate([`/${link}`]);
  }


}
