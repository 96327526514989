import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../shared/guards/auth.guard';
import { AppPreviewComponent } from './app-preview/app-preview.component';

const previewRoute: Routes = [
  { path: 'preview/:section/:country/:appName', component: AppPreviewComponent, canActivate: [AuthGuard] }
];

@NgModule({
  imports: [RouterModule.forChild(previewRoute)],
  exports: [RouterModule],
})
export class PreviewRoutingModule { }
