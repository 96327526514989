import { Component, OnInit, Inject } from '@angular/core';
import { Router, ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import Utils from '../../../../shared/utils/utils';
export interface DialogData {
  title: string;
  subtitle: string;
  link: string;
  hint: string;
  Details: string;
  name: string;
}
@Component({
  selector: 'app-caveat',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.css']
})
export class PopupComponent {

  constructor(
    public router: Router,
    public dialogRef: MatDialogRef<PopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,) { }

  onOkClick(link): void {
    // this.router.navigate([link]);
    window.location.href = link;
  }
  onCancelClick(): void {
    this.dialogRef.close();
  }
  setMyStyles() {
    return Utils.reverseContent();
  }
}