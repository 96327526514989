import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map, tap, catchError } from 'rxjs/operators';
import { AuthService } from '../service/auth.service';
import { GeoLocationService } from '../service/location';
import { HttpClient } from '@angular/common/http';
import { Parametre } from '../service/parametre';
const PARAMETRE = Parametre.getInstance();

@Injectable()
export class AuthGuard implements CanActivate {
  isLoggedIn: boolean = false;
  isRegReq: boolean = false;
  isAppExist: string = "";

  constructor(
    private authService: AuthService,
    private router: Router,
    public location: GeoLocationService,
    private http: HttpClient
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    let routePage = route.url[0].path;
    //this.isRegReq = this.authService.isRegRequired;
    this.isLoggedIn = this.authService.isLoggedIn;
    this.isAppExist = this.authService.isApplicationExist(route.url[0].path);

    if (this.isAppExist == 'No') {
      this.router.navigate(['home']);
      return false;
    } else {
      if (this.isLoggedIn) {
        if (routePage == 'login' || routePage == 'register' || routePage == 'forgot-password' || routePage == 'verify-email-address') {
          this.router.navigate(['home']);
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    }
  }
}

