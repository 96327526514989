import { Component, OnInit } from '@angular/core';
import { AuthService } from "./../shared/service/auth.service";
import { GeoLocationService } from './../shared/service/location';
import { Router, ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  public invokeURL: string = "";
  constructor(
    public route: ActivatedRoute,
    public authService: AuthService,
    public router: Router,
    public location: GeoLocationService
  ) { }

  ngOnInit() {
    this.route.params.subscribe(param => {
      if (param.invokeURL != null) {
        this.invokeURL = param.invokeURL;
        this.invokeURL = this.invokeURL.replace(/\*/g, "/")
      }
    });
  }
}
