import { throwError as observableThrowError, Observable } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { FirestoreDataService } from "./firestore-data.service";


@Injectable({
  providedIn: "root"
})
export class StartUpService {
  baseUrl = environment.apiBaseUrl;
  constructor(
    private http: HttpClient,
    public toast: MatSnackBar,
    public firestoreDataService: FirestoreDataService
  ) {
    // const url = 'https://us-central1-fao-portfolio-dev.cloudfunctions.net/callMeteoAllCountryApis?country=' + country_name;
  }

  public handleError = (err: Response) => {
    if (err.status === 500 || err.status === 503) {
      this.toast.open(
        "Service is temporarily unavailable, please try again later",
        "OK",
        { duration: 3000 }
      );
    }
    return observableThrowError(err.json());
  };
  getAllLanguagesList() {
    return this.firestoreDataService.getLanguageData();
  }
}