import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AgroEcologicalZone } from '../../../models/aez.dto';



@Component({
  selector: 'app-modal-all-zones',
  templateUrl: './modal-all-zones.component.html',
  styleUrls: ['./modal-all-zones.component.scss']
})
export class ModalAllZonesComponent implements OnInit {

  constructor(
    private dialogRef: MatDialogRef<ModalAllZonesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { zone: AgroEcologicalZone }) {
  }

  ngOnInit() {}

  closeModal() {
    this.dialogRef.close();
  }
}

