import { Subscription, Observable } from 'rxjs';

import { Component, OnInit, OnDestroy } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import { environment } from '../../../environments/environment';
import Utils from "./../../shared/utils/utils";
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { GeoLocationService } from './../../shared/service/location';
import { NgxSpinnerService } from "ngx-spinner";
import { Router, ActivatedRoute } from '@angular/router';
import { LivestockService, } from '../../shared/service/livestock.service';
import { TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { finalize } from 'rxjs/operators';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { ForumService } from '../../shared/service/forum.service';
import { TranslationService } from '../../shared/service/translation.service';
import { InsectRecogniseService } from '../../shared/service/insect-recognise.service';
import { Ng2ImgMaxService } from 'ng2-img-max';

@Component({
  selector: 'app-recognise-insects',
  templateUrl: './recognise-insects.component.html',
  styleUrls: ['./recognise-insects.component.scss']
})

export class RecogniseInsectsComponent implements OnInit, OnDestroy {

  @ViewChild('choosePhotoDialog') choosePhotoDialog: TemplateRef<any>;
  public objectKeys = Object.keys;
  speciesName: any;
  public arrSpeciesDetails: any = [];
  public loader: boolean = false;

  themeId: string;
  appTabs: any;
  appTheme: any;

  crops: any;
  cropSelected: string;
  insectImage: string = null;

  lang_code: any;
  groups: any = [];
  providers: any;
  topics: any = [];
  sub: any;
  requestId: any;
  counter = 7;
  translateMessage = 'This is auto translated content';
  keyMessage = '';
  selectedIndex = 0;
  subscriptions: Subscription[] = [];
  spinner: boolean = false;
  downloadURL: Observable<string>;
  public editPost: boolean = false;
  public showimage = false;
  public postphotoUrl = '';
  public postid: string = "";

  constructor(
    public gaService: GoogleAnalyticsService,
    public geoLocationService: GeoLocationService,
    public router: Router,
    private route: ActivatedRoute,
    private livestock: LivestockService,
    private ngxspinner: NgxSpinnerService,
    private dialog: MatDialog,
    private translate: TranslationService,
    public toast: MatSnackBar,
    private afs: AngularFireStorage,
    private fs: ForumService,
    private insectRecogniseService: InsectRecogniseService,
    private ng2ImgMaxService: Ng2ImgMaxService
  ) {
    this.ngxspinner.show();
  }

  public back() {
  }

  ngOnInit() {

    const themes = JSON.parse(localStorage.getItem('appThemes'));

    this.lang_code = JSON.parse(localStorage.getItem(environment.userLanguageStorageKey)).code;

    this.route.params.subscribe(param => {

      if (param.themeId)
        this.themeId = param.themeId.split('?')[0];

      if (this.themeId)
        this.appTheme = themes.filter(_ => _.id === this.themeId)[0];
      else {
        this.appTheme = themes.filter(_ => _.template_id === 'insect')[0];
      }

      this.appTabs = this.appTheme.tabs;
      this.appTabs = this.appTabs.filter(_ => _.status == undefined || _.status);

      this.getCrops(this.appTheme.id);
    });
  }

  setMyStyles() {
    return Utils.reverseContent();
  }

  ngOnDestroy() {
    if (this.sub)
      this.sub.unsubscribe();
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  getDimentions(parentid) {
    this.subscriptions.push(this.livestock.getAllDimensions(parentid, 'theme_id', 'order').subscribe(res => {
      this.loader = true;
      this.ngxspinner.hide();
      if (res) {
        this.crops = res.filter(_ => _.tab_id == "crops");
      }
    }));
  }

  getCrops(parentid) {
    this.subscriptions.push(this.livestock.getAllCrops(parentid).subscribe(res => {
      this.loader = true;
      this.ngxspinner.hide();
      if (res) {
        this.crops = res.filter(_ => _.tab_id == "crops");
      }
    }));
  }

  handleClick() {
    document.getElementById('upload-file').click();
  }

  async addAttachment(fileInput: any) {
    const fileReaded = fileInput.target.files[0];
    console.log(fileReaded);
  }

  openModal() {
    let dialogRef = this.dialog.open(this.choosePhotoDialog, {
      width: '320px'
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result !== undefined) {
        if (result === 'yes') {
          // TODO: Replace the following line with your code.
          console.log('User clicked yes.');
        } else if (result === 'no') {
          // TODO: Replace the following line with your code.
          console.log('User clicked no.');
        }
      }
    })
  }


  closeDialog() {
    //this.dialog.close();
  }


  DeletePhoto() {
    let fileDelted = false;
    try {
      this.afs.storage.refFromURL(this.postphotoUrl).delete();
      fileDelted = true;
    } catch (exception) {

      fileDelted = true;
    }
    if (fileDelted) {
      this.showimage = false;
      if (this.postid)
        this.fs.DeletePostImage(this.postid);
    }

  }

  onFileSelected(event) {

    let changepPhoto = false;
    const file = event.target.files[0];
    this.ng2ImgMaxService.compressImage(file, 1).subscribe(fileResized => {
      if (fileResized.size > 50000000) {
        this.toast.open(this.translate.translate("forum.filesizeerror"), this.translate.translate("Post.Ok"), { duration: 10000 });
        return;
      }
      this.getBase64(fileResized).then(
        data => {
          this.insectImage = "" + data;
        }
      );

      if (this.showimage) {
        this.DeletePhoto();
      }
      this.spinner = true;
      if (this.editPost)
        changepPhoto = true;
    });
  }

  saveRecogniseInsectsDetails() {
    let apiBody = {
      "theme_id": this.appTheme.id,
      "image_b64": this.insectImage
    };
    if (this.cropSelected) apiBody['crop_id'] = this.cropSelected;
    this.ngxspinner.show();
    this.insectRecogniseService.sendInsectToRecognise(apiBody).subscribe((res) => {
      this.ngxspinner.hide();
      let result = res['result'];
      let probability = res['probabilities'][result];
      //TODO _ update this value to manage the lowest probability in recognition
      let minProbability = 0.60;
      if (probability >= minProbability) {
        this.subscriptions.push(this.livestock.getInsectsDocumentFilter(this.appTheme.id, 'insect_id', res["result"]).subscribe(speciesRes => {
          if (speciesRes && speciesRes.length > 0) {
            this.router.navigate(['/insect', this.appTheme.id, speciesRes[0].id]);
          } else {
            this.toast.open(this.translate.translate("insect_recognition.no_match_insect"), this.translate.translate("Post.Ok"), { duration: 10000 });
          }
        }));
      } else { //recognition fail
        this.toast.open(this.translate.translate("insect_recognition.no_match_insect"), this.translate.translate("Post.Ok"), { duration: 10000 });
      }
    }, (error: HttpErrorResponse) => {

      this.ngxspinner.hide();
      if (error.status == 413) {
        this.toast.open(this.translate.translate("insect_recognition.image_too_large"), this.translate.translate("Post.Ok"), { duration: 10000 });

      } else {
        this.toast.open(this.translate.translate("system.service_unavailable"), this.translate.translate("Post.Ok"), { duration: 10000 });
      }

    });
  }

  getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

}
