import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Subscription, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from '../../../shared/service/user.service';
import { AuthService } from '../../../shared/service/auth.service';
import { ForumService } from '../../../shared/service/forum.service';
import { MatListModule } from '@angular/material/list';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import Utils from '../../../shared/utils/utils';
import { GeoLocationService } from '../../../shared//service/location';

interface Report {
  uid: any;
  ufirstName: any;
  ulastName: any;
  rDetails: string;
  rCreatedDate: any;
  rView: any;
  docid: any;
  pid: any;
  rid: any;
  cid: any;
  type: any;
}

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.css']
})

export class ReportsComponent implements OnInit, OnDestroy {
  public loader = false;
  public userdoc: any = [];
  public IsforumAdmin: boolean = false;
  public langCode = UserService.langCode;
  public arrApplications: any;
  searchText = '';
  public arrUser: any = [];
  public path: any;
  config: any;
  page = 1;
  itemsPerPage = 25;
  public arrDocs: any;
  public arrDocs0: any;
  country: any;
  forum: any;
  subscriptions: Subscription[] = [];

  constructor(
    private router: Router,
    public userService: UserService,
    public authService: AuthService,
    public forumService: ForumService, private location: Location, private Countrylocation: GeoLocationService
  ) {
    if (this.authService.isLoggedIn) {
      this.arrUser = this.authService.isLoggedIn;
      this.arrUser.role = 'Admin';
    }
  }

  loadreportsData() {
    this.subscriptions.push(this.forumService.getPostswithreports().subscribe(actionArray => {
      this.arrDocs = actionArray.map(item => {
        this.path = item.payload.doc.ref.path;
        this.path = item.payload.doc.ref.parent.parent.path;
        const docid = item.payload.doc.id;
        let _report: any = item.payload.doc.data() as Report;
        _report.docid = docid;
        // _report.rid = docid;
        // _report.type = 'c';
        _report.docPath = item.payload.doc.ref.path;
        _report.parentDocPath = item.payload.doc.ref.parent.parent.path;
        if (_report.docPath.includes('comments')) {
          _report.type = 'c';
        }
        else {
          _report.type = 'p';
        }


        item.payload.doc.ref.parent.parent.get().then(x => {
          var postPath = item.payload.doc.ref.path.substring(item.payload.doc.ref.path.indexOf('posts'));
          postPath = postPath.substring(6);
          postPath = postPath.substring(0, postPath.indexOf('/'));
          _report.publish = x.data().publish;
          if (_report.type == 'c') {
            _report.pid = postPath;
            _report.cDetails = x.data().cDetails;
            _report.cCreatedDate = x.data().cCreatedDate;

          }
          if (_report.type == 'p') {
            _report.pid = x.id;
            _report.pTitle = x.data().pTitle;
            _report.pDetails = x.data().pDetails;
            _report.pphotoUrl = x.data().pphotoUrl;
          }

        });

        return _report;

      });
      this.config = {
        itemsPerPage: this.itemsPerPage,
        currentPage: this.page,
        totalItems: this.arrDocs.length
      };
    }));




  }

  Search() {
    if (this.searchText == '') {
      this.arrDocs = this.arrDocs0;
      return;
    }
    else {
      this.arrDocs = [];
      this.arrDocs0.forEach(_post => {
        if (_post.pTitle.includes(this.searchText) || _post.pDetails.includes(this.searchText)) {
          this.arrDocs.push(_post);
        }
      });
    }
    this.config = {
      itemsPerPage: this.itemsPerPage,
      currentPage: this.page,
      totalItems: this.arrDocs.length
    };

  }


  ngOnInit() {
    const themes = JSON.parse(localStorage.getItem('appThemes'));
    this.forum = themes.filter(_ => _.template_id === 'forum')[0];
    this.country = JSON.parse(localStorage.getItem("countryData"));
    if (!this.authService.isLoggedIn) {
      this.router.navigate(['login/forum*admin']);
    }

    this.subscriptions.push(this.authService.afAuth.authState.subscribe(async user => {
      if (user) {
        this.IsforumAdmin = this.IsAdmin(user.uid);
      }
    }));


    this.langCode = UserService.langCode;
    // Check application is exist or not
    this.subscriptions.push(this.userService.getApplicationList().subscribe(res => {
      this.arrApplications = res['applications'];
    }));
    this.config = {
      itemsPerPage: this.itemsPerPage,
      currentPage: this.page,
      totalItems: this.itemsPerPage
    };
    this.loadreportsData();
  }
  public IsAdmin(UserId) {
    this.subscriptions.push(this.forumService.getUserDetails(UserId).subscribe((res: any[]) => {
      if (res) {
        this.userdoc = res;
        const country = this.Countrylocation.getCountry();
        if (this.userdoc.roles["dsp"].indexOf("admin") > -1) {
          this.loader = true;
        } else if (!(this.userdoc.roles["dsp"].includes("admin") || this.userdoc.roles["dsp"].includes("forum_admin")) || this.userdoc['country_iso3'].toLowerCase() != country.country.toLowerCase()) {
          this.router.navigate([`forum`]);
        }
        this.loader = true;
      }
    }));
    return true;
  }

  nav(link) {
    this.router.navigate([`forum/post-details`, `${link}`]);
  }

  publish(docPath, parentDocPath) {
    this.forumService.publishPost(docPath, parentDocPath);
    // this.loadreportsData();
  }

  unpublish(docPath, parentDocPath) {
    this.forumService.unpublishPost(docPath, parentDocPath);
    this.loadreportsData();

  }

  DeleteReport(docPath, parentDocPath) {
    // publish post or comment if the report is fake then delete the fake report
    this.publish(docPath, parentDocPath);
    this.forumService.deleteReport(docPath, parentDocPath);
    this.loadreportsData();
  }



  pageChanged(event) {
    //this.config.currentPage = event;
    this.page = event;
    this.config.currentPage = this.page;
  }

  back() {
    this.location.back();
  }
  setMyStyles() {
    return Utils.reverseContent();
  }
  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
