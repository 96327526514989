import { Component, OnInit, Inject } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { Subscription, Observable } from 'rxjs';
import { environment } from '../../../src/environments/environment';
import { NgxSpinnerService } from "ngx-spinner";
import { PandemReportService } from '../shared/service/pandemic-report.service';
import { ForumService } from '../shared/service/forum.service';
import { TranslationService } from '../shared/service/translation.service';
import Utils from '../shared/utils/utils';
import { FirestoreDataService } from '../shared/service/firestore-data.service';
import { HttpErrorResponse } from '@angular/common/http';
// import packageJson from './../../../package.json';

export interface DialogData {
  title: string;
  Details: string;
  email: string;
  name: string;
}
@Component({
  selector: 'app-pandemic-report',
  templateUrl: './pandemic-report.component.html',
  styleUrls: ['./pandemic-report.component.css']
})
export class PandemicReportComponent {
  locationSelected: string;
  locations: any = [];
  spinner: boolean = false;
  downloadURL: Observable<string>;
  insectImage: string = null;
  subscriptions: Subscription[] = [];
  public editPost: boolean = false;
  public showimage = false;
  public postphotoUrl = '';
  public postid: string = "";
  public image: any;
  public survey_categories: any[];
  public survey_questions = [];
  public chklabel = "Label"
  themeId: String;

  myFormGroup: UntypedFormGroup;
  // public version: string = packageJson.version;
  constructor(
    public dialogRef: MatDialogRef<PandemicReportComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private gaService: GoogleAnalyticsService,
    private toast: MatSnackBar,
    private translate: TranslationService, private fb: UntypedFormBuilder,
    private pandemReportService: PandemReportService,
    private afs: AngularFireStorage,
    private firestoreService: FirestoreDataService,
    private fs: ForumService,
    private ngxspinner: NgxSpinnerService,) {

    //TODO _ implement multiple survey support!
    this.themeId = JSON.parse(localStorage.getItem('appThemes')).filter(t => (t.template_id == 'survey')).pop()?.id;

    this.myFormGroup = new UntypedFormGroup({
      location: new UntypedFormControl('', [Validators.required]),
      gpsLocation: new UntypedFormControl('', [Validators.required])
    })
    this.ngxspinner.show();
  }

  ngOnInit() {
    this.getSurveyCategories();
    this.getLocations();
  }

  getLocations() {
    this.subscriptions.push(
      this.pandemReportService.getLocations(this.themeId).subscribe(res => {
        this.ngxspinner.hide();
        if (res) {
          this.locations = res;
        }
      })
    );


  }

  async getSurveyCategories() {
    const categories = await this.firestoreService.surveyCategories(this.themeId);
    this.survey_categories = categories.sort((a, b) => a.order - b.order);
    this.survey_categories.forEach(async element => {
      var questions = await (await this.firestoreService.surveyQuestionSettings(this.themeId, element["id"])).sort((a, b) => { return a.order - b.order })
      questions.forEach(question => {
        this.survey_questions.push(question);
        //add validation
        const requiredArr = question.required ? [Validators.required] : [];
        this.myFormGroup.addControl(question.id, new UntypedFormControl('', requiredArr));
      })
    });
  }


  setMyStyles() {
    return Utils.reverseContent();
  }


  DeletePhoto() {
    let fileDelted = false;
    try {
      this.afs.storage.refFromURL(this.postphotoUrl).delete();
      fileDelted = true;
    } catch (exception) {

      fileDelted = true;
    }
    if (fileDelted) {
      this.showimage = false;
      if (this.postid)
        this.fs.DeletePostImage(this.postid);
    }

  }

  onFileSelected(event) {
    let changepPhoto = false;
    const file = event.target.files[0];
    this.image = file;
    if (file.size > 50000000) {
      this.toast.open(this.translate.translate("forum.filesizeerror"), this.translate.translate("Post.Ok"), { duration: 10000 });
      return;
    }

    this.getBase64(file).then(
      data => {
        this.insectImage = "" + data;
      }
    );

    if (this.showimage) {
      this.DeletePhoto();
    }
    if (this.editPost)
      changepPhoto = true;
  }

  getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  onSubmit() {
    //trigger the validation
    this.highlightInvalidFields();
    if (this.myFormGroup.valid && this.myFormGroup.controls['gpsLocation'].value) {
      const locationId = this.myFormGroup.controls['location'].value;
      const locationObj = this.locations.find(o => o.id == locationId);
      const gpsLocation = this.myFormGroup.controls['gpsLocation'].value || false;
      let gps = ''
      if (gpsLocation) {
        const coords = JSON.parse(localStorage.getItem('coords'));
        gps = coords.toString();
      }

      const dataObj = {
        "location": {
          "id": locationObj.id,
          "name": locationObj.name,
          "gps": gps
        },
        "img": null,
        "survey": {
          "questions": []
        },
        "theme_id": this.themeId
      };

      const dynamicFormArr = [];
      this.survey_questions.forEach(element => {
        switch (element['type']) {
          case 'text':
            const obj: any = Object.assign({}, element);
            obj['reply'] = this.myFormGroup.controls[element['id']].value || '';
            dynamicFormArr.push(obj);
            break;
          case 'boolean':
            const obj1: any = Object.assign({}, element);
            obj1['reply'] = this.myFormGroup.controls[element['id']].value == '1' ? true : false;
            dynamicFormArr.push(obj1);
            break;
          case 'options':
            const obj2: any = Object.assign({}, element);
            const replyObj = obj2['options'].find(ele => ele['id'] == this.myFormGroup.controls[element['id']].value);
            obj2['reply'] = replyObj;
            dynamicFormArr.push(obj2);
            break;
          default: console.log('do nothing');
        }
      })
      dataObj['survey']['questions'] = dynamicFormArr;

      this.spinner = true;
      if (this.image) {
        this.firestoreService.uploadImage(this.image).subscribe((res) => {
          if (res) {
            this.submitPandemicForm(dataObj, res);
          }
        });
      } else {
        this.submitPandemicForm(dataObj, null);
      }
    }
  }
  private submitPandemicForm(dataObj, img: string | null) {
    dataObj['img'] = img;
    let gpsCountry = JSON.parse(localStorage.countryData).country;
    gpsCountry = gpsCountry.toUpperCase();
    this.pandemReportService.submitReport(dataObj, gpsCountry).subscribe((res: string) => {
      if (res) {
        this.toast.open("Report submitted Successfully", this.translate.translate("Post.Ok"), { duration: 10000 });
        this.spinner = false;
        this.dialogRef.close();
      }
    }, (error: HttpErrorResponse) => {
      this.toast.open(this.translate.translate("system.service_unavailable"), this.translate.translate("Post.Ok"), { duration: 10000 });
      this.spinner = false;
    });
  }

  // Function to highlight invalid fields
  highlightInvalidFields() {
    // Mark all controls as touched to trigger validation
    Object.values(this.myFormGroup.controls).forEach(control => {
      control.markAsTouched();
    });
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}