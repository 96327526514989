import { AppNutrifoodPreviewComponent } from "./app-nutrifood-preview/app-nutrifood-preview.component";
import { AppLivestockPreviewComponent } from "./app-livestock-preview/app-livestock-preview.component";
import { AppMenuComponant } from "./app-menu-componant/app-menu-componant.component";
import { AppWeatherPreviewComponent } from "./app-weather-preview/app-weather-preview.component";
import { AppAgrimarketPreviewComponent } from "./app-agrimarket-preview/app-agrimarket-preview.component";
import { AppSpeciesPreviewComponent } from "./app-species-preview/app-species-preview.component";
import { AppDiseasePreviewComponent } from "./app-disease-preview/app-disease-preview.component";
import { AppVaccinationPreviewComponent } from "./app-vaccination-preview/app-vaccination-preview.component";
import { AppAgrimarketDetailPreviewComponent } from "./app-agrimarket-detail-preview/app-agrimarket-detail-preview.component";
import { AppMarketsPreviewComponent } from "./app-markets-preview/app-markets-preview.component";
import { AppEnutrifoodSubjectPreviewComponent } from "./app-enutrifood-subject-preview/app-enutrifood-subject-preview.component";
import { AppPlantPestsPreviewComponent } from "./app-plant-pests-preview/app-plant-pests-preview.component";
import { AppCovidAdvicePreviewComponent } from "./app-covid-advice-preview/app-covid-advice-preview.component";
import { AppCropDetailPreviewComponent } from "./app-crop-detail-preview/app-crop-detail-preview.component";

export const componants = {
    menu: AppMenuComponant,
    weather: AppWeatherPreviewComponent,
    livestock: AppLivestockPreviewComponent,
    nutrifood: AppNutrifoodPreviewComponent,
    agrimarket: AppAgrimarketPreviewComponent,
    species: AppSpeciesPreviewComponent,
    diseases: AppDiseasePreviewComponent,
    vaccination: AppVaccinationPreviewComponent,
    commoditiy: AppAgrimarketDetailPreviewComponent,
    market: AppMarketsPreviewComponent,
    nutrifoodDetail: AppEnutrifoodSubjectPreviewComponent,
    citrus: AppNutrifoodPreviewComponent,
    citrusDetail: AppEnutrifoodSubjectPreviewComponent,
    dateProduction: AppNutrifoodPreviewComponent,
    dateProductionDetail: AppEnutrifoodSubjectPreviewComponent,
    householdPoultry: AppNutrifoodPreviewComponent,
    householdPoultryDetail: AppEnutrifoodSubjectPreviewComponent,
    healthyNutrition: AppNutrifoodPreviewComponent,
    healthyNutritionDetail: AppEnutrifoodSubjectPreviewComponent,
    platPests: AppPlantPestsPreviewComponent,
    covidAdvice: AppCovidAdvicePreviewComponent,
    covidFoodSafety: AppNutrifoodPreviewComponent,
    covidFoodSafetyDetail: AppEnutrifoodSubjectPreviewComponent,
    cropDetail: AppCropDetailPreviewComponent
};