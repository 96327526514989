import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { Location } from '@angular/common';
import { AudioService } from '../audio/audio.service';
import { VideoService } from '../video/video.service';
import { ImageService } from '../image-modal/image.service';

import { Parametre } from '../../service/parametre';
const PARAMETRE = Parametre.getInstance();
import { UserService } from '../../service/user.service';
import { environment } from '../../../../environments/environment';
import Utils from '../../utils/utils';
import { GeoLocationService } from '../../service/location';
import { LivestockService } from '../../service/livestock.service';
import { DomSanitizer } from '@angular/platform-browser';
import { EnutrifoodService } from '../../service/nutrifood.service';
import { ActivatedRoute } from '@angular/router';
import { LinkService } from '../../../shared/component/link/link.service';

@Component({
  selector: 'app-single-node',
  templateUrl: './single-node.component.html',
  styleUrls: ['./single-node.component.scss']
})

export class SingleNodeComponent implements OnInit, OnDestroy, OnChanges {
  @Input('menuBarTitle') menuBarTitle;
  @Input('arrCommodity') arrCommodity;
  @Input('generalTabData') generalTabData;
  @Input('loader') loader;
  @Output('ga') ga = new EventEmitter<any>(); groups: any;
  lang_code: any;
  public txtSpeech: any;
  objectKeys = Object.keys;
  public selCountry: any;
  providers: any;
  sub: any;
  counter = 7;
  isVideoMessages: boolean;
  messages: any;
  requestId: any;
  topics: any = [];
  digitalGreen = 'Digital green';
  categories: any;
  selectedIndex: number;
  estYear: any = {};
  week_days: any = {};
  marketType: any = {};
  owner: any = {};
  digitalGreenSubCat = {
    'banana': 'banana',
    'tomato': 'tomato',
    'irish potato': 'potato',
    'maize': 'maize'
  }
  translateMessage = 'This is auto translated content';
  keyMessage = '';
  subscriptions: Subscription[] = [];
  constructor(
    private location: Location,
    private audio: AudioService,
    private videoService: VideoService,
    private imageService: ImageService,
    public geoLocationService: GeoLocationService,
    private livestockService: LivestockService,
    private enutrifoodService: EnutrifoodService,
    public route: ActivatedRoute,
    private linkService: LinkService
  ) { }

  ngOnInit() {
    this.subscriptions.push(this.route.queryParams.subscribe(params => {
      if (params['keyMessage'])
        this.keyMessage = params['keyMessage'];
    }));
    //remove useless GEOINFORMATION refresh
    // this.geoLocationService.getLatestLocation();
    var isEthiopia = false;
    var sub = this.geoLocationService.location$.subscribe(location => {
      if (location && location.toLowerCase() == 'ethiopia' && !isEthiopia) {
        isEthiopia = true;
        this.sub = this.livestockService.getLocationData(
          this.geoLocationService.coords[0], this.geoLocationService.coords[1])
          .subscribe(valueRes => {
            // this.requestId = valueRes['requestId'];
            // this.getVideoData(valueRes['requestId']);
            this.objectKeys(valueRes).forEach(_ => {
              this.getVideoData(valueRes[_]);
            });
            if (this.lang_code != 'en') {
              this.subscriptions.push(this.livestockService.getTranslation({
                "q": [this.digitalGreen, this.translateMessage],
                "target": this.lang_code
              }).subscribe(res => {
                this.digitalGreen = res['data']['translations'][0].translatedText;
                this.translateMessage = res['data']['translations'][1].translatedText;
              }));
            }
          });
      }
    });
    this.lang_code = JSON.parse(localStorage.getItem(environment.userLanguageStorageKey)).code;
    UserService.langCodeTextSpeech = JSON.parse(localStorage.getItem(environment.userLanguageStorageKey)).text_to_speech_code;
    this.txtSpeech = UserService.langCodeTextSpeech;
    this.selCountry = (this.geoLocationService.getCountry().country).toLowerCase();
    this.subscriptions.push(this.livestockService.getProviders().subscribe(res => {
      this.providers = res;
    }));
    this.getFilterData();
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (this.arrCommodity) {
      this.subscriptions.push(this.livestockService.getMessageGroup().subscribe(res => {
        this.groups = res;
        if (!this.topics.length) {
          var topics = [];
          let selectedTopic = '';
          if (this.keyMessage) {
            Object.keys(this.arrCommodity).forEach(ele => {
              if (!selectedTopic)
                selectedTopic = this.arrCommodity[ele].filter(_ => _.id == this.keyMessage).length ?
                  this.arrCommodity[ele].filter(_ => _.id == this.keyMessage)[0].topic_id : '';
            })
          }
          var filteredGroups = this.groups.filter(_ => _.group_type == 'topic' && Object.keys(this.arrCommodity).includes(_.id));
          filteredGroups.forEach((element, index) => {
            topics.push(element.id);
            if (this.keyMessage && selectedTopic == element.id)
              this.selectedIndex = index;
          });
          this.topics = topics;
        }
      }));
    }
  }
  back() {
    this.location.back();
  }
  public openMediaPlayer(src) {
    this.audio.openMediaPlayer(src);
  }

  openMediaForImage(src) {

    this.imageService.openMediaPlayer(src);

  }

  openMediaPlayerForVideo(src, isYoutube) {
    this.videoService.openMediaPlayer(src, isYoutube);
  }

  openMediaForSpeech(content) {
    const strippedString = content.replace(/(<([^>]+)>)/g, "");
    const strippedString1 = strippedString.replace(/&nbsp;/g, "");
    const strUrl = PARAMETRE.strTextToSpeechUrl + encodeURIComponent(strippedString1) + '&language=' + this.txtSpeech;
    this.audio.openMediaPlayer(strUrl);
  }

  setMyStyles() {
    return Utils.reverseContent();
  }

  getDefImage(event) {
    event.target.src = Utils.defaultImage();
  }

  textJustify(txt) {
    return txt.toString().replace(/(?:\r\n|\r|\n)/g, '<br>');
  }

  show_general_tab_or_not() {
    return (!this.generalTabData.description && !this.generalTabData.proteins && !this.generalTabData.vitamins) ? false : true;
  }
  onTabChange(event) {
    if (event.tab) {
      let obj = { action: '', category: 'Tab_Change', label: event.tab.textLabel }
      this.ga.emit(obj);
    }
  }
  keyMessages(action, label) {
    let obj = { action: action, category: 'KEY_MESSAGES', label: label }
    this.ga.emit(obj);
  }
  checkContent(data) {
    return data.filter(_ => _.content).length ? true : false;
  }
  getGroupDetails(id) {
    if (id == '') return 'system.information_tab';
    if (this.groups && this.groups.length) {
      var data = this.groups.filter(_ => _.id == id);
      return data.length ? data[0].name : id;
    } else return id
  }
  getProviderDetaiils(id, returnValue) {
    if (this.providers && this.providers.length) {
      var data = this.providers.filter(_ => _.id == id);
      return data.length ? data[0][returnValue] : id;
    } else return id
  }
  getVideoData(res) {
    // this.subscriptions.push(this.livestockService.getVideoData(valueRes).subscribe(res => {
    //   if (res) {
    this.isVideoMessages = true;
    this.messages = res['Video'];
    let subCate = this.digitalGreenSubCat[this.generalTabData.enutri_id.toLowerCase()] ? this.digitalGreenSubCat[this.generalTabData.enutri_id.toLowerCase()] : this.generalTabData.enutri_id.toLowerCase();
    this.messages = this.messages.filter(_ => _.Category == 'Agriculture' && _.Sub_Category.toLowerCase() == subCate);
    if (this.lang_code != 'en') {
      let jumpVal = 50;
      let roundvalue = this.messages.length < 50 ? 1 : Math.round(this.messages.length / jumpVal);
      for (let i = 0; i < roundvalue; i++) {
        var translationData = [];
        this.messages.slice(i * jumpVal, i * jumpVal + jumpVal).forEach(element => {
          translationData.push(element.Video_Benefit);
        });
        this.subscriptions.push(this.livestockService.getTranslation({
          "q": translationData,
          "target": this.lang_code
        }).subscribe(res => {
          for (let index = 0; index < this.messages.slice(i * jumpVal, i * jumpVal + jumpVal).length; index++) {
            if (res['data']['translations'][index].translatedText)
              this.messages.slice(i * jumpVal, i * jumpVal + jumpVal)[index].Video_Benefit = res['data']['translations'][index].translatedText;
          }
        }));
      }
    }
    //   }
    // }, (error: any) => {
    //   this.counter--;
    //   if (this.counter) setTimeout(() => this.getVideoData(valueRes), 5000);
    // }));
  }
  ngOnDestroy() {
    if (this.sub)
      this.sub.unsubscribe();
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
  // getId(url) {
  //   var video_id = url.split('v=')[1];
  //   var ampersandPosition = video_id.indexOf('&');
  //   if (ampersandPosition != -1) {
  //     video_id = video_id.substring(0, ampersandPosition);
  //   }
  //   return video_id;
  // }
  getFilterData() {
    this.route.params.subscribe(param => {
      if (param.id)
        var parentid = param.id.split('?')[0];
      this.subscriptions.push(this.enutrifoodService.getNutrifoodCategories(parentid).take(1).subscribe((result: any) => {
        if (result && result.length) {
          result = Utils.sort(result, 'order');
          result = result.sort(function (x) { return x.default ? -1 : 0; });
          this.categories = result;
          this.categories.forEach((element: any) => {
            this.subscriptions.push(this.enutrifoodService.getNutrifoodFilter(parentid, element.docId).subscribe((res: any) => {
              if (res && res.length) {
                element.filters = res.filter(_ => _.hidden != true);
                element.filters = Utils.sort(element.filters, 'order');
                this.selectedIndex = 0;
                if (element.default) {
                  this.estYear = res.filter(_ => _.key == 'year').length ? res.filter(_ => _.key == 'year')[0] : { label: 'year' };
                  this.week_days = res.filter(_ => _.key == 'week_days').length ? res.filter(_ => _.key == 'week_days')[0] : null;
                  this.marketType = res.filter(_ => _.key == 'type').length ? res.filter(_ => _.key == 'type')[0] : { label: 'Market Type' };
                  this.owner = res.filter(_ => _.key == 'owner').length ? res.filter(_ => _.key == 'owner')[0] : { label: 'Market Owner' };
                  if (!this.categories.filter(_ => _.isWeekData).length)
                    this.categories.splice(1, 0, { name: this.week_days.label, filters: ['dummy'], isWeekData: true });
                }
              }
            }));
          });
        }
      }));
    });
  }
  // isFilterAvailable(key) {
  //     return Object.keys(this.generalTabData).includes(key);
  // }
  getDay(id, time) {
    if (this.generalTabData.weeks && this.generalTabData.weeks.length && this.generalTabData.weeks.filter(_ => _.id == id).length) {
      return this.generalTabData.weeks.filter(_ => _.id == id)[0][time];
    }
    return time == 'opening_hour' ? 'administrative_division.closed' : '';
  }
  getProviders(keymessages) {
    return this.groupByKey(keymessages, 'provider_id');
  }
  groupByKey(data, key) {
    return data.reduce(function (rv, x) {
      x[key] = !x[key] ? '' : x[key];
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  }
  identify(index, item) {
    return item.docId;
  }
}
