import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { WindowService } from './../shared/service/phone-auth/window.service';
import firebase from 'firebase/compat/app';
import { PhoneNumber } from './../shared/models/phonenumber';
import { Router } from "@angular/router";
import { MatSnackBar } from '@angular/material/snack-bar';
import { GeoLocationService } from './../shared/service/location';
import { AuthService } from './../shared/service/auth.service';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslationService } from './../shared/service/translation.service';
import { environment } from '../../../src/environments/environment';
import Utils from '../shared/utils/utils';

@Component({
  selector: 'app-phone-login',
  templateUrl: './phone-login.component.html',
  styleUrls: ['./phone-login.component.scss']
})

export class PhoneLoginComponent implements OnInit {

  windowRef: any;
  // phoneNumber = new PhoneNumber();
  // phoneNumber: number;
  // verificationCode: string;
  user: any;
  phoneNumberform: UntypedFormGroup = new UntypedFormGroup({});
  public loader: boolean = true;
  private countryData;
  private isCorrectFormat: Boolean = false;
  private mobileNumber: string = '';

  constructor(
    private win: WindowService,
    public router: Router,
    public toast: MatSnackBar,
    public location: GeoLocationService,
    public authService: AuthService,
    private elRef: ElementRef,
    public afs: AngularFirestore,
    private TranslateS: TranslationService,
    private fb: UntypedFormBuilder
  ) {
    this.loadScript();
    this.phoneNumberform = fb.group({
      phoneNumber: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(13), Validators.pattern("(\\+?)?[0-9]*")]],
      verificationCode: ['']
    });
  }

  ngOnInit(): void {
    if (this.authService.isLoggedIn) {
      this.router.navigate(['forum']);
    }
    this.windowRef = this.win.windowRef;
    this.countryData = JSON.parse(localStorage.getItem('countryData'));
  }

  ngAfterViewInit() {
    this.windowRef.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container',);
    this.windowRef.recaptchaVerifier.render();
  }

  sendLoginCode() {
    const appVerifier = this.windowRef.recaptchaVerifier;
    if (this.isCorrectFormat) {
      firebase.auth().signInWithPhoneNumber(this.mobileNumber, appVerifier)
        .then(result => {
          this.windowRef.confirmationResult = result;
        }).catch((error) => {
          this.toast.open(error.message, 'OK');
        });
    }
    else {
      this.toast.open(this.TranslateS.translate("Registration.phoneNumberFormatError"), 'OK');
    }

  }

  telInputObject(obj) {
    if (this.countryData.country.toLowerCase() != 'fao')
      obj.setCountry(Utils.getCountryData(this.countryData.country, "code"));
  }

  onCountryChange($event) {
  }

  getNumber($event) {
    this.mobileNumber = $event;
  }

  hasError($event) {
    this.isCorrectFormat = $event;
  }

  verifyLoginCode() {
    this.windowRef.confirmationResult.confirm(this.phoneNumberform.controls['verificationCode'].value).then(result => {
      this.windowRef.confirmationResult = '';
      this.loader = false;
      this.authService.signInWithMobile(result);
    }).catch((error) => {
      this.toast.open(error, "Incorrect code entered?");
    });
  }

  keytab(event, elName) {
    if (elName) {
      let element = this.elRef.nativeElement.querySelector('input[name=' + elName + ']');
      if (element == null) {
        return;
      } else {
        if (event.srcElement.maxLength === event.srcElement.value.length) {
          element.focus();
        }
      }
    }
  }
  private loadScript() {
    var head = document.getElementsByTagName('head')[0];
    var scripts = head.getElementsByTagName('script');
    if (scripts[0] && scripts[0].attributes['src']['nodeValue'].includes('recaptcha'))
      head.removeChild(scripts[0]);
    const lang_Code = JSON.parse(localStorage.getItem(environment.userLanguageStorageKey)).code
    let chatcha = document.createElement("script");
    chatcha.type = "text/javascript";
    chatcha.async = true;
    chatcha.src = "https://www.google.com/recaptcha/api.js?hl=" + lang_Code;
    chatcha.id = "recaptcha-script";
    document.body.appendChild(chatcha);
  }
}
