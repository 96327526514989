import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, QueryDocumentSnapshot } from '@angular/fire/compat/firestore';
import { Observable, Subscription } from 'rxjs';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RplyDialogComponent } from '../rply-dialog/rply-dialog.component';
import { ForumService } from '../../shared/service/forum.service';
import { AuthService } from '../../shared/service/auth.service';
import { TranslationService } from '../../shared/service/translation.service';
import Utils from '../../shared/utils/utils';
import { Router } from '@angular/router';
import { LoginRequestDialogComponent } from '../login-request-dialog/login-request-dialog.component';
import { GeoLocationService } from '../../shared//service/location';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgxSpinnerService } from "ngx-spinner";
@Component({
  selector: 'app-comment',
  templateUrl: './comment.component.html',
  styleUrls: ['./comment.component.scss']
})

export class CommentComponent implements OnInit, OnDestroy {
  public forumAdmin: boolean = false;
  public publishDecision = true;
  @Input() comment: QueryDocumentSnapshot<any>;
  public show = false;
  id: string;
  data: any = [];

  repliesRef: AngularFirestoreCollection;
  replies: Observable<any>;

  path: string;
  public arrUser: any = [];
  public uid: string = "";
  public userComment = "";//Store comments of comments in case of current user made comment to answer or comment
  //public commentLabel = "";
  public updateComment = false;

  public likeclicked = false;
  public numberLikeClicks = 0;
  public userLike = "";
  country: any;
  subscriptions: Subscription[] = [];

  constructor(public Countrylocation: GeoLocationService,
    public toast: MatSnackBar,
    private TranslateS: TranslationService,
    public authService: AuthService, private router: Router, private db: AngularFirestore, public dialog: MatDialog, public forumService: ForumService,
    private spinner: NgxSpinnerService) { }

  ngOnInit() {
    this.country = JSON.parse(localStorage.getItem("countryData"));
    this.subscriptions.push(this.authService.afAuth.authState.subscribe(user => {
      if (user) {
        this.uid = user.uid;
        this.getuserdata();
        this.getUserComment();
        this.getUserLike();
        this.forumAdmin = this.IsAdmin(this.uid);
      }
    }));

    // Document ID
    this.id = this.comment.id;

    // Document data
    this.data = this.comment.data();
    this.data.cDetails = this.data.cDetails.toString().replace(/(?:\r\n|\r|\n)/g, '<br>');
    // Full path in the database
    this.path = this.comment.ref.path;
    this.publishDecision = this.data.publish;
    // Replies to this comment
    this.repliesRef = this.db.collection(this.path + '/comments');
    this.replies = this.repliesRef.snapshotChanges(); // .pipe(tap(console.log));
  }

  public getuserdata() {
    this.subscriptions.push(this.forumService.getUserDetails(this.uid).subscribe((res: any[]) => {
      if (res) {
        this.arrUser = res;
      }
    }));

  }
  public getUserComment() {
    var ans: any = [];
    var docref = this.db.collection(this.path + "/comments/", ref => ref.where('uid', "==", this.uid)).snapshotChanges().subscribe(res => {
      var len = (res.map(doc => {
        ans = doc;
        if (ans.payload.doc.data().uid == this.uid) {
          this.userComment = ans.payload.doc.data().cDetails;
          this.updateComment = true;
        }
        else {

          this.userComment = "";
          this.userComment = "";

        }


      })).length;
    });
    // docref.unsubscribe();
    this.subscriptions.push(docref);
  }

  public getUserLike() {
    var ans: any = [];
    var docref = this.db.collection(this.path + "/likes/", ref => ref.where('uid', "==", this.uid)).snapshotChanges().subscribe(res => {
      var len = (res.map(doc => {
        ans = doc;
        if (ans.payload.doc.data().like != null && ans.payload.doc.data().uid == this.uid)
          this.userLike = "including you"
        else
          this.userLike = "";
      })).length;;
      if (len == 0)
        this.userLike = "";

    });
    // docref.unsubscribe();
  }



  showReplies() {
    this.show = true;
  }

  hideReplies() {
    this.show = false;
  }


  publishQuestion() {
    // alert('question published');
  }
  ckeckLogin(): boolean {
    if (!this.authService.isLoggedIn) {
      const dialogRef = this.dialog.open(LoginRequestDialogComponent, {
        width: '300px',
        data: {}
      });
      return false;
    }

    return true;
  }
  /*****
   * Open dialog for adding reports on internal comment
   */
  openDialogRComment(cid): void {
    if (!this.ckeckLogin())
      return;

    const dialogRef = this.dialog.open(RplyDialogComponent, {
      width: '300px',
      data: { title: this.TranslateS.translate("Post.ReportTitlePage"), subtitle: this.TranslateS.translate("Post.ReportTitlePageExplain") }
    });

    dialogRef.afterClosed().subscribe(result => {

      if (result == null || result.Details.trim() == "") {
        return;
      }
      this.spinner.show();
      const report = {}; // reply document
      report['uid'] = this.uid;
      report["ufirstName"] = this.arrUser.firstName;;
      report["ulastName"] = this.arrUser.lastName;
      report["nickName"] = this.arrUser.nickName || '';
      report['rCreatedDate'] = Date.now();
      report['rDetails'] = result.Details;
      report['rView'] = false;
      const country = this.Countrylocation.getCountry();
      report['countryName'] = country.country_name;
      report['country_iso3'] = country.country;
      try {
        this.forumService.ReportComment(this.path, this.uid, report).then(res => {
          this.toast.open(this.TranslateS.translate("Post.SuccessReportSave"), this.TranslateS.translate("Post.Ok"),
            { duration: 10000 });
          this.spinner.hide();
        });
      }

      catch (exception) {
        this.spinner.hide();
        this.toast.open(this.TranslateS.translate("Post.ErrorReportSave"), this.TranslateS.translate("Post.Ok"),
          { duration: 10000 });
      }
    });
  }

  openDialogReplyComment(cid): void {
    if (!this.ckeckLogin())
      return;

    const dialogRef = this.dialog.open(RplyDialogComponent, {
      width: '300px',
      data: { title: this.TranslateS.translate("Post.Comment"), subtitle: this.TranslateS.translate("Post.Details"), Details: this.userComment }
    });

    dialogRef.afterClosed().subscribe(result => {

      if (result == null || result.Details.trim() == "") {
        return;
      }

      this.spinner.show();
      const reply = {}; // reply document
      reply['uid'] = this.uid;
      reply["ufirstName"] = this.arrUser.firstName;
      reply["ulastName"] = this.arrUser.lastName;
      reply["nickName"] = this.arrUser.nickName || '';
      reply['numComments'] = 0;
      reply['numDislikes'] = 0;
      reply['numLikes'] = 0;
      reply['cCreatedDate'] = Date.now();
      reply['cDetails'] = result.Details;
      reply["publish"] = true;
      reply['cView'] = false;
      try {
        this.forumService.ReplyComment(this.path, this.uid, reply).then(res => {
          this.spinner.hide();
          this.toast.open(this.TranslateS.translate("Post.SuccessSave"), this.TranslateS.translate("Post.Ok"),
            { duration: 10000 });
        })
      }
      catch (exception) {
        this.spinner.hide();
        this.toast.open(this.TranslateS.translate("Post.ErrorSave"), this.TranslateS.translate("Post.Ok"),
          { duration: 10000 });
      }

    });
  }


  openDialogUpdateComment(cid): void {
    if (!this.ckeckLogin())
      return;
    this.showReplies();
    const dialogRef = this.dialog.open(RplyDialogComponent, {
      width: '300px',
      data: { title: this.TranslateS.translate("Post.EditComment"), subtitle: this.TranslateS.translate("Post.Details"), Details: this.userComment }

    });

    dialogRef.afterClosed().subscribe(result => {

      if (result == null || result.Details.trim() == "") {
        return;
      }
      this.spinner.show();
      try {
        this.forumService.UpdateReplyComment(this.path, this.uid, result.Details).then(res => {
          this.spinner.hide();
          this.toast.open(this.TranslateS.translate("Post.SuccessSave"), this.TranslateS.translate("Post.Ok"),
            { duration: 10000 });
        });
      }
      catch (exception) {
        this.spinner.hide();
        this.toast.open(this.TranslateS.translate("Post.ErrorSave"), this.TranslateS.translate("Post.Ok"),
          { duration: 10000 });
      }

    });
  }

  async LikeComment() {
    if (!this.ckeckLogin())
      return;
    this.likeclicked = true;
    this.numberLikeClicks += 1;

    if (this.numberLikeClicks > 2) //user try to hack system and click many likes or dislikes
      return;


    var likeObject = {};
    likeObject["uid"] = this.uid;
    likeObject["like"] = true;
    this.forumService.LikeComment(this.path, this.uid, likeObject)
    await this.delay(2000);



    if (this.numberLikeClicks == 2)
      this.likeclicked = true;
    else
      this.likeclicked = false;

  }

  async DisLikeComment() {
    if (!this.ckeckLogin())
      return;
    this.likeclicked = true;
    this.numberLikeClicks += 1;

    if (this.numberLikeClicks > 2) //user try to hack system and click many likes or dislikes
      return;


    var likeObject = {};
    likeObject["uid"] = this.uid;
    likeObject["dislike"] = true;
    this.forumService.DislikeComment(this.path, this.uid, likeObject)

    await this.delay(2000);
    //alert("ss");
    if (this.numberLikeClicks == 2)
      this.likeclicked = true;
    else
      this.likeclicked = false;;
  }
  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
  nav(link) {
    this.router.navigate([`/${link}`]);
  }


  PublishComment(PublishOrNot) {

    if (PublishOrNot == true) {
      this.forumService.publishPost("any", this.path);
    }
    else
      this.forumService.unpublishPost("any", this.path);
  }
  public IsAdmin(UserId) {
    this.subscriptions.push(this.forumService.getUserDetails(UserId).subscribe((res: any[]) => {
      var userdoc: any = []
      if (res) {
        userdoc = res;
        const country = this.Countrylocation.getCountry();
        if (!(userdoc.roles["dsp"].includes("admin") || userdoc.roles["dsp"].includes("forum_admin")) || userdoc['country_iso3'].toLowerCase() != country.country.toLowerCase()) {
          this.forumAdmin = false;
          return false;
        }
      }
    }));
    return true;
  }
  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
