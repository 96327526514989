import { throwError as observableThrowError, BehaviorSubject, Observable } from 'rxjs';
import { retry, tap, catchError, map } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
//import { VaccinationData } from './livestock.service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GeoLocationService } from './location';
import { UserService } from './user.service';
import * as isOnline from 'is-online';
import { Parametre } from './parametre'
const PARAMETRE = Parametre.getInstance();
import { environment } from '../../../environments/environment';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { FirestoreDataService } from "./firestore-data.service";
import Utils from "./../utils/utils";


export interface Vaccination {
  id: any;
  name: string;
  description: string;
}
export interface VaccinationData {
  name: string;
  description: string;
  icon: string;
  vacinations: Vaccination[];
  parent?: any;

}
export interface DeseaseData {
  name: string;
  description: string;
  icon: string;

}
export interface SpecieData {
  name: string;
  description: string;
  vacinations: Vaccination[];
  icon: string;

}
@Injectable()
export class LivestockService {
  deseases: any;
  vaccinationsDetailsSubject: BehaviorSubject<VaccinationData> = new BehaviorSubject(null);
  vaccinationsDetails$: Observable<VaccinationData> = this.vaccinationsDetailsSubject.asObservable();
  livestockDetails: any;
  messages: any;
  species: any;
  vacinationCalendars: any;
  areas: any;
  actionTypes: any;
  userSpecies: any;
  ForecastList: any;
  res: any;

  constructor(
    private fireStoreDataService: FirestoreDataService,
    private UService: UserService,
    public location: GeoLocationService,
    private http: HttpClient,
    public toast: MatSnackBar,
    public afs: AngularFirestore
  ) { }

  public handleError = (err: Response) => {
    this.toast.open('Service is temporarily unavailable, please try again later', 'OK', { duration: 7000 });
    if ((err.status === 500) || (err.status === 503)) {
      // TODO: Once the status get back to normal bring the toast back here
    }
    return observableThrowError('Erroe while loading the weather');
  }

  getAllVaccinations() {
    let apiType = "dimensions-api";
    let sheetType = "livestock_vaccinations_final";
    let filterArr: string[] = [];
    const country = this.location.getCountry();
    return this.fireStoreDataService.getDocument(apiType, sheetType, filterArr, country);
  }

  getSpeAndDesForVaccination(vaccinationName) {
    let apiType = "dimensions-api";
    let sheetType = "livestock_vaccinations_final";
    let filterArr: string[] = [vaccinationName];
    const country = this.location.getCountry();
    return this.fireStoreDataService.getDocument(apiType, sheetType, filterArr, country);
  }

  getAllDeseases() {
    let apiType = "dimensions-api";
    let sheetType = "livestock_diseases_final";
    let filterArr: string[] = [];
    const country = this.location.getCountry();
    return this.fireStoreDataService.getDocument(apiType, sheetType, filterArr, country);
  }

  getSpeAndVacForDisease(disease) {
    let apiType = "dimensions-api";
    let sheetType = "livestock_diseases_final";
    let filterArr: string[] = [disease];
    const country = this.location.getCountry();
    return this.fireStoreDataService.getDocument(apiType, sheetType, filterArr, country);
  }

  public getMessageForSpecie(key, value): Observable<any> {
    return this.fireStoreDataService.getDimensionsDocument('key_messages', key, value);
  }

  public getMessageGroup(countryCode?): Observable<any> {
    const country = JSON.parse(localStorage.getItem('countryData'));
    country.country = countryCode ? countryCode : country.country;
    return this.fireStoreDataService.getDataUsingCountry('key_messages_groups', country, 'order');
  }

  public getProviders(countryCode?): Observable<any> {
    const country = JSON.parse(localStorage.getItem('countryData'));
    country.country = countryCode ? countryCode : country.country;
    return this.fireStoreDataService.getDataUsingCountry('providers', country);
  }

  getAllInsects(themeId) {
    var url = '/themes/' + themeId + '/insects';
    return this.fireStoreDataService.getInsectsDocument(url);
  }
  getAllCrops(themeId) {
    var url = '/themes/' + themeId + '/crops';
    return this.fireStoreDataService.getCropDocument(url);
  }
  getAllCropsByInsect(themeId, insectId) {
    var url = '/themes/' + themeId + '/crops';
    return this.fireStoreDataService.getCropDocumentByInsect(url, insectId);
  }
  getAllDimensions(tabId, key?, order?) {
    key = key ? key : 'tab_id';
    return this.fireStoreDataService.getDimensionsDocument('dimensions', key, tabId, order);
  }
  getDimensionsDocument(key, value, order?) {
    return this.fireStoreDataService.getDimensionsDocument('dimensions', key, value, order);
  }
  getDimensionDocument(id) {
    return this.fireStoreDataService.getDimensionDocument('dimensions/' + id);
  }
  getInsectDocument(themeId, InsectId) {
    var url = '/themes/' + themeId + '/insects/' + InsectId;
    return this.fireStoreDataService.getInsectDocument(url);
  }
  getInsectsDocumentFilter(themeId, key, value) {
    var url = '/themes/' + themeId + '/insects';
    return this.fireStoreDataService.getInsectsDocumentFilter(url, key, value);
  }
  getDisAndVacForSpecies(speciesName) {
    let apiType = "dimensions-api";
    let sheetType = "livestock_species_final";
    let filterArr: string[] = [speciesName];
    const country = this.location.getCountry();
    return this.fireStoreDataService.getDocument(apiType, sheetType, filterArr, country);
  }

  getMultinodeData() {
    return this.http.get('assets/multi-node-1.json').pipe(
      map(res => res));
  }
  getLocationData(lat, long) {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    const options = { headers: headers };
    return this.http.post('https://fao-farmstack-proxy-ma2ojojwdq-ey.a.run.app/location', {
      "lat": lat,
      "long": long
    }, options);
  }
  getVideoData(id) {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    const options = { headers: headers };
    return this.http.get('https://fao-farmstack-proxy-ma2ojojwdq-ey.a.run.app/videos?requestId=' + id, options);
  }
  getTranslation(body) {
    return this.http.post('https://translation.googleapis.com/language/translate/v2?key=' + environment.config.apiKey, body);
  }
  getAllTeams(tabId, key?) {
    return this.fireStoreDataService.getDimensionsDocument('user_teams', key, tabId);
  }
  getTeamDetail(teamId) {
    return this.fireStoreDataService.getTeamDetail(teamId);
  }
  getFeedDatabase(id) {
    var url = '/dimensions/' + id + '/feed_database/';
    return this.fireStoreDataService.getCollection(url);
  }
  getFeedAnimals(id) {
    var url = '/dimensions/' + id + '/animals/';
    return this.fireStoreDataService.getCollection(url);
  }
  getFeedAnimalsWeight(dimension, id) {
    var url = '/dimensions/' + dimension + '/animals/' + id + '/nutritional_requirements/';
    return this.fireStoreDataService.getCollection(url);
  }
  calculateFeed(url, data) {
    return this.http.post(url, data);
  }
  getCalculatorGuide(themeId, lang) {
    var url = '/themes/' + themeId + '/calculator_guide/' + lang;
    return this.fireStoreDataService.getDimensionDocument(url);
  }
}
