import { FilterPipe } from './../shared/pipes/filter.pipe';
import { FormsModule } from '@angular/forms';
import { AnimalFeedRoutingModule } from './animal-feed.routing';
import { SharedModule } from './../shared/shared.module';
import { AnimalFeedComponent } from './animal-feed';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {CapitalizePipe}  from '../shared/pipes/capitalize.pipe';
import { AnimalFeedCategoriesComponent } from './animal-feed-categories/animal-feed-categories.component';
import { AnimalFeedSubjectsComponent } from './animal-feed-subjects/animal-feed-subjects.component';
import { MatTableModule } from "@angular/material/table";
@NgModule({
    imports: [
        SharedModule,
        FormsModule,
        AnimalFeedRoutingModule,
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        MatTableModule
    ],
    declarations: [
      AnimalFeedComponent,
      AnimalFeedCategoriesComponent,
      AnimalFeedSubjectsComponent
    ],
    exports: [
        AnimalFeedComponent,
        AnimalFeedRoutingModule
    ]
})
export class AnimalFeedModule { }
// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
