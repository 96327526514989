import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Parametre } from './parametre';
import { environment } from '../../../environments/environment';
import { FirestoreDataService } from "./firestore-data.service";
const PARAMETRE = Parametre.getInstance();

@Injectable()
export class PandemReportService {
    constructor(private fireStoreDataService: FirestoreDataService, private http: HttpClient) { }
    submitReport(data, country) {
        let url = environment.pandemicReportUrl;
        url = url.replace('{country}', country);
        return this.http.post(url, data).pipe(
            catchError(this.handleError)
        );
    }

    private handleError(error: Response | any) {

        let errMsg: string;
        if (error instanceof Response) {
            const err = error || '';
            errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
        } else {
            errMsg = error.message ? error.message : error.toString();
        }
        console.log("ErrorMessage");

        console.error(error);
        return throwError(error);
    }

    getLocations(locationid) {
        const url = '/themes/' + locationid + '/locations';
        return this.fireStoreDataService.getLocationDocument(url);
    }
}
