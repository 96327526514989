import { TranslationService } from '../service/translation.service';
import { Pipe, PipeTransform } from '@angular/core';
import { environment } from '../../../../src/environments/environment';

@Pipe({
  name: 'localTranslate',
  pure: false
})
export class TranslatePipe implements PipeTransform {
  constructor(private translationService: TranslationService) {
  }
  transform(value: any, args?: any): any {
    let countryData = JSON.parse(localStorage.getItem('countryData'));
    let defaultLang = countryData ? countryData.languages.find(_ => _.default).code : 'en';
    var langCode = args == undefined ? (JSON.parse(localStorage.getItem(environment.userLanguageStorageKey))) ? JSON.parse(localStorage.getItem(environment.userLanguageStorageKey)).code : 'en' : args;
    if (typeof value != 'string' && value) {
      return value[langCode] ? value[langCode] : value[defaultLang];
    }
    return this.translationService.translate(value, langCode);
  }

}
