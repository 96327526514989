import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { from, Observable, Subscription } from 'rxjs';
import { WeatherService } from './../../shared/service/weather.service';
import { TranslatePipe } from '../../shared/pipes/translate.pipe';
import { environment } from '../../../../src/environments/environment';
@Component({
  selector: 'app-weekly',
  templateUrl: './weekly.component.html',
  styleUrls: ['./weekly.component.css']
})
export class WeeklyComponent implements OnInit, OnDestroy {
  @Input() datas;
  @Input() infoButton: boolean;
  lang_code: any;
  constructor(public weatherService: WeatherService) {
  }

  ngOnInit() {
    this.lang_code = JSON.parse(localStorage.getItem(environment.userLanguageStorageKey)).code;
    const country = JSON.parse(localStorage.getItem("countryData"));
    this.lang_code = country['country'] == 'VEN' && this.lang_code == 'es' ? 'en' : this.lang_code;
  }

  ngOnDestroy() { }

  onImgError(event) {
    event.target.src = 'assets/offline/icons/default-icon.svg';
  }
}
