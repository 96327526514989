import { TranslationService } from '../../shared/service/translation.service';
import { SectionService } from '../../shared/service/section.service';
import { SubForumService } from '../../shared/service/subforum.service';
import { Section } from '../section.model';
import { UserService } from '../../shared/service/user.service';
import { SubForum } from '../subforum.model';
import { Location } from '@angular/common';
import { Subscription, Observable } from 'rxjs';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../../shared/service/auth.service';
import { UntypedFormGroup, FormControl, Validators, UntypedFormBuilder } from '@angular/forms';
import { ForumService } from '../../shared/service/forum.service';
import { GeoLocationService } from '../../shared/service/location';
import { Question } from '../question';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import {
  AngularFireStorageModule,
  AngularFireStorageReference,
  AngularFireUploadTask
} from '@angular/fire/compat/storage';
import { map, finalize } from 'rxjs/operators';
import firebase from 'firebase/compat/app';
import { Response } from '@angular/http';
import { RichtextareaComponent } from '../richtextarea/richtextarea.component';
import { environment } from '../../../environments/environment';
import Utils from '../../shared/utils/utils';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgxSpinnerService } from "ngx-spinner";
interface ForumSettings {
  directPublsh: any;
}

export interface postLabels {

  type: string;
  toolbarTitle: string;
  title: string;
  titlePlaceholder: string;
  details: string;
  detailsPlaceholder: string;
}
@Component({
  selector: 'app-add-question',
  templateUrl: './add-question.component.html',
  styleUrls: ['./add-question.component.scss']
})
export class AddQuestionComponent implements OnInit, OnDestroy {
  public SubForumsList: SubForum[];
  public SectionsList: Section[];
  public SubForumsListOptions: any;
  public SectionsListOptions: any;
  public langCode = UserService.langCode;

  public loader = false;
  public editPost: boolean = false;
  public postid: string = "";
  qFormGroup: UntypedFormGroup;
  public fquestion = new Question();
  public forumSettingsDoc: any;
  public arrUser: any = [];
  selectedFile: File = null;
  public postphotoUrl = '';
  private postType = "question";
  public showimage = false;
  downloadURL: Observable<string>;
  forum: any;
  spinner: boolean = false;
  subscriptions: Subscription[] = [];
  constructor(
    public toast: MatSnackBar,
    private TranslateS: TranslationService,
    public userService: UserService,
    private router: Router,
    public authService: AuthService,
    private fb: UntypedFormBuilder,
    private fs: ForumService,
    private afs: AngularFireStorage,
    public route: ActivatedRoute,
    private subForumService: SubForumService,
    private sectionService: SectionService,
    public location: GeoLocationService,
    private ngxspinner: NgxSpinnerService
  ) {
    let user = JSON.parse(localStorage.getItem('user'));
    // const country = Utils.modifyString(localStorage.getItem('gpsCountry'));
    const country = this.location.getCountry();
    if (!this.authService.isLoggedIn) {
      this.router.navigate(['login/forum*post*add']);
    } else if (user?.roles["dsp"].indexOf("admin") < 0 && user['country_iso3'].toLowerCase() != country.country.toLowerCase()) {
      this.router.navigate(['forum']);
    }

    this.qFormGroup = this.fb.group({

    });

    this.qFormGroup = this.fb.group({
      questionTitle: ['', Validators.required],
      questionDetails: ['', null],
      category: ['', Validators.min(1)],
      PostTypeList: ['', Validators.min(1)]

    });
    this.qFormGroup.patchValue({ category: '0' });
    this.qFormGroup.patchValue({ PostTypeList: '0' });
  }

  ngOnInit() {
    this.ngxspinner.show();
    const themes = JSON.parse(localStorage.getItem('appThemes'));
    this.forum = themes.filter(_ => _.template_id === 'forum')[0];
    this.loader = true;
    this.subscriptions.push(this.authService.afAuth.authState.subscribe(user => {
      if (user) {
        this.fquestion.uid = user.uid;
        this.getuserdata();
        this.getforumsetting();
        this.loadListsData();
      }
    }));

    this.route.params.subscribe(param => {
      this.postType = param.pt;
      if (param.pid != null) {
        this.postid = param.pid;
        this.editPost = true;
        this.getPostdata(param.pid);

      }
    });
    var title = "Add";
    if (this.editPost)
      title = "Edit";

  }
  public getuserdata() {
    this.subscriptions.push(this.fs.getUserDetails(this.fquestion.uid).subscribe((res: any[]) => {
      if (res) {
        this.arrUser = res;
        this.fquestion.firstName = this.arrUser.firstName;
        this.fquestion.lastName = this.arrUser.lastName;
        this.fquestion['nickName'] = this.arrUser.nickName || '';
        if (this.arrUser.photoUrl) {
          this.fquestion.photoUrl = this.arrUser.photoUrl;
        } else {
          this.fquestion.photoUrl = '';
        }
      }
    }));
  }

  public getforumsetting() {
    this.subscriptions.push(this.fs.getForumSettings().subscribe((res) => {
      this.forumSettingsDoc = res as ForumSettings;
      this.fquestion.publish = this.forumSettingsDoc.directPublish;
    }));
  }

  public getPostdata(postId) {
    var PostData: any = [];
    this.subscriptions.push(this.fs.getPostDetails(postId).subscribe((res: any[]) => {
      if (res) {
        PostData = res;

        this.qFormGroup.patchValue({ questionDetails: PostData.pDetails });
        this.qFormGroup.patchValue({ questionTitle: PostData.pTitle });
        this.qFormGroup.patchValue({ category: PostData.ptag });
        this.qFormGroup.patchValue({ PostTypeList: PostData.pType });
        this.postType = PostData.pType;
        if (PostData.pphotoUrl != null && PostData.pphotoUrl != "") {
          this.postphotoUrl = PostData.pphotoUrl;
          this.showimage = true;
        }
      };
    }));
  }

  onFileSelected(event) {
    let changepPhoto = false;
    const file = event.target.files[0];
    if (file.size > 2000000) {
      this.toast.open(this.TranslateS.translate("forum.filesizeerror"), this.TranslateS.translate("Post.Ok"), { duration: 10000 });
      return;
    }
    if (this.showimage) {
      this.DeletePhoto();
    }
    this.spinner = true;
    if (this.editPost)
      changepPhoto = true;

    const n = Date.now();
    const filePath = `ForumImages/${n}`;
    const fileRef = this.afs.ref(filePath);
    const task = this.afs.upload(`ForumImages/${n}`, file);
    task
      .snapshotChanges()
      .pipe(
        finalize(() => {
          this.downloadURL = fileRef.getDownloadURL();
          this.subscriptions.push(this.downloadURL.subscribe(async url => {
            if (url) {
              this.spinner = false;
              this.postphotoUrl = url;
              this.showimage = true;
            }
            this.showimage = true;
            if (changepPhoto)
              await this.fs.ChangePostImage(this.postid, this.postphotoUrl);
          }));
        })
      )
      .subscribe(url => {
        if (url) {
        }
      });
  }


  DeletePhoto() {
    let fileDelted = false;
    try {
      this.afs.storage.refFromURL(this.postphotoUrl).delete();
      fileDelted = true;
    } catch (exception) {

      fileDelted = true;
    }
    if (fileDelted) {
      this.showimage = false;
      if (this.postid)
        this.fs.DeletePostImage(this.postid);
    }

  }

  addPost() {

    if (this.qFormGroup.invalid) {
      return;
    } else {
      this.ngxspinner.show();
      this.fquestion.questionTitle = this.qFormGroup.get('questionTitle').value;
      this.fquestion.questionDetails = this.qFormGroup.get('questionDetails').value;

      this.fquestion.questionTag = this.qFormGroup.get('category').value;


      this.fquestion.questionCreatedDate = Date.now();

      firebase.firestore.FieldValue.serverTimestamp;
      const qo = {};
      qo['uid'] = this.fquestion.uid;
      qo['ufirstName'] = this.fquestion.firstName || '';
      qo['ulastName'] = this.fquestion.lastName || '';
      qo['nickName'] = this.fquestion['nickName'] || '';
      qo['pType'] = this.qFormGroup.get('PostTypeList').value;
      qo['pCreatedDate'] = this.fquestion.questionCreatedDate;
      qo['pTitle'] = this.fquestion.questionTitle;
      qo['pDetails'] = this.fquestion.questionDetails;
      if (this.postphotoUrl != '') {
        qo['pphotoUrl'] = this.postphotoUrl;
      }
      else
        qo['pphotoUrl'] = null;

      qo['ptag'] = this.fquestion.questionTag;
      qo['publish'] = this.fquestion.publish;
      qo['redirect_url'] = location.origin + '/forum/post-details/';

      if (!this.editPost) {
        qo['numComments'] = 0;
        qo['numLikes'] = 0;
        qo['numDislikes'] = 0;
      }
      try {
        if (!this.editPost)
          this.fs.AddPost(qo).then(res => {
            this.ngxspinner.hide();

            this.toast.open(this.TranslateS.translate("Post.SuccessSave"), this.TranslateS.translate("Post.Ok"),
              { duration: 10000 });
            this.qFormGroup.reset();
            this.postphotoUrl = "";
            this.showimage = false;
            this.router.navigate(['forum']);
          });
        else
          this.fs.EditPost(this.postid, qo).then(res => {
            this.ngxspinner.hide();
            this.toast.open(this.TranslateS.translate("Post.SuccessSave"), this.TranslateS.translate("Post.Ok"),
              { duration: 10000 });
            this.qFormGroup.reset();
            this.postphotoUrl = "";
            this.showimage = false;
            this.router.navigate(['forum']);
          });

      }
      catch (exception) {
        this.ngxspinner.hide();
        this.toast.open(this.TranslateS.translate("Post.ErrorSave"), this.TranslateS.translate("Post.Ok"),
          { duration: 10000 });
      }

    }



  }

  setMyStyles() {
    return Utils.reverseContent();
  }
  allF: SubForum;
  allS: Section;

  loadListsData() {
    this.ngxspinner.show();
    this.SectionsListOptions = [];
    this.SectionsListOptions.push({ name: ' ', value: '0' });

    this.SubForumsListOptions = [];
    this.SubForumsListOptions.push({ name: ' ', value: '0' });

    this.subscriptions.push(this.subForumService.getSubForums().subscribe(actionArray => {
      this.ngxspinner.hide();
      this.SubForumsList = actionArray.map(item => {
        var sub = {
          id: item.payload.doc.id,
          ...item.payload.doc.data() as {}
        } as SubForum;
        for (let x = 0; x < sub.names.length; x++) {
          if (sub.names[x].language_code == this.langCode) {
            if (!this.SubForumsListOptions.filter(_ => _.value == sub.value).length)
              this.SubForumsListOptions.push({ name: '' + sub.names[x].value, value: '' + sub.value });
          }
        }

        return sub;
      });
      this.allF = new SubForum();
      this.allF.id = '0';
      this.allF.names = [];
      this.allF.order = 0;
      this.allF.value = '0';
      this.SubForumsList.splice(0, 0, this.allF);
    }));

    this.subscriptions.push(this.sectionService.getSections().subscribe(actionArray => {
      this.SectionsList = actionArray.map(item => {
        var sec = {
          id: item.payload.doc.id,
          ...item.payload.doc.data() as {}
        } as Section;

        for (var x = 0; x < sec.names.length; x++) {
          if (sec.names[x].language_code == this.langCode) {
            if (!this.SectionsListOptions.filter(_ => _.value == sec.value).length)
              this.SectionsListOptions.push({ name: '' + sec.names[x].value, value: '' + sec.value });
          }
        }
        return sec;
      });
      this.allS = new Section();
      this.allS.id = '0';
      this.allS.names = [];
      this.allS.order = 0;
      this.allS.value = '0';
      this.SectionsList.splice(0, 0, this.allS);
    }));

  }

  backToForum() {
    this.router.navigate(['forum']);
  }
  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
