import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'capitalize' })
export class CapitalizePipe implements PipeTransform {

  transform(value: any) {
    if (value) {
      return (!!value) ? value.split(' ').map(function (wrd) { return wrd.charAt(0).toUpperCase() + wrd.substr(1); }).join(' ') : '';//value.replace(/\b\w/g, symbol => symbol.toLocaleUpperCase());
    }
    return value;
  }

}

/*
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'capitalize'
})
export class CapitalizePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (value) {
      if (args) {
        return value.replace(/\b\w/g, first => first.toLocaleUpperCase());
      } else {
        return value.charAt(0).toUpperCase() + value.slice(1);
      }
    }
    return null;
  }

}

*/


