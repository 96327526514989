import { TranslateLoader } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { AngularFireDatabase } from '@angular/fire/compat/database';


export class FirebaseTransLoader implements TranslateLoader {
	constructor(private db: AngularFireDatabase) { }
	public getTranslation(lang: string, prefix: string = 'translations/'): any {
		// return this.db.object(`${prefix}${lang}`).valueChanges() as Observable<any>;		
	}
}

