import { GeoLocationService } from './../../../shared/service/location';
import { Component, Input, OnInit } from '@angular/core';
import { SectionService } from '../../../shared/service/section.service';
import Utils from '../../../shared/utils/utils';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Section } from '../../../forum/section.model';
import { AuthService } from '../../../shared/service/auth.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-sections-list',
  templateUrl: './sections-list.component.html',
  styleUrls: ['./sections-list.component.css']
})
export class SectionsListComponent implements OnInit {
  @Input() list: Section[];
  country: any;
  constructor(public geoLocationService: GeoLocationService,
    private sectionService: SectionService,
    private authService: AuthService,
    private router: Router,
    private firestore: AngularFirestore) { }

  ngOnInit() {
    this.country = this.geoLocationService.getCountry();
  }

  onEdit(section: Section) {
    this.sectionService.formData = Object.assign({}, section);
  }

  onDelete(id: string) {
    if (confirm("Are you sure to delete this record?")) {
      this.sectionService.deleteSection(id);
      alert('Deleted successfully');
    }
  }

  setMyStyles() {
    return Utils.reverseContent();
  }
}

