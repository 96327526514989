import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Recipe } from '../models/recipe.model'; // Import the model

@Component({
  selector: "app-recipe-dialog",
  templateUrl: "./recipe-dialog.component.html",
  styleUrls: ["./recipe-dialog.component.css"],
})
export class RecipeDialogComponent {
  recipes: Recipe[]; // Strongly typed array of recipes
  currentPage = 1;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { animal: any; recipes: Recipe[] }
  ) {
    this.recipes = data.recipes;
  }

  get currentRecipe() {
    return this.recipes[this.currentPage - 1];
  }

  nextPage() {
    if (this.currentPage < this.recipes.length) {
      this.currentPage++;
    }
  }

  previousPage() {
    if (this.currentPage > 1) {
      this.currentPage--;
    }
  }

  getIngredientKeys(ingredients: { [key: string]: any }): string[] {
    return Object.keys(ingredients);
  }

  getDeviationClass(deviation: number): string {
    if (Math.abs(deviation) <= 10) {
      return "green";
    } else if (Math.abs(deviation) > 10 && Math.abs(deviation) <= 25) {
      return "orange";
    } else {
      return "red";
    }
  }
}
