import { Component, OnInit, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import Utils from '../../../shared/utils/utils';
import { Router } from '@angular/router';
import { UserService } from '../../../shared/service/user.service';
import { AuthService } from '../../../shared/service/auth.service';
import { ForumService } from '../../../shared/service/forum.service';
import { GeoLocationService } from '../../../shared/service/location';
import { Subscription } from 'rxjs';
import * as moment from 'moment';
interface User {
  uid: any;
  firstName: any;
  lastName: any;
  photoURL: string;
  email: any;
  address: any;
  docid: any;
  isActive: any;
  expert: any;
  regDate: any;
  birth: any;
}
interface Post {
  docid: any;
  pTitle: any;
  pCreatedDate: any;
  publish: any;
  ptag: any;
}

interface Report {
  uid: any;
  ufirstName: any;
  ulastName: any;
  rDetails: string;
  rCreatedDate: any;
  rView: any;
  docid: any;
  pid: any;
}

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit, OnDestroy {
  public loader = false;
  public userdoc: any = [];
  public IsforumAdmin: boolean = false;
  public UsersCount = 0;
  public PostsCount = 0;
  public ReportsCount = 0;
  public arrUsers: any;
  public arrPosts: any;
  public arrReports: any;
  country: any;
  forum: any;
  subscriptions: Subscription[] = [];

  constructor(private router: Router, public Countrylocation: GeoLocationService,
    public userService: UserService,
    public authService: AuthService,
    public forumService: ForumService, private location: Location) { }

  ngOnInit() {

    const themes = JSON.parse(localStorage.getItem('appThemes'));
    this.forum = themes.filter(_ => _.template_id === 'forum')[0];
    this.country = JSON.parse(localStorage.getItem("countryData"));
    if (!this.authService.isLoggedIn) {
      this.router.navigate(['login/forum*admin']);
    }

    this.subscriptions.push(this.authService.afAuth.authState.subscribe(async user => {
      if (user) {
        this.IsforumAdmin = this.IsAdmin(user.uid);
      }
    }));

    this.getUsers();
    this.getPosts();
    this.getReports();
  }
  public IsAdmin(UserId) {
    this.subscriptions.push(this.forumService.getUserDetails(UserId).subscribe((res: any[]) => {
      if (res) {
        this.userdoc = res;
        const country = this.Countrylocation.getCountry();
        if (this.userdoc.roles["dsp"].indexOf("admin") > -1) {
          this.loader = true;
        } else if (!(this.userdoc.roles["dsp"].includes("admin") || this.userdoc.roles["dsp"].includes("forum_admin")) || this.userdoc['country_iso3'].toLowerCase() != country.country.toLowerCase()) {
          this.router.navigate([`forum`]);
        }
        this.loader = true;
      }
    }));
    return true;
  }
  getUsers() {
    this.subscriptions.push(this.forumService.getusers().subscribe(actionArray => {
      this.arrUsers = actionArray.map(item => {
        const docid = item.payload.doc.id;
        let _user = item.payload.doc.data() as User;
        _user.docid = docid;
        if (typeof (_user.regDate) == 'string') {
          var parts: any = _user.regDate.split('/')
          _user.regDate = new Date(parts[2], parts[1] - 1, parts[0])
        } else if (_user.regDate) {
          _user.regDate = _user.regDate.toDate();
        } else
          _user.regDate = new Date(1900, 1, 1)
        if (!_user.photoURL) {
          _user.photoURL = '../../../../assets/forum/userprofile.svg';
        }
        return _user;
      });
      this.arrUsers = this.arrUsers.sort(function (a, b) {
        return (b.regDate) - (a.regDate);
      });
      if (this.arrUsers.length > 10)
        this.arrUsers = this.arrUsers.slice(0, 10);
      this.UsersCount = this.arrUsers.length;
    }));
  }

  getPosts() {
    this.subscriptions.push(this.forumService.getPosts_OrderedByDate().subscribe(actionArray => {
      this.arrPosts = actionArray.map(item => {
        const docid = item.payload.doc.id;
        let _post = item.payload.doc.data() as Post;
        _post.docid = docid;
        return _post;
      });
      this.PostsCount = this.arrPosts.length;
    }));
  }

  IsReportCountry(ReportPath: string) {
    const country = this.Countrylocation.getCountry();
    let countryName = country.country;
    let CountryReportPath = "forumData/" + countryName + "/posts";
    alert(countryName);
    if (ReportPath.indexOf(CountryReportPath) == -1)
      return false;
    else
      return true;
  }
  getReports() {
    this.subscriptions.push(this.forumService.getReports_OrderedByDate().subscribe(actionArray => {
      this.arrReports = actionArray.map(item => {
        const docid = item.payload.doc.id;
        let _report = item.payload.doc.data() as Report;
        _report.docid = docid;



        item.payload.doc.ref.parent.parent.get().then(x => {
          _report.pid = x.id;
          // _report.pTitle = x.data().pTitle;
          // _report.pDetails = x.data().pDetails;
          // _report.pphotoUrl = x.data().pphotoUrl;
          // _report.publish = x.data().publish;

        });

        return _report;
      });
      this.ReportsCount = this.arrReports.length;
    }));
  }



  navUser(link) {
    this.router.navigate([`forum/admin/user-details`, `${link}`]);
  }
  navPost(link) {
    this.router.navigate([`/forum/post-details`, `${link}`]);
  }
  navReport(link) {
    this.router.navigate([`/forum/admin/reports`, `${link}`]);
  }
  setMyStyles() {
    return Utils.reverseContent();
  }
  back() {
    this.location.back();
  }
  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
