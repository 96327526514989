import { GeoLocationService } from './../shared/service/location';
import { LayoutService } from './../shared/service/layout.service';
import { Observable, Subscription } from 'rxjs';
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Parametre } from '../shared/service/parametre';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from './../shared/service/user.service';

const PARAMETRE = Parametre.getInstance();
@Component({
  selector: 'app-covid',
  templateUrl: 'covid.html',
  styles: [
    `
        .fix {
            position:  fixed;
            width: 100%;
            margin-top: 65px;
            height:100%
        }
        `
  ]
})

export class CovidComponent implements OnInit, OnDestroy {
  public objectKeys = Object.keys;
  v: Subscription;
  p: Subscription;
  rowH: string;
  cols = 1;
  state: string;
  data = [];
  isLoading = true;
  isDetailsLoading = true;
  appData: any;

  constructor(
    private translate: TranslateService,
    private http: HttpClient,
    private layout: LayoutService,
    private us: UserService,
    public location: GeoLocationService,
    public media: MediaObserver) {

  }

  ngOnInit() {
    const themes = JSON.parse(localStorage.getItem('appThemes'));
    this.appData = themes.filter(_ => _.template_id === 'covid19')[0];
    this.isLoading = false;
    this.p = this.layout.layout$.subscribe(res => {
      this.cols = res.cols;
      this.rowH = res.rowH;
    });
  }

  ngOnDestroy() {
    this.p.unsubscribe();
  }
}
