import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable()
export class VideoService {
  openVideo = new BehaviorSubject(null);
  constructor() { }

  openMediaPlayer(video_link, isYoutubeVideo?) {
    isYoutubeVideo = video_link.includes('youtube.com') ? true : false;
    this.openVideo.next({ video: video_link, title: isYoutubeVideo ? 'Youtube' : '' });
  }
}

