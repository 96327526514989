import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CitrusComponent } from './citrus.component';
import { CitrusSubjectsComponent } from './citrus-subjects/citrus-subjects.component';
import { CitrusCategoriesComponent } from './citrus-categories/citrus-categories.component';
import { CitrusRoutingModule } from './citrus.routing';
import { FilterPipe } from './../shared/pipes/filter.pipe';
import { FormsModule } from '@angular/forms';
import { SharedModule } from './../shared/shared.module';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { CitrusService } from '../shared/service/Citrus.service';

@NgModule({
  declarations: [CitrusComponent, CitrusSubjectsComponent, CitrusCategoriesComponent],
  imports: [
    CommonModule,
    CitrusRoutingModule,
    FormsModule,
    SharedModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  exports: [
    CitrusRoutingModule
  ],
  providers: [
    CitrusService
  ]
})
export class CitrusModule { }
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}