import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { Observable } from 'rxjs/Observable';
import { environment } from '../../../environments/environment';
import Utils from '../utils/utils';
import { AngularFireStorage } from '@angular/fire/compat/storage';

@Injectable()
export class FirestoreDataService {
  private fireDataMainNodeName: string = "app_data";

  constructor(
    public afs: AngularFirestore,
    public storage: AngularFireStorage
  ) {
  }

  getAllCommodities(apiType, sheetType, filterArr, country, subType = '', boolStatus) {
    return this.getDocument(apiType, sheetType, filterArr, country, subType = '', boolStatus);
  }

  getDocument(apiType: string, sheetType: string, filterArr: string[], country, subType = '', boolStatus = false) {
    var docsUrl: string = this.getDocsUrl(apiType, sheetType, filterArr, country, subType);
    var document = this.afs.doc(docsUrl);
    return document.snapshotChanges().map(
      changes => {
        var retData: any = changes.payload.data();
        if (apiType === "pwa-configuration-api" || apiType === "segment-api") {
          return retData;
        } else if (apiType === "dimensions-api" && filterArr.length > 0 && !boolStatus) {
          return retData;
        } else {
          var res = [];
          for (var x in retData) {
            retData.hasOwnProperty(x) && res.push(retData[x])
          }
          return res;
        }
      });
  }

  getDocsUrl(apiType: string, sheetType: string, filterArr: string[], country, subType) {
    var langCode = (JSON.parse(localStorage.getItem(environment.userLanguageStorageKey))) ? JSON.parse(localStorage.getItem(environment.userLanguageStorageKey)).code : 'en';
    const countryName = (country != null) ? Utils.modifyCountryString(Utils.getCountryData(country.country, 'name')) : "";
    let strFilterValue: string = "";
    for (let i = 0; i < filterArr.length; i++) {
      strFilterValue += "/";
      strFilterValue += filterArr[i];
    }
    var getMessagesUrl: string;
    if (apiType === "pwa-configuration-api") {
      if (countryName == 'allcountry') {
        let countryName1 = 'allCountry'
        getMessagesUrl = this.fireDataMainNodeName + "/" + apiType + "/" + countryName1 + "/" + sheetType;
      }
      else {
        getMessagesUrl = this.fireDataMainNodeName + "/" + apiType + "/" + countryName + "/" + sheetType;
      }

    } else if (apiType === "segment-api") {
      getMessagesUrl = this.fireDataMainNodeName + "/" + apiType + "/" + sheetType + "" + strFilterValue + "/" + langCode + "/allData";
    } else if (apiType === "dimensions-api") {
      if (strFilterValue != "") {
        getMessagesUrl = this.fireDataMainNodeName + "/" + apiType + "/" + countryName + "/" + sheetType + "/" + langCode + strFilterValue;
      } else {
        getMessagesUrl = this.fireDataMainNodeName + "/" + apiType + "/" + countryName + "/" + sheetType + "/" + langCode + "/allData";
      }
    } else if (apiType === "agrimarket-api") {
      if (sheetType == 'prices_final') {
        getMessagesUrl = this.fireDataMainNodeName + "/" + apiType + "/" + countryName + "/" + sheetType + "/" + langCode + "/" + subType + "/allData" + strFilterValue;
      } else {
        getMessagesUrl = this.fireDataMainNodeName + "/" + apiType + "/" + countryName + "/" + sheetType + "/" + langCode + "/allData";
      }
    } else if (apiType === "registration-api") {
      getMessagesUrl = this.fireDataMainNodeName + "/" + apiType + "/" + countryName + "/" + sheetType;
    } else {
      getMessagesUrl = this.fireDataMainNodeName + "/" + apiType + "/" + countryName + "/" + sheetType + "/" + langCode + strFilterValue;
    }
    return getMessagesUrl;
  }

  getNotificationData(collectionUrl, applicationId) {
    // const country = JSON.parse(localStorage.getItem('countryData'));
    var langCode = (JSON.parse(localStorage.getItem(environment.userLanguageStorageKey))) ? JSON.parse(localStorage.getItem(environment.userLanguageStorageKey)).code : 'en';
    // var notificationCollection = this.afs.collection(collectionUrl);
    var data: any = this.afs.collection(collectionUrl, ref => {
      return ref
        .where(`theme_id`, '==', `${applicationId}`)
        .where(`language_code`, '==', `${langCode}`)
      // .where(`country`, '==', `${country.country}`)
      // .orderBy('created_at')
    });

    return data.snapshotChanges().map(
      changes => {
        return changes.map(
          a => {
            const data = a.payload.doc.data();
            // data.id = data.id ? data.id : a.payload.doc.id;
            return data;
          });
      });
  }

  getNotificationDocument(docsUrl) {
    var document = this.afs.doc(docsUrl);
    return document.snapshotChanges().map(
      changes => {
        var retData: any = changes.payload.data();
        return retData;
      });
  }

  get_Document(apiType: string, country) {
    var docsUrl: string = apiType + "/" + country;
    var document = this.afs.doc(docsUrl);
    return document.snapshotChanges().map(
      changes => {
        var retData: any = changes.payload.data();
        return retData;
      });
  }
  getDataUsingCountry(api, country, orderByField?) {
    var data: any;
    if (orderByField) {
      data = this.afs.collection(api, ref => {
        return ref
          .where(`country`, '==', `${country.country}`)
          .orderBy(`${orderByField}`)
      });
    } else {
      data = this.afs.collection(api, ref => {
        return ref
          .where(`country`, '==', `${country.country}`)
      });
    }
    return data.snapshotChanges().map(
      changes => {
        return changes.map(
          a => {
            const data = a.payload.doc.data();
            data.id = data.id ? data.id : a.payload.doc.id;
            return data;
          });
      });
  }

  getInsectsDocument(api, countryCode?) {
    const country = JSON.parse(localStorage.getItem('countryData'));
    var data: any = this.afs.collection(api, ref => {
      return ref
        .where(`country`, '==', `${countryCode ? countryCode : country.country}`)
    });
    return data.snapshotChanges().map(
      changes => {
        return changes.map(
          a => {
            const data = a.payload.doc.data();
            data.id = data.id ? data.id : a.payload.doc.id;
            return data;
          });
      });
  }

  getCropDocument(api, countryCode?) {
    const country = JSON.parse(localStorage.getItem('countryData'));
    var data: any = this.afs.collection(api, ref => {
      return ref
        .where(`country`, '==', `${countryCode ? countryCode : country.country}`)
    });
    return data.snapshotChanges().map(
      changes => {
        return changes.map(
          a => {
            const data = a.payload.doc.data();
            data.id = data.id ? data.id : a.payload.doc.id;
            return data;
          });
      });
  }
  getCropDocumentByInsect(api, insectId, countryCode?) {
    const country = JSON.parse(localStorage.getItem('countryData'));
    var data: any = this.afs.collection(api, ref => {
      return ref
        .where(`country`, '==', `${countryCode ? countryCode : country.country}`)
        .where(`insect_ids`, 'array-contains', insectId)
    });
    return data.snapshotChanges().map(
      changes => {
        return changes.map(
          a => {
            const data = a.payload.doc.data();
            data.id = data.id ? data.id : a.payload.doc.id;
            return data;
          });
      });
  }
  getDimensionsDocument(api, key, value, order?, countryCode?) {
    const country = JSON.parse(localStorage.getItem('countryData'));
    if (order || api == 'key_messages') {
      order = api == 'key_messages' ? 'order' : order;
      var data: any = this.afs.collection(api, ref => {
        return ref
          .where(`${key}`, '==', `${value}`)
          .where(`country`, '==', `${countryCode ? countryCode : country.country}`)
          .orderBy(order)
      });
    } else {
      var data: any = this.afs.collection(api, ref => {
        return ref
          .where(`${key}`, '==', `${value}`)
          .where(`country`, '==', `${countryCode ? countryCode : country.country}`)
      });
    }
    return data.snapshotChanges().map(
      changes => {
        return changes.map(
          a => {
            const data = a.payload.doc.data();
            data.id = data.id ? data.id : a.payload.doc.id;
            return data;
          });
      });
  }
  getLanguageData() {
    var data: any = this.afs.collection('languages');
    return data.snapshotChanges().map(
      changes => {
        return changes.map(
          a => {
            const data = a.payload.doc.data();
            data.id = data.id ? data.id : a.payload.doc.id;
            return data;
          });
      });
  }
  getWizardTranslation() {
    var data: any = this.afs.collection('translations', ref => {
      return ref
        .where(`country`, '==', `general`)
    });
    return data.snapshotChanges().map(
      changes => {
        return changes.map(
          a => {
            const data = a.payload.doc.data();
            return data;
          });
      });
  }

  getWeatherAlertTranslation(country: string) {
    var data: any = this.afs.collection('translations', ref => {
      return ref
        .where(`country`, '==', country)
    });
    return data.snapshotChanges().map(
      changes => {
        return changes.map(
          a => {
            const data = a.payload.doc.data();
            return data;
          });
      });
  }
  get_UserByField(field, value) {
    var data: any = this.afs.collection('users', ref => {
      return ref
        .where(field, '==', value)
    });
    return data.snapshotChanges().map(
      changes => {
        return changes.map(
          a => {
            const data = a.payload.doc.data();
            return data;
          });
      });
  }

  getTeamDetail(teamId: string) {
    var docsUrl: string = 'user_teams/' + teamId + '/team';
    var document = this.afs.collection(docsUrl);
    return document.snapshotChanges().map(
      changes => {
        return changes.map(
          a => {
            const data = a.payload.doc.data();
            return data;
          });
      });
  }
  getNutrifoodFilter(docUrl: string) {
    // var docsUrl: string = 'themes/' + id + '/filters';
    var document: any = this.afs.collection(docUrl);
    return document.snapshotChanges().map(
      changes => {
        return changes.map(
          a => {
            const data = a.payload.doc.data();
            data.docId = data.docId ? data.docId : a.payload.doc.id;
            return data;
          });
      });
  }
  getNutrifoodDivisions(country: string, type, chaildUrl?, administrative_divisions_type?) {
    var docsUrl: string = 'administrative_division/' + country + '/' + type;
    var data: any;
    if (chaildUrl) {
      docsUrl = docsUrl + chaildUrl;
      data = this.afs.collection(docsUrl);
    } else {
      data = this.afs.collection(docsUrl, ref => {
        return ref
          .where('administrative_divisions_type', '==', administrative_divisions_type)
      });
    }
    return data.snapshotChanges().map(
      changes => {
        return changes.map(
          a => {
            const data = a.payload.doc.data();
            data.docId = data.docId ? data.docId : a.payload.doc.id;
            return data;
          });
      });
  }

  getDimensionDocument(api) {
    var document: any = this.afs.doc(api);
    return document.snapshotChanges().map(
      changes => {
        var retData: any = changes.payload.data();
        retData.id = changes.payload.id;
        return retData;
      });
  }

  getInsectDocument(api) {
    var document: any = this.afs.doc(api);
    return document.snapshotChanges().map(
      changes => {
        var retData: any = changes.payload.data();
        retData.id = changes.payload.id;
        return retData;
      });
  }

  getUserDetails(uid) {
    //alert(uid + " *********************************");
    var user = this.afs.doc("users/" + uid);
    return user.snapshotChanges().map(
      changes => {
        var retData: any = changes.payload.data();
        return retData;
      });
  }
  getCollection(docsUrl: string) {
    var document = this.afs.collection(docsUrl);
    return document.snapshotChanges().map(
      changes => {
        return changes.map(
          a => {
            const data: any = a.payload.doc.data();
            data.id = data.id ? data.id : a.payload.doc.id;
            return data;
          });
      });
  }
  getAdvices(tabId, theme_id) {
    const country = JSON.parse(localStorage.getItem('countryData'));
    var data: any = this.afs.collection('key_messages', ref => {
      return ref
        .where(`tab_id`, '==', `${tabId}`)
        .where(`theme_id`, '==', `${theme_id}`)
        .where(`country`, '==', `${country.country}`)
        .orderBy('order')
    });
    return data.snapshotChanges().map(
      changes => {
        return changes.map(
          a => {
            const data = a.payload.doc.data();
            data.id = data.id ? data.id : a.payload.doc.id;
            return data;
          });
      });
  }
  getInsectsDocumentFilter(api, key, value, countryCode?) {
    const country = JSON.parse(localStorage.getItem('countryData'));
    var data: any = this.afs.collection(api, ref => {
      return ref
        .where(`country`, '==', `${countryCode ? countryCode : country.country}`)
        .where(key, '==', value)
    });
    return data.snapshotChanges().map(
      changes => {
        return changes.map(
          a => {
            const data = a.payload.doc.data();
            data.id = data.id ? data.id : a.payload.doc.id;
            return data;
          });
      });
  }

  uploadImage(file: File): Observable<string> {
    const country = (JSON.parse(localStorage.getItem('countryData'))).country;
    const filePath = `reports/${country}/${file.name}`;
    const fileRef = this.storage.ref(filePath);
    const uploadTask = this.storage.upload(filePath, file);

    return new Observable(observer => {
      uploadTask.then(() => {
        fileRef.getDownloadURL().subscribe(url => {
          observer.next(url);
          observer.complete();
        });
      }).catch(error => {
        observer.error(error);
      });
    });
  }
  getLocationDocument(api) {
    var data: any = this.afs.collection(api);
    return data.snapshotChanges().map(
      changes => {
        return changes.map(
          a => {
            const data = a.payload.doc.data();
            data.id = data.id ? data.id : a.payload.doc.id;
            return data;
          });
      });
  }
  serveyEnableInCountry() {
    const country = (JSON.parse(localStorage.getItem('countryData')))?.country || '';
    var data: any = this.afs.collection('themes', ref => {
      return ref
        .where(`country`, '==', country)
        .where(`template_id`, '==', 'survey')
    });
    return data.snapshotChanges().map(
      changes => {
        return changes.map(
          a => {
            const data = a.payload.doc.data();
            data.id = data.id ? data.id : a.payload.doc.id;
            return data;
          });
      });
  }
  serveyEnableInCountryLocation() {
    const country = (JSON.parse(localStorage.getItem('countryData')))?.country || '';
    var data: any = this.afs.collection('themes', ref => {
      return ref
        .where(`country`, '==', country)
        .where(`template_id`, '==', 'survey')
    });
    return data.snapshotChanges().map(
      changes => {
        return changes.map(
          a => {
            const data = a.payload.doc.data();
            const id = a.payload.doc.id;
            console.log('id', id);
            const locationdata = this.afs.collection('themes/' + id + '/locations');
            return locationdata.snapshotChanges().map(
              changes => {
                return changes.map(
                  a => {
                    const data = a.payload.doc.data();
                    return data;
                  });
              });
          });
      });
  }
  surveyCategories(themeId: String): Promise<any[]> {
    return new Promise((resolve, reject) => {
      this.afs.collection(`/themes/${themeId}/categories`).get().toPromise()
        .then(querySnapshot => {
          const categories = querySnapshot.docs.map(doc => {
            const value: any = doc.data();
            //add the documentId to the object returned
            value.id = doc.id;
            return value;
          });
          resolve(categories);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  surveyQuestionSettings(themeId: String, categoryId: String): Promise<any[]> {
    return new Promise((resolve, reject) => {
      this.afs.collection(`/themes/${themeId}/categories/${categoryId}/question_settings`).get().toPromise()
        .then(querySnapshot => {
          const questionSettings = querySnapshot.docs.map(doc => {
            const value: any = doc.data();
            //add the documentId to the object returned
            value.id = doc.id;
            return value;
          });
          resolve(questionSettings);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
}
