import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-app-nutrifood-preview',
  templateUrl: './app-nutrifood-preview.component.html',
  styleUrls: ['./app-nutrifood-preview.component.scss']
})
export class AppNutrifoodPreviewComponent implements OnInit {
  @Input() data: any;
  @Input() appName: any;
  searchText = { name: '' };
  title = 'e-nutrifood';
  constructor(
    private route: ActivatedRoute
  ) {
  }

  ngOnInit(): void {
    const section = this.route.snapshot.params.section;
    if (section === 'nutrifood')
      this.title = 'e-nutrifood';
    else if (section === 'citrus')
      this.title = 'Home.CitrusProductionTitle';
    else if (section === 'dateProduction')
      this.title = 'Home.DateProductionTitle';
    else if (section === 'householdPoultry')
      this.title = 'Home.HouseholdPoultryTitle';
    else if (section === 'healthyNutrition')
      this.title = 'Home.HealthyNutritionTitle';
    else if (section === 'covidFoodSafety')
      this.title = 'Home.covid19_vegetables_fruitsTitle';
  }

}
