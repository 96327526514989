import { Router } from '@angular/router';
import { Component, Input, OnInit, OnDestroy, OnChanges } from '@angular/core';
import { LivestockService } from '../../shared/service/livestock.service';
import { Subject } from 'rxjs';
import Utils from "../../shared/utils/utils";
import { UserService } from '../../shared/service/user.service';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { environment } from '../../../../src/environments/environment';
import { ImageStorageService } from '../../shared/service/img-storage.service';

@Component({
  selector: 'app-diseases',
  templateUrl: './diseases.component.html',
  styleUrls: ['./diseases.component.scss']
})

export class DeseasesComponent implements OnInit, OnDestroy, OnChanges {
  @Input() parentSubject: Subject<any>;
  searchText = {
    name: {}
  };
  deseases: any;
  @Input() columns = 1;
  @Input() modalWidth: any;
  @Input() rowHs: any;
  @Input() loader: boolean;
  groupSelectedDiesease = [];
  public arrApplications: any;
  isFilterExist: boolean = false;
  filterTypeGroup: any = [];
  @Input() tabData: any;
  lang_code: any;
  constructor(
    public livestock: LivestockService,
    private router: Router,
    public uService: UserService,
    public gaService: GoogleAnalyticsService,
    private imageStorageService: ImageStorageService
  ) { }

  ngOnInit() {
    this.lang_code = JSON.parse(localStorage.getItem(environment.userLanguageStorageKey)).code;
    this.searchText.name[this.lang_code] = '';
  }

  ngOnChanges() {
    if (this.tabData)
      this.deseases = this.tabData.data;
  }

  ngOnDestroy() {
    this.parentSubject.unsubscribe();
  }

  onImgError(event) {
    event.target.src = 'assets/offline/icons/default-icon.svg';
  }

  getx48Image(inputUrl: string): string {
    return this.imageStorageService.getx48Image(inputUrl);
  }

}
