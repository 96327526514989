
import { Component, ComponentFactoryResolver, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Directive, ViewContainerRef } from '@angular/core';
import { componants } from '../preview.constant';

@Directive({
  selector: '[childDirective]',
})
export class ChildDirective {
  constructor(public viewContainerRef: ViewContainerRef) { }
}
export interface AdComponent {
  data: any;
  appName: any;
}

@Component({
  selector: 'app-app-preview',
  templateUrl: './app-preview.component.html',
  styleUrls: ['./app-preview.component.css']
})
export class AppPreviewComponent implements OnInit {
  @ViewChild(ChildDirective, { static: true }) adHost!: ChildDirective;
  constructor(private route: ActivatedRoute,
    private componentFactoryResolver: ComponentFactoryResolver) { }
  ngOnInit(): void {
    const section = this.route.snapshot.params.section;
    const appName = this.route.snapshot.params.appName;
    let options: string = this.route.snapshot.queryParams.options;
    if (options)
      options = JSON.parse(options);
    this.loadComponent(componants[section], options, appName)
  }
  loadComponent(section, options, appName) {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(section);
    const viewContainerRef = this.adHost.viewContainerRef;
    viewContainerRef.clear();
    let componentRef = viewContainerRef.createComponent(componentFactory);
    (<AdComponent>componentRef.instance).data = options;
    (<AdComponent>componentRef.instance).appName = appName;
  }

}
