import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
@Injectable()

export class ImageService {
  ifOffline = new BehaviorSubject(false);
  openImage = new BehaviorSubject(null);
  constructor() {
  }
  openMediaPlayer(image_link) {
    this.openImage.next({ image: image_link });
  }
  setIfOffline(value) {
    this.ifOffline.next(value);
  }
}
