import { ImageStorageService } from "./../../shared/service/img-storage.service";
import {
  Component,
  OnInit,
  Input,
  EventEmitter,
  Output,
  ViewChild,
  ElementRef
} from "@angular/core";
import { GeoLocationService } from "../../shared/service/location";
import { UserService } from '../../shared/service/user.service';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import { environment } from '../../../environments/environment';
import Utils from '../../shared/utils/utils';
import { GoogleAnalyticsService } from "ngx-google-analytics";
import { ImageService } from "../../shared/component/image-modal/image.service";
import { Router } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { PandemicReportComponent } from "../../pandemic-report/pandemic-report.component";
import { TranslationService } from "../../shared/service/translation.service";

@Component({
  selector: "app-home-mobile",
  templateUrl: "home-mobile.component.html",
  styleUrls: ['./home-mobile.component.scss']
})
export class HomeMobileComponent implements OnInit {
  public phoneNumber: number;
  public isScrollDownButtonVisible: boolean = true;
  @Input() user;
  @Output() onConnect = new EventEmitter();
  @Output() onLogout = new EventEmitter();
  @Output() countrySelectorChanged = new EventEmitter();
  cc = "+221";
  @Input() arrData;
  public selLangCode: any;
  @Input("appList") appListData;
  hideImages: boolean;

  constructor(
    public img: ImageStorageService,
    public location: GeoLocationService,
    public uService: UserService,
    private sanitizer: DomSanitizer,
    private gaService: GoogleAnalyticsService,
    private imgService: ImageStorageService,
    private imageservice: ImageService,
    private router: Router,
    public mddialog: MatDialog,
    private translationService: TranslationService
  ) {

  }

  ngOnInit() {
    this.imageservice.ifOffline.subscribe(res => {
      this.hideImages = res;
    });

    var arr = this.appListData.filter(items => {
      return (items.status == true);
    });

    this.selLangCode = JSON.parse(localStorage.getItem(environment.userLanguageStorageKey)).code;
    let country = this.location.getCountry();
    if (country != null) {
      this.cc = country.country_telephone_prefix;
    }
    window.addEventListener('scroll', this.scroll, true); //third parameter
    this.isScrollDownButtonVisible = (this.arrData.length - 1) > 4 ? true : false;
  }

  setMyStyles() {
    var buttonFab: any = document.querySelector('.fab-bottom-right');
    let lgCode = JSON.parse(localStorage.getItem(environment.userLanguageStorageKey)).code;
    if (lgCode == 'ar') {
      buttonFab.style.setProperty('left', '10px');
    }
    else {
      buttonFab.style.setProperty('right', '10px');
    }
  }


  scroll = (event): void => {
    //handle your scroll here
    this.isButtonVisible();
  };

  scrollToBottom() {
    if (this.checkScrollPos()) {
      document.documentElement.scrollTop += window.innerHeight; //document.documentElement.scrollHeight;
      this.isButtonVisible();
    }
  }

  isButtonVisible() {
    if ((this.arrData.length - 1) > 4 && this.checkScrollPos()) {
      this.isScrollDownButtonVisible = true;
    }
    else {
      this.isScrollDownButtonVisible = false;
    }
  }

  checkScrollPos() {
    return (document.documentElement.scrollHeight - window.innerHeight != document.documentElement.scrollTop);
  }

  login() {
    const fullNumber = this.cc + this.phoneNumber;

    if (this.phoneNumber) {
      this.onConnect.emit(fullNumber);
    } else {
      return;
    }
  }

  logout() {
    this.onLogout.emit("Logout");
  }

  changed(event) {
    this.countrySelectorChanged.emit(event);
  }

  onImgError(event, theme) {
    if (!theme.isImageError) {
      theme.isImageError = true;
      let img = this.getId(theme.template_id);
      if (!this.hideImages)
        event.target.src = 'assets/offline/icons/desktop/' + img + '.svg';
    }
  }

  ngOnDestroy() {
    window.removeEventListener('scroll', this.scroll, true);
  }
  ga(link) {
    this.gaService.event('Home_Theme_Navigation_Click', 'Theme_Navigation', link);
  }

  getId(template_id) {
    return Utils.routedata[template_id] ? Utils.routedata[template_id] : template_id;
  }
  navThemes(data) {
    this.ga(data.template_id)
    let link = this.getId(data.template_id);
    //TODO _ remove this once finalized the implementation of farmer-practicies
    let template_id_tmp = data?.template_id_tmp;

    this.gaService.event('Navbar_Theme_Navigation_Click', 'Theme_Navigation', link);
    if (link === 'e-nutrifood')
      this.router.navigate([`/e-nutrifood/categories`, data.id]);
    else if (link === 'administrative')
      this.router.navigate([`/administrative/categories`, data.id]);
    else if (link === 'livestock')
      this.router.navigate([link, data.id]);
    else if (template_id_tmp === 'family_practices')
      this.router.navigate(['/family-practices/categories', data.id]);
    else if (link === 'animal_feed')
      this.router.navigate(['/animal-feed/categories', data.id]);
    else if (link === 'insect') {
      this.router.navigate(['/insect']);
      localStorage.setItem('WeatherPopupStatus', 'true');
    } else if (link === 'survey')
      this.openPandemicReportDialog();
    else
      this.router.navigate([link]);
  }
  openPandemicReportDialog() {
    const dialogRef = this.mddialog.open(PandemicReportComponent, {
      width: '400px',
      data: { title: this.translationService.translate('reports.pandemic_title') }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result == null || result.Details == "") {
        return;
      }

    });
  }
}
