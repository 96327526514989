import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from '../../../shared/service/user.service';
import { AuthService } from '../../../shared/service/auth.service';
import { ForumService } from '../../../shared/service/forum.service';
import { MatListModule } from '@angular/material/list';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { Parametre } from '../../../shared/service/parametre';
import Utils from '../../../shared/utils/utils';
import { GeoLocationService } from '../../../shared/service/location';
import { Subscription } from 'rxjs';
interface User {
  uid: any;
  firstName: any;
  lastName: any;
  photoURL: string;
  email: any;
  address: any;
  docid: any;
  isActive: any;
  expert: any;
  regDate: any;
  birth: any;
}
const PARAMETRE = Parametre.getInstance();

@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.css']
})
export class UserDetailsComponent implements OnInit, OnDestroy {

  public loader = false;
  public IsforumAdmin: boolean = false;
  public arrUser: any = [];
  public userdoc: any = [];
  public thisUserdoc: any;
  private uid: any;
  country: any;
  forum: any;
  subscriptions: Subscription[] = [];

  constructor(
    private router: Router,
    public userService: UserService,
    public authService: AuthService,
    public forumService: ForumService,
    public route: ActivatedRoute,
    private location: Location,
    public geoLocationService: GeoLocationService
  ) {
    if (this.authService.isLoggedIn) {
      this.arrUser = this.authService.isLoggedIn;
      this.arrUser.role = 'Admin';
    }
  }

  ngOnInit() {
    const themes = JSON.parse(localStorage.getItem('appThemes'));
    this.forum = themes.filter(_ => _.template_id === 'forum')[0];
    this.country = JSON.parse(localStorage.getItem("countryData"));
    if (!this.authService.isLoggedIn) {
      this.router.navigate(['login/forum*admin']);
    }
    this.subscriptions.push(this.authService.afAuth.authState.subscribe(async user => {
      if (user) {
        this.IsforumAdmin = this.IsAdmin(user.uid);
      }
    }));
    this.route.params.subscribe(param => {
      this.uid = param.id;
      this.subscriptions.push(this.forumService.getUserDetails(this.uid).subscribe((res) => {
        this.thisUserdoc = res as User;
        if (!this.thisUserdoc.photoURL) {
          this.thisUserdoc.photoURL = '../../../../assets/forum/userprofile.svg';
        }
        if (this.thisUserdoc.isActive == undefined) {
          this.thisUserdoc.isActive = true;
        }
      }));

    });
  }

  public IsAdmin(UserId) {
    this.subscriptions.push(this.forumService.getUserDetails(UserId).subscribe((res: any[]) => {
      if (res) {
        this.userdoc = res;
        const country = this.geoLocationService.getCountry();
        if (this.userdoc.roles["dsp"].indexOf("admin") > -1) {
          this.loader = true;
        } else if (!(this.userdoc.roles["dsp"].includes("admin") || this.userdoc.roles["dsp"].includes("forum_admin")) || this.userdoc['country_iso3'].toLowerCase() != country.country.toLowerCase()) {
          this.router.navigate([`forum`]);
        }
      }
    }));
    return true;
  }

  userActive(event) {
    //alert(event.checked);
    this.forumService.UpdateUserActive(this.uid, event.checked);
  }
  userExpert(event) {
    //alert(event.checked);
    this.forumService.UpdateUserExpert(this.uid, event.checked);
  }

  nav(link) {
    this.router.navigate([`forum/admin/user-details`, `${link}`]);
  }

  back() {
    this.location.back();
  }
  setMyStyles() {
    return Utils.reverseContent();
  }
  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}

