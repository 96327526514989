import { Component, OnInit, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { AudioService } from '../../shared/component/audio/audio.service';
import { VideoService } from '../../shared/component/video/video.service';
import { Parametre } from '../../shared/service/parametre';
const PARAMETRE = Parametre.getInstance();
import { UserService } from '../../shared/service/user.service';
import { environment } from '../../../environments/environment';
import Utils from '../../shared/utils/utils';
import { FirestoreDataService } from "../../shared/service/firestore-data.service";
import { GeoLocationService } from '../../shared/service/location';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { Subscription } from 'rxjs';
import { NgxSpinnerService } from "ngx-spinner";
@Component({
  selector: 'app-citrus-subjects',
  templateUrl: './citrus-subjects.component.html',
  styles: []
})
export class CitrusSubjectsComponent implements OnInit, OnDestroy {
  public menuBarTitle = "Home.CitrusProductionTitle";
  objectKeys = Object.keys;
  arrCommodity: any;
  public loader: Boolean;
  public generalTabData: any;
  public txtSpeech: any;
  subscriptions: Subscription[] = [];
  constructor(
    public route: ActivatedRoute,
    private location: Location,
    private audio: AudioService,
    private videoService: VideoService,
    private fireStoreDataService: FirestoreDataService,
    public geoLocationService: GeoLocationService,
    private gaService: GoogleAnalyticsService,
    private spinner: NgxSpinnerService
  ) {
    this.spinner.show();
  }

  ngOnInit() {
    UserService.langCodeTextSpeech = JSON.parse(localStorage.getItem(environment.userLanguageStorageKey)).text_to_speech_code;
    this.txtSpeech = UserService.langCodeTextSpeech;
    this.route.params.subscribe(param => {
      this.loader = false;
      const country = this.geoLocationService.getCountry();
      let mainFilterArr: string[] = [param.commodity_parent];
      this.subscriptions.push(this.fireStoreDataService.getAllCommodities("dimensions-api", "citrus", mainFilterArr, country, '', true).subscribe(res => {
        if (res.length > 0) {
          var arrData = [];
          for (var x in res) {
            if (res[x]['commodity_name'] == param.commodity_name) {
              res.hasOwnProperty(x) && arrData.push(res[x])
            }
          }
          this.generalTabData = arrData[0];
          let subFilterArr: string[] = [param.commodity_name];
          this.subscriptions.push(this.fireStoreDataService.getAllCommodities("key-messages-api", "citrus_final", subFilterArr, country, '', true).subscribe(data => {
            this.spinner.hide();
            this.loader = true;
            this.arrCommodity = data["reduce"]((acc, message) => {
              const providerName = message.provided_by;
              const kindof = message.proces_phase_name;
              if (acc[kindof]) {
                acc[kindof].push(message);
                return acc;
              }
              acc[kindof] = [message];
              return acc;
            }, {});
          }));
        }
      }));
    });
  }
  public ga(event) {
    const name = 'Citrus-Production_' + this.route.snapshot.params.commodity_name;
    const action = event.action ? event.action + name : name;
    this.gaService.event(action, event.category, event.label);
  }
  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
