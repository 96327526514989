import { Component, OnInit, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GeoLocationService } from '../shared/service/location';
import { UserService } from '../shared/service/user.service';
import { environment } from '../../environments/environment';
import { AppComponent } from '../app.component';
import { Location } from '@angular/common';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import Utils from '../shared/utils/utils';
import { AuthService } from '../shared/service/auth.service';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-select-language',
  templateUrl: './select-language.component.html',
  styleUrls: ['./select-language.component.scss']
})
export class SelectLanguageComponent implements OnInit, OnDestroy {
  public languages;
  currentURL = '';
  sub: any;
  arrApplications: any = [];
  arrData: any;
  isLogged: any;
  user: any;
  isXs: any;
  layout: any;
  notificationService: any;
  appSettings: any;
  distribution: boolean = false;
  subscriptions: Subscription[] = [];
  constructor(
    public locationService: GeoLocationService,
    public userService: UserService,
    private translate: TranslateService,
    private appComponent: AppComponent,
    public location: Location,
    private router: Router,
    public toast: MatSnackBar,
    private route: ActivatedRoute,
    public authService: AuthService,
    private gaService: GoogleAnalyticsService
  ) {
    this.locationService.getLatestLocation();
  }

  ngOnInit() {
    this.distribution = sessionStorage.getItem('distribution') == 'true' ? true : false;
    const countryName = Utils.modifyCountryString(localStorage.getItem('gpsCountry'));
    if (countryName) {
      this.getCountryDetails(countryName);
    } else {
      this.subscriptions.push(this.locationService.getCountryFromGeoIp().subscribe(res => {
        if (res != undefined) {
          // Set positions
          let geoIpCountry = Utils.modifyCountryString(res['country_name']);
          localStorage.setItem('gpsCountry', geoIpCountry);
          let position = {
            "coords": {
              "latitude": res['latitude'],
              "longitude": res['longitude'],
              "altitude": null,
              "accuracy": 100000,
              "altitudeAccuracy": null,
              "heading": null,
              "speed": null
            }
          };
          this.locationService.setPostions(position);
          this.getCountryDetails(geoIpCountry);
        }
      }));
    }
  }

  getCountryDetails(country) {
    if (country) {
      country = Utils.getCountryData(country, 'code3');
      this.subscriptions.push(this.locationService.getCountryLanguages(country).subscribe(res => {
        if (res != undefined) {
          localStorage.setItem('countryData', JSON.stringify(res));
          if (this.distribution) {
            this.getThemes();
          }
          if (res.country == 'EGY') {
            res["languages"] = res["languages"].filter(_ => _.code != 'en');
          }
          this.languages = res["languages"];
          //If there is only one language
          if (res['languages'].length <= 1) {
            this.selAvailableLang(res['languages'][0]);
          }
        } else {
          // Set default country to fao
          localStorage.setItem('gpsCountry', 'fao');
          this.subscriptions.push(this.locationService.getCountryLanguages('FAO').subscribe(res => {
            localStorage.setItem('countryData', JSON.stringify(res));
            localStorage.setItem('appSettings', JSON.stringify(res));
            this.languages = res["languages"];
            if (this.distribution) {
              this.getThemes();
            }
            //If there is only one language
            if (res['languages'].length <= 1) {
              this.selAvailableLang(res['languages'][0]);
            }
          }));
        }
      }));
    }
  }

  onLangChange({ language }) {
    this.gaService.event(language.name, 'Language_Change', '');
    this.userService.saveLangId(language.code);
    localStorage.setItem(environment.userLanguageStorageKey, JSON.stringify(language));
    this.subscriptions.push(this.userService.getTranslation('translations').subscribe(res => {
      res = Utils.translationConvertor(res);
      localStorage.setItem('localLanguageData', JSON.stringify(res));
    }));
    this.appComponent.setDefaultLang();
    this.route.params.subscribe(param => {
      if (param.location) {
        //this.router.navigate(['/home']);
        var strUrl: any = localStorage.getItem('strDeepLink');
        if (param.location == 1 && (strUrl == "" || strUrl == null)) {
          this.router.navigate(['/home']);
        } else {
          if (this.distribution) {
            this.router.navigate([`${strUrl.split('?')[0]}`, { queryParams: { distribution: true } }]);
          } else
            this.router.navigate([strUrl]);
        }
      } else {
        this.location.back();
      }
    });
  }

  selAvailableLang(language) {
    this.userService.saveLangId(language.code);
    // this.appComponent.setDefaultLang(language.code);
    localStorage.setItem(environment.userLanguageStorageKey, JSON.stringify(language));
    this.subscriptions.push(this.userService.getTranslation('translations').subscribe(res => {
      res = Utils.translationConvertor(res);
      localStorage.setItem('localLanguageData', JSON.stringify(res));
      this.route.params.subscribe(param => {
        if (param.location) {
          this.router.navigate(['/home']);
        } else {
          this.location.back();
        }
      });
    }));
  }
  getThemes() {
    this.subscriptions.push(this.userService.getDataUsingCountry('themes', 'order').subscribe(res => {
      if (res) {
        res = res.filter(_ => _['status'] == undefined || _['status']);
        this.arrApplications = res;
        localStorage.setItem('appThemes', JSON.stringify(res));
        const arrApplicationList = res.map(items => {
          return Utils.routedata[items['template_id']] ? Utils.routedata[items['template_id']] : items['template_id'];
        });
        if (arrApplicationList.length > 0) {
          localStorage.setItem('arrAppList', JSON.stringify(arrApplicationList));
          this.authService.isAppExistSubject.next(arrApplicationList);
        }
      }
    }));
  }
  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
