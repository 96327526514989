import { Injectable, NgZone } from '@angular/core';
import { User } from './../models/user';
//import { auth } from 'firebase/app';
import firebase from 'firebase/compat/app';
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { Router } from "@angular/router";
import { GeoLocationService } from './location';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BehaviorSubject, Observable } from 'rxjs';
import { FirestoreDataService } from "./firestore-data.service";
import { environment } from "../../../environments/environment";
//import { map, take, do } from 'rxjs/operators';
import { NotificationService } from './notification.service';

import 'rxjs/add/operator/take';
import 'rxjs/add/operator/do';
import { TranslationService } from './translation.service';
import { NgxSpinnerService } from "ngx-spinner";
import * as moment from 'moment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})

export class AuthService {
  userData: any; // Save logged in user data
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;
  public regRequiredSubject: BehaviorSubject<any>;
  public registrationRequired: Observable<any>;

  public isAppExistSubject: BehaviorSubject<any>;
  public isAppExist: Observable<any>;

  constructor(
    public afs: AngularFirestore,   // Inject Firestore service
    //private fdb: AngularFireDatabase,
    public afAuth: AngularFireAuth, // Inject Firebase auth service
    public router: Router,
    public ngZone: NgZone, // NgZone service to remove outside scope warning
    public location: GeoLocationService,/* ,
        public registrtion: RegistrationService */
    public toast: MatSnackBar,
    public fireStoreDataService: FirestoreDataService,
    public notificationService: NotificationService,
    private translate: TranslationService,
    private spinner: NgxSpinnerService,
    private http: HttpClient
  ) {
    // Variables to store user
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('user')));
    this.currentUser = this.currentUserSubject.asObservable();

    // Variables to store is registration required
    this.regRequiredSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('regRequired')));
    this.registrationRequired = this.regRequiredSubject.asObservable();

    // Variables to store is if application exist
    this.isAppExistSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('arrAppList')));
    this.isAppExist = this.isAppExistSubject.asObservable();

  }

  async initAuth() {
    let isRegRequired = await this.location.chkRegRequired();
    if (!isRegRequired) {
      localStorage.removeItem('user');
      localStorage.setItem('registration', 'false');
    }
    else {
      localStorage.setItem('registration', 'true');
      this.afAuth.authState.subscribe(user => {
        if (user) {
          const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${user.uid}`);
          let userFbObj = userRef.valueChanges();
          userFbObj.subscribe(value => {
            this.userData = value;
            localStorage.setItem('user', JSON.stringify(this.userData));
            JSON.parse(localStorage.getItem('user'));
            this.currentUserSubject.next(this.userData);
          });
        } else {
          localStorage.setItem('user', null);
        }
      })
    }
  }

  // Sign in with email/password
  SignIn(email, password, invokeURL) {
    this.spinner.show();
    return this.afAuth.signInWithEmailAndPassword(email, password).then((result) => {
      this.getToken(true);
      var ref = this;
      if (result.user.emailVerified) {
        const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${result.user.uid}`);

        userRef.get().do(action => {
          this.spinner.hide();
          var value = action.data();
          const country = this.location.getCountry();
          if (this.isAdminRole(value?.roles?.dsp) || (value.country_iso3 == country.country)) {
            this.checkClaims(result.user.uid);
            this.SetUserData(result.user, value);
            this.userData = value;
            localStorage.setItem('user', JSON.stringify(this.userData));
            this.currentUserSubject.next(this.userData);
            if (invokeURL) {
              this.router.navigate([invokeURL]);
            } else {
              this.router.navigate(['profile']);
            }
            this.notificationService.registerNotification("login", result.user.uid);
          } else {
            this.SignOut('email-login');
            this.toast.open('The user registered for a different country.', 'OK');
          }
        }).subscribe();

        let user = userRef.valueChanges();


      } else {
        this.spinner.hide();
        this.toast.open(this.translate.translate('registration.verifyemailmessage2'), this.translate.translate("Post.Ok"));
        this.SendVerificationMail();
        this.router.navigate(['verify-email-address']);
      }
    }).catch((error) => {
      this.spinner.hide();
      this.toast.open(error.message, this.translate.translate("Post.Ok"));
    })

  }

  // Sign in with email/password then redirect to the page that invoked sign in
  // SignIninvokeURL(email, password, invokeURL) {
  //     return this.afAuth.signInWithEmailAndPassword(email, password)
  //         .then((result) => {
  //             var ref = this;
  //             if (result.user.emailVerified) {
  //                 const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${result.user.uid}`);
  //                 let user = userRef.valueChanges();

  //                 user.subscribe(value => {
  //                     this.SetUserData(result.user, value);
  //                     this.userData = value;
  //                     localStorage.setItem('user', JSON.stringify(this.userData));
  //                     this.currentUserSubject.next(this.userData);
  //                     this.router.navigate([invokeURL]);
  //                 });
  //             } else {
  //                 this.toast.open('Email address is not verified. Please verify and login.', 'OK');
  //             }
  //         }).catch((error) => {
  //             this.toast.open(error.message, 'OK');
  //         })
  // }



  redirect() {
    this.router.navigate(['home']);
  }

  // Sign up with email/password
  SignUp(obj) {
    let email = obj.email;
    let password = obj.password;
    return this.afAuth.createUserWithEmailAndPassword(email, password)
      .then((result) => {
        this.SendVerificationMail();
        this.SetUserData(result.user, obj);
      }).catch((error) => {
        this.toast.open(error.message, this.translate.translate("Post.Ok"));
      })
  }

  // Send email verfificaiton when new user sign up
  SendVerificationMail() {
    return this.afAuth.currentUser
      .then((user) => {
        let langCode = localStorage.getItem(
          environment.userLanguageStorageKey
        )
          ? JSON.parse(localStorage.getItem(environment.userLanguageStorageKey))
            .code
          : "en";
        if (langCode == 'ar') {
          //this.afAuth.auth.languageCode = 'Ar';
          firebase.auth().languageCode = 'Ar';
        }
        user.sendEmailVerification();
        this.router.navigate(['verify-email-address']);
      })
    /* return this.afAuth.auth.currentUser.sendEmailVerification()
        .then(() => {
            this.router.navigate(['verify-email-address']);
        }) */
  }

  // Reset Forggot password
  ForgotPassword(passwordResetEmail) {
    return this.afAuth.sendPasswordResetEmail(passwordResetEmail)
      .then(() => {
        this.toast.open(this.translate.translate('registration.verifyemailmessage1') + ' ' + passwordResetEmail, this.translate.translate("Post.Ok"));
      }).catch((error) => {
        this.toast.open(error, this.translate.translate("Post.Ok"));
      })
  }

  public get isLoggedIn(): any {
    return this.currentUserSubject.value;
  }

  // Sign in with Google
  GoogleAuth() {
    //return this.AuthLogin(new auth.GoogleAuthProvider());
  }

  // Auth logic to run auth providers
  AuthLogin(provider) {
    this.spinner.show();
    return this.afAuth.signInWithPopup(provider)
      .then((result) => {
        this.spinner.hide();
        var ref = this;
        const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${result.user.uid}`);
        let user = userRef.valueChanges();
        user.subscribe(value => {

        });
        ref.ngZone.run(() => {
          ref.router.navigate(['home']);
        })
      }).catch((error) => {
        this.toast.open(error, this.translate.translate("Post.Ok"));
      })
  }

  /* Setting up user data when sign in with username/password,
  sign up with username/password and sign in with social auth
  provider in Firestore database using AngularFirestore + AngularFirestoreDocument service */
  SetUserData(user, obj, isMobileLogin?) {
    const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${user.uid}`);
    if (obj.hasOwnProperty("password")) {
      delete obj["password"];
    }
    if (obj.hasOwnProperty("confirmpassword")) {
      delete obj["confirmpassword"];
    }
    obj.emailVerified = user.emailVerified;
    obj.uid = user.uid;
    obj.displayName = user.displayName;
    obj.photoURL = '';
    this.userData = obj;
    return userRef.set(this.userData, {
      merge: true
    }).then(res => {
      if (isMobileLogin) {
        let url = environment.cloudApiUrl + 'user/custom_claims/' + user.uid;
        this.http.post(url, {}).subscribe(res => {
          this.refreshToken();
          this.spinner.hide();
          this.router.navigate(['profile']);
        },
          error => {
            this.refreshToken();
            this.spinner.hide();
            this.router.navigate(['profile']);
          });
      }
    })
  }

  UpdateUserData(user, obj) {
    this.spinner.show();
    const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${user.uid}`);
    const userData = obj;
    userRef.set(userData, {
      merge: true
    })

    userRef.snapshotChanges().take(1).do(action => {
      var value = action.payload.data();
      this.spinner.hide();
      this.userData = value;
      localStorage.setItem('user', JSON.stringify(this.userData));
      this.currentUserSubject.next(this.userData);
    }).subscribe();

    this.router.navigate(['profile']);
  }

  // Sign out.
  SignOut(backToPage?) {
    this.spinner.show();
    return this.afAuth.signOut().then(() => {
      localStorage.removeItem('user');
      localStorage.removeItem('authToken');
      this.currentUserSubject.next(null);
      //this.router.navigate(['message']);
      this.spinner.hide();
      if (backToPage)
        this.router.navigate([backToPage]);
      else
        this.router.navigate(['home']);
    });
  }

  public get isRegRequired(): any {
    return this.regRequiredSubject.value;
  }

  public isApplicationExist(appName): any {
    const arrAllAppList = [
      "weather",
      "livestock",
      "agrimarket",
      "e-nutrifood",
      "plant-pests",
      "healthy-nutrition",
      "citrus-production",
      "date-production",
      "household-poultry",
      "egyptmarkets",
      "citrus",
      "forum",
      "administrative"
    ];
    // Check App is include in all application list
    const isAppInclude = arrAllAppList.includes(appName);
    if (isAppInclude) {
      // Check app in current country list
      const arrCurrentAppList = this.isAppExistSubject.value;
      const isExist = arrCurrentAppList && arrCurrentAppList.length ? arrCurrentAppList.includes(appName) : false;
      if (isExist) {
        return 'Yes';
      } else {
        return 'No';
      }
    } else {
      return 'NA';
    }
  }

  public signInWithMobile(result) {
    // Set user data
    const country = this.location.getCountry();
    var obj = {
      uid: result.user.uid,
      roles: { dsp: ["user"] },
      countryName: country['country_name'],
      displayName: "",
      email: "",
      firstName: "",
      lastName: "",
      gender: "",
      photoURL: "",
      emailVerified: true,
      country_iso3: country['country'],
      regDate: moment().format('DD/MM/YYYY'),
      nickName: ''
    }
    this.checkIfMobileUserExists(result.user.uid, result.user, obj)
    //this.user = result.user;
  }


  public checkIfMobileUserExists(uid, user, obj) {
    this.spinner.show();
    const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${uid}`);


    userRef.snapshotChanges().take(1).do(action => {
      var value = action.payload.data();
      if (value) {
        this.spinner.hide();
        this.getToken(true);
        const country = this.location.getCountry();
        if (this.isAdminRole(value?.roles?.dsp) || value.country_iso3 == country.country) {
          localStorage.setItem('user', JSON.stringify(value));
        } else {
          this.SignOut('phone-number');
          this.toast.open('The user registered for a different country.', 'OK');
        }
        this.notificationService.registerNotification("login", uid);
        this.currentUserSubject.next(value);
        this.router.navigate(['profile']);
      } else {
        localStorage.setItem('user', JSON.stringify(obj));
        this.notificationService.registerNotification("login", uid);
        this.currentUserSubject.next(value);
        this.SetUserData(user, obj, true);
      }
      //this.windowRef.confirmationResult = '';


    }).subscribe();

    userRef.valueChanges();



    // let userFbObj = userRef.valueChanges();
    // userFbObj.subscribe(value => {
    //     if (value) {
    //         localStorage.setItem('user', JSON.stringify(value));
    //     } else {
    //         this.SetUserData(user, obj);
    //     }
    //     //this.windowRef.confirmationResult = '';
    //     this.router.navigate(['profile']);
    //     this.currentUserSubject.next(this.userData);
    // });

  }
  checkClaims(uid) {
    this.afAuth.currentUser.then((user) => {
      user.getIdTokenResult().then(token => {
        if (!token || !token.claims || !token.claims.roles) {
          let url = environment.cloudApiUrl + 'user/custom_claims/' + uid;
          this.http.post(url, {}).subscribe(res => {
            this.refreshToken();
          });
        }
      })
    });
  }
  refreshToken() {
    this.afAuth.currentUser.then((user) => {
      user.getIdToken(true).then((token) => {
        localStorage.setItem('authToken', token);
        user.getIdTokenResult(true).then(token => {
        })
      });
    });
  }
  signInAnonymously() {
    this.afAuth.signInAnonymously().then((user) => {
      this.getToken();
    });
  }
  getToken(isLoder?) {
    this.afAuth.currentUser.then((user) => {
      user.getIdToken().then((token) => {
        localStorage.setItem('authToken', token);
        if (!isLoder)
          this.spinner.hide();
      });
    });
  }
  signoutAnonymousUser() {
    return this.afAuth.signOut().then(() => {
      localStorage.removeItem('authToken');
    });
  }
  isAdminRole(rolesDsp: Array<string>) {
    return rolesDsp?.indexOf('admin') > -1 ? true : false;
  }
}

