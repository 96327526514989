import { Component, OnInit, OnDestroy } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { LayoutService } from './../shared/service/layout.service';
import { UserService } from './../shared/service/user.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-date-production',
  templateUrl: './date-production.component.html',
  styles: [
    `
        .fix {
            position:  fixed;
            width: 100%;
            margin-top: 65px;
            height:100%
        }
        `
  ]
})
export class DateProductionComponent implements OnInit, OnDestroy {
  v: Subscription;
  p: Subscription;
  climate: any;
  usage: any;
  cure: any;
  soil: any;
  health: any;
  info$;
  searchText = { tfvname: '' };
  favs = [];
  rowH: string;
  modalWidth: string;
  cols = 1;
  state: string;
  star = 'star_border';
  data = [];
  isLoading = true;
  isDetailsLoading = true;

  constructor(
    private translate: TranslateService,
    private http: HttpClient, private layout: LayoutService,
    private us: UserService
  ) { }

  ngOnInit() {
    this.isLoading = false;
    this.p = this.layout.layout$.subscribe(res => {
      this.cols = res.cols;
      this.rowH = res.rowH;
    });
  }

  ngOnDestroy() {
    this.p.unsubscribe();
  }
}
