import { MarketDetailsComponent } from './market-details/market-details.component';
import { AgrimarketDetailsComponent } from './agrimarket-details/agrimarket-details.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AgrimarketComponent } from './home/agrimarket.component';
import { AuthGuard } from '../shared/guards/auth.guard';

const routes: Routes = [
  { path: 'agrimarketplace', redirectTo: 'agrimarket' },
  { path: 'agrimarket', component: AgrimarketComponent, canActivate: [AuthGuard] },
  { path: 'agrimarket/:id', component: AgrimarketDetailsComponent, canActivate: [AuthGuard] },
  { path: 'market/:id', component: MarketDetailsComponent, canActivate: [AuthGuard] }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AgrimarketRoutingModule { }
