import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { Covid19FoodSafetyComponent } from './covid19-food-safety.component';

import { CategoriesComponent } from './categories/categories.component';
import { SubjectsComponent } from './subjects/subjects.component';
import { AuthGuard } from '../shared/guards/auth.guard';

const Covid19FoodSafety: Routes = [
  {
    path: 'covid19_vegetables_fruits',
    canActivate: [AuthGuard],
    children: [
      { path: '', redirectTo: 'categories', pathMatch: 'full' },
      { path: 'categories', component: CategoriesComponent, canActivate: [AuthGuard] },
      { path: 'categories/:commodity_parent', component: CategoriesComponent, canActivate: [AuthGuard] },
      { path: 'subject/:commodity_parent/:commodity_name', component: SubjectsComponent, canActivate: [AuthGuard] }
    ]
  }
];
@NgModule({
  imports: [RouterModule.forChild(Covid19FoodSafety)],
  exports: [RouterModule]
})
export class Covid19FoodSafetyRoutingModule { }
