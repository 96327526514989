import { Injectable } from "@angular/core";
import { CropCalendarService } from "./api/cropcalendar-api.service";
import { Filters } from "../models/filters.dto";

@Injectable()
export class CropService {
  filter: Filters;

  constructor(private cropcalendarService: CropCalendarService) { }

  getAllCountries() {
    return this.cropcalendarService.getAllCountries();
  }

  getAllCropsByCountry(country: string) {
    return this.cropcalendarService.getAllCropsByCountry(country);
  }

  getAllCropsByFilter(country: string, aez: string) {
    return this.cropcalendarService.getAllCropsByFilter(country, aez);
  }

  getAllAEZByCountry(country: string) {
    return this.cropcalendarService.getAllAEZByCountry(country);
  }

  getAllCropCalendar(country: string, zones: string[], crops: string[]) {
    return this.cropcalendarService.getAllCropCalendar(country, zones, crops);
  }
}
