import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';

@Pipe({
    name: 'customDate',
    pure: false
})
export class CustomDatePipe extends DatePipe implements PipeTransform {
    transform(value: any, args?: any): any {
        //return super.transform(value.seconds, "dd-MM-yyyy");
        let ms = value.seconds * 1000;
        return moment(ms).format("DD-MM-YYYY");
    }
}