import { Subscription, Subject } from 'rxjs';
import { LayoutService } from './../shared/service/layout.service';
import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { AudioService } from '../shared/component/audio/audio.service';
import Utils from './../shared/utils/utils';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { ActivatedRoute } from '@angular/router';
import { LivestockService, } from '../shared/service/livestock.service';
import { NgxSpinnerService } from "ngx-spinner";
@Component({
  selector: 'app-livestock',
  templateUrl: 'livestock.html',
  styles: [`
    maj {
        text-transform: uppercase;
    }

    min {
        text-transform: lowercase;
    }

    cap {
        text-transform: capitalize;
    }
        .active-button {
            background-color: #03a9f4;
        }

        #header {
            border-bottom:2px solid darkgrey;
        }

        .white-icons {
            color: white;
        }

        .active {
            color:#212121;
        }

        .meteo-nav {
            display: flex;
            height: 50px;
        }

        .meteo-nav-spacing {
            margin-left: 2%;
            margin-right: 2%;
        }

        .lightgray {
            background-color:grey
        }
    `],
})

export class LivestockComponent implements OnInit, OnDestroy {
  l: Subscription;
  isDataLoading: Boolean;
  cols: any = 1;
  rowH: any = "2:3";
  modalWidth: any;
  parentSubject: Subject<any> = new Subject();
  selectedTab: number = 0;
  appTabs: any;
  appTheme: any;
  loader: Boolean;
  subscriptions: Subscription[] = [];
  constructor(private layout: LayoutService,
    private gaService: GoogleAnalyticsService,
    private route: ActivatedRoute,
    private livestock: LivestockService,
    private spinner: NgxSpinnerService) {
    this.spinner.show();
    localStorage.removeItem("selWeatherTabIndex");
    localStorage.removeItem("selAgmTabIndex");
    if (Utils.getLivestockTabIndex()) {
      this.selectedTab = Utils.getLivestockTabIndex();
    }
  }

  ngOnInit() {
    this.l = this.layout.layout$.subscribe(res => {
      this.cols = res.cols;
      this.rowH = res.rowH;
    });
    this.subscriptions.push(this.l);
    const themes = JSON.parse(localStorage.getItem('appThemes'));
    let parentid: string;
    this.route.params.subscribe(param => {
      if (param.themeId)
        parentid = param.themeId.split('?')[0];
      if (parentid)
        this.appTheme = themes.filter(_ => _.id === parentid)[0];
      else {
        this.appTheme = themes.filter(_ => _.template_id === 'livestock')[0];
      }
      this.appTabs = this.appTheme.tabs;
      this.appTabs = this.appTabs.filter(_ => _.status == undefined || _.status);
      this.getDimentions(this.appTheme.id);
    });
  }

  setMyStyles() {
    return Utils.reverseContent();
  }

  getDimentions(parentid) {
    this.subscriptions.push(this.livestock.getAllDimensions(parentid, 'theme_id', 'order').subscribe(res => {
      this.loader = true;
      this.spinner.hide();
      if (res) {
        res = res.filter(_ => _.status == undefined || _.status);
        this.appTabs.forEach(tab => {
          tab.data = res.filter(_ => _.tab_id == tab.id);
          tab.themeId = parentid;
        })
      }
    }));
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  tabChange(i) {
    this.gaService.event('Livestock', 'Tab_Change', i.tab.textLabel);
    this.selectedTab = Utils.setLivestockTabIndex(i.index);
  }
}
