import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-language-picker',
  templateUrl: './language-picker.component.html',
  styleUrls: ['./language-picker.component.scss']
})
export class LanguagePickerComponent {
  @Input() languages;
  @Output() onLangChange: EventEmitter<{[key: string]: any}> = new EventEmitter<{[key: string]: any}>();

  constructor() { }

  onLangClick(event, lang) {
    this.onLangChange.emit({
      language: lang,
    });
  }
}
