import { FamilyPracticesComponent } from './family-practices';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../shared/guards/auth.guard';
import { FamilyPracticesCategoriesComponent } from './family-practices-categories/family-practices-categories.component';
import { FamilyPracticesSubjectsComponent } from './family-practices-subjects/family-practices-subjects.component';

const FamilyPracites: Routes = [
  { path: 'family-practices', redirectTo: 'family-practices' },
  {
    path: 'family-practices',
    component: FamilyPracticesComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', redirectTo: 'categories', pathMatch: 'full' },
      { path: 'categories', component: FamilyPracticesCategoriesComponent, canActivate: [AuthGuard] },
      { path: 'categories/:id', component: FamilyPracticesCategoriesComponent, canActivate: [AuthGuard] },
      { path: 'subject/:themeId/:id', component: FamilyPracticesSubjectsComponent, canActivate: [AuthGuard] },
      { path: 'subject/:themeId/:id/:topicId', component: FamilyPracticesSubjectsComponent, canActivate: [AuthGuard] }
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(FamilyPracites)],
  exports: [RouterModule],
})
export class FamilyPracitesRoutingModule { }
