import { Router } from '@angular/router';
import { Component, Input, OnInit, OnDestroy, OnChanges } from '@angular/core';
import { LivestockService } from '../../shared/service/livestock.service';
import { Subject, Subscription } from 'rxjs';
import Utils from "../../shared/utils/utils";
import { UserService } from '../../shared/service/user.service';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { environment } from '../../../../src/environments/environment';
import { ImageStorageService } from '../../shared/service/img-storage.service';

@Component({
  selector: 'app-vaccinations',
  templateUrl: './vaccinations.component.html',
  styleUrls: ['./vaccinations.component.scss']
})

export class VaccinationsComponent implements OnInit, OnDestroy, OnChanges {
  @Input() parentSubject: Subject<any>;
  searchText = {
    name: {}
  };
  @Input() columns = 1;
  @Input() modalWidth: any;
  @Input() rowHs: any;
  @Input() loader: boolean;
  vaccinations: any;
  groupSelectedVaccination = [];
  public arrApplications: any;
  isFilterExist: boolean = false;
  filterTypeGroup: any = [];
  @Input() tabData: any;
  lang_code: any;
  subscriptions: Subscription[] = [];
  constructor(
    public livestock: LivestockService,
    private router: Router,
    public uService: UserService,
    public gaService: GoogleAnalyticsService,
    private imageStorageService: ImageStorageService
  ) { }

  ngOnInit() {
    this.lang_code = JSON.parse(localStorage.getItem(environment.userLanguageStorageKey)).code
    this.searchText.name[this.lang_code] = '';
    // this.livestock.getAllDimensions(this.tabData.id, '', 'order').subscribe(res => {
    //   this.vaccinations = res;
    //   this.loader = true;
    // });
  }

  ngOnChanges() {
    if (this.tabData)
      this.vaccinations = this.tabData.data;
  }
  ngOnDestroy() {
    this.parentSubject.unsubscribe();
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
  onImgError(event) {
    event.target.src = 'assets/offline/icons/default-icon.svg';
  }

  filterVaccine(selectedFilterType) {
    this.subscriptions.push(this.livestock.getAllVaccinations().subscribe((res: any[]) => {
      this.loader = true;
      res.forEach(element => {
        element["vaccination"] = Utils.modifyString(element["vaccination"]);
      });
      this.vaccinations = res;
      this.groupSelectedVaccination.length = 0;
      if (selectedFilterType.value != 'ALL') {
        this.vaccinations.forEach(item => {
          const DieseaseType = item.filterType;
          if (DieseaseType === selectedFilterType.value) {
            this.groupSelectedVaccination.push(item);
          }
        });
        this.groupSelectedVaccination = this.groupSelectedVaccination;
        this.vaccinations = this.groupSelectedVaccination;
      }
    }));
  }
  getx48Image(inputUrl: string): string {
    return this.imageStorageService.getx48Image(inputUrl);
  }
}
