import { SubForum } from './../../forum/subforum.model';
import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { GeoLocationService } from './location';

@Injectable({
  providedIn: 'root'
})
export class SubForumService {

  formData: SubForum;

  constructor(public location: GeoLocationService, private firestore: AngularFirestore) { }

  getSubForums() {
    const country = this.location.getCountry();
    return this.firestore.collection("forumData/" + country.country + "/ForumSettings/settings/subForums", ref => ref.orderBy('order')).snapshotChanges();
  }

  getSubForumTitle(Id) {
    const country = this.location.getCountry();
    return this.firestore.collection("forumData/" + country.country + "/ForumSettings/settings/subForums", ref => ref.where('value', '==', Id)).snapshotChanges();
  }

  addSubForum(data) {
    const country = this.location.getCountry();
    this.firestore.collection("forumData/" + country.country + "/ForumSettings/settings/subForums").add(data);
  }
  updateSubForum(data, subforumId) {
    const country = this.location.getCountry();
    this.firestore.doc("forumData/" + country.country + "/ForumSettings/settings/subForums/" + subforumId).update(data);
  }
  deleteSubForum(subforumId) {
    const country = this.location.getCountry();
    this.firestore.doc("forumData/" + country.country + "/ForumSettings/settings/subForums/" + subforumId).delete();
  }


}
