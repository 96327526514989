import { throwError as observableThrowError, BehaviorSubject, Observable } from 'rxjs';
import { UserService } from './user.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GeoLocationService } from './location';
import { Parametre } from './parametre';

const PARAMETRE = Parametre.getInstance();
import { FirestoreDataService } from "./firestore-data.service";
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore';

@Injectable()
export class CovidService {

  constructor(
    private UService: UserService,
    public location: GeoLocationService,
    private http: HttpClient,
    public toast: MatSnackBar,
    private fireStoreDataService: FirestoreDataService,
    public afs: AngularFirestore
  ) { }

  public handleError = (err: Response) => {
    if ((err.status === 500) || (err.status === 503)) {
      this.toast.open('Service is temporarily unavailable, please try again later', 'OK', { duration: 3000 });
    }
    return observableThrowError(err.json());
  }
  public getCovidAdvices(key, value): Observable<any> {
    return this.fireStoreDataService.getDimensionsDocument('key_messages', key, value);
  }
}
