import { SectionService } from '../../shared/service/section.service';
import { SubForumService } from '../../shared/service/subforum.service';
import { Location } from '@angular/common';
import { Subscription, Observable } from 'rxjs';
import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { ForumService } from '../../shared/service/forum.service';
import { AuthService } from '../../shared/service/auth.service';
import { TranslationService } from '../../shared/service/translation.service';
import { Parametre } from '../../shared/service/parametre';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RplyDialogComponent } from '../rply-dialog/rply-dialog.component';
import { LoginRequestDialogComponent } from '../login-request-dialog/login-request-dialog.component';
import * as firebase from 'firebase/compat';
import { environment } from '../../../environments/environment';
import Utils from '../../shared/utils/utils';
import { MatSnackBar } from '@angular/material/snack-bar';
import { GeoLocationService } from '../../shared//service/location';
import { UserService } from '../../shared/service/user.service';
import { NgxSpinnerService } from "ngx-spinner";
interface ForumSettings {
  directPublsh: any;
}

const PARAMETRE = Parametre.getInstance();

@Component({
  selector: 'app-question-details',
  templateUrl: './question-details.component.html',
  styleUrls: ['./question-details.component.scss']
})
export class QuestionDetailsComponent implements OnInit, OnDestroy {
  public loader: boolean = false;
  public forumAdmin: boolean = false;
  public publishDecision = true;
  public forumSettingsDoc: any;
  public DirectPublish = true;
  public arrData: any;
  public arrUser: any = [];
  public arrAnswers: any = [];
  //public arrExpertAnswers: any = [];
  AnswersCount = 0;
  public qid: string = "";
  public uid: string = "";
  public udisplayName: string = "";
  public uphotoURL: string = "";
  public imageShow = false;
  public countAnswers = 0;
  public likeclicked = false;
  public numberLikeClicks = 0;
  public repyDetails = "";
  public userAnswer = "";//Store answer of question in case of current user made answer
  public userLike = "";//Store 'user like' of question in case of current user made like

  fqdetails: UntypedFormGroup;
  country: any;
  caveatDetails: boolean;
  subscriptions: Subscription[] = [];
  constructor(public Countrylocation: GeoLocationService,
    public toast: MatSnackBar,
    private subForumService: SubForumService,
    private sectionService: SectionService,
    private router: Router,
    private TranslateS: TranslationService,
    public forumService: ForumService,
    public authService: AuthService,
    public route: ActivatedRoute,
    private fb: UntypedFormBuilder, private afs: AngularFirestore, public dialog: MatDialog, private location: Location,
    public userService: UserService,
    private spinner: NgxSpinnerService
  ) {

    // if (!this.authService.isLoggedIn) {
    //   this.router.navigate(['login']);
    // }

    if (this.authService.isLoggedIn) {
      this.arrUser = this.authService.isLoggedIn;
      //this.arrUser.role = "Admin";

    }
    this.fqdetails = this.fb.group({
      AnswerDetails: ['', Validators.required]
    });

  }

  ngOnInit() {
    this.spinner.show();
    this.country = JSON.parse(localStorage.getItem("countryData"));
    this.subscriptions.push(this.authService.afAuth.authState.subscribe(user => {
      if (user) {
        this.uid = user.uid;
        this.uphotoURL = user.photoURL;
        this.udisplayName = user.displayName;
        this.getuserdata();
        this.getUserAnswer();
        this.getUserLike();
        this.forumAdmin = this.IsAdmin(this.uid);
        this.getforumsetting();
        this.loader = true;
      }
      this.loader = true;
    }));

    // this.userService.getApplicationList().subscribe(res => {
    //   this.caveatDetails = res.country_info.forum_cavet_description
    // });
    const themes = JSON.parse(localStorage.getItem('appThemes'));
    const forum = themes.filter(_ => _.template_id === 'forum')[0];
    this.caveatDetails = forum.initialPopupVisible ? true : false;
    //alert(this.authService.afAuth.auth.currentUser.uid);
    this.route.params.subscribe(param => {
      //  alert(param.id);
      this.qid = param.id;
      try {
        this.subscriptions.push(this.forumService.getPostDetails(param.id).subscribe((res: any[]) => {
          if (res) {
            this.arrData = res;
            this.arrData.pDetails = this.arrData.pDetails.toString().replace(/(?:\r\n|\r|\n)/g, '<br>');
            this.subscriptions.push(this.forumService.getUserDetails(res['uid']).subscribe((res: any[]) => {
              this.spinner.hide();
              if (res) {
                this.arrData['nickName'] = res['nickName'] || '';
              }
            }));
            if (this.arrData.pphotoUrl != null)
              this.imageShow = true;
            this.publishDecision = this.arrData.publish;
            if (this.arrData.numLikes == "1")
              this.userLike = this.udisplayName;
            else
              this.userLike = "";
          }
          else {
            this.spinner.hide();
            this.location.back();
          }
          window.scrollTo(10, 15);

        }));
      }
      catch (exception) {
        this.spinner.hide();
        this.location.back();

      }
    });




    ////////////////////////////////////////////////////////

    //////////////////////////////////////////////////////////

    // if (this.IsAdmin(this.uid))
    this.arrAnswers = this.forumService.getPostAnswersForAdmin(this.qid);
    ///  else
    //    this.arrAnswers = this.forumService.getPostAnswers(this.qid);
  }

  public getUserAnswer() {
    var ans: any = [];
    this.subscriptions.push(this.forumService.getPostAnswerForUser(this.qid, this.uid).subscribe((res: any[]) => {
      res.map(doc => {
        ans = doc;
        if (ans.payload.doc.data().uid == this.uid)
          this.userAnswer = ans.payload.doc.data().cDetails;
        this.fqdetails.patchValue({ AnswerDetails: this.userAnswer });

      });
    }));
  }

  public getUserLike() {
    var likearray: any = [];
    //alert(this.uid);
    this.subscriptions.push(this.forumService.getPostLikeForUser(this.qid, this.uid).subscribe(res => {
      var len = (res.map(doc => {
        likearray = doc;
        if (likearray.payload.doc.data().like != null && likearray.payload.doc.data().uid == this.uid)
          this.userLike = this.TranslateS.translate("Post.YouLike")
        else
          this.userLike = "";
      })).length;;
    }));
  }



  public getuserdata() {
    this.subscriptions.push(this.forumService.getUserDetails(this.uid).subscribe((res: any[]) => {
      if (res) {
        this.arrUser = res;
      }
    }));

  }

  ///////////////////////////////////////////////////////////////////////////////////////////////

  onSubmit(obj) {

    if (!this.ckeckLogin())
      return;
    if (this.fqdetails.invalid) {
      return;
    } else {
      var ao = {};//answer (comment) oject
      ao["uid"] = this.uid; //this.authService.afAuth.currentUser.uid;
      if (this.arrUser.expert == true)
        ao["byexpert"] = true;
      this.subscriptions.push(this.forumService.getUserDetails(this.uid).subscribe((res: any[]) => {

        if (res) {
          this.arrUser = res;
          ao['numComments'] = 0;
          ao['numDislikes'] = 0;
          ao['numLikes'] = 0;
          ao["ufirstName"] = this.arrUser.firstName;
          ao["ulastName"] = this.arrUser.lastName;
          ao["nickName"] = this.arrUser.nickName || '';
          if (this.arrUser.photoUrl)
            ao["uphotoUrl"] = this.arrUser.photoUrl;

          ao["cCreatedDate"] = Date.now();
          ao["cDetails"] = this.fqdetails.get('AnswerDetails').value;
          ao["publish"] = this.DirectPublish;
          ao["answer"] = "true";//to distinguish beween answer and comment forr other comments
          this.spinner.show();
          try {
            if (this.userAnswer == "") {
              this.forumService.AddComment(this.qid, ao, this.uid).then(res => {
                this.spinner.hide();
                this.fqdetails.patchValue({ AnswerDetails: '' });
                this.toast.open(this.TranslateS.translate("Post.SuccessSave"), this.TranslateS.translate("Post.Ok"),
                  { duration: 10000 });
              });
            }
            else {
              this.forumService.UpdateComment(this.qid, this.uid, this.fqdetails.get('AnswerDetails').value).then(res => {
                this.spinner.hide();
                this.toast.open(this.TranslateS.translate("Post.SuccessSave"), this.TranslateS.translate("Post.Ok"),
                  { duration: 10000 });
              });;
            }

          }

          catch (exception) {
            this.spinner.hide();
            this.toast.open(this.TranslateS.translate("Post.ErrorSave"), this.TranslateS.translate("Post.Ok"),
              { duration: 10000 });
          }
        }
      }));



    }
  }

  nav(link) {
    this.router.navigate([`/${link}`]);
  }
  publishQuestion() {
    // alert('question published');
  }

  async LikePost() {
    if (!this.ckeckLogin())
      return;

    this.likeclicked = true;
    this.numberLikeClicks++;
    if (this.numberLikeClicks > 2) //user try to hack system and click many likes or dislikes
      return;

    //alert('like post clicked');
    var likeObject = {};//answer (comment) oject
    likeObject["uid"] = this.uid;
    likeObject["like"] = true;

    this.forumService.LikePost(this.qid, this.uid, likeObject)
    //this.likeclicked = false;
    await this.delay(2000);
    //alert("ss");


    if (this.numberLikeClicks == 2)
      this.likeclicked = true;
    else
      this.likeclicked = false;

  }

  async DislikePost() {
    if (!this.ckeckLogin())
      return;
    this.likeclicked = true;
    this.numberLikeClicks++;
    if (this.numberLikeClicks > 2) //user try to hack system and click many likes or dislikes
      return;

    // alert('dislike post clicked');
    var likeObject = {};//answer (comment) oject
    likeObject["uid"] = this.uid;
    likeObject["dislike"] = true;
    this.forumService.DislikePost(this.qid, this.uid, likeObject)

    await this.delay(2000);
    //alert("ss");
    if (this.numberLikeClicks == 2)
      this.likeclicked = true;
    else
      this.likeclicked = false;;
  }

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  ckeckLogin(): boolean {
    if (!this.authService.isLoggedIn) {
      const dialogRef = this.dialog.open(LoginRequestDialogComponent, {
        width: '300px',
        data: {}
      });
      return false;
    }

    return true;
  }
  openRepotDialog(): void {
    if (!this.ckeckLogin())
      return;

    const dialogRef = this.dialog.open(RplyDialogComponent, {
      width: '300px',
      data: { title: this.TranslateS.translate("Post.ReportTitlePage"), subtitle: this.TranslateS.translate("Post.ReportTitlePageExplain") }
    });

    dialogRef.afterClosed().subscribe(result => {

      if (result == null || result.Details.trim() == "") {
        return;
      }
      this.spinner.show();
      const report = {}; // reply document
      report['uid'] = this.uid;
      report["ufirstName"] = this.arrUser.firstName;
      report["ulastName"] = this.arrUser.lastName;
      report["nickName"] = this.arrUser.nickName || '';
      report['rCreatedDate'] = Date.now();
      report['rDetails'] = result.Details;;
      //reply['pid'] = this.qid;
      report['rView'] = false;
      const country = this.Countrylocation.getCountry();
      report['countryName'] = country.country_name;
      report['country_iso3'] = country.country;
      try {
        this.forumService.ReportPost(this.qid, this.uid, report).then(res => {
          this.spinner.hide();
          this.toast.open(this.TranslateS.translate("Post.SuccessReportSave"), this.TranslateS.translate("Post.Ok"),
            { duration: 10000 });
        });
      }
      catch (exception) {
        this.spinner.hide();
        this.toast.open(this.TranslateS.translate("Post.ErrorReportSave"), this.TranslateS.translate("Post.Ok"),
          { duration: 10000 });
      }


    });
  }

  DeletePost() {
    const dialogRef = this.dialog.open(RplyDialogComponent, {
      width: '300px',
      data: { title: "", subtitle: this.TranslateS.translate("Post.ConfirmDelete"), HideInput: "true" }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result == null) {
        return;
      }
      this.spinner.show();
      let success: boolean = true;
      if (this.arrData.pphotoUrl != null)
        success = this.forumService.DeletePost(this.qid, this.arrData.pphotoUrl);
      else
        success = this.forumService.DeletePost(this.qid, "");
      if (success) {
        this.toast.open(this.TranslateS.translate("Post.SuccessDelete"), this.TranslateS.translate("Post.Ok"),
          { duration: 10000 });
        this.spinner.hide();
        this.nav("forum");
      }
      else {
        this.spinner.hide();
        this.toast.open(this.TranslateS.translate("Post.ErrorDelete"), this.TranslateS.translate("Post.Ok"),
          { duration: 10000 });
      }

    });

  }
  setMyStyles() {
    return Utils.reverseContent();
  }
  GetSubformTitle(SubformId) {

    return "";
  }
  back() {
    this.location.back();
  }

  PublishPost(PublishOrNot) {
    if (PublishOrNot == true)
      this.forumService.publish_Post(this.qid);
    else
      this.forumService.unpublish_Post(this.qid);
  }
  public IsAdmin(UserId) {
    this.subscriptions.push(this.forumService.getUserDetails(UserId).subscribe((res: any[]) => {
      var userdoc: any = []
      if (res) {
        userdoc = res;
        const country = this.Countrylocation.getCountry();
        if (!(userdoc.roles["dsp"].includes("admin") || userdoc.roles["dsp"].includes("forum_admin")) || userdoc['country_iso3'].toLowerCase() != country.country.toLowerCase()) {
          this.forumAdmin = false;
          return false;
        }
      }
    }));
    return true;
  }

  /**
 *To check if the post will be directly published without admin approve
 */
  public getforumsetting() {

    this.subscriptions.push(this.forumService.getForumSettings().subscribe((res) => {
      this.forumSettingsDoc = res as ForumSettings;
      this.DirectPublish = this.forumSettingsDoc.directPublish;
    }));


  }
  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

}
