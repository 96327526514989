import { Observable } from 'rxjs';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { GeoLocationService } from '../shared/service/location';
import { UserService } from '../shared/service/user.service';
import Utils from '../shared/utils/utils';
import { UntypedFormControl } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';
import { AppComponent } from '../app.component';
import { environment } from '../../environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-disclaimer',
  templateUrl: './disclaimer.component.html',
  styleUrls: ['./disclaimer.component.css'],
})
export class DisclaimerComponent implements OnInit, OnDestroy {
  myControl: UntypedFormControl = new UntypedFormControl();
  filteredOptions: Observable<string[]>;
  arrLanguages: any = [];
  selLanguage: any;
  public countryStatus: boolean = false;
  disclaimerText: any;
  country: string;
  subscriptions: Subscription[] = [];

  constructor(
    private appComponent: AppComponent,
    public router: Router,
    private location: GeoLocationService,
    private userService: UserService,
    private route: ActivatedRoute
  ) {
    const countryName = Utils.modifyCountryString(localStorage.getItem('gpsCountry'));
    if (countryName) {
      this.countryStatus = true;
    } else {
      this.countryStatus = false;
      let language = {
        "default_language": true,
        "code": "en",
        "language_code_text_to_speech": "en-us",
        "language_id": 1,
        "name": "English"
      };
      localStorage.setItem(environment.userLanguageStorageKey, JSON.stringify(language));
      this.selLanguage = "en";
      // this.appComponent.setDefaultLang(this.selLanguage);

      this.arrLanguages = [];
      // this.filteredOptions = this.myControl.valueChanges.pipe(startWith(''),
      //   map(value =>
      //     value ? this.filterOption(value) : this.arrLanguages.slice())
      // );
    }
  }

  // private filterOption(value) {
  //   const filterValue = value.toLowerCase();
  //   return this.arrLanguages.filter(value => value.toLowerCase().indexOf(filterValue) === 0);
  // }

  ngOnInit() {
    // const urlSearchParams = new URLSearchParams(window.location.search)
    // this.country = urlSearchParams.get('country');
    this.country = this.route.snapshot.params.code;
    if (this.country) {
      this.country = this.country == 'dhaka-freash-markets' ? 'BGD' : this.country;
      localStorage.setItem('gpsCountry', Utils.getCountryData(this.country, 'name'));
      localStorage.setItem('originalCountry', Utils.getCountryData(this.country, 'name').toLowerCase());
      this.subscriptions.push(this.userService.getTranslation('translations').subscribe(res => {
        res = Utils.translationConvertor(res);
        localStorage.setItem('localLanguageData', JSON.stringify(res));
      }));
      this.subscriptions.push(this.location.getAppSettingsByCountryCode(this.country).subscribe(res => {
        this.disclaimerText = res.disclaimer;
        this.arrLanguages = res["languages"];
        localStorage.setItem('countryData', JSON.stringify(res));
      }));
    }
    else {
      this.subscriptions.push(this.location.getCountryLanguages(Utils.modifyCountryString(localStorage.getItem('gpsCountry'))).subscribe(res => {
        this.disclaimerText = res.disclaimer;
      }));
    }
  }
  setMyStyles() {
    return Utils.reverseContent();
  }

  chngOptionalLang(selLang) {
    let arrLang;
    let language;
    this.selLanguage = selLang;
    arrLang = this.arrLanguages.filter(function (item) {
      return item.code == selLang
    });
    language = arrLang[0];
    localStorage.setItem(environment.userLanguageStorageKey, JSON.stringify(language));

    this.subscriptions.push(this.userService.getTranslation('translations').subscribe(res => {
      res = Utils.translationConvertor(res);
      localStorage.setItem('localLanguageData', JSON.stringify(res));
    }));
    // this.appComponent.setDefaultLang(selLang);
  }

  nav() {
    var link;
    const countryName = Utils.modifyCountryString(localStorage.getItem('gpsCountry'));
    if (countryName) {
      link = 'home';
    } else {
      link = 'message';
    }
    this.router.navigate([`/${link}`]);
  }
  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
