import { Question } from './../../forum/question';
import { throwError as observableThrowError, BehaviorSubject, Observable } from 'rxjs';
import { retry, tap, catchError, map } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { VaccinationData } from './livestock.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GeoLocationService } from './location';
import { UserService } from './user.service';
import { Parametre } from './parametre'
const PARAMETRE = Parametre.getInstance();
import { environment } from '../../../environments/environment';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { FirestoreDataService } from "./firestore-data.service";
import Utils from "./../utils/utils";
import firebase from 'firebase/compat/app';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import {
  AngularFireStorageModule,
  AngularFireStorageReference,
  AngularFireUploadTask,
  /* StorageBucket, */
} from '@angular/fire/compat/storage';

import 'rxjs/add/operator/take';
import 'rxjs/add/operator/do';

@Injectable()
export class ForumService {
  constructor(public location: GeoLocationService,
    public toast: MatSnackBar,
    private http: HttpClient, private afs: AngularFirestore, private afstorage: AngularFireStorage,
  ) { }

  getForumData() {
    return this.http.get('assets/forum-details.json').pipe(
      map(res => res));
  }


  //////////////////////////Handle location country
  getPostDetails(pid) {
    const country = this.location.getCountry();
    var question = this.afs.doc("forumData/" + country.country + "/posts/" + pid);
    return question.snapshotChanges().map(
      changes => {
        var retData: any = changes.payload.data();
        return retData;
      });

  }

  //////////////////////////Handle location country
  /**
   * Get all post comments for admin
   * @param pid post id
   */
  getPostAnswersForAdmin(pid) {
    const country = this.location.getCountry();
    var AnswerRef = this.afs.collection("forumData/" + country.country + "/posts/").doc(pid).collection('comments', ref => ref.orderBy('cCreatedDate', "desc"));
    // var AnswerRef = this.afs.collection("forumData/" + country.country + "/posts/").doc(pid).collection('comments', ref => ref.where('publish', "==", true).orderBy('cCreatedDate', "desc"));

    return AnswerRef.snapshotChanges();
  }

  //////////////////////////Handle location country
  getPostAnswers(pid) {
    const country = this.location.getCountry();
    var AnswerRef = this.afs.collection("forumData/" + country.country + "/posts/").doc(pid).collection('comments', ref => ref.where('publish', "==", true).orderBy('cCreatedDate', "desc"));
    return AnswerRef.snapshotChanges();
  }
  ////////////////Get answer of post for current user
  getPostAnswerForUser(pid, uid) {
    const country = this.location.getCountry();
    var docref = this.afs.collection("forumData/" + country.country + "/posts/" + pid + "/comments/", ref => ref.where('uid', "==", uid));//.snapshotChanges().subscribe(res => {
    return docref.snapshotChanges();

  }
  ////////////////Get like of post for current user
  public getPostLikeForUser(pid, uid) {
    const country = this.location.getCountry();
    var docref = this.afs.collection("forumData/" + country.country + "/posts/" + pid + "/likes/", ref => ref.where('uid', "==", uid));//.snapshotChanges().subscribe(res => {
    return docref.snapshotChanges();
  }


  //////////////////////////Handle location country
  AddPost(post) {
    const country = this.location.getCountry();
    return this.afs.collection("forumData/" + country.country + "/posts").add(post);
  }

  EditPost(pid, post) {
    const country = this.location.getCountry();
    return this.afs.collection("forumData/" + country.country + "/posts").doc(pid).set({
      pCreatedDate: Date.now(),
      pDetails: post['pDetails'],
      pTitle: post['pTitle'],
      pphotoUrl: post['pphotoUrl'],
      ptag: post['ptag'],
      pType: post['pType'],
    }, { merge: true });
  }

  public DeletePost(pid, photoURL): boolean {
    let result: boolean = true;
    if (photoURL != "") {
      try { this.afstorage.storage.refFromURL(photoURL).delete(); }
      catch {

        return false;
      }
    }
    const country = this.location.getCountry();
    this.afs.collection("forumData/" + country.country + "/posts").doc(pid).delete().then(function () {
      result = true;
    }).catch(function (error) {
      alert("Error removing document: ");
      result = false;
    });
    return result;
  }

  DeletePostImage(pid) {
    const country = this.location.getCountry();
    return this.afs.collection("forumData/" + country.country + "/posts").doc(pid).update({

      pphotoUrl: null,

    }), { merge: true };
  }
  ChangePostImage(pid, ImageURL) {
    const country = this.location.getCountry();
    return this.afs.collection("forumData/" + country.country + "/posts").doc(pid).update({

      pphotoUrl: ImageURL,

    }), { merge: true };
  }

  publish_Post(pid) {
    const country = this.location.getCountry();
    return this.afs.collection("forumData/" + country.country + "/posts").doc(pid).update({

      publish: true
    }), { merge: true };
  }

  unpublish_Post(pid) {
    const country = this.location.getCountry();
    return this.afs.collection("forumData/" + country.country + "/posts").doc(pid).update({

      publish: false
    }), { merge: true };
  }


  publishPost(docPath, parentDocPath) {
    // const country = this.location.getCountry();

    return this.afs.doc(parentDocPath).update({
      publish: true
    }), { merge: true };
  }
  unpublishPost(docPath, parentDocPath) {
    this.location.getCountry();
    return this.afs.doc(parentDocPath).update({
      publish: false
    }), { merge: true };

  }

  deleteReport(docPath, parentDocPath) {
    this.afs.doc(docPath).delete();
  }


  AddQuestion(question) {
    const country = this.location.getCountry();
    return this.afs.collection("forumData/" + country.country + "/questions").add(question);
  }


  UpdateComment(pid, uid, details) {
    const country = this.location.getCountry();
    return this.afs.collection("forumData/" + country.country + "/posts/" + pid + "/comments").doc(uid).set({
      cCreatedDate: Date.now(),
      cDetails: details
    }, { merge: true });


  }

  AddComment(pid, comment, uid) {
    const country = this.location.getCountry();
    var postRef = this.afs.firestore.collection("forumData/" + country.country + "/posts").doc(pid);
    var CommentRef = postRef.collection('comments').doc(uid);
    return this.afs.firestore.runTransaction(transaction => {
      return transaction.get(postRef).then(res => {
        if (!res.exists) {
          throw "Document does not exist!";
        }
        transaction.update(postRef, {
          numComments: firebase.firestore.FieldValue.increment(1)
        });
        transaction.set(CommentRef, comment), { merge: true };
      })
    });
  }

  ReportPost(pid, uid, reply) {
    const country = this.location.getCountry();
    return this.afs.collection("forumData/" + country.country + "/posts/" + pid + "/reports").doc(uid).set(reply, { merge: true });
  }
  //this function consider location country
  ReportComment(path, uid, report) {
    return this.afs.collection(`${path}/reports`).doc(uid).set(report, { merge: true });
  }

  ReplyComment(path, uid, reply) {
    this.afs.collection(`${path}/comments`).doc(uid).set(reply), { merge: true };
    var postRef = this.afs.firestore.doc(path);
    var CommentRef = postRef.collection('comments').doc(uid);
    return this.afs.firestore.runTransaction(transaction => {
      return transaction.get(postRef).then(res => {
        if (!res.exists) {
          throw "Document does not exist!";
        }
        transaction.update(postRef, {
          numComments: firebase.firestore.FieldValue.increment(1)
        });
        transaction.set(CommentRef, reply), { merge: true };
      })
    });


  }


  UpdateReplyComment(path, uid, details) {
    return this.afs.collection(path + "/comments").doc(uid).set({
      cCreatedDate: Date.now(),
      cDetails: details
    }, { merge: true });


  }

  LikePost(pid, uid, likeDocument) {

    const country = this.location.getCountry();
    let querryPath: string = "forumData/" + country.country + "/posts/" + pid
    var likedata: any = [];
    var docref = this.afs.collection(querryPath + "/likes", ref => ref.where('uid', "==", uid)).snapshotChanges().subscribe(res => {
      var len = (res.map(doc => {
        likedata = doc.payload.doc.data();
      })).length;
      docref.unsubscribe();
      var batch = this.afs.firestore.batch();
      var postref = this.afs.doc(querryPath);
      var likeref = this.afs.collection(querryPath + "/likes").doc(uid)

      //return;
      //add
      if (len == 0) {
        batch.update(postref.ref, {
          numLikes: firebase.firestore.FieldValue.increment(1)
        }), { merge: true };;
        batch.set(likeref.ref, likeDocument), { merge: true };

      }
      else if (likedata.like == true) {
        batch.update(postref.ref, { numLikes: firebase.firestore.FieldValue.increment(-1) }), { merge: true };;
        batch.delete(likeref.ref);

      }
      else if (likedata.dislike == true) {
        batch.update(postref.ref, {
          numDislikes: firebase.firestore.FieldValue.increment(-1),
          numLikes: firebase.firestore.FieldValue.increment(1)
        }), { merge: true };;
        batch.set(likeref.ref, likeDocument), { merge: true };
      }
      batch.commit();
    })
  }

  LikeComment(querryPath, uid, likeDocument) {
    var likedata: any = [];
    var docref = this.afs.collection(querryPath + "/likes", ref => ref.where('uid', "==", uid)).snapshotChanges().subscribe(res => {
      var len = (res.map(doc => {
        likedata = doc.payload.doc.data();
      })).length;
      docref.unsubscribe();
      var batch = this.afs.firestore.batch();
      var postref = this.afs.doc(querryPath);
      var likeref = this.afs.collection(querryPath + "/likes").doc(uid)

      //return;
      //add
      if (len == 0) {
        batch.update(postref.ref, {
          numLikes: firebase.firestore.FieldValue.increment(1)
        }), { merge: true };;
        batch.set(likeref.ref, likeDocument), { merge: true };

      }
      else if (likedata.like == true) {
        batch.update(postref.ref, { numLikes: firebase.firestore.FieldValue.increment(-1) }), { merge: true };;
        batch.delete(likeref.ref);

      }
      else if (likedata.dislike == true) {
        batch.update(postref.ref, {
          numDislikes: firebase.firestore.FieldValue.increment(-1),
          numLikes: firebase.firestore.FieldValue.increment(1)
        }), { merge: true };;
        batch.set(likeref.ref, likeDocument), { merge: true };
      }
      batch.commit();
    })
  }

  DislikePost(pid, uid, disLikeDocument) {

    const country = this.location.getCountry();
    let querryPath: string = "forumData/" + country.country + "/posts/" + pid
    var likedata: any = [];
    var docref = this.afs.collection(querryPath + "/likes", ref => ref.where('uid', "==", uid)).snapshotChanges().subscribe(res => {
      var len = (res.map(doc => {
        likedata = doc.payload.doc.data();

      })).length;
      docref.unsubscribe();
      var batch = this.afs.firestore.batch();
      var postref = this.afs.doc(querryPath);
      var likeref = this.afs.collection(querryPath + "/likes").doc(uid)

      if (len == 0) {
        batch.update(postref.ref, {
          numDislikes: firebase.firestore.FieldValue.increment(1)
        }), { merge: true };;
        batch.set(likeref.ref, disLikeDocument), { merge: true };

      }
      else if (likedata.like == true) {
        batch.update(postref.ref, {
          numLikes: firebase.firestore.FieldValue.increment(-1),
          numDislikes: firebase.firestore.FieldValue.increment(1)

        }), { merge: true };;
        batch.set(likeref.ref, disLikeDocument), { merge: true };
      }
      else if (likedata.dislike == true) {

        batch.update(postref.ref, {
          numDislikes: firebase.firestore.FieldValue.increment(-1)
        }), { merge: true };;
        batch.delete(likeref.ref);
      }
      batch.commit();
    })
    //https://stackoverflow.com/questions/50755638/angularfire-firestore-check-if-a-document-exists-whilst-performing-queries
  }

  DislikeComment(querryPath, uid, disLikeDocument) {
    var likedata: any = [];
    var docref = this.afs.collection(querryPath + "/likes", ref => ref.where('uid', "==", uid)).snapshotChanges().subscribe(res => {
      var len = (res.map(doc => {
        likedata = doc.payload.doc.data();

      })).length;
      docref.unsubscribe();
      var batch = this.afs.firestore.batch();
      var postref = this.afs.doc(querryPath);
      var likeref = this.afs.collection(querryPath + "/likes").doc(uid)

      if (len == 0) {
        batch.update(postref.ref, {
          numDislikes: firebase.firestore.FieldValue.increment(1)
        }), { merge: true };;
        batch.set(likeref.ref, disLikeDocument), { merge: true };

      }
      else if (likedata.like == true) {
        batch.update(postref.ref, {
          numLikes: firebase.firestore.FieldValue.increment(-1),
          numDislikes: firebase.firestore.FieldValue.increment(1)

        }), { merge: true };;
        batch.set(likeref.ref, disLikeDocument), { merge: true };
      }
      else if (likedata.dislike == true) {

        batch.update(postref.ref, {
          numDislikes: firebase.firestore.FieldValue.increment(-1)
        }), { merge: true };;
        batch.delete(likeref.ref);
      }
      batch.commit();
    })
  }



  getUserDetails(uid) {
    //alert(uid + " *********************************");
    var user = this.afs.doc("users/" + uid);
    return user.snapshotChanges().map(
      changes => {
        var retData: any = changes.payload.data();
        return retData;
      });
  }
  ///////////////////////////////////////////////////////////

  getUserPosts(uid) {
    const country = this.location.getCountry();
    var orderByField = 'pCreatedDate';
    return this.afs.collection(`forumData/${country.country}/posts/`, ref => ref.orderBy(`${orderByField}`, 'desc')
      .where('publish', '==', true).where(`uid`, '==', `${uid}`)).snapshotChanges();

  }


  getUserAnswers(uid) {
    var orderByField = 'cCreatedDate';
    return this.afs.collectionGroup(`comments`, ref => ref.orderBy(`${orderByField}`, 'desc')
      .where('answer', '==', 'true').where(`uid`, '==', `${uid}`)).snapshotChanges();

  }

  // ========== Bakr ==============

  getPosts(selectedsection, selectedforum) {
    const country = this.location.getCountry();
    var orderByField = 'pCreatedDate';
    // Question or experience (selectedsection)
    var PostType = 'pType';
    // Citrus , date, .... (selectedforum)
    var PostTag = 'ptag';
    if (selectedsection == '0' && selectedforum == '0') {
      return this.afs.collection(`forumData/${country.country}/posts/`, ref => ref.orderBy(`${orderByField}`, 'desc')
        .where('publish', '==', true)).snapshotChanges();
    } else if (selectedsection == '0' && selectedforum != '0') {
      return this.afs.collection(`forumData/${country.country}/posts/`, ref => ref.orderBy(`${orderByField}`, 'desc')
        .where('publish', '==', true).where(`${PostTag}`, '==', `${selectedforum}`)).snapshotChanges();
    } else if (selectedsection != '0' && selectedforum == '0') {
      return this.afs.collection(`forumData/${country.country}/posts/`, ref => ref.orderBy(`${orderByField}`, 'desc')
        .where('publish', '==', true).where(`${PostType}`, '==', `${selectedsection}`)).snapshotChanges();
    }

    return this.afs.collection(`forumData/${country.country}/posts/`, ref => ref.orderBy(`${orderByField}`, 'desc')
      .where('publish', '==', true).where(`${PostType}`, '==', `${selectedsection}`)
      .where(`${PostTag}`, '==', `${selectedforum}`)).snapshotChanges();

  }

  getPostswithreports() {
    const country = this.location.getCountry();
    var reports = this.afs.collectionGroup('reports', ref => ref.where('countryName', '==', `${country.country_name}`).orderBy('rCreatedDate', 'desc')).snapshotChanges();
    return reports;

  }
  getusers() {
    const country = this.location.getCountry();
    var users = this.afs.collection('users', ref => ref.where('country_iso3', '==', `${country.country}`)).snapshotChanges(); //.orderBy('firstName').orderBy('lastName')
    return users;
  }

  // getusers_OrderedByRegDate() {
  //   const country = this.location.getCountry();
  //   var users = this.afs.collection('users', ref => ref.where('country_iso3', '==', `${country.country}`).orderBy('regDate', 'desc').limit(10)).snapshotChanges(); //.orderBy('firstName').orderBy('lastName')
  //   return users;
  // }
  getPosts_OrderedByDate() {
    const country = this.location.getCountry();
    var Posts = this.afs.collection(`forumData/${country.country}/posts/`, ref => ref.orderBy('pCreatedDate', 'desc').limit(10)).snapshotChanges();
    return Posts;
  }

  getReports_OrderedByDate() {
    const country = this.location.getCountry();
    var reports = this.afs.collectionGroup('reports', ref => ref.where('countryName', '==', `${country.country_name}`).orderBy('rCreatedDate', 'desc').limit(10)).snapshotChanges();
    return reports;
  }


  UpdateUserActive(uid, active) {
    return this.afs.collection('users').doc(uid).update({
      isActive: active
    }), { merge: true };

  }
  UpdateUserExpert(uid, expert) {
    return this.afs.collection('users').doc(uid).update({
      expert: expert
    }), { merge: true };

  }

  getForumSettings() {
    const country = this.location.getCountry();
    var fset = this.afs.doc("forumData/" + country.country + "/ForumSettings/settings");
    return fset.snapshotChanges().map(
      changes => {
        var retData: any = changes.payload.data();
        return retData;
      });
  }

  updateForumSettings(data) {
    const country = this.location.getCountry();;
    this.afs.doc("forumData/" + country.country + "/ForumSettings/settings").update(data);
  }

  addForumSettings(SettingName, settings, data) {
    const country = this.location.getCountry();
    this.afs.collection("forumData/").doc(country.country).collection(SettingName).doc(settings).set(data), { merge: true };
  }

}
