import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { InsectComponent } from "./insect";
import { AuthGuard } from "../shared/guards/auth.guard";
import { InsectDetailsComponent } from './insect-details/insect-details.component';
import { RecogniseInsectsComponent } from './recognise-insects/recognise-insects.component';

const meteoroutes: Routes = [
  { path: "insect", component: InsectComponent, canActivate: [AuthGuard] },
  { path: 'insect/:themeId/:id', component: InsectDetailsComponent, canActivate: [AuthGuard] },
  { path: "recognise-insect", component: RecogniseInsectsComponent, canActivate: [AuthGuard] },
];

@NgModule({
  imports: [RouterModule.forChild(meteoroutes)],
  exports: [RouterModule]
})
export class InsectRoutingModule { }
