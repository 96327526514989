import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from '../../../environments/environment';
import { LayoutService } from '../../../app/shared/service/layout.service';
import Utils from '../../../app/shared/utils/utils';
import { ActivatedRoute } from '@angular/router';
import { GeoLocationService } from '../../../app/shared/service/location';

@Component({
  selector: 'app-app-menu-componant',
  templateUrl: './app-menu-componant.component.html',
  styleUrls: ['./app-menu-componant.component.scss']
})
export class AppMenuComponant implements OnInit {
  @Input() data: any;
  isXs: boolean;
  isScrollDownButtonVisible: boolean;
  appSettings: any;
  constructor(private sanitizer: DomSanitizer,
    private layout: LayoutService,
    private route: ActivatedRoute,
    private location: GeoLocationService) { }

  ngOnInit(): void {
    this.isXs = this.layout.media.isActive('xs');
    const country = this.route.snapshot.params.country;
    this.location.getCountryLanguages(country).subscribe(res => {
      this.appSettings = res;
    });
  }
  getImage() {
    return this.sanitizer.bypassSecurityTrustStyle(`url(${this.appSettings.app_bck_img})`);
  }
  setMyStyles() {
    return {
      'direction': 'ltr',
      'text-align': 'left'
    };
  }

  setMyStyles1() {
    var buttonFab: any = document.querySelector('.fab-bottom-right');
    buttonFab.style.setProperty('right', '10px');
  }

  scrollToBottom() {
    if (this.checkScrollPos()) {
      document.documentElement.scrollTop += window.innerHeight; //document.documentElement.scrollHeight;
      this.isButtonVisible();
    }
  }

  isButtonVisible() {
    if ((this.data.length - 1) > 4 && this.checkScrollPos()) {
      this.isScrollDownButtonVisible = true;
    }
    else {
      this.isScrollDownButtonVisible = false;
    }
  }

  checkScrollPos() {
    return (document.documentElement.scrollHeight - window.innerHeight != document.documentElement.scrollTop);
  }
  onImgError(event, img) {
    img = Utils.routedata[img] ? Utils.routedata[img] : img;
    event.target.src = 'assets/offline/icons/desktop/' + img + '.svg';
  }
}
