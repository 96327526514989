import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { environment } from '../../../src/environments/environment';
import { FeedbackService } from '../shared/service/feedback.service';
import { TranslationService } from '../shared/service/translation.service';
import Utils from '../shared/utils/utils';
import packageJson from './../../../package.json';
export interface DialogData {
  title: string;
  Details: string;
  email: string;
  name: string;
}
@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.css']
})
export class FeedbackComponent {

  myFormGroup: UntypedFormGroup;
  public version: string = packageJson.version;
  constructor(
    public dialogRef: MatDialogRef<FeedbackComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private gaService: GoogleAnalyticsService,
    private toast: MatSnackBar,
    private translate: TranslationService, private fb: UntypedFormBuilder,
    private feedbackService: FeedbackService) {
    let emailregex: RegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    this.myFormGroup = new UntypedFormGroup({
      email: new UntypedFormControl('', [Validators.pattern(emailregex)]),
      name: new UntypedFormControl('', [Validators.minLength(2)]),
      comment: new UntypedFormControl('', [Validators.required]),
      shareMyMap: new UntypedFormControl(false)
    })
  }

  onSubmit(): void {
    if (this.myFormGroup.valid) {
      let browserName = this.detectBrowserName();
      let browserVersion = this.detectBrowserVersion();
      const originalCountry = localStorage.getItem('originalCountry');
      var apiBody = {
        "name": this.myFormGroup.controls.name.value,
        "email": this.myFormGroup.controls.email.value,
        "shareLink": this.myFormGroup.controls.shareMyMap.value ? location.href : '',
        "comment": this.myFormGroup.controls.comment.value + ' (' + originalCountry + ').',
        "buildVersion": {
          "Client_Branch": environment.production ? 'master' : 'devlop',
          "Core_Branch": 'FAO Digital Service Portfolio (' + originalCountry + '). Browser Name = ' + browserName + ' & Version = ' + browserVersion,
          "Build_Number": this.version,
          "Timestamp": Math.round(new Date().getTime() / 1000),
        }
      };
      this.feedbackService.sendFeedback(apiBody).subscribe(res => {
      })
      this.gaService.event('Feedback', 'Feedback', this.data['Details']);
      this.toast.open(this.translate.translate('feedback.success'), this.translate.translate("Post.Ok"));
      this.dialogRef.close();
    }
  }
  setMyStyles() {
    return Utils.reverseContent();
  }

  detectBrowserName() {
    const agent = window.navigator.userAgent.toLowerCase()
    switch (true) {
      case agent.indexOf('edge') > -1:
        return 'Edge';
      case agent.indexOf('edg/') > -1:
        return 'Edge (chromium based)';
      case agent.indexOf('opr') > -1 && !!(<any>window).opr:
        return 'Opera';
      case agent.indexOf('chrome') > -1 && !!(<any>window).chrome:
        return 'Chrome';
      case agent.indexOf('trident') > -1:
        return 'ie';
      case agent.indexOf('firefox') > -1:
        return 'Firefox';
      case agent.indexOf('safari') > -1:
        return 'Safari';
      default:
        return 'Other';
    }
  }

  detectBrowserVersion() {
    var userAgent = navigator.userAgent, tem,
      matchTest = userAgent.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
    if (/trident/i.test(matchTest[1])) {
      tem = /\brv[ :]+(\d+)/g.exec(userAgent) || [];
      return 'IE ' + (tem[1] || '');
    }
    if (matchTest[1] === 'Chrome') {
      tem = userAgent.match(/\b(OPR|Edge)\/(\d+)/);
      if (tem != null) return tem.slice(1).join(' ').replace('OPR', 'Opera');
    }
    matchTest = matchTest[2] ? [matchTest[1], matchTest[2]] : [navigator.appName, navigator.appVersion, '-?'];
    if ((tem = userAgent.match(/version\/(\d+)/i)) != null) matchTest.splice(1, 1, tem[1]);
    return matchTest.join(' ');
  }
}