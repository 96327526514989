import {
  Component,
  ViewChild,
  OnInit,
  ViewEncapsulation,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
  ElementRef,
  OnDestroy
} from "@angular/core";

import { UntypedFormControl } from "@angular/forms";
import { Observable, Subscription } from "rxjs";
import { map, startWith } from "rxjs/operators";
//import { SelectAutocompleteComponent } from "mat-select-autocomplete";
import { Filters } from "../../models/filters.dto";
import { CropService } from "../../services/crops.service";
import { FilterService } from "../../services/filter.service";
import { CropCalendarService } from "../../services/api/cropcalendar-api.service";
import { UserService } from "../../../../../../app/shared/service/user.service";
import { TranslationService } from "../../../../../../app/shared/service/translation.service";
import { GeoLocationService } from "../../../../../shared/service/location";
import { UpperCasePipe, LocationStrategy } from "@angular/common";
import { Router } from "@angular/router";
import { ChangeDetectorRef } from '@angular/core'
import { GoogleAnalyticsService } from "ngx-google-analytics";
import { environment } from "../../../../../../environments/environment";
import Utils from "../../../../../shared/utils/utils";
import { NgxSpinnerService } from "ngx-spinner";
import { LivestockService } from '../../../../../shared/service/livestock.service';
@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: "app-filters-pwa",
  templateUrl: "./filters-pwa.component.html",
  styleUrls: ["./filters-pwa.component.scss"],
  encapsulation: ViewEncapsulation.None
})
export class FiltersPWAComponent implements OnInit, OnDestroy {
  @Output() search = new EventEmitter<Filters>();

  @ViewChild("iconClick", { static: true }) iconClick: ElementRef;

  showAEZinfo = true;

  userFilter: Filters = {
    id: "",
    name: "",
    crops: [],
    agroEcologicalZone: []
  };

  cropGroupControl = new UntypedFormControl("");
  cropGroup = [
    {
      value: "ALL CROPS",
      viewValue: "Weather.CropCalendar.GroupCrops.AllCrops"
    },
    {
      value: "CEREALS & PSEUDOCEREALS",
      viewValue: "Weather.CropCalendar.GroupCrops.Cereals"
    },
    {
      value: "ROOT/TUBER CROPS",
      viewValue: "Weather.CropCalendar.GroupCrops.RootTuber"
    },
    {
      value: "LEGUMINOUS CROPS",
      viewValue: "Weather.CropCalendar.GroupCrops.Leguminous"
    },
    {
      value: "OIL AND SUGAR CROPS",
      viewValue: "Weather.CropCalendar.GroupCrops.OilSugar"
    },
    {
      value: "VEGETABLES",
      viewValue: "Weather.CropCalendar.GroupCrops.Vegetables"
    },
    {
      value: "OTHER CROPS",
      viewValue: "Weather.CropCalendar.GroupCrops.Other"
    }
  ];

  //@ViewChild(SelectAutocompleteComponent, { static: true })
  //multiselect: SelectAutocompleteComponent;

  /* countries */
  country = new UntypedFormControl(null);

  options = [];
  filteredOptions: Observable<string[]>;

  optionsZones = [];

  name;

  listCrops = [];
  dataCrops = [];

  groupSelectedCrop = [];

  inicialZone = "";

  listAllCrops = [];

  labelPlaceholderCountry;
  storageCountryList = [];
  showtgps: boolean;
  lang_code: any;
  subscriptions: Subscription[] = [];
  supportedLang = ['en', 'ar', 'es', 'ru', 'fr', 'zh'];
  constructor(
    private cropService: CropService,
    private filterService: FilterService,
    private cropcalendarService: CropCalendarService,
    private userService: UserService,
    private location: GeoLocationService,
    private url: LocationStrategy, private reference: ChangeDetectorRef,
    private gaService: GoogleAnalyticsService,
    private spinner: NgxSpinnerService,
    private livestockService: LivestockService
  ) {
    this.subscriptions.push(this.userService.changeLanguage$.subscribe(() => {
      this.getAllContries();
    }));
  }

  ngOnInit() {
    this.getAllContries();
    this.filterCached();
    this.lang_code = JSON.parse(localStorage.getItem(environment.userLanguageStorageKey)).code;
    this.filteredOptions = this.country.valueChanges.pipe(
      map(value => this._filter(value))
    );

    this.subscriptions.push(this.location.country$.subscribe(country => {
      const obj = this.options.find(option => option.id === country);
      if (obj) {
        this.country.setValue(obj);
        this.selectedCountry(obj);
      }
    }));
  }

  ngOnDestroy() {
    if (
      this.url.path() === "/livestock" ||
      this.url.path() === "/agrimarket" ||
      this.url.path() === "/plant-pests" ||
      this.url.path() === "/livese-nutrifood/categorietock" ||
      this.url.path() === "/home" ||
      this.url.path() === "/forum" ||
      this.url.path() === "/disclaimer"
    ) {
      this.initialValues();
    }
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  initialValues() {
    let obj = { id: "", name: "" };
    this._filter("");
    this.selectedCountry("");
    this.filterService.setZone("");
    this.showAEZinfo = true;
  }

  getAllContries() {
    var ref = this;
    this.subscriptions.push(this.cropService.getAllCountries().subscribe((data: Array<any>) => {
      if (data) {
        ref.options = data;
        ref._filter("");
        let countryCached = ref.filterService.getCountry();
        if (countryCached) {
          countryCached = ref.options.find(
            countrySel => countrySel.id === countryCached.id
          );
          ref.filterService.setCountry(countryCached);
          ref.country.setValue(countryCached);
        }
        var gps = localStorage.getItem('gpsCountry').toLowerCase();
        var oc = localStorage.getItem('originalCountry').toLowerCase();
        var c = ref.location.getCountry();

        let i = ref.options.filter((item) => {
          if (item.id.toLowerCase() == Utils.getCountryData(gps, 'code').toLowerCase()) {
            return item
          }
        });

        if (gps !== 'fao' && i.length > 0) {
          this.showtgps = false;
          var objCountry = {
            "id": i[0]['id'],
            "name": i[0]['name']
          };
          this.selectedCountry(objCountry);
        } else if (gps === 'fao') {
          this.showtgps = true;
        } else {
          this.showtgps = true;
        }
        this.reference.detectChanges();
      }
    }));
  }

  filterCached() {
    this.showAEZinfo = true;
    const countryCached = this.filterService.getCountry(); //obj
    const ZoneCached = this.filterService.getZone(); // string

    if (countryCached) {
      this.country.setValue(countryCached);
      this.selectedCountry(countryCached);
    }

    if (ZoneCached) {
      // id agroEcologicalZone
      this.inicialZone = ZoneCached;
      this.onSelectZone(ZoneCached);
    }
  }

  filterCropGroup(cropGroup) {
    this.listAllCrops = this.filterService.getCropList();

    const groupSelectedCrop = [];

    this.listAllCrops.forEach(item => {
      const familycrop = item.cropGroup;

      if (familycrop === cropGroup.value) {
        groupSelectedCrop.push(item);
      }
      if (cropGroup.value === "ALL CROPS") {
        groupSelectedCrop.push(item);
      }
    });

    this.groupSelectedCrop = groupSelectedCrop;
    this.listAllCrops = this.groupSelectedCrop;
  }

  // en el selector de country compara el valor del selector para obtener el dato
  displayCountry(value: any): string {
    return value && typeof value === "object" ? value.name : value;
  }

  // Country selected
  selectedCountry(objCountry) {
    this.spinner.show();
    this.userFilter.id = objCountry.id;
    this.userFilter.name = objCountry.name;
    // recuperar los datos de seleccion
    this.filterZonesByCountry(objCountry.id);
    this.filterService.setCountry(objCountry);
  }

  filters() {
    const filters = Object.assign({}, this.userFilter);
    this.search.emit(filters);
    this.showAEZinfo = false;
  }

  onSelectZone(ValuezoneSelected) {
    this.spinner.show();
    this.userFilter.agroEcologicalZone = [];
    this.showAEZinfo = false;
    this.filterService.setZone(ValuezoneSelected);
    this.userFilter.agroEcologicalZone.push(ValuezoneSelected);

    this.subscriptions.push(this.cropcalendarService
      .getAllCropsByFilter(this.userFilter.id, ValuezoneSelected)
      .subscribe(crops => {
        const orignalCountry = JSON.parse(localStorage.getItem('countryData'));
        const country = orignalCountry.country == "FAO" ? Utils.getCountryData(this.userFilter.id, 'code3') : orignalCountry.country;
        this.subscriptions.push(this.cropcalendarService.getCropsFromStorage(country).subscribe(response => {
          this.spinner.hide();
          var arr = [];
          var arrImg = [];
          var arrStatus = [];
          var arrOrder = [];
          response.forEach(item => {
            arr[item.crop_id] = (item.name[this.lang_code]) ? item.name[this.lang_code] : '';
            arrImg[item.crop_id] = (item.img) ? item.img : '';
            arrStatus[item.crop_id] = item.status != undefined ? item.status : true;
            arrOrder[item.crop_id] = (item.order) ? item.order : 999;
          });
          // Collect all the countries from storage json
          var availableCountry = response;
          if (availableCountry.length > 0) {
            var arrStorageCrops = response.map((item) => item.crop_id);
            var countryCrops = crops.filter(items => {
              if (items && arrStorageCrops.indexOf(items['id']) !== -1) {
                items['name'] = (arr[items['id']]) ? arr[items['id']] : items['name'];
                items['linkImage'] = (arrImg[items['id']]) ? arrImg[items['id']] : items['linkImage'];
                items['status'] = (arrStatus[items['id']] != undefined) ? arrStatus[items['id']] : true;
                items['order'] = (arrOrder[items['id']]) ? arrOrder[items['id']] : 999;
                return items;
              }
            });
            countryCrops = Utils.sort(countryCrops, 'order');
            this.listAllCrops = countryCrops;
            this.filterService.setCropList(countryCrops);
            this.filters();
          } else {
            crops = crops.filter(crop => crop);
            this.listAllCrops = crops;
            this.filterService.setCropList(crops);
            this.filters();
          }
        },
          (err) => {
          }
        ));
      },
        (err) => {
        }));

    this.filters();
  }

  private filterZonesByCountry(id) {
    this.subscriptions.push(this.cropService.getAllAEZByCountry(id).take(1).subscribe(aez => {
      this.subscriptions.push(this.cropcalendarService.getZonesFromStorage().take(1).subscribe(response => {
        if (this.supportedLang.includes(this.lang_code))
          this.spinner.hide();
        var zoneIdArr = [];
        var zoneNameArr = [];
        var mainAgriPracticeArr = [];
        var geographicalDesArr = [];
        var adminUnitsArr = [];

        response.forEach(item => {
          zoneIdArr[item.zone_id] = (item.zone_id) ? item.zone_id : '';
          zoneNameArr[item.zone_id] = (item.name) ? item.name[this.lang_code] : '';
          mainAgriPracticeArr[item.zone_id] = (item.main_agricultural_practices) ? item.main_agricultural_practices[this.lang_code] : '';
          geographicalDesArr[item.zone_id] = (item.geographical_description) ? item.geographical_description[this.lang_code] : '';
          adminUnitsArr[item.zone_id] = (item.administratrative_units) ? item.administratrative_units : '';
        });

        if (response.length > 0) {
          var arrZones = response.map((item) => item.zone_id);
          if (aez) {
            var aezFinalArr = aez.filter(items => {
              if (arrZones.indexOf(items['zone_id']) !== -1) {
                items['zone_id'] = (zoneIdArr[items['zone_id']]) ? zoneIdArr[items['zone_id']] : items['zone_id'];
                items['name'] = (zoneNameArr[items['zone_id']]) ? zoneNameArr[items['zone_id']] : items['name'];
                items['main_agricultural_practices'] = (mainAgriPracticeArr[items['zone_id']]) ? mainAgriPracticeArr[items['zone_id']] : items['main_agricultural_practices'];
                items['geographical_description'] = (geographicalDesArr[items['zone_id']]) ? geographicalDesArr[items['zone_id']] : items['geographical_description'];
                items['administratrative_units'] = (adminUnitsArr[items['zone_id']]) ? adminUnitsArr[items['zone_id']] : items['administratrative_units'];
              }
              return items;
            });
          }
          if (this.supportedLang.includes(this.lang_code)) {
            this.optionsZones = aezFinalArr;
          } else {
            let data = [];
            aezFinalArr.forEach(res => {
              data.push(res.name)
            });
            this.subscriptions.push(this.livestockService.getTranslation({
              "q": data,
              "target": this.lang_code
            }).subscribe(res => {
              res['data']['translations'].forEach((res, index) => {
                aezFinalArr[index].name = res.translatedText;
              });
              this.spinner.hide();
              this.optionsZones = aezFinalArr;
            },
              err => {
                this.optionsZones = aezFinalArr;
                this.spinner.hide();
              }));
          }
          const ZoneCached = this.filterService.getZone();
          this.inicialZone = ZoneCached;
        } else {
          if (this.supportedLang.includes(this.lang_code)) {
            this.optionsZones = aez;
          } else {
            let data = [];
            aez.forEach(res => {
              data.push(res.name)
            });
            this.subscriptions.push(this.livestockService.getTranslation({
              "q": data,
              "target": this.lang_code
            }).subscribe(res => {
              res['data']['translations'].forEach((res, index) => {
                aez[index].name = res.translatedText;
              });
              this.spinner.hide();
              this.optionsZones = aez;
            },
              err => {
                this.optionsZones = aez;
                this.spinner.hide();
              }));
          }
          const ZoneCached = this.filterService.getZone();
          this.inicialZone = ZoneCached;
        }
      },
        (err) => {
        }));
    }));
  }

  private _filter(value: string | object): string[] {
    let filterValue;

    if (typeof value === "object") {
      filterValue = value["name"].toLowerCase();
    } else {
      filterValue = value.toLowerCase();
    }

    return this.options.filter(
      option => option.name.toLowerCase().indexOf(filterValue) === 0
    );
  }
  ga(label) {
    this.gaService.event('Crop_Details', 'Page_Navigation', label);
  }
  getThumbRelPath(filename: string): string {
    if (!filename) {
      return '';
    }
    if (filename.includes(environment.cropImageStorage))
      return filename.replace(/^(.+\/)(.+)\.(.+)$/, '$1thumbs%2F$2_65x200.$3');
    else
      return filename;
  }
}
