import { UserDetailsComponent } from './admin/user-details/user-details.component';
import { AdminComponent } from './admin/admin.component';
import { SectionsComponent } from './admin/sections/sections.component';
import { SubforumsComponent } from './admin/subforums/subforums.component';
import { ForumsettingsComponent } from './admin/forumsettings/forumsettings.component';
import { UsersComponent } from './admin/users/users.component';
import { ReportsComponent } from './admin/reports/reports.component';
import { DashboardComponent } from './admin/dashboard/dashboard.component';
import { ForumhomeComponent } from './forumhome/forumhome.component';
import { AuthGuard } from './../shared/guards/auth.guard';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ForumComponent } from './forum';
import { AddQuestionComponent } from './add-question/add-question.component';
import { QuestionDetailsComponent } from './question-details/question-details.component';
import { UserpostComponent } from './userpost/userpost.component';

const meteoroutes: Routes = [
  { path: 'forum', component: ForumComponent, canActivate: [AuthGuard] },
  { path: 'forumhome', component: ForumhomeComponent, canActivate: [AuthGuard] },
  { path: 'forum/admin', component: AdminComponent, canActivate: [AuthGuard] },
  { path: 'forum/admin/dashboard', component: DashboardComponent, canActivate: [AuthGuard] },
  { path: 'forum/admin/reports', component: ReportsComponent, canActivate: [AuthGuard] },
  { path: 'forum/admin/reports/:id', component: ReportsComponent, canActivate: [AuthGuard] },
  { path: 'forum/admin/users', component: UsersComponent, canActivate: [AuthGuard] },
  { path: 'forum/admin/forumsettings', component: ForumsettingsComponent, canActivate: [AuthGuard] },
  { path: 'forum/admin/subforums', component: SubforumsComponent, canActivate: [AuthGuard] },
  { path: 'forum/admin/sections', component: SectionsComponent, canActivate: [AuthGuard] },
  { path: 'forum/admin/user-details/:id', component: UserDetailsComponent, canActivate: [AuthGuard] },
  { path: 'forum/post/add', component: AddQuestionComponent, canActivate: [AuthGuard] },
  { path: 'forum/post/edit/:pid', component: AddQuestionComponent, canActivate: [AuthGuard] },
  { path: 'forum/post-details/:id', component: QuestionDetailsComponent, canActivate: [AuthGuard] },
  { path: 'forum/userpost', component: UserpostComponent, canActivate: [AuthGuard] },
  { path: 'forum/userpost/:Userid', component: UserpostComponent, canActivate: [AuthGuard] }
];

@NgModule({
  imports: [RouterModule.forChild(meteoroutes)],
  exports: [RouterModule],
})
export class ForumRoutingModule { }
