import { ImageStorageService } from './shared/service/img-storage.service';
import { MediaObserver } from '@angular/flex-layout';
import { UserService } from './shared/service/user.service';
import { AuthService } from './shared/service/auth.service';
import { Router } from '@angular/router';
import { Component, OnInit, EventEmitter, Input, Output, OnDestroy } from '@angular/core';
import { GeoLocationService } from './shared/service/location';
import { environment } from './../environments/environment';
import { Location } from '@angular/common';
import Utils from './shared/utils/utils';
import { DownloadOfflineModeDialogComponent } from './offline-support/download-offline-mode-dialog/download-offline-mode-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { ImageService } from './shared/component/image-modal/image.service';
import { Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PandemicReportComponent } from "./pandemic-report/pandemic-report.component";
import { TranslationService } from "./shared/service/translation.service";
@Component({
  selector: 'app-toolbar',
  template: `
    <mat-toolbar color='primary' class='white-text'>

    <!-- Back button for sub category -->
    <button class="toolbar-back-button" mat-icon-button (click)="goBack()" *ngIf="(submenubar == 'true')||(backbar=='true')">
        <mat-icon>arrow_back</mat-icon>
    </button>
    <!-- End -->

    <!-- Menu for all modules -->
    <button *ngIf="(modulePage == 'true') && (submenubar == 'false') && !distribution" mat-icon-button [matMenuTriggerFor]="appMenu" id="side-menu-bar">
        <mat-icon>menu</mat-icon>
    </button>
    <mat-menu #appMenu="matMenu" backdropClass="sMenu">
        <app-menu></app-menu>
    </mat-menu>
    <!-- End -->

    <!-- Main logo  -->
    <img name="mainLogo" *ngIf="(submenubar == 'false')" alt="logo" src="assets/fao-logo-white.svg" style="width:150px;height:29px;" (click)="distribution ? false : nav('home')">
    <!-- End -->

    <!-- govt logo for jordan only -->
    <img alt="logo" class="govt_logo" [src]="appSettings.app_logo" *ngIf="appSettings?.app_logo && !ifOffline">
    <!-- End -->

    <div style='flex: 1 1 auto'></div>

    <!-- Application title-->
    <ng-template [ngIf]="(menubar == 'true')">
    <div *ngFor="let l of arrApplications; trackBy:identify" [ngStyle]="setMyStyles()" [hidden]="l.status != undefined && !l.status">
      <a fxHide.xs mat-button (click)="navThemes(l)" id="{{l.template_id}}">
        <img  class="menu-icon menu-margin" [src]="l.logo_img" (error)="onImgError($event,l)" />
        <span fxHide.md fxHide.sm>{{l.name | localTranslate | capitalize }}</span>
      </a>
    </div>
    </ng-template>
    <!-- End -->
    <ng-template [ngIf]="(menubar == 'true')">
        <button fxHide.xs mat-icon-button  [matMenuTriggerFor]="extraMenu" id="extraMenu" *ngIf="extraMenuList.length">
            <mat-icon>expand_more</mat-icon>
        </button>
    </ng-template>
    <mat-menu #extraMenu class="extraMenu">
        <div *ngFor="let l of extraMenuList">
            <span>
                <button *ngIf="l.id != 'registration.userprofiletitle'" mat-menu-item (click)="navThemes(l)" id="{{l.template_id}}">
                    <img  class="menu-icon disclaimer-text" [src]="l.logo_img" (error)="onImgError($event,l)" />
                    <span>{{l.name | localTranslate | capitalize}}</span>
                </button>
            </span>
        </div>
    </mat-menu>
    <!-- Module title -->
    <div *ngIf="(menubar == 'false')" class="module-title-w">
    <h4 *ngIf="!media.isActive('xs') || submenubar == 'true'" class="module-title">{{title | localTranslate | capitalize}}</h4>
    </div>
    <!-- End -->

    <div fxHide.xs class="toolbar-flex"></div>
    <div style='flex: 1 1 auto'></div>

    <!-- Forum user button -->
    <button mat-icon-button (click)="GoUserPost()" *ngIf="(forumuser == 'true')">
    <mat-icon>account_circle</mat-icon>
    </button>
    <!-- End -->

    <!-- Translate button -->
    <button mat-icon-button *ngIf="countryCode != 'EGY'" (click)="nav('select-language')">
      <mat-icon>translate</mat-icon>
    </button>
    <!-- End -->

    <!-- User profile menu -->
    <button mat-icon-button [matMenuTriggerFor]="userMenu" id="profile-menu" *ngIf="!distribution">
      <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu #userMenu x-position="before" class="uMenu">
        <div *ngFor="let l of arrProfileMenu">
            <span>
                <button *ngIf="(l.id == 'registration.userprofiletitle') && authService.isLoggedIn" mat-menu-item (click)="nav(l.id)">
                    <img  class="menu-icon disclaimer-text" [src]="l.img_path"/>
                    <span>{{l.name | localTranslate | capitalize}}</span>
                </button>
                <button *ngIf="l.id != 'registration.userprofiletitle'" mat-menu-item (click)="nav(l.id)">
                    <img  class="menu-icon disclaimer-text" [src]="l.img_path"/>
                    <span>{{l.name | localTranslate | capitalize}}</span>
                </button>
            </span>
        </div>
        <div>
            <button *ngIf="authService.isLoggedIn" mat-menu-item (click)='logOut()'>
                <mat-icon>exit_to_app</mat-icon>
                <span>{{'Home.Logout' | localTranslate | capitalize}}</span>
            </button>
        </div>
        <div>
            <button mat-menu-item (click)='openOfflineModeDialog()'>
                <mat-icon>download_for_offline</mat-icon>
                <span>{{'Offline.DownloadOfflineData' | localTranslate | capitalize}}</span>
            </button>
        </div>
    </mat-menu>
    <!-- End -->
    </mat-toolbar>
    `,
  styles: [

    `
        mat-toolbar{
            position: fixed;
          }

          .disclaimer-text {
              margin-right: 16px;
              vertical-align: middle;
          }
        @media (min-width: 425px) {
          .govt_logo {
            height:auto;
            width:auto;
            padding-left: 0.9rem;
            max-height:2.9rem;
            max-width: 8rem;
            }
         }
        @media (max-width: 424px) {
            .module-title-w{
                max-width:80%
            }
            .module-title {
                min-width: 100px;
                text-overflow: ellipsis;
                max-width: 130px;
                overflow: hidden;
            }
            .govt_logo {
                height:auto;
                width:auto;
                padding-left: 0.9rem;
                max-height:2.9rem;
                max-width: 6rem !important;
            }
}
        `
  ]
})

export class ToolbarComponent implements OnInit, OnDestroy {
  user: any;
  logStat: Boolean;
  @Input() title: String;
  public choiceOFDataHidden: Boolean = false;
  countryCode: String = "SN";
  @Output() updateCountry = new EventEmitter<Boolean>();
  public arrApplications: any;
  lgCode = '';
  @Input() menubar: String;
  @Input() submenubar: String;
  @Input() backbar: String;
  @Input() forumuser: String;
  @Input() modulePage: String;
  arrLang: any = [];
  public arrProfileMenu: any;
  @Input() strDeepLink: String;
  public arrPageTitles: any;
  sub: any;
  ifOffline: boolean;
  appSettings: any;
  extraMenuList: any = [];
  teamId: any;
  getCroodDetails: boolean;
  distribution: boolean;
  subscriptions: Subscription[] = [];
  isProfilePage = false;
  lang_code = '';
  constructor(public location: GeoLocationService, public uService: UserService,
    public media: MediaObserver,
    public router: Router,
    public userService: UserService,
    public authService: AuthService,
    public imgService: ImageStorageService,
    private loc: Location,
    public dialog: MatDialog,
    private gaService: GoogleAnalyticsService,
    private imgservice: ImageService,
    public toast: MatSnackBar,
    public mddialog: MatDialog,
    private translationService: TranslationService
  ) {
    this.checkLang();
    //remove useless GEOINFORMATION refresh
    //this.detect();
    localStorage.setItem('currentUrl', this.router.url.split('/')[1]);
  }
  ngOnInit() {

    this.lang_code = JSON.parse(localStorage.getItem(environment.userLanguageStorageKey)).code;
    // check if offline
    this.subscriptions.push(this.imgservice.ifOffline.subscribe(res => {
      this.ifOffline = res;
    }));
    let country = this.location.getCountry();
    this.countryCode = country ? country.country : 'SNE';
    this.imgService.loadLogo();
    this.logStat = this.userService.isLogged();
    if (this.logStat) {
      this.user = JSON.parse(this.userService.getUser());
    }

    let countryData = JSON.parse(localStorage.getItem('countryData'));
    this.subscriptions.push(this.location.countryData$.subscribe(data => {
      if (data && data.country_code_2 !== countryData.country_code_2) {
        countryData = data;
        this.sub.unsubscribe();
        this.getData();
      }
    }));
    this.getData();
    this.distribution = sessionStorage.getItem('distribution') == 'true' ? true : false;
    this.isProfilePage = location.pathname == '/profile' ? true : false;
  }

  getData() {
    const gpsCountry = Utils.modifyCountryString(localStorage.getItem('gpsCountry'));
    this.subscriptions.push(this.location.getCountryLanguages(gpsCountry).subscribe(res => {
      this.appSettings = res;
    }));
    this.arrProfileMenu = JSON.parse(localStorage.getItem('localProfileData'));
    if (localStorage.getItem('appThemes')) {
      //this.arrApplications = JSON.parse(localStorage.getItem('appThemes'));
      this.themeSetting(JSON.parse(localStorage.getItem('appThemes')));
    }
    // this.arrProfileMenu = translation.filter(_ => _.type == 'profile_menu');
    this.subscriptions.push(this.uService.getDataUsingCountry('themes', 'order').subscribe(res => {
      let team = null;
      if (res.filter(_ => _.template_id == 'team').length) {
        team = res.filter(_ => _.template_id == 'team')[0];
        this.arrProfileMenu = this.arrProfileMenu.filter(_ => _.id != 'home.teamlisttitle');
        this.arrProfileMenu.push({
          "name": team.name,
          "id": "home.teamlisttitle",
          "img_path": team.desktop_icon_img ? team.desktop_icon_img : team.svg_icon,
          "country": team.country
        });
        localStorage.setItem('team_data', JSON.stringify(team));
        res = res.filter(_ => _.template_id != 'team');
      }
      res = res.filter(_ => _['status'] == undefined || _['status']);
      this.getCroodDetails = false;
      this.districtThemeSetting(res);
    }));
  }

  openOfflineModeDialog(): void {
    const dialogRef = this.dialog.open(DownloadOfflineModeDialogComponent, {
      width: '250px', disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  nav(link) {
    link = Utils.routedata[link] ? Utils.routedata[link] : link;
    this.gaService.event('Navbar_Theme_Navigation_Click', 'Theme_Navigation', link);
    this.router.navigate([link]);
  }

  navThemes(data) {
    let link = Utils.routedata[data.template_id] ? Utils.routedata[data.template_id] : data.template_id;
    this.gaService.event('Navbar_Theme_Navigation_Click', 'Theme_Navigation', link);

    //TODO _ remove this once finalized the implementation of farmer-practicies
    let template_id_tmp = data?.template_id_tmp;

    if (link === 'e-nutrifood')
      this.router.navigate([`/e-nutrifood/categories`, data.id]);
    else if (link === 'administrative')
      this.router.navigate([`/administrative/categories`, data.id]);
    else if (link === 'livestock')
      this.router.navigate([link, data.id]);
    else if (template_id_tmp === 'family_practices')
      this.router.navigate(['/family-practices/categories', data.id]);
    else if (link === 'animal_feed')
      this.router.navigate(['/animal-feed/categories', data.id]);
    else if (link === 'insect') {
      this.router.navigate(['/insect']);
      localStorage.setItem('WeatherPopupStatus', 'true');
    } else if (link === 'survey') {
      this.openPandemicReportDialog();
    } else
      this.router.navigate([link]);
  }
  openPandemicReportDialog() {
    const dialogRef = this.mddialog.open(PandemicReportComponent, {
      width: '400px',
      data: {
        title: this.translationService.translate('reports.pandemic_title')
      }
    });
  }

  setMyStyles() {
    let styles = {
      'direction': 'ltr',
      'text-align': 'left'
    };
    return styles;
  }
  onImgError(event, theme) {
    if (!theme.isImageError) {
      theme.isImageError = true;
      let img = Utils.routedata[theme.template_id] ? Utils.routedata[theme.template_id] : theme.template_id;
      if (!this.ifOffline)
        event.target.src = 'assets/offline/icons/desktop/' + img + '.svg';
    }
  }

  public goBack() {
    if (this.strDeepLink) {
      this.router.navigate(['/weather']);
    } else if (this.isProfilePage) {
      this.router.navigate(['/forum']);
    } else {
      this.loc.back();
      localStorage.setItem('WeatherPopupStatus', 'false');
    }
    //this.loc.back();
  }

  public GoUserPost() {
    this.router.navigate(['forum/userpost']);

  }


  checkLang() {
    //this.location.getLatestLocation();
    const countryName = Utils.modifyCountryString(localStorage.getItem('gpsCountry'));
    if (countryName) {
      this.subscriptions.push(this.location.getCountryLanguages(countryName).subscribe(res => {
        if (res != undefined) {
          if (this.countryCode == 'EGY') {
            res['languages'] = res['languages'].filter(_ => _.code != 'en');
          }
          if (res['languages'].length > 1) {
            this.langExist(res['languages']);
          } else {
            //Set default language
            this.selAvailableLang(res['languages'][0]);
          }
        } else {
          // Set default country to fao
          localStorage.setItem('gpsCountry', 'fao');
          this.subscriptions.push(this.location.getCountryLanguages('fao').subscribe(res => {
            if (res['languages'].length > 1) {
              this.langExist(res['languages']);
            } else {
              //Set default language
              this.selAvailableLang(res['languages'][0]);
            }
          }));
        }
      }));
    }
  }

  selAvailableLang(language) {
    if (this.userService.isLogged()) {
      //const user: { [key: string]: any } = {};
      var user: { [key: string]: any };
      if (localStorage.getItem("user") === null) {
        user = {};
      }
      else {
        user = JSON.parse(localStorage.getItem("user"));
      }
      user.lang = language.language_code;
      user.langId = language.language_id;
      this.userService.saveToLocalStorage(JSON.stringify(user));
    }
    this.userService.saveLangId(language.language_id);
    // this.appComponent.setDefaultLang(language.code);
    localStorage.setItem(environment.userLanguageStorageKey, JSON.stringify(language));
  }

  langExist(langResponse) {
    var defLanguage;
    const langInfo = JSON.parse(localStorage.getItem(environment.userLanguageStorageKey));
    langResponse.forEach(element => {
      this.arrLang.push(element.code);
      if (element.default_language) {
        defLanguage = element;
      }
    });
    // If language is not available redirect to select language page
    if (!(this.arrLang.indexOf(langInfo.code) > -1)) {
      this.selAvailableLang(defLanguage);
    }
  }
  themeSetting(res) {
    res = res.filter(_ => _['status'] == undefined || _['status']);
    let maxLength = 6;
    if (res.length >= maxLength) {
      let nameString = '';
      res.slice(0, maxLength).forEach(_ => {
        nameString = nameString + _.name[this.lang_code];
      });
      if (nameString.length > 100) {
        let val = Math.round((nameString.length - 100) / 20);
        maxLength = maxLength - val;
      }
    }
    this.arrApplications = res.length > maxLength ? res.slice(0, maxLength) : res;
    this.extraMenuList = res.length > maxLength ? res.slice(maxLength, res.length) : [];
    localStorage.setItem('appThemes', JSON.stringify(res));
    // localStorage.setItem('arrApplications', JSON.stringify(this.arrApplications));
    let arrUrl = (this.router.url).split('/');
    var strAppLabelName = arrUrl[1].toLowerCase();
    this.arrPageTitles = res.filter(items => strAppLabelName == items.template_id)[0];
    this.title = this.title ? this.title : (this.arrPageTitles) ? this.arrPageTitles['template_id'] : '';
    const arrApplicationList = res.map(items => {
      return Utils.routedata[items['template_id']] ? Utils.routedata[items['template_id']] : items['template_id'];
    });

    if (arrApplicationList.length > 0) {
      localStorage.setItem('arrAppList', JSON.stringify(arrApplicationList));
      this.authService.isAppExistSubject.next(arrApplicationList);
    } else {
      localStorage.setItem('arrAppList', JSON.stringify(null));
      this.authService.isAppExistSubject.next(null);
    }
  }
  districtThemeSetting(themes) {
    if (this.userService.districtId) {
      this.filterDistric(themes, this.userService.districtId);
      this.themeSetting(themes);
      return;
    }
    var coords = JSON.parse(localStorage.getItem("coords"));
    if (coords) {
      this.subscriptions.push(this.uService.getDistrictDetails(coords[0], coords[1]).subscribe((res: any) => {
        if (res && res.length) {
          this.filterDistric(themes, res[0].district_id);
        } else {
          this.userService.districtId = 'Not_Available';
        }
        this.themeSetting(themes);
      }, error => {
        this.themeSetting(themes);
      }));
    } else {
      this.subscriptions.push(this.location.locality$.subscribe(location => {
        if (location.originalCoords && location.originalCoords.lat && location.originalCoords.long && !this.getCroodDetails) {
          this.uService.getDistrictDetails(location.originalCoords.lat, location.originalCoords.long).subscribe((res: any) => {
            this.getCroodDetails = true;
            if (res && res.length)
              this.filterDistric(themes, res[0].district_id);
            else {
              this.userService.districtId = 'Not_Available';
              this.themeSetting(themes);
            }
          }, error => {
            this.themeSetting(themes);
          });
        }
      }));
    }
  }
  filterDistric(themes, id) {
    this.userService.districtId = id;
    this.userService.updateDistric(id);
    themes.forEach(element => {
      element.status = element.district_list && element.district_list.length ?
        element.district_list.includes(id) ? true : false : true;
    });
  }

  detect() {
    this.location.localise();
  }
  identify(index, item) {
    return item.id;
  }
  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe())
  }
  logOut() {
    let user = JSON.parse(localStorage.getItem('user'));
    if (user && this.isProfilePage) {
      if (!user.nickName) {
        //this.router.navigate(['profile']);
        this.toast.open('Please update your profile!', 'OK', { duration: 10000 });
        return;
      }
    }
    this.authService.SignOut()
  }
}
