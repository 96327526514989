import { Component, Input, OnInit } from '@angular/core';
import Utils from '../../shared/utils/utils';

@Component({
  selector: 'app-app-markets-preview',
  templateUrl: './app-markets-preview.component.html',
  styleUrls: ['./app-markets-preview.component.scss']
})
export class AppMarketsPreviewComponent implements OnInit {
  @Input() data: any;
  @Input() appName: any;
  searchText = { commodity_label: '' };
  constructor() { }

  ngOnInit(): void {
  }

  setMyStyles() {
    return {
      'direction': 'ltr',
      'text-align': 'left'
    };
  }
}
