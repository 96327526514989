import { AudioService } from './audio.service';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
//import { VgAPI } from 'videogular2/core';
@Component({
  selector: 'app-audio',
  template: `
   
           <div class="player-wrapper">

    
    <vg-player>
      <audio #media [vgMedia]="media" id="singleVideo" preload="auto" controls autoplay>
        <source [src]="audioSrc" type="audio/mp4">
      </audio>
    </vg-player>

  </div>

    `
})

export class AudioComponent implements OnInit, OnDestroy {
  @Input()
  audioSrc;

  //api: VgAPI;
  constructor(private audio: AudioService) { }

  ngOnInit() {
  }

  closeMediaPlayer() {
    this.audio.closeMediaPlayer();
  }

  ngOnDestroy() {

  }
}
