import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Parametre } from './parametre';
import { environment } from '../../../environments/environment';
const PARAMETRE = Parametre.getInstance();

@Injectable()
export class FeedbackService {
    constructor(private http: HttpClient) { }
    sendFeedback(data) {
        var url = environment.feedbackApi;
        return this.http.post(url, data).pipe();
    }
}
