import { Component, Input, OnInit, OnDestroy } from "@angular/core";
import { WeatherService } from "../../shared/service/weather.service";
import { Location } from "@angular/common";
import { ActivatedRoute } from "@angular/router";
import { Subscription, Observable } from "rxjs";
import { AudioService } from "../../shared/component/audio/audio.service";
import { VideoService } from "../../shared/component/video/video.service";
import { ImageService } from "../../shared/component/image-modal/image.service";
import { Parametre } from "../../shared/service/parametre";
const PARAMETRE = Parametre.getInstance();
import { UserService } from "../../shared/service/user.service";
import { environment } from "../../../environments/environment";
import Utils from "../../shared/utils/utils";
import { LinkService } from './../../shared/component/link/link.service';

@Component({
	selector: "app-crop",
	templateUrl: "./crop.component.html",
	styleUrls: ["./crop.component.scss"]
})
export class CropComponent implements OnInit, OnDestroy {
	public objectKeys = Object.keys;
	arrCropDetails: { [key: string]: any } = {};
	strCommodityName: any;
	public loader: Boolean = false;
	public generalTabData: any;
	public txtSpeech: any;
	appData: any;

	constructor(
		private route: ActivatedRoute,
		private location: Location,
		public weather: WeatherService,
		private audio: AudioService,
		private videoService: VideoService,
		private imageService: ImageService,
		private linkService: LinkService
	) {
	}

	ngOnInit() {
		UserService.langCodeTextSpeech = JSON.parse(localStorage.getItem(environment.userLanguageStorageKey)).text_to_speech_code;
		this.txtSpeech = UserService.langCodeTextSpeech;
		const themes = JSON.parse(localStorage.getItem('appThemes'));
		this.appData = themes.filter(_ => _.template_id === 'weathercropcalendar')[0];
		this.route.params.subscribe(param => {
			this.weather.getAllCrops().subscribe(res => {
				if (res) {
					let arrSelCommodity = res.filter(items => {
						return (items.commodity_name == param.crop_id)
					});
					this.generalTabData = arrSelCommodity[0];
					this.strCommodityName = arrSelCommodity[0]['commodity_name'];
					//this.strCommodityName = res[0]['commodity_name'];
					//this.generalTabData = res[0];
					if (this.strCommodityName) {
						this.weather.getCropDetails(this.strCommodityName).subscribe(data => {
							this.loader = true;
							this.arrCropDetails = data["reduce"]((acc, message) => {
								const providerName = message.provided_by;
								const kindof = message.crop_action_type_name;
								if (acc[kindof]) {
									acc[kindof].push(message);
									return acc;
								}
								acc[kindof] = [message];
								return acc;
							}, {});
						});
					}
				}
			});
		});
	}

	public goBack() {
		this.location.back();
	}

	public openMediaPlayer(src) {
		this.audio.openMediaPlayer(src);
	}

	ngOnDestroy() { }

	openMediaPlayerForVideo(src) {
		this.videoService.openMediaPlayer(src);
	}

	openMediaForImage(src) {
		this.imageService.openMediaPlayer(src);
	}

	openMediaForSpeech(content) {
		const strUrl =
			PARAMETRE.strTextToSpeechUrl +
			encodeURIComponent(content) +
			"&language=" +
			this.txtSpeech;
		this.audio.openMediaPlayer(strUrl);
	}

	setMyStyles() {
		return Utils.reverseContent();
	}

	onImgError(event) {
		event.target.src = Utils.offlineImage("");
	}
	getDefImage(event) {
		event.target.src = Utils.defaultImage();
	}
}
