import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import Utils from '../../shared/utils/utils';
import { AuthService } from "../../shared/service/auth.service";
import { Router } from '@angular/router';
import { GeoLocationService } from '../../shared/service/location';
import { Location } from '@angular/common';
export interface DialogData {
  title: string;
}
@Component({
  selector: 'app-login-request-dialog',
  templateUrl: './login-request-dialog.component.html',
  styleUrls: ['./login-request-dialog.component.css']
})
export class LoginRequestDialogComponent {

  constructor(
    public authService: AuthService,
    public router: Router,
    public location: Location,
    public dialogRef: MatDialogRef<LoginRequestDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  onNoClick(): void {

    this.dialogRef.close();
    // this.location.back();
  }
  onNoClick2(): void {
    this.dialogRef.close();
  }
  public SignIn(email, password): void {
    this.authService.SignIn(email, password, '').then(result => {
      if (this.authService.isLoggedIn)
        this.dialogRef.close();
      else if (this.authService.isLoggedIn)
        this.dialogRef.close();

    }).catch(function (error) {
      alert(error);
    });

  }

  public SignIn2(): void {
    if (this.authService.isLoggedIn)
      this.dialogRef.close();

  }

  setMyStyles() {
    return Utils.reverseContent();
  }
}