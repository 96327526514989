import { LivestockDetailsComponent } from './livestock-details/livestock-details.component';
import { DeseaseDetailsComponent } from './disease-details/disease-details.component';
import { VaccinationDetailsComponent } from './vaccination-details/vaccination-details.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LivestockComponent } from './livestock';
import { AuthGuard } from '../shared/guards/auth.guard';

const meteoroutes: Routes = [
  { path: 'livestock/:themeId', component: LivestockComponent, canActivate: [AuthGuard] },
  { path: 'livestock/:themeId/:id', component: LivestockDetailsComponent, canActivate: [AuthGuard] },
  { path: 'disease/:themeId/:id', component: DeseaseDetailsComponent, canActivate: [AuthGuard] },
  { path: 'vaccination/:themeId/:id', component: VaccinationDetailsComponent, canActivate: [AuthGuard] }
];

@NgModule({
  imports: [RouterModule.forChild(meteoroutes)],
  exports: [RouterModule],
})
export class LivestockRoutingModule { }
