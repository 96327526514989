import { Component, OnInit } from '@angular/core';
import { AuthService } from './../shared/service/auth.service';
import { Router } from '@angular/router';
import { GeoLocationService } from './../shared/service/location';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})

export class ForgotPasswordComponent implements OnInit {

  constructor(
    public authService: AuthService,
    public router: Router,
    public location: GeoLocationService
  ) { }

  ngOnInit() {
  }

}