import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChartModule, HIGHCHARTS_MODULES } from 'angular-highcharts';
import * as highstock from 'highcharts/modules/stock.src';
import * as more from 'highcharts/highcharts-more.src';
import * as exporting from 'highcharts/modules/exporting.src';
import { HighChartJsComponent } from './highcharts-js-chart.component';
import { LineChartComponent } from './line-chart.component';


let loaded = false;
export function highchartsModules() {
  if (!loaded) {
    loaded = true;
    return [highstock, more, exporting];
  }
  return [];
}

@NgModule({
  imports: [
    CommonModule,
    ChartModule
  ],
  providers: [
    { provide: HIGHCHARTS_MODULES, useFactory: highchartsModules }],
  declarations: [HighChartJsComponent, LineChartComponent],
  exports: [HighChartJsComponent, LineChartComponent],
})
export class HighChartJsModule { }
