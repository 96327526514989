// import { environment } from './../../../environments/environment.prod';
import { environment } from "../../../environments/environment";

export class Parametre {
  static parametre: Parametre;
  apiBaseUrl: String = environment.apiBaseUrl;
  sync_url: String = environment.sync_url;
  meteo: String = environment.meteo;
  WAPOR_CATALOG_API: String = environment.WAPOR_CATALOG_API;
  WAPOR_QUERY_API: String = environment.WAPOR_QUERY_API;
  strTextToSpeechUrl: String = environment.strTextToSpeechUrl;
  constructor() { }
  public static getInstance() {
    if (Parametre.parametre == null) {
      Parametre.parametre = new Parametre();
    }
    return Parametre.parametre;
  };
}
