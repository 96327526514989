import { SnackbarComponent } from './../snackbar/snackbar.component';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { MediaObserver } from '@angular/flex-layout';
import { Injectable } from '@angular/core';
import { Howl } from 'howler';
import { MatSnackBarRef, MatSnackBar } from '@angular/material/snack-bar';

@Injectable()
export class AudioService {
  ref: MatSnackBarRef<SnackbarComponent>;
  constructor(public snackBar: MatSnackBar) {
  }

  openMediaPlayer(audio_link) {

    this.ref = this.snackBar.openFromComponent(SnackbarComponent, {
      data: audio_link
    });
    this.ref._open();
  }

  closeWhenSoundFinishes(duration) {
    this.ref._dismissAfter(duration);
  }

  closeMediaPlayer() {
    this.ref.dismiss();
  }

}

