import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { InsectComponent } from "./insect";
import { SharedModule } from "../shared/shared.module";
import { InsectRoutingModule } from "./insect.routing";
// import ngx-translate and the http loader
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { HttpClient, HttpClientModule } from "@angular/common/http";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { HighChartJsModule } from "../shared/component/charts/highcharts-js-chart/highcharts-js-chart.module";
import { InsectListComponent } from './insectlist/insectlist.component';
import { InsectDetailsComponent } from './insect-details/insect-details.component';
import { WeatherModule } from "../weather/weather.module";
import { WeatherAlertComponent } from './weather-alert/weather-alert.component';
import { RecogniseInsectsComponent } from './recognise-insects/recognise-insects.component';
@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        InsectRoutingModule,
        MatAutocompleteModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        WeatherModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        HighChartJsModule
    ],
    declarations: [
        InsectComponent,
        InsectListComponent,
        InsectDetailsComponent,
        WeatherAlertComponent,
        RecogniseInsectsComponent
    ],
    exports: [
        InsectComponent,
        InsectRoutingModule,
        InsectListComponent,
        InsectDetailsComponent
    ]
})
export class InsectModule { }
// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}
