import { Component, Input, OnInit } from '@angular/core';
import Utils from '../../../app/shared/utils/utils';

@Component({
  selector: 'app-app-weather-preview',
  templateUrl: './app-weather-preview.component.html',
  styleUrls: ['./app-weather-preview.component.scss']
})
export class AppWeatherPreviewComponent implements OnInit {
  @Input() data: any;
  @Input() appName: any;
  public objectKeys = Object.keys;
  advices: { [key: string]: any } = {};
  constructor() { }

  ngOnInit(): void {
    if (this.data.messages && this.data.messages.length) {
      this.advices = this.data.messages.reduce((acc, message) => {
        const kindof = message.group_id;
        if (acc[kindof]) {
          acc[kindof].push(message);
          return acc;
        }
        acc[kindof] = [message];
        return acc;
      }, {});
    }
  }
  setMyStyles() {
    return {
      'direction': 'ltr',
      'text-align': 'left'
    };
  }
  getGroupName(id) {
    return id ? id.split('_').join(' ') : '';
  }
}
