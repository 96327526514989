import { ImageStorageService } from './../../service/img-storage.service';
import { UserService } from './../../service/user.service';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { GeoLocationService } from './../../service/location';
import Utils from './../../utils/utils';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { MatDialog } from "@angular/material/dialog";
import { PandemicReportComponent } from "../../../pandemic-report/pandemic-report.component";
import { TranslationService } from "../../../shared/service/translation.service";

@Component({
    selector: 'app-menu',
    template: `
        <div [ngStyle]="setMyStyles()">
        <div [ngStyle]="{'margin-top': (l.template_id == 'weather')? '0px' : ''}"
        *ngFor="let l of arrApplications" mat-menu-item fxLayout='row' fxLayoutAlign='center center'
        (click)="navThemes(l)" [hidden]="l.status != undefined && !l.status">
            <div fxFlex='20%'>
                <button mat-icon-button>
                    <img class="menu-icon" [src]="l.logo_img" (error)="onImgError($event,l)"/>
                </button>
            </div>
            <div fxFlex='70%'>
                <h4 [ngStyle]="setTextAlign()">{{l.name | localTranslate | capitalize}}</h4>
            </div>
        </div>
       </div>
        <ng-template #profile>
    <div class="top" mat-menu-item (click)="nav('profile')"  fxLayout='row' fxLayoutAlign='center center'>
    <div fxFlex='20%'>
        <button mat-icon-button >
            <mat-icon style='color:#212121;'>account_box</mat-icon>
        </button>
    </div>
    <div fxFlex='70%'>
        <h4>{{user?.userData.last_name}}</h4>
    </div>
    </div>
    <div class="top" mat-menu-item (click)="logout()"  fxLayout='row' fxLayoutAlign='center center'>
    <div fxFlex='20%'>
        <button mat-icon-button >
            <mat-icon style='color:#212121;'>
            assignment_return
            </mat-icon>
        </button>
    </div>
    <div fxFlex='70%'>
        <h4>Logout</h4>
    </div>
    </div>
</ng-template>

        `
})

export class MenuComponent implements OnInit {
    logStat: Boolean;
    user: any = '';
    public arrApplications: any;

    constructor(
        iconRegistry: MatIconRegistry,
        public toast: MatSnackBar,
        sanitizer: DomSanitizer,
        public router: Router,
        public userService: UserService,
        public imgService: ImageStorageService,
        public location: GeoLocationService,
        public gaService: GoogleAnalyticsService,
        public mddialog: MatDialog,
        private translationService: TranslationService
    ) {

        this.imgService.weatherImg$.subscribe(res => {
            iconRegistry.addSvgIcon(
                'weather',
                sanitizer.bypassSecurityTrustResourceUrl(res.icon));

        });

        this.imgService.agriImg$.subscribe(res => {
            iconRegistry.addSvgIcon(
                'agrimarket',
                sanitizer.bypassSecurityTrustResourceUrl(res.icon));

        });

        this.imgService.livestockImg$.subscribe(res => {
            iconRegistry.addSvgIcon(
                'livestock',
                sanitizer.bypassSecurityTrustResourceUrl(res.icon));

        });

        this.imgService.nutriImg$.subscribe(res => {
            iconRegistry.addSvgIcon(
                'nutrifood',
                sanitizer.bypassSecurityTrustResourceUrl(res.icon));

        });

        this.imgService.bugImg$.subscribe(res => {
            iconRegistry.addSvgIcon(
                'bug',
                sanitizer.bypassSecurityTrustResourceUrl(res.icon));

        });

        //Check location on every refresh
        //this.location.getLatestLocation();
    }

    ngOnInit() {
        this.logStat = this.userService.isLogged();
        if (this.logStat) {
            this.user = JSON.parse(this.userService.getUser());
        } else {

        }
        //Get country application list
        this.userService.getDataUsingCountry('themes', 'order').subscribe(res => {
            this.arrApplications = res.filter(_ => _.template_id != "team");
            this.arrApplications = this.arrApplications.filter(_ => _['status'] == undefined || _['status']);
            if (this.userService.districtId) {
                this.arrApplications.forEach(element => {
                    element.status = element.district_list && element.district_list.length ?
                        element.district_list.includes(this.userService.districtId) ? true : false : true;
                });
                return
            }
            this.userService.district.subscribe(dist => {
                if (dist) {
                    this.arrApplications.forEach(element => {
                        element.status = element.district_list && element.district_list.length ?
                            element.district_list.includes(dist) ? true : false : true;
                    });
                }
            });
        });

        //End
    }

    nav(link) {
        // if(link == 'team-list')
        // {
        //   link = 'team';
        // }
        this.gaService.event('Sidebar_Theme_Navigation_Click', 'Theme_Navigation', link);
        this.router.navigate([`/${link}`]);
    }

    logout() {

    }

    setMyStyles() {
        return Utils.reverseContent();
    }

    setTextAlign() {
        let styles = {
            'text-align': UserService.langCode == 'ar' ? 'right' : 'left'
        };
        return styles;
    }

    onImgError(event, theme) {
        if (!theme.isImageError) {
            theme.isImageError = true;
            let img = Utils.routedata[theme.template_id] ? Utils.routedata[theme.template_id] : theme.template_id;
            event.target.src = 'assets/offline/icons/desktop/' + img + '.svg';
        }
    }


    disableDiv(status) {
        let styles;
        if (!status) {
            styles = {
                'color': 'darkgrey'
            };
        } else {
            styles = '';
        }
        return styles;
    }

    navThemes(data) {
        let link = Utils.routedata[data.template_id] ? Utils.routedata[data.template_id] : data.template_id;
        this.gaService.event('Navbar_Theme_Navigation_Click', 'Theme_Navigation', link);
        //TODO _ remove this once finalized the implementation of farmer-practicies
        let template_id_tmp = data?.template_id_tmp;
        if (link === 'e-nutrifood')
            this.router.navigate([`/e-nutrifood/categories`, data.id]);
        else if (link === 'administrative')
            this.router.navigate([`/administrative/categories`, data.id]);
        else if (link === 'livestock')
            this.router.navigate([link, data.id]);
        else if (template_id_tmp === 'family_practices')
            this.router.navigate(['/family-practices/categories', data.id]);
        else if (link === 'animal_feed')
            this.router.navigate(['/animal-feed/categories', data.id]);
        else if (link === 'insect') {
            this.router.navigate(['/insect']);
            localStorage.setItem('WeatherPopupStatus', 'true');
        } else if (link === 'survey') {
            this.openPandemicReportDialog();
        }
        else
            this.router.navigate([link]);
    }

    openPandemicReportDialog() {
        const dialogRef = this.mddialog.open(PandemicReportComponent, {
            width: '400px',
            data: {
                title: this.translationService.translate('reports.pandemic_title')
            }
        });
    }
}

