import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { AuthService } from '../shared/service/auth.service';
import { HttpClient } from '@angular/common/http';
import { GeoLocationService } from '../shared/service/location';
import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { environment } from '../../environments/environment';
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
})

export class ChatComponent implements OnInit, OnDestroy {
  @ViewChildren('messages') messages!: QueryList<any>;
  @ViewChild('content') content!: ElementRef;
  @ViewChild('textarea') textarea!: ElementRef;

  conversation = [{ text: `Hi! I'm DSP from FAO, I'm here to assist you on agriculture and livestock! Can you tell me what you're looking for? This way, I'll be able to give you the right info!`, user: false, error: false }];
  input = '';
  maxConversationLength = 4;
  subs!: Subscription;
  isLoading = false;
  user: any;
  public data: any;
  appData: any;
  subscriptions: Subscription[] = [];
  constructor(private http: HttpClient, public gaService: GoogleAnalyticsService,
    private spinner: NgxSpinnerService,
    private afAuth: AngularFireAuth,
    private authService: AuthService,
    public location: GeoLocationService) {
    this.user = JSON.parse(localStorage.getItem('user'));
    if (!this.user) {
      this.spinner.show();
      this.authService.signInAnonymously();
    } else {
      let token = localStorage.getItem('authToken');
      if (!token || this.tokenExpired(token)) {
        this.spinner.show();
        this.authService.signInAnonymously();
      }
    }
  }

  show_Popup = false;
  visibility = 'hidden';

  //stopHidePopupFunction is a switcher, it help hidePopup() and showPopup() not to overlap
  stopHidePopupFunction = true

  hidePopup() {
    if (this.stopHidePopupFunction) { return }

    else {
      this.show_Popup = false; this.visibility = 'hidden'
      setTimeout(() => { this.stopHidePopupFunction = true }, 1)
    }

  }

  showPopup() {

    this.show_Popup = !this.show_Popup;
    this.show_Popup == false
      ? (this.visibility = 'hidden')
      : (this.visibility = 'visible');

    setTimeout(() => { this.stopHidePopupFunction = false }, 1)
  }

  sendMessage() {

    if (!this.input.trim()) { return }

    this.conversation.push({
      text: this.input.trim(),
      user: true,
      error: false,
    });

    // Recuperare le ultime 3-4 battute
    const messages: any = this.conversation.slice(-this.maxConversationLength);
    // messages = messages.map((m: any) => m.text).toString();


    this.isLoading = true;
    this.input = '';
    this.textarea.nativeElement.style.height = '50px';
    let token = localStorage.getItem('authToken');
    this.subs = this.http
      .post(`${environment.chatBaseUrl}chat?access_token=${token}`, { message: messages, location: localStorage.getItem("formatted_address") })
      .subscribe(
        (res: any) => {
          console.log(res);

          this.isLoading = false;

          this.conversation.push({ text: res.data, user: false, error: false });

          this.input = '';
        },
        (error) => {
          this.conversation.push({
            text: error.message,
            user: false,
            error: true,
          });
        }
      );
  }

  scrollToBottom() {
    try {
      this.content.nativeElement.scrollTop =
        this.content.nativeElement.scrollHeight;
    } catch (err) { }
  }

  getCurrentTime() {
    const now = new Date();
    const hours = now.getHours().toString().padStart(2, '0');
    const minutes = now.getMinutes().toString().padStart(2, '0');
    return `${hours}:${minutes}`;
  }

  ngOnInit() {
    const themes = JSON.parse(localStorage.getItem('appThemes'));
    this.appData = themes.filter(_ => _.template_id === 'chat')[0];

  }
  ngOnDestroy() {
    if (!this.user) {
      this.authService.signoutAnonymousUser();
    }
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
  private tokenExpired(token: string) {
    const expiry = (JSON.parse(atob(token.split('.')[1]))).exp;
    return (Math.floor((new Date).getTime() / 1000)) >= expiry;
  }
}
