import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { EnutrifoodService } from '../../service/nutrifood.service';
import { ImageService } from '../image-modal/image.service';
declare const google: any;
@Component({
  selector: 'app-fresh-market',
  templateUrl: './fresh-market.component.html',
  styleUrls: ['./fresh-market.component.css']
})
export class FreshMarketComponent implements OnInit, OnChanges {
  @Input('data') data: any;
  @Input('markets') markets: any = [];
  @Input('themeData') themeData: any;
  @Input('croods') croods: any;
  mapData: any;
  @Input('filters') filters: unknown[];
  @Output() filterChanged = new EventEmitter();
  @Output() nav = new EventEmitter();
  @Output() nearByChanged = new EventEmitter();
  centerLocation: any;
  searchRequired: boolean = false;
  map: any;
  isOffline: boolean = false;
  constructor(private enutrifoodService: EnutrifoodService,
    private imageService: ImageService) {
    this.imageService.ifOffline.subscribe(_ => {
      this.isOffline = _;
    })
  }

  ngOnInit(): void {
    this.mapData = JSON.parse(JSON.stringify(this.data));
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (!this.mapData)
      this.mapData = { zoom: 12 };
    if (this.croods && this.mapData && (!this.mapData.lat && !this.mapData.long)) {
      this.mapData.lat = +this.croods.lat;
      this.mapData.long = +this.croods.long;
    }
    if (this.data.zoom != this.mapData.zoom)
      this.mapData.zoom = this.data.zoom;
    if (this.filters && this.filters.length)
      this.filters.forEach((element: any) => {
        if (element.type == "location")
          this.mapData.nearBy = element.value;
      });
  }

  changeSelected() {
    const filter: any = {}
    this.filters.forEach(res => {
      if (res['selected'] && (!res['type'] || res['type'] != 'location')) {
        filter[res['key']] = true;
      };
    });
    this.filterChanged.emit(filter);
  }
  changeNearby(filter) {
    if (filter.selected) {
      this.filters.forEach((element: any) => {
        if (element.type == "location" && element.id != filter.id)
          element.selected = "";
      });
      this.mapData.zoom = 12;
    } else {
      this.mapData.zoom = 8;
    }
    filter.centerLocation = this.centerLocation;
    this.nearByChanged.emit(filter);
  }
  getIcon(market) {
    return Object.keys(market.filters).includes('cleanliness') ? "assets/green-dot.png" : "assets/red-dot.png";
  }
  centerChanges(e) {
    if (this.filters && this.filters.length) {
      let selectedNearBy = this.filters.filter((element: any) => element.type == "location" && element.selected);
      if (selectedNearBy && selectedNearBy.length) {
        this.centerLocation = { lat: e.lat, long: e.lng };
        this.searchRequired = !this.ePointsNear(this.centerLocation, this.mapData);
      } else {
        this.searchRequired = false;
      }
    }
  }
  ePointsNear(checkPoint, centerPoint) {
    var ky = 40000 / 360;
    var kx = Math.cos(Math.PI * centerPoint.lat / 180.0) * ky;
    var dx = Math.abs(centerPoint.long - checkPoint.long) * kx;
    var dy = Math.abs(centerPoint.lat - checkPoint.lat) * ky;
    return Math.sqrt(dx * dx + dy * dy) <= this.mapData.nearBy;
  }
  searchNewArea() {
    this.mapData.lat = this.centerLocation.lat;
    this.mapData.long = this.centerLocation.long;
    this.searchRequired = false;
    this.filters.forEach((element: any) => {
      if (element.type == "location" && element.selected) {
        element.centerLocation = this.centerLocation;
        this.changeNearby(element);
      }
    });
  }
  mapReady(event: any) {
    this.map = event;
    this.map.controls[google.maps.ControlPosition.TOP_CENTER].push(document.getElementById('search'));
  }
  get fullscreen() {
    return document.fullscreenElement ? true : false;
  }
  identify(index, item) {
    return item.docId;
  }
}
