import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { Observable } from 'rxjs';
import { GoogleService, GoogleObj } from './google.services';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Parametre } from './parametre';
const PARAMETRE = Parametre.getInstance();
import { FirestoreDataService } from "./firestore-data.service";
import Utils from '../utils/utils';
import { environment } from '../../../../src/environments/environment';

@Injectable()
export class TranslationService {
    public googleObj: GoogleObj = new GoogleObj();
    private langPath = 'languages/';
    private segPath = 'segments/';
    public items: any;
    public arrData: any = {};
    public languageKeys: any;
    public boolStatus: boolean = false;
    public country = 'general';
    constructor(
        private db: AngularFireDatabase,
        private _google: GoogleService,
        private http: HttpClient,
        private fireStoreDataService: FirestoreDataService
    ) { }

    translate(value: string, langCode?) {
        // let countryName = localStorage.getItem('gpsCountry');
        // countryName = Utils.getCountryData(countryName, 'code3');
        // var langCode = langCode == undefined ? (JSON.parse(localStorage.getItem(environment.userLanguageStorageKey))) ? JSON.parse(localStorage.getItem(environment.userLanguageStorageKey)).code : 'en' : langCode;
        // if (countryName && this.arrData.length && this.arrData[0].country != countryName)
        //     this.arrData = [];
        // if (!this.arrData.length)
        this.arrData = JSON.parse(localStorage.getItem('localLanguageData')) ? JSON.parse(localStorage.getItem('localLanguageData')) : {};
        if (this.arrData && value) {
            // const filterdata = this.arrData.filter(_ => _.id.toLowerCase() == value.toLowerCase());
            // if (filterdata.length) {
            // If segment is present in database
            return this.arrData[value.toLowerCase()] ? this.arrData[value.toLowerCase()] : value;
            // } else {
            //     return value;
            // }
        }
        return '';
    }

    getLanguage() {
        return this.db.object(this.langPath).valueChanges();
    }
    getSegments() {
        return this.db.object(this.segPath).valueChanges();
    }
    // getLangSegments(langCode) {
    //     let apiType = "segment-api";
    //     let sheetType = "fao";
    //     let filterArr: string[] = ['TRANSLATION'];
    //     return this.fireStoreDataService.getDocument(apiType, sheetType, filterArr, '');
    // }
}