import { Component, Input, OnInit } from '@angular/core';
import Utils from '../../shared/utils/utils';

@Component({
  selector: 'app-single-node-preview',
  templateUrl: './app-single-node-preview.component.html',
  styleUrls: ['./app-single-node-preview.component.scss']
})
export class AppSingleNodePreviewComponent implements OnInit {
  @Input() generalTabData: any;
  @Input() menuBarTitle: string;
  @Input() keyMessages = [];
  objectKeys = Object.keys;
  constructor() {
  }

  ngOnInit(): void {
  }
  setMyStyles() {
    return {
      'direction': 'ltr',
      'text-align': 'left'
    };
  }
  textJustify(txt) {
    return txt.toString().replace(/(?:\r\n|\r|\n)/g, '<br>');
  }

  getDefImage(event) {
    event.target.src = Utils.defaultImage();
  }
}
