import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatListModule } from '@angular/material/list';
import Utils from '../../shared/utils/utils';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from "../../shared/service/auth.service";
import { GeoLocationService } from '../../shared/service/location';
import { ForumService } from '../../shared/service/forum.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit, OnDestroy {
  public loader = false;
  public userdoc: any = [];
  public IsforumAdmin: boolean = false;
  forum: any;
  subscriptions: Subscription[] = [];
  constructor(public route: ActivatedRoute, public forumService: ForumService,
    public authService: AuthService,
    public router: Router,
    public location: GeoLocationService) { }

  setMyStyles() {
    return Utils.reverseContent();
  }

  ngOnInit() {
    const themes = JSON.parse(localStorage.getItem('appThemes'));
    this.forum = themes.filter(_ => _.template_id === 'forum')[0];
    if (!this.authService.isLoggedIn) {
      this.router.navigate(['login/forum*admin']);
    }

    this.subscriptions.push(this.authService.afAuth.authState.subscribe(async user => {
      if (user) {
        this.IsforumAdmin = this.IsAdmin(user.uid);
      }
    }));


  }

  public IsAdmin(UserId) {
    this.subscriptions.push(this.forumService.getUserDetails(UserId).subscribe((res: any[]) => {
      if (res) {
        this.userdoc = res;
        const country = this.location.getCountry();
        if (this.userdoc.roles["dsp"].indexOf("admin") > -1) {
          this.loader = true;
        } else if (this.userdoc.roles["dsp"].indexOf("forum_admin") < 0 || this.userdoc['country_iso3'].toLowerCase() != country.country.toLowerCase()) {
          this.router.navigate([`forum`]);
        }
        this.loader = true;
      }
    }));
    return true;
  }


  nav(link) {
    this.router.navigate([`forum/admin`, `${link}`]);
  }
  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
