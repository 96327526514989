import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { CropDTO } from "../../models/crops.dtos";
import {
  Observable,
  throwError as observableThrowError,
  Subscription
} from "rxjs";
import { AgroEcologicalZone } from "../../models/aez.dto";
import { environment } from "../../../../../../environments/environment";
import { MatSnackBar } from "@angular/material/snack-bar";
import { GeoLocationService } from "../../../../../shared/service/location";
import { retry, tap, catchError, map } from 'rxjs/operators';
import { FirestoreDataService } from "../../../../../../app/shared/service/firestore-data.service";
import { Parametre } from '../../../../../shared/service/parametre';
const PARAMETRE = Parametre.getInstance();

@Injectable({
  providedIn: "root"
})
export class CropCalendarService {
  baseUrl = environment.apiBaseUrl;

  lang: Subscription;

  constructor(private fireStoreDataService: FirestoreDataService,
    private http: HttpClient,
    public toast: MatSnackBar,
    private location: GeoLocationService
  ) {
    // const url = 'https://us-central1-fao-portfolio-dev.cloudfunctions.net/callMeteoAllCountryApis?country=' + country_name;
  }

  public handleError = (err: Response) => {
    if (err.status === 500 || err.status === 503) {
      this.toast.open(
        "Service is temporarily unavailable, please try again later",
        "OK",
        { duration: 3000 }
      );
    }
    return observableThrowError(err.json());
  };

  getLanguage() {
    const obj = JSON.parse(
      localStorage.getItem(environment.userLanguageStorageKey)
    );

    const lang = obj ? obj.code : "en";

    return lang;
  }

  /*
   * Get All Countries
   * URL Base/countries
      Returns all countries
   */
  getAllCountries() {
    const url = this.baseUrl + "countries?language=" + this.getLanguage();
    return this.http.get(url);
  }

  /*
 * Get All information Crop Calendar
    URL Base/countries/{id_country}/cropCalendar
    Returns all crop calendar data filtered by country, crop id and agroecological zone
 */
  getAllCropCalendar(
    idCountry: string,
    agroEcologicalZone: string[],
    crops: string[]
  ) {
    let url =
      this.baseUrl +
      "countries/" +
      `${idCountry}` +
      "/cropCalendar?language=" +
      this.getLanguage();

    const params = new HttpParams()
      .append("crop", crops.join(","))
      .append("aez", agroEcologicalZone.join(","));

    return this.http.get(url, { params });
  }

  /*
   * Get All Crops By AEZ
   * URL Base/countries/{id_country}/aez/{agroEcologicalZone}
      Returns all crops filtered by country and agroecological zone
   */

  getAllCropsByFilter(
    idCountry: string,
    agroEcologicalZone: string
  ): Observable<CropDTO[]> {
    let url =
      this.baseUrl +
      "countries/" +
      `${idCountry}` +
      "/aez/" +
      `${agroEcologicalZone}` +
      "?language=" +
      this.getLanguage();

    return this.http.get<CropDTO[]>(url);
    //.catch(error => this.handleError(error));
  }

  /*
   * Get All Crops by Country
   * URL Base//countries/{id_country}/crops
      Returns all crops filtered by country
   */
  getAllCropsByCountry(idCountry: string): Observable<CropDTO[]> {
    const url =
      this.baseUrl +
      "countries/" +
      `${idCountry}` +
      "/crops?language=" +
      this.getLanguage();
    return this.http.get<CropDTO[]>(url);
  }

  /*
   * Get All Agroecological Zonestring
   * URL Base/countries/{id_country}/aez
      Returns all agroecological zones filtered by country
   * /
   */
  getAllAEZByCountry(idCountry: string): Observable<AgroEcologicalZone[]> {
    const url =
      this.baseUrl +
      "countries/" +
      idCountry +
      "/aez?language=" +
      this.getLanguage();

    return this.http.get<AgroEcologicalZone[]>(url);
  }

  /*
   ** Download
   * * URL Base/countries/{id_country}/cropCalendar/download
    Returns an Excel with the result of the search
   */

  downloadCropCalendar(
    idCountry: string,
    crops: string[],
    agroEcologicalZone: string[]
  ): Observable<any> {
    const url =
      this.baseUrl +
      "countries/" +
      `${idCountry}` +
      "/cropCalendar/download?language=" +
      this.getLanguage();

    const params = new HttpParams()
      .append("crop", crops.join(","))
      .append("aez", agroEcologicalZone.join(","));

    return this.http.get(url, { params, responseType: "blob" });
  }

  downloadFile(blob: Blob, type: string = null, fileName: string = "document") {
    // It is necessary to create a new blob object with mime-type explicitly set
    // otherwise only Chrome works like it should
    let newBlob = new Blob([blob], { type: type });

    // IE doesn't allow using a blob object directly as link href
    // instead it is necessary to use msSaveOrOpenBlob
    const nav = (window.navigator as any);
    if (window.navigator && nav.msSaveOrOpenBlob) {
      nav.msSaveOrOpenBlob(newBlob);
      return;
    }

    // For other browsers:
    // Create a link pointing to the ObjectURL containing the blob.
    const data = window.URL.createObjectURL(newBlob);
    let link = document.createElement("a");
    link.href = data;
    link.download = fileName;
    // this is necessary as link.click() does not work on the latest firefox
    link.dispatchEvent(
      new MouseEvent("click", { bubbles: true, cancelable: true, view: window })
    );

    setTimeout(function () {
      // For Firefox it is necessary to delay revoking the ObjectURL
      window.URL.revokeObjectURL(data);
      link.remove();
    }, 100);
  }

  getCropsFromStorage(country?) {
    return this.fireStoreDataService.getDimensionsDocument('dimensions', 'tab_id', 'cropcalendar', 'order', country);
  }

  getZonesFromStorage() {
    const country = this.location.getCountry();
    return this.fireStoreDataService.getDimensionsDocument('filters', 'type', 'AEZ');
  }
}