import { throwError as observableThrowError, BehaviorSubject, Observable } from 'rxjs';
import { retry, tap, catchError, map } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
//import { VaccinationData } from './livestock.service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GeoLocationService } from './location';
import { UserService } from './user.service';
import * as isOnline from 'is-online';
import { Parametre } from './parametre'
const PARAMETRE = Parametre.getInstance();
import { environment } from '../../../environments/environment';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { FirestoreDataService } from "./firestore-data.service";
import Utils from "../utils/utils";


@Injectable()
export class AnimalFeedService {
  areas: any;
  actionTypes: any;
  userSpecies: any;
  ForecastList: any;
  res: any;

  constructor(
    private fireStoreDataService: FirestoreDataService,
    private UService: UserService,
    public location: GeoLocationService,
    private http: HttpClient,
    public toast: MatSnackBar,
    public afs: AngularFirestore
  ) { }

  public handleError = (err: Response) => {
    this.toast.open('Service is temporarily unavailable, please try again later', 'OK', { duration: 7000 });
    if ((err.status === 500) || (err.status === 503)) {
      // TODO: Once the status get back to normal bring the toast back here
    }
    return observableThrowError('Erroe while loading the weather');
  }

  
  getAIRecipe(url, data) {
    return this.http.post(url, data, { responseType: 'text'});
  }

  getAlgoRecipe(url, data) {
    return this.http.post(url, data, { responseType: 'json'});
  }
 
}
