import {
  throwError as observableThrowError,
  BehaviorSubject,
  Observable,
  Subject
} from "rxjs";
import { MatSnackBar } from "@angular/material/snack-bar";
import { TranslateService } from "@ngx-translate/core";
import { FireDBService } from "./firedb.service";
import { AuthService } from "./auth.service";
import { environment } from "../../../environments/environment";
import { Router } from "@angular/router";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { Parametre } from "./parametre";
const PARAMETRE = Parametre.getInstance();
import { FirestoreDataService } from "./firestore-data.service";
@Injectable()
export class UserService {
  public static langId = localStorage.getItem(
    environment.userLanguageStorageKey
  )
    ? JSON.parse(localStorage.getItem(environment.userLanguageStorageKey))
      .language_id
    : 1;

  public static langCode = localStorage.getItem(
    environment.userLanguageStorageKey
  )
    ? JSON.parse(localStorage.getItem(environment.userLanguageStorageKey))
      .code
    : "en";

  public static langCodeTextSpeech = localStorage.getItem(
    environment.userLanguageStorageKey
  )
    ? JSON.parse(localStorage.getItem(environment.userLanguageStorageKey))
      .language_code_text_to_speech
    : "en-us";

  account: any;
  res: any;

  changeLanguage$ = new Subject<string>();

  private districSubject: BehaviorSubject<any> = new BehaviorSubject("");
  public district: Observable<any> = this.districSubject.asObservable();
  public districtId: string;
  constructor(
    private http: HttpClient,
    public Afauth: AngularFireAuth,
    private router: Router,
    public fireService: AuthService,
    private firedb: FireDBService,
    public translate: TranslateService,
    public toast: MatSnackBar,
    public firestoreDataService: FirestoreDataService
  ) { }

  public accountExists = (err: Response) => {
    if (err.status === 500 || err.status === 503) {
      //alert('Account Already Exists. Please log In');
      this.toast.open("Account Already Exists. Please log In.", "OK");
      this.router.navigate(["/home"]);
    }
    return observableThrowError(err.json());
  };

  isLogged() {
    let logStatus: Boolean;
    if (JSON.parse(localStorage.getItem("user")) !== null) {
      logStatus = true;
    } else {
      logStatus = false;
    }
    return logStatus;
  }
  hasActiveCountry() {
    let hasActiveCountry: Boolean;
    if (JSON.parse(localStorage.getItem("user")) !== null) {
      let country = JSON.parse(localStorage.getItem("user")).countryData;
      if (country == null) {
        hasActiveCountry = false;
      } else {
        hasActiveCountry = country.isActive;
      }
    } else {
      hasActiveCountry = false;
    }
    return hasActiveCountry;
  }
  //todo to remove
  isTestUser() {
    let isTestUser: Boolean = false;
    let user = JSON.parse(localStorage.getItem("user"));
    if (localStorage.getItem("user") !== null) {
      if (user.userData.email === "test.agrimarket@saida.com") {
        isTestUser = true;
      }
    }
    return isTestUser;
  }

  getUser() {
    if (localStorage.getItem("user") !== null) {
      return localStorage.getItem("user");
    } else {
      return null;
    }
  }

  changeCountryData(countryData: any) {
    //todo filter with id_default_language=	true
    UserService.langId = countryData.languages[0].language_id;
    this.changeLang(countryData.languages[0].code);
    localStorage.setItem("countryData", JSON.stringify(countryData));
  }

  changeLang(lang: string) {
    this.changeLanguage$.next(lang);
  }

  saveLangId(id: any) {
    UserService.langId = id;
  }

  save(user) {
    this.saveToLocalStorage(JSON.stringify(user)).then(e => {
    });
  }

  handleRegistrationerror(err) {
    this.toast.open("Registration Error", "OK", { duration: 4000 });
  }

  registerInFirebase() {
    return this.Afauth.signInAnonymously();
  }

  saveToLocalStorage(userAndData: string) {
    return new Promise((resolve, reject) => {
      localStorage.setItem("user", userAndData);

      if (localStorage.getItem("user") !== null) {
        resolve(null);
      } else {
        reject("[USER SERVICE] The user was not saved in the database");
      }
    });
  }

  getApplicationList() {
    let apiType = "pwa-configuration-api";
    let sheetType = "app_list_final";
    let filterArr: string[] = [];
    const country = JSON.parse(localStorage.getItem('countryData'));
    return this.firestoreDataService.getDocument(apiType, sheetType, filterArr, country);
  }

  getDataUsingCountry(api, orderby?) {
    const country = JSON.parse(localStorage.getItem('countryData'));
    return this.firestoreDataService.getDataUsingCountry(api, country, orderby);
  }

  getTranslation(api) {
    let country = JSON.parse(localStorage.getItem('countryData'));
    country = country ? country : { country: 'FAO' };
    return this.firestoreDataService.getDataUsingCountry(api, country);
  }

  checkIfAppExist(appList, routePath: string) {
    let exist: boolean = false;
    for (var i = 0; i < appList.applications.length; i++) {
      let appObj = appList.applications[i];
      if (appObj['application_id'] == routePath) {
        exist = true;
        break;
      }
    }
    return exist;
  }

  getWizardTranslation() {
    return this.firestoreDataService.getWizardTranslation();
  }
  getDistrictDetails(lat, long) {
    let country: any = localStorage.getItem('countryData');
    country = JSON.parse(country);
    let url = environment.district_Details;
    url = url.replace('{code_3}', country.country);
    url = url.replace('{lat}', lat);
    url = url.replace('{long}', long);
    return this.http.get(url);
  }
  updateDistric(dist) {
    this.districSubject.next(dist);
  }
  geKeyMessage(id) {
    return this.firestoreDataService.getDimensionDocument('key_messages/' + id);
  }
  getDimensionDocument(id) {
    return this.firestoreDataService.getDimensionDocument('dimensions/' + id);
  }
}
