import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from '../../../shared/service/user.service';
import { AuthService } from '../../../shared/service/auth.service';
import { ForumService } from '../../../shared/service/forum.service';
import { MatListModule } from '@angular/material/list';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import Utils from '../../../shared/utils/utils';
import { GeoLocationService } from '../../../shared/service/location';
import { Subscription } from 'rxjs';

interface User {
  uid: any;
  firstName: any;
  lastName: any;
  photoURL: string;
  email: any;
  address: any;
  docid: any;
  isActive: any;
  expert: any;
  regDate: any;
  birth: any;
}


@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit, OnDestroy {

  public loader = false;
  public userdoc: any = [];
  public IsforumAdmin: boolean = false;
  public arrApplications: any;
  searchText = '';
  public arrUser: any = [];
  public langCode = UserService.langCode;
  public loggeduser: any;
  country: any;
  forum: any;
  subscriptions: Subscription[] = [];
  constructor(
    private router: Router,
    public userService: UserService,
    public authService: AuthService,
    public forumService: ForumService, private location: Location,
    public geoLocationService: GeoLocationService
  ) {
    if (this.authService.isLoggedIn) {
      this.arrUser = this.authService.isLoggedIn;
      this.arrUser.role = 'Admin';
    }
  }
  public arrDocs: any;
  public arrDocs0: any;
  public arrExperiences: any;
  public arrTopics: any;
  config: any;
  page = 1;
  itemsPerPage = 25;


  loadUsersData() {
    this.subscriptions.push(this.forumService.getusers().subscribe(actionArray => {
      this.arrDocs = actionArray.map(item => {
        const docid = item.payload.doc.id;
        let _user = item.payload.doc.data() as User;
        if (_user.regDate) {
          _user.regDate = (typeof _user.regDate) == 'object' ? _user.regDate.toDate() : _user.regDate;
        }
        _user.docid = docid;
        if (!_user.photoURL) {
          _user.photoURL = '../../../../assets/forum/userprofile.svg';
        }
        return _user;
      });
      this.arrDocs0 = this.arrDocs;
      this.config = {
        itemsPerPage: this.itemsPerPage,
        currentPage: this.page,
        totalItems: this.arrDocs.length
      };
    }));
  }

  Search() {
    if (this.searchText == '') {
      this.arrDocs = this.arrDocs0;
      // return;
    }
    else {
      this.arrDocs = [];
      this.arrDocs0.forEach(_user => {
        if (_user.email.toLowerCase().includes(this.searchText.toLowerCase()) || (_user.firstName.toLowerCase() + ' ' + _user.lastName.toLowerCase()).includes(this.searchText.toLowerCase())) {
          this.arrDocs.push(_user);
        }
      });
    }
    this.config = {
      itemsPerPage: this.itemsPerPage,
      currentPage: this.page,
      totalItems: this.arrDocs.length
    };


  }

  setMyStyles() {
    return Utils.reverseContent();
  }
  ngOnInit() {
    const themes = JSON.parse(localStorage.getItem('appThemes'));
    this.forum = themes.filter(_ => _.template_id === 'forum')[0];
    this.country = JSON.parse(localStorage.getItem("countryData"));
    if (!this.authService.isLoggedIn) {
      this.router.navigate(['login/forum*admin']);
    }

    this.subscriptions.push(this.authService.afAuth.authState.subscribe(async user => {
      if (user) {
        this.IsforumAdmin = this.IsAdmin(user.uid);
      }
    }));

    // Check application is exist or not
    this.subscriptions.push(this.userService.getApplicationList().subscribe(res => {
      this.arrApplications = res['applications'];
    }));
    this.config = {
      itemsPerPage: this.itemsPerPage,
      currentPage: this.page,
      totalItems: this.itemsPerPage
    };
    this.loadUsersData();
  }
  public IsAdmin(UserId) {
    this.subscriptions.push(this.forumService.getUserDetails(UserId).subscribe((res: any[]) => {
      if (res) {
        this.userdoc = res;
        const country = this.geoLocationService.getCountry();
        if (this.userdoc.roles["dsp"].indexOf("admin") > -1) {
          this.loader = true;
        } else if (!(this.userdoc.roles["dsp"].includes("admin") || this.userdoc.roles["dsp"].includes("forum_admin")) || this.userdoc['country_iso3'].toLowerCase() != country.country.toLowerCase()) {
          this.router.navigate([`forum`]);
        }
        this.loader = true;
      }
    }));
    return true;
  }
  nav(link) {
    this.router.navigate([`forum/admin/user-details`, `${link}`]);
  }


  pageChanged(event) {
    this.page = event;
    this.config.currentPage = this.page;
  }

  back() {
    this.location.back();
  }
  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

}

