import { Component, Input, OnInit } from '@angular/core';
import Utils from '../../shared/utils/utils';

@Component({
  selector: 'app-app-disease-preview',
  templateUrl: './app-disease-preview.component.html',
  styleUrls: ['./app-disease-preview.component.scss']
})
export class AppDiseasePreviewComponent implements OnInit {
  @Input() data: any;
  @Input() appName: any;
  constructor() { }

  ngOnInit(): void {
  }
  setMyStyles() {
    return {
      'direction': 'ltr',
      'text-align': 'left'
    };
  }
  getDefImage(event) {
    event.target.src = Utils.defaultImage();
  }
}
