import { SectionService } from '../../shared/service/section.service';
import { SubForumService } from '../../shared/service/subforum.service';
import { Location } from '@angular/common';
import { Subscription, Observable } from 'rxjs';
import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { ForumService } from '../../shared/service/forum.service';
import { AuthService } from '../../shared/service/auth.service';
import { TranslationService } from '../../shared/service/translation.service';
import { Parametre } from '../../shared/service/parametre';
import { FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RplyDialogComponent } from '../rply-dialog/rply-dialog.component';
import * as firebase from 'firebase/compat';
import { environment } from '../../../environments/environment';
import Utils from '../../shared/utils/utils';
interface User {
  uphotoUrl: any;
  ufirstName: any;
  ulastName: any;
  uid: string;
}
interface Post {
  uphotoUrl: any;
  ufirstName: any;
  ulastName: any;
  pDetails: string;
  pTitle: string;
  pCreatedDate: any;
  numLikes: any;
  numComments: any;
  docid: any;
  publish: any;
}
interface Answer {
  answer: boolean;
  byexpert: boolean;
  cCreatedDate: any;
  cDetails: string;
  numComments: string;
  publish: boolean;
  uid: string;
  ulastName: string;
  ufirstName: string;
  docid: any;
  postid: string;
  posttitle: string;
}

const PARAMETRE = Parametre.getInstance();

@Component({
  selector: 'app-userpost',
  templateUrl: './userpost.component.html',
  styleUrls: ['./userpost.component.css']
})

export class UserpostComponent implements OnInit, OnDestroy {
  public loader: boolean = false;
  public AnswerArrayDocs: any;
  // public AnswersQuestions = [];
  public CountAnswers = 0;
  public CountPosts = 0;
  public arrDocs: any;
  public arrDocs0: any;
  public uid: string = "";
  public uphotoURL: string = "";
  public udisplayName: string = "";
  config: any;
  Answerconfig: any;
  public arrUser: any = [];
  currentUser: any;
  subscriptions: Subscription[] = [];
  //----------------------------------------------------

  constructor(
    private router: Router,
    private TranslateS: TranslationService,
    public forumService: ForumService,
    public authService: AuthService,
    public route: ActivatedRoute,
    private fb: UntypedFormBuilder, private afs: AngularFirestore, public dialog: MatDialog, private location: Location
  ) {
    this.currentUser = JSON.parse(localStorage.getItem('user'));
    if (!this.authService.isLoggedIn) {
      //this.router.navigate(['login']);
    }


  }

  ngOnInit() {
    this.config = {
      itemsPerPage: 50,
      currentPage: 1,
      totalItems: 50
    };

    this.Answerconfig = {
      itemsPerPage: 50,
      currentPage: 1,
      totalItems: 50
    };


    this.loader = true;

    this.route.params.subscribe(param => {
      this.uid = param.Userid;
      // alert(this.uid);
    });
    if (this.uid != null) { // show profile of other user
      this.getuserdata();
      this.getuserpost();
      this.getuseranswer();
      // alert(this.uid);
    }
    else {
      if (!this.authService.isLoggedIn) {
        this.router.navigate(['login']);
      }

      this.subscriptions.push(this.authService.afAuth.authState.subscribe(user => {
        if (user) {
          this.uid = user.uid;
          this.uphotoURL = user.photoURL;
          // this.udisplayName = user.displayName;
          this.getuserdata();
          this.getuserpost();
          this.getuseranswer();
        }
      }));
    }
  }

  public getuserdata() {
    this.subscriptions.push(this.forumService.getUserDetails(this.uid).subscribe((res: any[]) => {
      this.loader = true;
      if (res) {
        this.arrUser = res;
        const country = JSON.parse(localStorage.getItem("countryData"));
        this.udisplayName = this.arrUser['nickName'] ? this.arrUser['nickName'] : this.arrUser.firstName + " " + this.arrUser.lastName;
        if (this.uphotoURL == null)
          this.uphotoURL = this.arrUser.photoURL;
      }
    }));

  }



  public getuseranswer() {

    this.subscriptions.push(this.forumService.getUserAnswers(this.uid).subscribe(AnswerArray => {
      this.AnswerArrayDocs = AnswerArray.map(item => {
        const docid = item.payload.doc.id;
        let _answer = item.payload.doc.data() as Answer;
        _answer.docid = docid;
        _answer.cDetails = _answer.cDetails.toString().replace(/(?:\r\n|\r|\n)/g, '<br>');
        _answer.postid = item.payload.doc.ref.parent.path;
        _answer.postid = this.getPostId(_answer.postid);
        this.getPostTitle(_answer);
        return _answer;

      });
      this.CountAnswers = this.AnswerArrayDocs.length;
      this.Answerconfig = {
        itemsPerPage: 50,
        currentPage: 1,
        totalItems: this.AnswerArrayDocs.length
      };
    }));
  }


  public getPostId(AnswerPath: string) {

    let temp: number = 0;
    temp = AnswerPath.lastIndexOf("/")
    let postid = AnswerPath.substring(0, temp);
    temp = postid.lastIndexOf("/")
    postid = postid.substring(temp + 1, postid.length);
    //alert(postid)
    return postid

  }

  getPostTitle(myAnswer: Answer) {
    let PostTitle = "";
    let arrData: any = [];
    let doc = this.forumService.getPostDetails(myAnswer.postid).subscribe((res: any[]) => {
      if (res) {
        arrData = res;
        myAnswer.posttitle = arrData.pTitle;
        // this.AnswersQuestions.push(myAnswer);
      };
    });
    PostTitle = arrData.pTitle;
    this.subscriptions.push(doc);
  }




  nav(link) {
    this.router.navigate([`/${link}`]);
  }

  public getuserpost() {
    this.subscriptions.push(this.forumService.getUserPosts(this.uid).subscribe(actionArray => {
      this.arrDocs = actionArray.map(item => {
        const docid = item.payload.doc.id;
        let _post = item.payload.doc.data() as Post;
        _post.docid = docid;
        if (!_post.uphotoUrl) {
          _post.uphotoUrl = '../../assets/forum/userprofile.svg';
        }
        return _post;

      });
      this.CountPosts = this.arrDocs.length;
      this.arrDocs0 = this.arrDocs;
      this.config = {
        itemsPerPage: 50,
        currentPage: 1,
        totalItems: this.arrDocs.length
      };
    }));
  }

  pageChanged(event) {
    this.config.currentPage = event;
  }
  pageChangedAnswer(event) {
    this.Answerconfig.currentPage = event;
  }

  setMyStyles() {
    return Utils.reverseContent();
  }
  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}

