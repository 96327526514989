import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { UserService } from '../../shared/service/user.service';
import { LivestockService } from '../../shared/service/livestock.service';
import { OfflineSupportService } from '../offline-support.service';

@Component({
  selector: 'app-download-offline-mode-dialog',
  templateUrl: './download-offline-mode-dialog.component.html',
  styleUrls: ['./download-offline-mode-dialog.component.css']
})
export class DownloadOfflineModeDialogComponent {

  public arrApplications: any;
  loader: boolean;
  downloaded: boolean;

  constructor(
    public dialogRef: MatDialogRef<DownloadOfflineModeDialogComponent>,
    public livestock: LivestockService,
    public uService: UserService,
    private _offlineSupportService: OfflineSupportService
  ) {
    this._offlineSupportService.loader.subscribe(res => this.loader = res);
    this._offlineSupportService.downloaded.subscribe(res => this.downloaded = res);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  unsubscribe() {
    this._offlineSupportService.unsubscribe();
    this.onNoClick();
  }

  onYesClick(): void {
    this._offlineSupportService.setLoader(true);
    this.uService.getDataUsingCountry('themes', 'order').take(1).subscribe(res => {
      this.arrApplications = res;
      this.arrApplications.push({ 'template_id': 'select-language' });
      this.getAllModulesData();
    });
  }

  getAllModulesData() {
    this._offlineSupportService.ResponseArray.next([]);
    this._offlineSupportService.counter = 0;
    this.arrApplications.forEach(async application => {
      switch (application.template_id) {
        case 'livestock':
          this._offlineSupportService.getLivestockData(application);
          break;
        case 'enutrifood':
          this._offlineSupportService.getENutrifoodData(application);
          break;
        case 'forum':
          this._offlineSupportService.getForumData();
          break;
        case 'animal_feed':
          this._offlineSupportService.getAnimalFeedData(application);
          break;
        case 'administrative':
          this._offlineSupportService.getPOIData(application);
          break;
        case 'citrus-production':
          this._offlineSupportService.getEGYSpecialData(application);
          break;
        case 'date-production':
          this._offlineSupportService.getEGYSpecialData(application);
          break;
        case 'healthy-nutrition':
          this._offlineSupportService.getEGYSpecialData(application);
          break;
        case 'household-poultry':
          this._offlineSupportService.getEGYSpecialData(application);
          break;
        case 'covid19_vegetables_fruits':
          this._offlineSupportService.getEGYSpecialData(application);
          break;
        case 'team':
          this._offlineSupportService.getTeamData(application);
          break;
        case 'covid19':
          this._offlineSupportService.getCovidAdviceData(application);
          break;
        // case 'agrimarketplace':
        //   this._offlineSupportService.getAgrimarketData(application);
        //   break;
      }
    });
  }

}
