import { Component, OnInit, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { ImageStorageService } from './../../shared/service/img-storage.service';
import { Layout, LayoutService } from './../../shared/service/layout.service';
import { LivestockService } from '../../shared/service/livestock.service';
import { ActivatedRoute } from '@angular/router';
import { Parametre } from '../../shared/service/parametre';
const PARAMETRE = Parametre.getInstance();
import { UserService } from '../../shared/service/user.service';
import Utils from '../../shared/utils/utils';
import { FirestoreDataService } from "../../shared/service/firestore-data.service";
import { GeoLocationService } from '../../shared/service/location';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { Subscription } from 'rxjs';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-date-production-categories',
  templateUrl: './date-production-categories.component.html',
  styles: []
})
export class DateProductionCategoriesComponent implements OnInit, OnDestroy {
  public menuBarTitle = "Home.DateProductionTitle";
  searchText = { general_name: '' };
  public arrCategories: any = [];
  public loader: any;
  public commodity_parent: string;
  public commodity_parent_name = '';
  public displayback: string = 'none';
  subscriptions: Subscription[] = [];

  constructor(
    private location: Location,
    public layout: LayoutService,
    public img: ImageStorageService,
    private router: Router,
    public route: ActivatedRoute,
    private livestockService: LivestockService,
    private fireStoreDataService: FirestoreDataService,
    public geoLocationService: GeoLocationService,
    private gaService: GoogleAnalyticsService,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit() {
    let parentid: string;
    this.loader = false;
    this.route.params.subscribe(param => {
      parentid = param.commodity_parent;
      this.getCategoryData(parentid);
    });
  }

  getCategoryData(parentid) {
    this.spinner.show();
    if (parentid == null) {
      parentid = 'root';
    } else {
      this.displayback = 'inline';
    }
    const country = this.geoLocationService.getCountry();
    let filterArr: string[] = [parentid];
    this.subscriptions.push(this.fireStoreDataService.getAllCommodities("dimensions-api", "date_production", filterArr, country, '', true).subscribe((res: any[]) => {
      this.loader = true;
      this.spinner.hide();
      if (res) {
        this.arrCategories = res;
      }
    }));
  }

  nav(data) {
    if (data.has_child == 'yes') {
      this.gaService.event('Date-Production_Child_Category', 'Page_Navigation', data.commodity_name);
      this.router.navigate([`/date-production/categories`, `${data.commodity_name}`]);
    } else {
      this.gaService.event('Date-Production_Details', 'Page_Navigation', data.commodity_name);
      //Store data into local storage
      localStorage.setItem('commodityInfo', JSON.stringify(data));
      this.router.navigate([`/date-production/subject`, `${data.commodity_parent}`, `${data.commodity_name}`]);
    }
  }
  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
