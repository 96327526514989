import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Parametre } from './parametre';
import { environment } from '../../../environments/environment';
const PARAMETRE = Parametre.getInstance();

@Injectable()
export class InsectRecogniseService {
    constructor(private http: HttpClient) { }
    sendInsectToRecognise(data) {
        var url = environment.recognizeInsectUrl;
        return this.http.post(url, data).pipe(
            catchError(this.handleError)
        );
    }

    private handleError(error: Response | any) {

        console.log("ErrorResponse", error);
        console.log("ErrorResponse Status", error.status);
        let errMsg: string;
        if (error instanceof Response) {
            const err = error || '';
            errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
        } else {
            errMsg = error.message ? error.message : error.toString();
        }
        console.log("ErrorMessage");

        console.error(errMsg);
        return throwError(error);
    }
}
