import { NgModule } from "@angular/core";

// Angular Material
import { MatSelectModule } from "@angular/material/select";
import { MatOptionModule } from "@angular/material/core";
import { MatDialogModule } from "@angular/material/dialog";
import { MatTabsModule } from "@angular/material/tabs";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import { MatInputModule } from "@angular/material/input";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatTableModule } from "@angular/material/table";

import { TranslateModule } from "@ngx-translate/core";
import { FiltersPWAComponent } from "./components/filters-pwa/filters-pwa.component";
import { ResultsPWAComponent } from "./components/results-pwa/results-pwa.component";
import { CropCalendarRoutingModule } from "./crop-calendar-routing.module";
import { CropCalendarService } from "./services/api/cropcalendar-api.service";
import { CropService } from "./services/crops.service";
import { ModalAllZonesComponent } from "./components/filters-pwa/modal-all-zones/modal-all-zones.component";
//import { SelectAutocompleteModule } from "mat-select-autocomplete";
import { HelpPwaComponent } from "./help-pwa/help-pwa.component";
import { FilterService } from "./services/filter.service";

import { SharedModule } from "../../../shared/shared.module";
import { VideoService } from "../../../shared/component/video/video.service";
import { AudioService } from "../../../shared/component/audio/audio.service";
import { ImageService } from "../../../shared/component/image-modal/image.service";

@NgModule({
    declarations: [
        FiltersPWAComponent,
        ResultsPWAComponent,
        ModalAllZonesComponent,
        HelpPwaComponent
    ],
    imports: [
        MatTableModule,
        MatInputModule,
        MatSelectModule,
        MatDialogModule,
        MatIconModule,
        MatButtonModule,
        MatOptionModule,
        MatTabsModule,
        MatAutocompleteModule,
        MatCheckboxModule,
        ReactiveFormsModule,
        FormsModule,
        TranslateModule,
        CropCalendarRoutingModule,
        //SelectAutocompleteModule,
        SharedModule
    ],
    exports: [
        FiltersPWAComponent,
        ResultsPWAComponent,
        HelpPwaComponent,
        SharedModule
    ],
    providers: [
        CropCalendarService,
        CropService,
        FilterService,
        AudioService,
        VideoService,
        ImageService
    ],
    bootstrap: []
})
export class CropCalendarModule { }
