
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { SharedModule } from '../shared/shared.module';
import { PreviewRoutingModule } from './app-preview.routing';
import { AppPreviewComponent, ChildDirective } from './app-preview/app-preview.component';
import { AppMenuComponant } from './app-menu-componant/app-menu-componant.component';
import { PreviewToolbarComponent } from './preview-toolbar';
import { AppWeatherPreviewComponent } from './app-weather-preview/app-weather-preview.component';
import { AppLivestockPreviewComponent } from './app-livestock-preview/app-livestock-preview.component';
import { AppNutrifoodPreviewComponent } from './app-nutrifood-preview/app-nutrifood-preview.component';
import { AppAgrimarketPreviewComponent } from './app-agrimarket-preview/app-agrimarket-preview.component';
import { AppSpeciesPreviewComponent } from './app-species-preview/app-species-preview.component';
import { AppDiseasePreviewComponent } from './app-disease-preview/app-disease-preview.component';
import { AppVaccinationPreviewComponent } from './app-vaccination-preview/app-vaccination-preview.component';
import { AppAgrimarketDetailPreviewComponent } from './app-agrimarket-detail-preview/app-agrimarket-detail-preview.component';
import { AppMarketsPreviewComponent } from './app-markets-preview/app-markets-preview.component';
import { AppSingleNodePreviewComponent } from './app-single-node-preview/app-single-node-preview.component';
import { AppEnutrifoodSubjectPreviewComponent } from './app-enutrifood-subject-preview/app-enutrifood-subject-preview.component';
import { AppPlantPestsPreviewComponent } from './app-plant-pests-preview/app-plant-pests-preview.component';
import { AppCovidAdvicePreviewComponent } from './app-covid-advice-preview/app-covid-advice-preview.component';
import { AppCropDetailPreviewComponent } from './app-crop-detail-preview/app-crop-detail-preview.component';
@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        FormsModule,
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        PreviewRoutingModule,
        ReactiveFormsModule
    ],
    declarations: [
        AppPreviewComponent,
        AppMenuComponant,
        ChildDirective,
        PreviewToolbarComponent,
        AppWeatherPreviewComponent,
        AppLivestockPreviewComponent,
        AppNutrifoodPreviewComponent,
        AppAgrimarketPreviewComponent,
        AppSpeciesPreviewComponent,
        AppDiseasePreviewComponent,
        AppVaccinationPreviewComponent,
        AppAgrimarketDetailPreviewComponent,
        AppMarketsPreviewComponent,
        AppSingleNodePreviewComponent,
        AppEnutrifoodSubjectPreviewComponent,
        AppPlantPestsPreviewComponent,
        AppCovidAdvicePreviewComponent,
        AppCropDetailPreviewComponent
    ],
    exports: [
        PreviewRoutingModule
    ]
})
export class AppPreviewModule { }
// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}