import 'firebase/compat/messaging';
import firebase from 'firebase/compat/app';

import { first, take } from 'rxjs/operators';
import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { AngularFireAuth } from '@angular/fire/compat/auth';
// import { AngularFireMessaging } from '@angular/fire/messaging';
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore';

import { BehaviorSubject, Observable } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';
import { MatSnackBar } from "@angular/material/snack-bar";
import { environment } from '../../../environments/environment';
// import { UserService } from '../service/user.service';
import { FirestoreDataService } from "./firestore-data.service";
import { GeoLocationService } from '../service/location';
import { DeviceDetectorService } from "ngx-device-detector";

import { PopupComponent } from '../component/popup/ok-popup/popup.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


@Injectable()
export class MessagingService {
  currentMessage = new BehaviorSubject(null);
  private permissionInternal$: BehaviorSubject<
    NotificationPermission
  > = new BehaviorSubject(
    isPlatformBrowser(this.platformId) && 'Notification' in window
      ? Notification.permission
      : 'default'
  );
  permission$: Observable<
    NotificationPermission
  > = this.permissionInternal$.asObservable();

  token = new BehaviorSubject(null);
  token$ = this.token.asObservable();
  userdoc: any = [];
  constructor(
    @Inject(PLATFORM_ID) private platformId: object,
    // public us: UserService,
    public firestoreDataService: FirestoreDataService,
    public location: GeoLocationService,
    public toast: MatSnackBar,
    private http: HttpClient,
    private angularFireDB: AngularFireDatabase,
    private angularFireAuth: AngularFireAuth,
    public afs: AngularFirestore,   // Inject Firestore service
    private deviceService: DeviceDetectorService,
    private dialog: MatDialog
    // private angularFireMessaging: AngularFireMessaging
  ) {
    // this.angularFireMessaging.messaging.subscribe(
    //   (_messaging) => {
    //     _messaging.onMessage = _messaging.onMessage.bind(_messaging);
    //     _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
    //   }
    // )
  }

  /**
   * update token in firebase database
   *
   * @param userId userId as a key
   * @param token token as a value
   */
  updateToken(userId, topicName, token) {
    // we can change this function to request our backend service
    this.angularFireAuth.authState.pipe(take(1)).subscribe(
      () => {
        const tokenData = {};
        const notificationsData = {};
        if (token) {
          let userArr = token.split(":");
          tokenData[userArr[0]] = token;
          notificationsData[userArr[0]] = "";
          var tokenId = userArr[0];
          var notificationId = userArr[0];
          this.setTokenDetails(userId, topicName, tokenId, token);
        }
      })
  }

  /**
   * request permission for notification from firebase cloud messaging
   *
   * @param userId userId
   */
  async requestPermission(userId, country) {
    // country = country == null ? { country_name: 'FAO' } : country;
    // this.angularFireMessaging.requestToken.subscribe(
    //   (token) => {
    //     localStorage.setItem('isNotificationRegistered', 'true');
    //     this.updateToken(userId, country.country_name, token);
    //   },
    //   (err) => {
    //     console.error('Unable to get permission to notify.', err);
    //   }
    // );

    console.log('Requesting permission...');
    const permission: NotificationPermission = await Notification.requestPermission();
    this.permissionInternal$.next(permission);
    if (permission === 'granted') {
      await this.getToken(userId, country);
    } else {
      this.toast.open(
        "Unable to get permission to notify.",
        "OK",
        { duration: 10000 }
      );
      console.log('Unable to get permission to notify.');
    }
    // this.receiveMessage();

  }

  async getToken(userId, country) {
    let countryData = localStorage.getItem('countryData') ? JSON.parse(localStorage.getItem('countryData')) : {};
    var topicName = '';
    const messaging = firebase.messaging();
    const token = await messaging.getToken();
    // const token = await this.angularFireMessaging.getToken();
    console.log('Token is:');
    console.log(token);
    console.log('Sending request to /subscribe/' + countryData['country'].toUpperCase());
    if (userId == 'fao_user') {
      topicName = 'general_' + countryData['country'].toUpperCase();
      this.onGetToken(topicName, token, userId);
    }
    else {
      topicName = 'forum_forum_admin_general_' + countryData['country'].toUpperCase();
      this.isForumAdmin(userId, () => {
        this.onGetToken(topicName, token, userId)
      });
    }
  }

  async onGetToken(topicName, token, userId) {
    var apiURL = environment.cloudApiUrl + 'notification/subscribeToTopic/' + topicName
    await this.http
      // .post(`${environment.functions.notificationsHttp}/subscribe/topic/all`, {
      .post(apiURL, {
        token,
      })
      .toPromise();

    localStorage.setItem('isNotificationRegistered', 'true');
    this.updateToken(userId, topicName, token);
    this.toast.open(
      "Notification permission granted.",
      "OK",
      { duration: 10000 }
    );
    console.log('Notification permission granted.');
  }

  /**
   * hook method when new notification received in foreground
   */
  receiveMessage() {
    const messaging = getMessaging();
    onMessage(messaging, (payload) => {
      console.log('Message received. ', payload);
      if (payload != null) {
        // this.toast.open(payload.notification.title + ": " + payload.notification.body, 'OK', { duration: 10000 });
        this.dialog.open(PopupComponent, {
          width: '300px',
          data: { title: payload.notification.title, subtitle: payload.notification.body, link: payload.data.url }
        });
      }
      // this.message=payload;
    });
  }

  setTokenDetails(userId, topicName, tokenId, token) {
    let countryData = localStorage.getItem('countryData') ? JSON.parse(localStorage.getItem('countryData')) : {};
    var countryName: string = countryData['country'].toUpperCase()
    console.log(countryName);
    var tokenPath: string = '';

    var tokenData = {}
    var deviceInfo = this.deviceService.getDeviceInfo();
    console.log(deviceInfo);
    if (userId == 'fao_user') {
      tokenPath = `notification/${countryName}/fcm_tokens/${tokenId}`;
      tokenData = {
        uid: userId,
        token: token,
        fcm_topics: topicName,
        device_info: deviceInfo,
        created_at: new Date()
      }
    }
    else {
      var tokenArr = [];
      var topicArr = [];
      var tokenObj = {
        token: token,
        device_info: deviceInfo,
        updated_at: new Date(),
        // created_at: new,
      }
      tokenPath = `users/${userId}`;
      //set tokens in users collection
      if (this.userdoc.fcm_tokens != undefined) {
        var isTokenFound = false;
        if (this.userdoc.fcm_tokens.length > 0) {
          for (var countToken = 0; countToken < this.userdoc.fcm_tokens.length; countToken++) {
            if (this.userdoc.fcm_tokens[countToken]['token'] == token) {
              isTokenFound = true;
              this.userdoc.fcm_tokens[countToken]['updated_at'] = new Date();
            }
          }
        }
        tokenArr = this.userdoc.fcm_tokens;
        if (!isTokenFound) {
          if (tokenArr.length >= 10) {
            tokenArr.sort(function (x, y) {
              return x.updated_at - y.updated_at;
            });
            tokenArr.shift();
          }
          tokenObj["created_at"] = new Date();
          tokenArr.push(tokenObj);
        }
      }
      else {
        this.userdoc.fcm_tokens = [];
        tokenObj["created_at"] = new Date();
        tokenArr.push(tokenObj);
      }
      this.userdoc.fcm_tokens = tokenArr;
      //set topics in users collection
      if (this.userdoc.fcm_topics != undefined) {
        topicArr = this.userdoc.fcm_topics;
        if (!this.userdoc.fcm_topics.includes(topicName)) {
          topicArr.push(topicName);
        }
      }
      else {
        this.userdoc.fcm_topics = [];
        topicArr.push(topicName);
      }
      this.userdoc.fcm_topics = topicArr;
      tokenData = {
        fcm_tokens: this.userdoc.fcm_tokens,
        fcm_topics: this.userdoc.fcm_topics
      }
    }
    const tokenNodeRef: AngularFirestoreDocument<any> = this.afs.doc(tokenPath);
    return tokenNodeRef.set(tokenData, {
      merge: true
    })
  }

  isForumAdmin(UserId, callFn) {
    this.firestoreDataService.getUserDetails(UserId).take(1).subscribe((res: any[]) => {
      // var userdoc: any = [];
      if (res) {
        this.userdoc = res;
        const country = this.location.getCountry();
        if ((this.userdoc.roles["dsp"].includes("forum_admin")) && this.userdoc['country_iso3'].toUpperCase() == country.country.toUpperCase()) {
          callFn();
        }
      }
    });
  }

}
