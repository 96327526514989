import { first, take } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { GeoLocationService } from './location';
import { MessagingService } from "./messaging.service";
import { FirestoreDataService } from "./firestore-data.service";
import { environment } from '../../../environments/environment';
import Utils from '../utils/utils';
@Injectable()
export class NotificationService {
    message: any;
    constructor(
        private toast: MatSnackBar,
        private location: GeoLocationService,
        private messagingService: MessagingService,
        public firestoreDataService: FirestoreDataService
    ) { }
    /**
     * register notification
     *
     * @param userId userId as a key
     * @param token token as a value
     */
    registerNotification(loc, userId) {
        let isNotificationRegistered = localStorage.getItem('isNotificationRegistered');
        if (!JSON.parse(isNotificationRegistered) || Notification.permission !== "granted") {
            //const userId = 'fao_user';
            const country = this.location.getCountry();
            this.messagingService.requestPermission(userId, country);
            // this.messagingService.currentMessage.subscribe(
            //     (payload) => {
            //         console.log("current message subscribed:");
            //         console.log(payload);
            //         if (payload != null) {
            //             this.toast.open(payload.notification.title + ": " + payload.notification.body, 'OK', { duration: 10000 });
            //         }
            //     })
        }
        else {
            if (loc == "message") {
                this.toast.open('User has been already registered for the notification.', "OK");
            }
            else if (loc == "login") {
                const country = this.location.getCountry();
                this.messagingService.requestPermission(userId, country);
            }
        }
        this.messagingService.receiveMessage();
    }

    getNotificationsHistory(applicationId) {
        // var fireDataNotificationNodeName: string = "notification/notifications_history";
        // const country = localStorage.getItem('originalCountry');
        // const countryName = (country != null) ? this.firestoreDataService.modifyString(country) : "";
        // var langCode = (JSON.parse(localStorage.getItem(environment.userLanguageStorageKey))) ? JSON.parse(localStorage.getItem(environment.userLanguageStorageKey)).code : 'en';
        const country = JSON.parse(localStorage.getItem('countryData'));
        var collectionUrl: string = `notification/${country.country}/notifications`;
        return this.firestoreDataService.getNotificationData(collectionUrl, applicationId);
    }

    getNotificationsApplications() {
        var fireDataNotificationNodeName: string = "notification/applications";
        const country = localStorage.getItem('originalCountry');
        const countryName = (country != null) ? Utils.modifyCountryString(country) : "";
        var docsUrl: string = fireDataNotificationNodeName + "/" + countryName + "/allData";
        return this.firestoreDataService.getNotificationDocument(docsUrl);
    }



}
