import { HouseholdPoultryCategoriesComponent } from './household-poultry-categories/household-poultry-categories.component';
import { HouseholdPoultryComponent } from './household-poultry.component';
import { HouseholdPoultrySubjectsComponent } from './household-poultry-subjects/household-poultry-subjects.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../shared/guards/auth.guard';

const HouseholdPoultry: Routes = [
    {
        path: 'household-poultry', component: HouseholdPoultryComponent, canActivate: [AuthGuard], children: [
            { path: '', redirectTo: 'categories', pathMatch: 'full' },
            { path: 'categories', component: HouseholdPoultryCategoriesComponent, canActivate: [AuthGuard] },
            { path: 'categories/:commodity_parent', component: HouseholdPoultryCategoriesComponent, canActivate: [AuthGuard] },
            { path: 'subject/:commodity_parent/:commodity_name', component: HouseholdPoultrySubjectsComponent, canActivate: [AuthGuard] }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(HouseholdPoultry)],
    exports: [RouterModule],
})
export class HouseholdPoultryRoutingModule { }
