
import {
  throwError as observableThrowError,
  BehaviorSubject,
  Observable
} from "rxjs";
import { Router, NavigationEnd } from "@angular/router";
import { map, tap, catchError } from "rxjs/operators";
import { AngularFireDatabase } from "@angular/fire/compat/database";
import { MatSnackBar } from "@angular/material/snack-bar";
import { HttpClient } from "@angular/common/http";
import { Injectable, EventEmitter } from "@angular/core";
//import { UserService } from './user.service';
import { Parametre } from "./parametre";

const PARAMETRE = Parametre.getInstance();
import { FirestoreDataService } from "./firestore-data.service";
import { Pipe, PipeTransform } from "@angular/core";
import Utils from "../../shared/utils/utils";
import { environment } from "../../../environments/environment";

@Injectable()
export class GeoLocationService {
  private locationSubject: BehaviorSubject<any> = new BehaviorSubject("");
  private anonymousSubject: BehaviorSubject<boolean> = new BehaviorSubject(
    false
  );
  private countryDataSubject: BehaviorSubject<any> = new BehaviorSubject("");
  private countrySubject: BehaviorSubject<any> = new BehaviorSubject("");
  private localitySubject: BehaviorSubject<any> = new BehaviorSubject({
    formatted_address:
      "please click the blue Location button to get your exact location",
    coords: { lat: "Lattitude", lng: "Longitude" }
  });
  private countryListSubject: BehaviorSubject<any> = new BehaviorSubject("");
  public locality$: Observable<any> = this.localitySubject.asObservable();
  public country$: Observable<any> = this.countrySubject.asObservable();
  public location$: Observable<any> = this.locationSubject.asObservable();
  public anonymous$: Observable<any> = this.anonymousSubject.asObservable();
  public countryList$: Observable<any> = this.countryListSubject.asObservable();
  public countryData$: Observable<any> = this.countryDataSubject.asObservable();
  public coords: number[] = [];

  constructor(
    private http: HttpClient,
    public toast: MatSnackBar,
    private router: Router,
    public fireStoreDataService: FirestoreDataService
  ) { }

  getCountryID() {
    let country: any = localStorage.getItem("countryData");
    if (country == null) {
      this.toast.open(
        "No country detected please login or accept the localisation request in the home page!!!",
        "OK",
        { duration: 6000 }
      );
      return 0;
    }
    country = JSON.parse(country);
    if (!country.isActive) {
    }
    return country.country_id;
  }

  getCountryLanguages(countryName) {
    let apiType = "app_settings";
    countryName = Utils.getCountryData(countryName, 'code3');
    return this.fireStoreDataService.get_Document(apiType, countryName);
  }

  getAppSettingsByCountryCode(countryCode) {
    let apiType = "app_settings";
    // countryName = Utils.getCountryData(countryName, 'code3');
    return this.fireStoreDataService.get_Document(apiType, countryCode);
  }

  getCountryFromGeoIp() {
    return this.http.get(environment.geoLocationIp).pipe(map(res => res));
  }

  getDefaultCountryLanguages(countryName) {
    let apiType = "pwa-configuration-api";
    let sheetType = "language_list_final";
    let filterArr: string[] = [];
    const country = {
      "country_name": countryName
    };
    return this.fireStoreDataService.getDocument(apiType, sheetType, filterArr, country);
  }

  getCountry() {
    let country: any = localStorage.getItem("countryData");
    if (country != "undefined") {
      country = JSON.parse(country);
      if (country)
        country['country_name'] = country.country != 'fao' ? Utils.getCountryData(country.country, 'name').toLowerCase() : country.country;
      return country;
    } else {
      return "";
    }
  }

  public handleError = (err: Response) => {
    if (err.status === 500 || err.status === 503) {
      this.toast.open(
        "Service is temporarily unavailable, please try again later",
        "OK",
        { duration: 3000 }
      );
    }
    return Observable.throw(err.json());
  };

  allCountries(): Observable<any> {
    return this.http
      .get(
        `https://fao-digital-services-portfolio.herokuapp.com/synchronisation/account/countries/`
      )
      .pipe(
        map(res => res),
        catchError(this.handleError),
        tap(res => console.log(res))
      );
  }

  getLatestLocation() {
    const coords = {};
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        position => {
          // If user allow the location then remove user denied value
          //sessionStorage.removeItem("userDeniedGeoLocation");
          sessionStorage.setItem("userDeniedGeoLocation", "no");
          this.setPostions(position);
        },
        error => {
          this.showError(error, this);
        },
        { enableHighAccuracy: true }
      );
    } else {
      this.toast.open(
        "Geolocation is not supported by this device/browser",
        "OK",
        { duration: 10000 }
      );
    }
  }

  setPostions(position, countryData?) {
    this.coords = [position.coords.latitude, position.coords.longitude];
    localStorage.setItem("coords", JSON.stringify(this.coords));
    this.getGeocodedInfo(
      position.coords.latitude,
      position.coords.longitude
    ).subscribe(res => {
      const address = res.results.find(
        x => x.types[0] === "country" || x.types[1] === "country"
      );
      const country = res.results.find(
        x => x.types.includes("country") && x.types.includes("political")
      );
      const locality =
        res.results.find(
          x => x.types[0] === "locality" || x.types[1] === "locality"
        ) ||
        res.results.find(
          x =>
            x.types[0] === "administrative_area_level_1" ||
            x.types[1] === "administrative_area_level_2"
        ) ||
        res.results.find(
          x =>
            x.types[0] === "administrative_area_level_1" ||
            x.types[1] === "administrative_area_level_1"
        );
      this.locationSubject.next(address.formatted_address);
      localStorage.setItem("formatted_address", locality.formatted_address);
      try {
        const countryCode = country.address_components[0].short_name;
        this.countrySubject.next(countryCode);
      } catch { }

      this.localitySubject.next({
        formatted_address: locality.formatted_address,
        coords: {
          lat: locality.geometry.location.lat,
          lng: locality.geometry.location.lng
        },
        originalCoords: {
          lat: position.coords.latitude,
          long: position.coords.longitude
        }
      });
      this.locationSubject.subscribe(localization => {
        localStorage.setItem("gpsCountry", localization);
        localStorage.setItem("originalCountry", Utils.modifyCountryString(localization));
        if (countryData) {
          this.getCountryLanguages(localization).subscribe(res => {
            if (res != undefined) {
              localStorage.setItem('countryData', JSON.stringify(res));
            }
            this.countryDataSubject.next(res);
          });
        }
      });
      //Get latest location
      //const countryName = this.modifyString(localStorage.getItem('gpsCountry'));
      const countryName = Utils.modifyCountryString(
        localStorage.getItem("gpsCountry")
      );
      this.getCountryLanguages(countryName).subscribe(res => {
        if (res != undefined) {
          localStorage.setItem("countryData", JSON.stringify(res));
        } else {
          // Set default country to fao country
          localStorage.setItem("gpsCountry", "fao");
          this.getCountryLanguages("fao").subscribe(res => {
            localStorage.setItem("countryData", JSON.stringify(res));
          });
        }
      });
    });
  }

  identifyCountry() {
    this.locationSubject.subscribe(localization => {
      localStorage.setItem("gpsCountry", localization);
      localStorage.setItem("originalCountry", Utils.modifyCountryString(localization));
    });
  }

  localise() {
    // TODO SAVE IN DB
    const coords = {};
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        position => {
          // If user allow the location then remove user denied value
          sessionStorage.removeItem("userDeniedGeoLocation");
          this.coords = [position.coords.latitude, position.coords.longitude];
          this.getGeocodedInfo(
            position.coords.latitude,
            position.coords.longitude
          ).subscribe(res => {
            const address = res.results.find(
              x => x.types[0] === "country" || x.types[1] === "country"
            );
            // const country = res.results.find(
            //   x => x.types.includes("country") && x.types.includes("political")
            // );
            const locality =
              res.results.find(
                x => x.types[0] === "locality" || x.types[1] === "locality"
              ) ||
              res.results.find(
                x =>
                  x.types[0] === "administrative_area_level_1" ||
                  x.types[1] === "administrative_area_level_2"
              ) ||
              res.results.find(
                x =>
                  x.types[0] === "administrative_area_level_1" ||
                  x.types[1] === "administrative_area_level_1"
              );
            //this.countryGeo$.next(countryCode);
            this.locationSubject.next(address.formatted_address);
            localStorage.setItem("formatted_address", locality.formatted_address);
            this.localitySubject.next({
              formatted_address: locality.formatted_address,
              coords: {
                lat: locality.geometry.location.lat,
                lng: locality.geometry.location.lng
              },
              originalCoords: {
                lat: position.coords.latitude,
                long: position.coords.longitude
              }
            });
            this.identifyCountry();
          });
        },
        error => {
          this.showError(error, this);
        },
        { enableHighAccuracy: true }
      );
    } else {
      this.toast.open(
        "Geolocation is not supported by this device/browser",
        "OK",
        { duration: 10000 }
      );
    }
  }

  public getGeocodedInfo(lat: number, lng: number): Observable<any> {
    return this.http.get(
      `${environment.geoLocationApiEndpoint}?lat=${lat}&long=${lng}`
    );
  }

  public showError(error, ref) {
    switch (error.code) {
      case error.PERMISSION_DENIED:
        //this.router.navigate(['message']);
        let userDeniedLocation = sessionStorage.getItem(
          "userDeniedGeoLocation"
        );
        if (userDeniedLocation == undefined || userDeniedLocation == null) {
          sessionStorage.setItem("userDeniedGeoLocation", "yes");
          this.toast.open(error.message, "OK");
        }
        else if (userDeniedLocation == 'yes') {
          this.toast.open('User already denied Geolocation. To enable go to your browser location settings and clear location settings', "OK");
        }
        else if (userDeniedLocation == 'no') {
          this.toast.open('User already enabled Geolocation.', "OK");
        }
        break;
      case error.POSITION_UNAVAILABLE:
        this.toast.open("Location information is unavailable.", "OK", {
          duration: 10000
        });
        break;
      case error.TIMEOUT:
        this.toast.open("The request to get user location timed out.", "OK", {
          duration: 10000
        });
        break;
      case error.UNKNOWN_ERROR:
        this.toast.open("An unknown error occurred.", "OK", {
          duration: 10000
        });
        break;
    }
  }

  chkRegRequired() {
    let apiType = "registration-api";
    let sheetType = "countries";
    let filterArr: string[] = [];
    const country = this.getCountry();
    return this.fireStoreDataService.getDocument(
      apiType,
      sheetType,
      filterArr,
      country
    );
  }
}
