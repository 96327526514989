import { MediaObserver } from '@angular/flex-layout';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { environment } from '../../environments/environment';
import { GeoLocationService } from '../shared/service/location';
import { UserService } from '../shared/service/user.service';
import { AuthService } from '../shared/service/auth.service';
import { ImageStorageService } from '../shared/service/img-storage.service';
import Utils from '../shared/utils/utils';

@Component({
    selector: 'preview-toolbar',
    template: `
    <mat-toolbar color='primary' class='white-text'>
    <!-- Back button for sub category -->
    <button class="toolbar-back-button" mat-icon-button *ngIf="(submenubar == 'true')||(backbar=='true')">
        <mat-icon>arrow_back</mat-icon>
    </button>
    <!-- End -->
        <button *ngIf="(modulePage == 'true') && (submenubar == 'false')" mat-icon-button id="side-menu-bar">
            <mat-icon>menu</mat-icon>
        </button>
    <!-- Main logo -->
    <img *ngIf="(submenubar == 'false')" alt="logo" src="assets/fao-logo-white.svg" style="width:150px;height:29px;">
    <!-- End -->
    <!-- govt logo -->
    <img alt="logo" class="govt_logo" [src]="appSettings.app_logo" *ngIf="appSettings?.app_logo">
    <!-- End -->
    <div style='flex: 1 1 auto'></div>

    <!-- Application title-->
    <ng-template [ngIf]="(menubar == 'true')">
        <div *ngFor="let l of arrApplications" [ngStyle]="setMyStyles()">
        <a fxHide.xs mat-button>
            <img class="menu-icon menu-margin" [src]="l.icon" (error)="onImgError($event,l.id)"/>
            <span fxHide.md fxHide.sm>{{l.title | capitalize }}</span>
        </a>
        </div>
    </ng-template>

    <div *ngIf="(menubar == 'false')">
    <h4 *ngIf="!media.isActive('xs') || submenubar == 'true'" class="module-title">{{title | localTranslate | capitalize }}</h4>
    </div>

    <!-- End -->
    <div fxHide.xs class="toolbar-flex"></div>
    <div style='flex: 1 1 auto'></div>
    <!-- Translate button -->
    <button mat-icon-button>
      <mat-icon>translate</mat-icon>
    </button>
    <!-- End -->
    
    <button mat-icon-button id="profile-menu">
      <mat-icon>more_vert</mat-icon>
    </button>
   
    </mat-toolbar>
    `,
    styles: [
        `
        mat-toolbar{
            position: fixed;
          }

          .disclaimer-text {
              margin-right: 16px;
              vertical-align: middle;
          }
          .govt_logo {
            height:2.9rem;
            padding-left: 0.9rem;
        } `
    ]
})

export class PreviewToolbarComponent implements OnInit {
    user: any;
    logStat: Boolean;
    @Input() title: String;
    public choiceOFDataHidden: Boolean = false;
    @Output() updateCountry = new EventEmitter<Boolean>();
    @Input() arrApplications: any;
    lgCode = '';
    @Input() menubar: String;
    @Input() submenubar: String;
    @Input() backbar: String;
    @Input() forumuser: String;
    @Input() modulePage: String;
    translateIconStatus: boolean = false;
    arrLang: any = [];
    public arrProfileMenu: any;
    @Input() strDeepLink: String;
    public arrPageTitles: any;
    public pageTitle: any;
    country_info: any;
    appSettings: any;


    constructor(public location: GeoLocationService, public uService: UserService,
        public media: MediaObserver,
        public router: Router,
        public userService: UserService,
        public authService: AuthService,
        public imgService: ImageStorageService,
        private route: ActivatedRoute
    ) {
    }

    ngOnInit() {
        const country = this.route.snapshot.params.country;
        this.location.getCountryLanguages(country).subscribe(res => {
            this.appSettings = res;
        });
    }
    setMyStyles() {
        return {
            'direction': 'ltr',
            'text-align': 'left'
        };
    }
    onImgError(event, img) {
        img = Utils.routedata[img] ? Utils.routedata[img] : img;
        event.target.src = 'assets/offline/icons/desktop/' + img + '.svg';
    }
}
