import { throwError as observableThrowError, Observable } from 'rxjs';
import { retry, first, tap, catchError, map } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserService } from './user.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as isOnline from 'is-online';
import { GeoLocationService } from './location';
import { Parametre } from './parametre';
const PARAMETRE = Parametre.getInstance();
import { environment } from '../../../environments/environment';
import { FirestoreDataService } from "./firestore-data.service";

@Injectable()
export class RegistrationService {
    res: any;
    constructor(
        public location: GeoLocationService,
        private http: HttpClient,
        private UService: UserService,
        public toast: MatSnackBar,
        public firestoreDataService: FirestoreDataService
    ) { }

    public handleServerError = (err: Response) => {

        if ((err.status === 500) || (err.status === 503)) {
            this.toast.open('Service is temporarily unavailable, please try again later', 'OK', { duration: 3000 });
        }
        return observableThrowError(err.json());
    }

    getRegistrationConfiguration() {
        let apiType = "registration-api";
        let sheetType = "field_configuration";
        let filterArr: string[] = [];
        const country = JSON.parse(localStorage.getItem('countryData'));
        return this.firestoreDataService.getDocument(apiType, sheetType, filterArr, country);

    }

    get_UserByField(value: string) {
        return this.firestoreDataService.get_UserByField('nickName', value);
    }
}
