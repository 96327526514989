import { ImageStorageService } from './../../shared/service/img-storage.service';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { GeoLocationService } from '../../shared/service/location';
import { UserService } from '../../shared/service/user.service';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { ImageService } from '../../shared/component/image-modal/image.service';
import Utils from '../../../app/shared/utils/utils';
import { Router } from '@angular/router';
import { MatDialog } from "@angular/material/dialog";
import { PandemicReportComponent } from "../../pandemic-report/pandemic-report.component";
import { TranslationService } from "../../shared/service/translation.service";

@Component({
    selector: 'app-home-pc',
    templateUrl: 'home-pc.component.html',
    styles: [
        `
      .head {
        -webkit-background-size: cover !important;
        -moz-background-size: cover !important;
        -o-background-size: cover !important;
        background-size: cover !important;
        height:100% !important;
    }

    @media (min-width:768px) {
        .home-section {
            padding: 14px 100px;
      }
    }
    @media (min-width: 1024px) {
        .home-section {
            padding: 14px 200px;
      }
    }

    .home-footer {
        background-color: #212121;
        padding: 14px auto;
        text-align:center;
        margin : 0 auto;
    }

    #version {
        color: white;
    }
    .text-format {
        margin-top:250px;
        font-weight: bold;
        color: white;
        background-color: #03a9f4;
        position: absolute;
        padding: 5px;
        opacity: 0.8;
    }
    .theme-img {
        background-repeat: no-repeat;
        display          : inline-block;
        fill             : currentColor;
        height           : 50px;
        max-width: fit-content;
      }
    .pc-container {
        position: relative;
        top: 60px;
    }
    `]
})

export class HomePcComponent implements OnInit {
    public phoneNumber: number;
    @Input() user;
    @Input() appSettings;
    @Output() onConnect = new EventEmitter();
    @Output() onLogout = new EventEmitter();
    @Output() countrySelectorChanged = new EventEmitter();
    cc = '+221';
    @Input() arrData;
    hideImages: boolean;

    constructor(
        public img: ImageStorageService,
        public location: GeoLocationService,
        public uService: UserService,
        private sanitizer: DomSanitizer,
        private gaService: GoogleAnalyticsService,
        private imgService: ImageStorageService,
        private imgservice: ImageService,
        public router: Router,
        public mddialog: MatDialog,
        private translationService: TranslationService
    ) { }

    ngOnInit() {
        this.imgservice.ifOffline.subscribe(res => {
            this.hideImages = res;
        });

        let country = this.location.getCountry();
        if (country != null) {
            this.cc = country.country_telephone_prefix;
        }
    }

    login() {
        const fullNumber = this.cc + this.phoneNumber;

        if (this.phoneNumber) {
            this.onConnect.emit(fullNumber);
        } else {
            return;
        }

    }


    changed(event) {
        this.countrySelectorChanged.emit(event);
    }

    logout() {
        this.onLogout.emit('Logout');
    }

    getImage(strUrl) {
        return !this.hideImages ?
            this.sanitizer.bypassSecurityTrustStyle(`url(${strUrl})`) : null;
    }

    onImgError(event, theme) {
        if (!theme.isImageError) {
            theme.isImageError = true;
            let img = this.getId(theme.template_id);
            if (!this.hideImages)
                event.target.src = 'assets/offline/icons/desktop/' + img + '.svg';
        }
    }
    ga(link) {
        this.gaService.event('Home_Theme_Navigation_Click', 'Theme_Navigation', link);
    }
    getId(template_id) {
        return Utils.routedata[template_id] ? Utils.routedata[template_id] : template_id;
    }
    navThemes(data) {
        this.ga(data.template_id)
        let link = Utils.routedata[data.template_id] ? Utils.routedata[data.template_id] : data.template_id;
        //TODO _ remove this once finalized the implementation of farmer-practicies
        let template_id_tmp = data?.template_id_tmp;
        this.gaService.event('Navbar_Theme_Navigation_Click', 'Theme_Navigation', link);
        if (link === 'e-nutrifood')
            this.router.navigate([`/e-nutrifood/categories`, data.id]);
        else if (link === 'administrative')
            this.router.navigate([`/administrative/categories`, data.id]);
        else if (link === 'livestock')
            this.router.navigate([link, data.id]);
        else if (template_id_tmp === 'family_practices')
            this.router.navigate(['/family-practices/categories', data.id]);
        else if (link === 'animal_feed')
            this.router.navigate(['/animal-feed/categories', data.id]);
        else if (link === 'insect') {
            this.router.navigate(['/insect']);
            localStorage.setItem('WeatherPopupStatus', 'true');
        } else if (link === 'survey') {
            this.openPandemicReportDialog();
        } else
            this.router.navigate([link]);
    }

    openPandemicReportDialog() {
        const dialogRef = this.mddialog.open(PandemicReportComponent, {
            width: '400px',
            data: {
                title: this.translationService.translate('reports.pandemic_title')
            }
        });
    }

}
