import { Component, OnInit, OnDestroy } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { LayoutService } from './../shared/service/layout.service';
import { UserService } from './../shared/service/user.service';
import { HttpClient } from '@angular/common/http';
import { HttpClientModule } from '@angular/common/http';

// @Component({
//   selector: 'app-twitter',
//   template: '<h1>HELLO</h1>',
//   styleUrls: ['./twitter.component.scss']
// })

@Component({
  selector: 'app-twitter',
  templateUrl: './twitter.component.html',
  styleUrls: ['./twitter.component.scss']
})

export class TwitterComponent implements OnInit {

  v: Subscription;
  p: Subscription;
  climate: any;
  usage: any;
  cure: any;
  soil: any;
  health: any;
  info$;
  searchText = { tfvname: '' };
  favs = [];
  rowH: string;
  modalWidth: string;
  cols = 1;
  state: string;
  star = 'star_border';
  data = [];
  isLoading = true;
  isDetailsLoading = true;
  appTheme: any;

  constructor(private translate: TranslateService,
    private http: HttpClient, private layout: LayoutService,
    private us: UserService) { }

  ngOnInit() {
    const themes = JSON.parse(localStorage.getItem('appThemes'));
    this.appTheme = themes.filter(_ => _.template_id === 'twitter')[0];
    this.isLoading = false;
    this.p = this.layout.layout$.subscribe(res => {
      this.cols = res.cols;
      this.rowH = res.rowH;
    });
  }
  ngAfterViewInit(): void {
    (<any>window).twttr.widgets.load();
  }
}
