import { Location } from '@angular/common';
import { LivestockService } from '../../shared/service/livestock.service';
import { AudioService } from '../../shared/component/audio/audio.service';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { VideoService } from '../../shared/component/video/video.service';
import { UserService } from '../../shared/service/user.service';
import { Parametre } from '../../shared/service/parametre';
import { ImageService } from '../../shared/component/image-modal/image.service';
import { ImageStorageService } from '../../shared/service/img-storage.service';

const PARAMETRE = Parametre.getInstance();
import { environment } from '../../../environments/environment';
import Utils from "../../shared/utils/utils";
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { GeoLocationService } from '../../shared/service/location';
import { Subscription } from 'rxjs';
import { NgxSpinnerService } from "ngx-spinner";
import { LinkService } from './../../shared/component/link/link.service';

@Component({
  selector: 'app-insect-details',
  templateUrl: './insect-details.component.html',
  styleUrls: ['./insect-details.component.scss']
})

export class InsectDetailsComponent implements OnInit, OnDestroy {
  public objectKeys = Object.keys;
  speciesName: any;
  public arrSpeciesDetails: any = [];
  public loader: boolean = false;
  public arrTabInfo: any;
  public isCount: any;
  public arrSpeciesInfo: any = [];
  public txtSpeech: any;
  public generalData: any;
  public cropsData: any = [];
  lang_code: any;
  groups: any = [];
  providers: any;
  topics: any = [];
  appTheme: any;
  digitalGreen = 'Digital green';
  digitalGreenSubCat = {
    'cattle': 'cows',
    'chicken': 'poultry',
    'goat': 'goats'
  }
  messages: { Video_Title: string; Video_Benefit: string; Category: string; Sub_Category: string; YOUTUBE_ID: string; }[];
  isVideoMessages: boolean;
  sub: any;
  requestId: any;
  counter = 7;
  translateMessage = 'This is auto translated content';
  keyMessage = '';
  selectedIndex = 0;
  subscriptions: Subscription[] = [];
  constructor(
    private audioService: AudioService,
    private location: Location,
    private route: ActivatedRoute,
    private livestockService: LivestockService,
    private videoService: VideoService,
    private imageService: ImageService,
    public gaService: GoogleAnalyticsService,
    public geoLocationService: GeoLocationService,
    private spinner: NgxSpinnerService,
    private imageStorageService: ImageStorageService,
    private linkService: LinkService
  ) {
    this.spinner.show();
  }

  public back() {
    this.location.back();
  }

  ngOnInit() {
    const themes = JSON.parse(localStorage.getItem('appThemes'));
    this.lang_code = JSON.parse(localStorage.getItem(environment.userLanguageStorageKey)).code;
    UserService.langCodeTextSpeech = JSON.parse(localStorage.getItem(environment.userLanguageStorageKey)).text_to_speech_code;
    this.txtSpeech = UserService.langCodeTextSpeech;
    this.route.params.subscribe(response => {
      this.appTheme = themes.filter(_ => _.id === response.themeId)[0];
      // this.speciesName = Utils.modifyString(response.id);
      this.keyMessage = this.route.snapshot.queryParams['keyMessage'];
      this.getGeneralDesc()
      // Get disease and vaccinat;ion details for information tab
      this.subscriptions.push(this.livestockService.getMessageGroup().subscribe(res => {
        this.groups = res;
      }));
      this.subscriptions.push(this.livestockService.getProviders().subscribe(res => {
        this.providers = res;
      }));
      this.subscriptions.push(this.livestockService.getInsectDocument(response.themeId, response.id).subscribe(res => {
        this.spinner.hide();
        this.arrSpeciesDetails = res;
        this.speciesName = this.arrSpeciesDetails.species;
        this.subscriptions.push(this.livestockService.getAllCropsByInsect(response.themeId, res?.insect_id).subscribe(cropres => {
          if (cropres) {
            this.cropsData = cropres;
          }
        }));
        this.subscriptions.push(this.livestockService.getMessageForSpecie('dimension_id', response.id).subscribe(messgae => {
          messgae = messgae.filter(_ => _.status == undefined || _.status);
          this.arrTabInfo = messgae ? messgae.reduce(this.messagesTabs, {}) : {};
          var topics = [];
          var filteredGroups = this.groups.filter(_ => _.group_type == 'topic' && Object.keys(this.arrTabInfo).includes(_.id));
          filteredGroups.forEach(element => {
            topics.push(element.id);
          });
          this.topics = topics;
          this.topics.forEach(element => {
            var obj = {};
            var filteredGroups = this.groups.filter(_ => _.group_type == "group" && Object.keys(this.arrTabInfo[element]).includes(_.id));
            filteredGroups.forEach(ele => {
              obj[ele.id] = this.arrTabInfo[element][ele.id];
            });
            this.arrTabInfo[element] = obj;
          });
          this.isCount = Object.keys(this.arrTabInfo).length;
          this.loader = true;
          // this.selectedIndex = 2;
        }));
        this.arrSpeciesInfo = res;
        this.loader = true;
      }));
    });
  }

  getGeneralDesc() {
    this.subscriptions.push(this.livestockService.getAllDimensions('species').subscribe(res => {
      this.generalData = res.filter((item) => { if (item.species == this.speciesName) return item })[0]
    }));
  }

  messagesTabs(acc, msg) {
    if (msg.topic_id !== "") {
      if (!acc[msg.topic_id]) {
        acc[msg.topic_id] = {};
      }
      if (!acc[msg.topic_id][msg.group_id]) {
        acc[msg.topic_id][msg.group_id] = [msg];
        return acc;
      }
      acc[msg.topic_id][msg.group_id].push(msg);
    }
    return acc;
  }

  openMediaPlayer(src) {
    this.audioService.openMediaPlayer(src);
  }

  openMediaPlayerForVideo(src, isYoutube?) {
    this.videoService.openMediaPlayer(src, isYoutube);
  }

  openMediaForImage(src) {

    this.imageService.openMediaPlayer(src);

  }

  openMediaForSpeech(content) {
    const strUrl = PARAMETRE.strTextToSpeechUrl + encodeURIComponent(content) + "&language=" + this.txtSpeech;
    this.audioService.openMediaPlayer(strUrl);
  }

  setMyStyles() {
    return Utils.reverseContent();
  }

  onImgError(event) {
    event.target.src = Utils.offlineImage("");
  }

  getDefImage(event) {
    event.target.src = Utils.defaultImage();
  }
  getDefaultImage() {
    return Utils.defaultImage();
  }
  tabChange(i) {
    this.gaService.event('Insects_' + this.speciesName, 'Tab_Change', i.tab.textLabel);
  }
  getGroupDetails(id) {
    if (id == '') return 'system.information_tab';
    if (this.groups && this.groups.length) {
      var data = this.groups.filter(_ => _.id == id);
      return data.length ? data[0].name : id;
    } else return id
  }

  getProviderDetaiils(id, returnValue) {
    if (this.providers && this.providers.length) {
      var data = this.providers.filter(_ => _.id == id);
      return data.length ? data[0][returnValue] : id;
    } else return id
  }
  textJustify(txt) {
    return txt.toString().replace(/(?:\r\n|\r|\n)/g, '<br>');
  }
  keyMessages(action, label) {
    this.gaService.event(action, 'KEY_MESSAGES', label);
  }

  getVideoData(res) {
    // this.subscriptions.push(this.livestockService.getVideoData(valueRes).subscribe(res => {
    //   if (res) {
    this.isVideoMessages = true;
    this.messages = res['Video'];
    let subCate = this.digitalGreenSubCat[this.arrSpeciesDetails.species.toLowerCase()] ? this.digitalGreenSubCat[this.arrSpeciesDetails.species.toLowerCase()] : this.arrSpeciesDetails.species.toLowerCase();
    this.messages = this.messages.filter(_ => _.Category == 'Livestock' && _.Sub_Category.toLowerCase() == subCate);
    if (this.lang_code != 'en') {
      let jumpVal = 50;
      let roundvalue = this.messages.length < 50 ? 1 : Math.round(this.messages.length / jumpVal);
      for (let i = 0; i < roundvalue; i++) {
        var translationData = [];
        this.messages.slice(i * jumpVal, i * jumpVal + jumpVal).forEach(element => {
          translationData.push(element.Video_Benefit);
        });
        this.subscriptions.push(this.livestockService.getTranslation({
          "q": translationData,
          "target": this.lang_code
        }).subscribe(res => {
          for (let index = 0; index < this.messages.slice(i * jumpVal, i * jumpVal + jumpVal).length; index++) {
            if (res['data']['translations'][index].translatedText)
              this.messages.slice(i * jumpVal, i * jumpVal + jumpVal)[index].Video_Benefit = res['data']['translations'][index].translatedText;
          }
        }));
      }
    }
    //   }
    // }, (error: any) => {
    //   this.counter--;
    //   if (this.counter) setTimeout(() => this.getVideoData(valueRes), 5000);
    // }));
  }
  ngOnDestroy() {
    if (this.sub)
      this.sub.unsubscribe();
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
  isTabOpened(keyMessages) {
    let value = this.keyMessage && keyMessages.filter(_ => _.id == this.keyMessage).length ? true : false;
    if (value) {
      this.topics.forEach((element, index) => {
        if (keyMessages.filter(_ => _.id == this.keyMessage)[0].topic_id == element) {
          //this.selectedIndex = this.arrSpeciesDetails.info && this.arrSpeciesDetails.info[this.lang_code] ? index + 1 : index;
        }
      });
    }
    return value;
  }
  getProviders(keymessages) {
    return this.groupByKey(keymessages, 'provider_id');
  }
  groupByKey(data, key) {
    return data.reduce(function (rv, x) {
      x[key] = !x[key] ? '' : x[key];
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  };
  getx48Image(inputUrl: string): string {
    return this.imageStorageService.getx48Image(inputUrl);
  }
}
