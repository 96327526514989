import { Component, Input, OnInit } from '@angular/core';
import Utils from '../../../app/shared/utils/utils';

@Component({
  selector: 'app-app-livestock-preview',
  templateUrl: './app-livestock-preview.component.html',
  styleUrls: ['./app-livestock-preview.component.scss']
})
export class AppLivestockPreviewComponent implements OnInit {
  searchSpecies = { name: '' };
  searchDisease = { name: '' };
  searchVaccination = { name: '' };
  @Input() data: any;
  @Input() appName: any;
  constructor() { }

  ngOnInit(): void {
  }
  setMyStyles() {
    return {
      'direction': 'ltr',
      'text-align': 'left'
    };
  }
}
