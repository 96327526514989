import { HouseholdPoultryService } from './../shared/service/householdpoultry.service';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HouseholdPoultryCategoriesComponent } from './household-poultry-categories/household-poultry-categories.component';
import { HouseholdPoultryComponent } from './household-poultry.component';
import { HouseholdPoultryRoutingModule } from './household-poultry.routing';
import { FilterPipe } from './../shared/pipes/filter.pipe';
import { FormsModule } from '@angular/forms';
import { SharedModule } from './../shared/shared.module';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HouseholdPoultrySubjectsComponent } from './household-poultry-subjects/household-poultry-subjects.component';

@NgModule({
  declarations: [HouseholdPoultryCategoriesComponent, HouseholdPoultryComponent, HouseholdPoultrySubjectsComponent],
  imports: [
    CommonModule,
    HouseholdPoultryRoutingModule,
    FormsModule,
    SharedModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  exports: [
    HouseholdPoultryRoutingModule
  ],
  providers: [
    HouseholdPoultryService
  ]
})
export class HouseholdPoultryModule { }
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
