import { Observable } from 'rxjs';
import { ActivatedRoute, Router, ParamMap } from '@angular/router';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';


@Component({
    selector: 'app-details',
    templateUrl: 'details.component.html',
    styleUrls: ['details.component.css']
})

export class DetailsComponent implements OnInit {
    @Input() details$: Observable<any>;
    @Input() service;
    @Input() Iterator;
    @Output() nav = new EventEmitter<any>();
    constructor() { }

    ngOnInit() { }

    navigate(id: any ) {
        this.nav.emit(id);
    }
}

// ["/market", d.market_info.market_id] 