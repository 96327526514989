//import { ImageStorageService } from './shared/service/img-storage.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { LayoutService } from './shared/service/layout.service';
import { UserService } from './shared/service/user.service';
import { Component, OnInit, Renderer2, OnChanges } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { GeoLocationService } from './shared/service/location';
//import { TranslateService } from '@ngx-translate/core';
import { environment } from '../environments/environment';
import { TranslationService } from './shared/service/translation.service';
import { SwUpdate } from '@angular/service-worker';
import { MessagingService } from "./shared/service/messaging.service";
import { BehaviorSubject } from 'rxjs';
import Utils from './shared/utils/utils';
import { Location } from '@angular/common';
import { OfflineModeDialogComponent } from './offline-support/offline-mode-dialog/offline-mode-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { OfflineSupportService } from './offline-support/offline-support.service';
import { ImageService } from './shared/component/image-modal/image.service';
import { FeedbackComponent } from './feedback/feedback.component';
import { Title } from '@angular/platform-browser';
import { filter } from 'rxjs/operators';
import { VideoService } from './shared/component/video/video.service';
import { FirestoreDataService } from './shared/service/firestore-data.service';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit, OnChanges {
  message: any;
  public arrTranslations: any = {};
  showFlag: boolean = false;
  selectedImageIndex: number = -1;
  imageObject: any = [];
  showPandemicFormPopup: boolean = false;
  subscriptions: Subscription[] = [];
  constructor(
    public location: GeoLocationService,
    //private translate: TranslateService,
    private router: Router,
    public layout: LayoutService,
    public us: UserService,
    private renderer: Renderer2,
    public toast: MatSnackBar,
    swUpdate: SwUpdate,
    private loc: Location,
    public dialog: MatDialog,
    private offlineSupportService: OfflineSupportService,
    private locationService: GeoLocationService,
    private imageService: ImageService,
    public mddialog: MatDialog,
    private videoService: VideoService,
    private titleService: Title
  ) {
    if ('serviceWorker' in navigator && environment.production) {
      navigator.serviceWorker.register('/ngsw-worker.js').then(reg => {
        swUpdate.available.subscribe(event => {
          swUpdate.activateUpdate().then(() => {
            // let snackBarRef = this.toast.open('New version installed. Reloading...', 'OK');
            // snackBarRef.onAction().subscribe(() => {
            //   window.location.reload();
            // });
            this.toast.open('New version installed. Reloading...');
            window.location.reload();
          }).catch(err => console.warn('No Update found, cannot update'));
        });
      }).catch(e => console.error('Error during service worker registration:', e));
    } else {
      console.warn('Service Worker is not supported');
    }
    this.imageService.openImage.subscribe(image => {
      if (image) {
        this.imageObject = [image];
        this.showLightbox();
      }
    });
    this.videoService.openVideo.subscribe(image => {
      if (image) {
        this.imageObject = [image];
        this.showLightbox();
      }
    });
  }

  ngOnInit() {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
    ).subscribe(() => {
      let countryData = localStorage.getItem('countryData') ? JSON.parse(localStorage.getItem('countryData')) : {};
      let lang_Code = JSON.parse(localStorage.getItem(environment.userLanguageStorageKey));
      var appName = countryData && countryData.app_name && lang_Code ? countryData.app_name[lang_Code.code] : '';
      if (appName)
        this.titleService.setTitle(appName);
    });
    let url = this.loc.path();
    const urlSearchParams = new URLSearchParams(window.location.search)
    const lat = urlSearchParams.get('lat');
    const long = urlSearchParams.get('long');
    const distribution = urlSearchParams.get('distribution');
    if (distribution) {
      localStorage.setItem('strDeepLink', url);
      sessionStorage.setItem('distribution', distribution);
    }
    if (lat && long) {
      this.changeCrods(lat, long);
    }
    this.message = new BehaviorSubject(null);
    // Replace with Offline.js
    if (!navigator.onLine) {
      // this.toast.open('No Internet', 'OK', { duration: 10000 });
      this.imageService.setIfOffline(true);
      const dialogRef = this.dialog.open(OfflineModeDialogComponent, {
        width: '250px',
        disableClose: true
      });
    }

    this.renderer.listen('window', 'offline', () => {
      // this.toast.open('No Internet', 'OK', { duration: 10000 });
      this.imageService.setIfOffline(true);
      const dialogRef = this.dialog.open(OfflineModeDialogComponent, {
        width: '250px',
        disableClose: true
      });
    });

    this.renderer.listen('window', 'online', () => {
      // this.dialog.closeAll();
      this.imageService.setIfOffline(false);
      this.toast.open('Internet Connection Established', 'OK',
        { duration: 10000 });
    });
    const user = JSON.parse(this.us.getUser());
    const langInfo = JSON.parse(localStorage.getItem(environment.userLanguageStorageKey));
    const countryName = Utils.modifyCountryString(localStorage.getItem('gpsCountry'));
    const countryCode = Utils.modifyCountryString(localStorage.getItem('country'));
    if (countryName) {
      this.us.getTranslation('translations').subscribe(res => {
        res = Utils.translationConvertor(res);
        localStorage.setItem('localLanguageData', JSON.stringify(res));
      });
    } else {
      this.us.getWizardTranslation().subscribe((data) => {
        data = Utils.translationConvertor(data, 'en');
        localStorage.setItem('localLanguageData', JSON.stringify(data));
      });
    }
    // this.location.getLatestLocation();
    if (user != null) {
      // this.setDefaultLang(user.lang);
      // } else if (langInfo) {
      // this.setDefaultLang(langInfo.code);
    } else {
      // this.setDefaultLang('en');
      //this.location.localise();
      if (location.pathname.split('/').includes('preview'))
        return;
      if (countryName) {
        // if (url == "/disclaimer") {
        //   this.router.navigate(['disclaimer']);
        // } else
        if (!langInfo) {
          this.router.navigate(['select-language']);
        }
      } else {
        // if (countryCode != undefined) {
        if (url.includes('privacy-policy')) {
          return;
          //this.router.navigate(['disclaimer']);
        } else {
          if (!lat || !long)
            localStorage.setItem('strDeepLink', url);
          this.router.navigate(['message']);
        }
        // }
      }
    }
    //sessionStorage.setItem('isNotificationRegistered', 'false');
  }

  ngOnChanges() {
    this.layout.refresh();
  }

  setDefaultLang() {
    this.us.getTranslation('translations').subscribe(response => {
      response = Utils.translationConvertor(response);
      this.arrTranslations = response;
      if (this.arrTranslations)
        localStorage.setItem('localLanguageData', JSON.stringify(this.arrTranslations));
      this.offlineSupportService.setDownloaded(false);
    });
  }

  private changeCrods(lat, long) {
    var customPosition: any = {};
    customPosition.coords = {};
    customPosition.coords.latitude = lat;
    customPosition.coords.longitude = long;
    navigator.geolocation.getCurrentPosition = function (success, error) {
      success(customPosition);
    };
    let position = {
      "coords": {
        "latitude": lat,
        "longitude": long,
        "altitude": null,
        "accuracy": 100000,
        "altitudeAccuracy": null,
        "heading": null,
        "speed": null
      }
    };
    this.locationService.setPostions(position, true);
  }
  public success(position) {
    var latitude = position.coords.latitude;
    var longitude = position.coords.longitude;
  }
  openfeedbackDialog() {
    const dialogRef = this.mddialog.open(FeedbackComponent, {
      width: '400px',
      data: { title: 'feedback.feedback' }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result == null || result.Details == "") {
        return;
      }

    });
  }
  get showFeedbackAndSurvey() {
    return window.location.pathname == '/message' || window.location.pathname.indexOf('/select-language') > -1 ? false : true;
  }
  showLightbox() {
    this.selectedImageIndex = 0;
    this.showFlag = true;
  }

  closeEventHandler() {
    this.showFlag = false;
    this.selectedImageIndex = -1;
  }

}
