
import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from '../../shared/service/user.service';
import { AuthService } from '../../shared/service/auth.service';
import { ForumService } from '../../shared/service/forum.service';
import { MatListModule } from '@angular/material/list';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

interface Post {
  uphotoUrl: any;
  ufirstName: any;
  ulastName: any;
  pDetails: string;
  pTitle: string;
  pCreatedDate: any;
  numLikes: any;
  numComments: any;
  docid: any;
  publish: any;
}

@Component({
  selector: 'app-forumhome',
  templateUrl: './forumhome.component.html',
  styleUrls: ['./forumhome.component.css']
})

export class ForumhomeComponent implements OnInit, OnDestroy {
  public loader: boolean = false;
  public arrApplications: any;
  searchText = '';
  public arrUser: any = [];
  forum: any;
  subscriptions: Subscription[] = [];

  constructor(
    private router: Router,
    public userService: UserService,
    public authService: AuthService,
    public forumService: ForumService
  ) {
    if (this.authService.isLoggedIn) {
      this.arrUser = this.authService.isLoggedIn;
      this.arrUser.role = 'Admin';
    }
    this.constructor2();
  }
  public arrDocs: any;
  public arrExperiences: any;
  public arrTopics: any;
  public selectedForum = 'citrus';
  public selectedRoom = 'question';

  public ForumsList = [{ value: 'citrus', text: 'citrus' }, { value: 'date', text: 'date' }, { value: 'household poultry', text: 'household poultry' }, { value: 'healthy nutrition', text: 'healthy nutrition' }];

  public RoomsList = [
    { value: 'question', text: 'Questions' },
    { value: 'experience', text: 'Experiences' }
  ];

  onForumSelectionChange() {
    this.loadForumData();
  }
  onRoomSelectionChange() {
    this.loadForumData();
  }
  loadForumData2() {
    this.subscriptions.push(this.forumService.getPosts(this.selectedRoom, this.selectedForum).subscribe(actionArray => {
      this.arrDocs = actionArray.map(item => {

        return {
          docid: item.payload.doc.id,
          docdata: item.payload.doc.data()
        };

      });
      this.config = {
        itemsPerPage: 3,
        currentPage: 1,
        totalItems: this.arrDocs.length
      };
    }));
  }

  loadForumData() {
    this.subscriptions.push(this.forumService.getPosts(this.selectedRoom, this.selectedForum).subscribe(actionArray => {
      this.arrDocs = actionArray.map(item => {
        const docid = item.payload.doc.id;
        let _post = item.payload.doc.data() as Post;
        _post.docid = docid;
        if (!_post.uphotoUrl) {

          _post.uphotoUrl = '../../assets/forum/userprofile.svg';
        }
        return _post;
      });
      this.config = {
        itemsPerPage: 3,
        currentPage: 1,
        totalItems: this.arrDocs.length
      };
    }));

  }

  Search() {
    this.loadForumData();
  }
  ngOnInit() {
    const themes = JSON.parse(localStorage.getItem('appThemes'));
    this.forum = themes.filter(_ => _.template_id === 'forum')[0];
    this.loader = true;
    // Check application is exist or not
    this.subscriptions.push(this.userService.getApplicationList().subscribe(res => {
      this.arrApplications = res['applications'];
    }));

    this.loadForumData();

  }

  nav(link) {
    if (link == this.selectedRoom) {
      this.router.navigate([`post`, `${link}`]);
    }
    else {
      this.router.navigate([`post-details`, `${link}`]);
    }
  }

  publishQuestion() {
    alert('Question publish successfuly....');
  }

  config: any;
  collection = { count: 60, data: [] };

  constructor2() {

    //Create dummy data
    for (var i = 0; i < this.collection.count; i++) {
      this.collection.data.push(
        {
          id: i + 1,
          value: "items number " + (i + 1)
        }
      );
    }
  }

  pageChanged(event) {
    this.config.currentPage = event;
  }
  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
