import { Component, OnInit, OnDestroy, Input, ElementRef, ViewChild, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { FormControl } from "@angular/forms";
import { DivisionFiltersComponent } from '../division-filters/division-filters.component';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { EnutrifoodService } from '../../service/nutrifood.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-custom-filter',
  templateUrl: './custom-filter.component.html',
  styleUrls: ['./custom-filter.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class CustomFilterComponent implements OnInit, OnDestroy {
  cropGroupControl = new FormControl("");
  @ViewChild("iconClick", { static: true }) iconClick: ElementRef;
  /* @ViewChild(SelectAutocompleteComponent, { static: true })
  multiselect: SelectAutocompleteComponent; */
  @Output() onSpeciesChange = new EventEmitter();
  @Output() onDiseaseChange = new EventEmitter();
  @Output() onVaccinationChange = new EventEmitter();
  @Input() arr;
  @Input() filterTypeGroup;
  @Input() isFreashMarket: boolean;
  @Output() onNutrifoodCategoryChange = new EventEmitter();
  @Output() onRegionChange = new EventEmitter();
  @Input() themeData;
  public selectedType: any;
  @Input() showAll = true;
  filter: any;
  isAgrimarket: boolean = false;
  @Input() fpmaPeriods = [];
  hideFilter: boolean = true;
  subscriptions: Subscription[] = [];
  constructor(public dialog: MatDialog,
    public route: ActivatedRoute,
    private enutrifoodService: EnutrifoodService
  ) {
  }

  ngOnInit() {
    if (location.pathname.includes('agrimarket') || location.pathname.includes('market')) {
      this.isAgrimarket = true;
    }
    if (!this.isAgrimarket) {
      this.route.params.subscribe(param => {
        this.filter = null;
        this.onNutrifoodCategoryChange.emit(false);
        if (param.id && this.isFreashMarket) {
          var parentid = param.id.split('?')[0];
          const themes = JSON.parse(localStorage.getItem('appThemes'));
          this.themeData = themes.filter(_ => _.id === parentid)[0];
          this.getDivisionSetting();
        }
      });
    }
  }

  filterLivestocks(filterType) {
    this.selectedType = filterType.value;
    this.onSpeciesChange.emit(filterType);
    this.onDiseaseChange.emit(filterType);
    this.onVaccinationChange.emit(filterType);
    this.onNutrifoodCategoryChange.emit(filterType);
    this.onRegionChange.emit(filterType);
  }

  close() {
    var obj = {
      "value": 'ALL'
    };
    this.filterLivestocks(obj);
    return false;
  }

  filterData(carporation?, zone?, ward?) {
    var levels = {};
    if (carporation)
      levels['1'] = carporation.id;
    if (zone)
      levels['2'] = zone.id;
    if (ward)
      levels['3'] = ward.number;
    this.onNutrifoodCategoryChange.emit(carporation ? levels : null);
  }

  openDivisionFilter() {
    const dialogRef = this.dialog.open(DivisionFiltersComponent, {
      width: '500px',
      data: { 'filter': this.filter, 'themeData': this.themeData }
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      var filter = {};
      for (let index = 0; index < result.length; index++) {
        if (result[index].value) {
          filter[result[index].level] = result[index].value;
        }
      }
      this.filter = result;
      this.onNutrifoodCategoryChange.emit(result ? filter : false);
    });
  }
  agrimarketPeriodChange(filter) {
    this.fpmaPeriods.forEach(element => {
      element.selected = false;
    });
    filter.selected = true;
    this.onNutrifoodCategoryChange.emit(filter.value)
  }
  getDivisionSetting() {
    this.subscriptions.push(this.enutrifoodService.getNutrifoodDivisionSetting(this.themeData.administrative_divisions_type).subscribe((setting: any) => {
      if (setting.length) {
        this.getFreashMarketFilters(setting);
      }
    }));
  }
  getFreashMarketFilters(setting) {
    this.subscriptions.push(this.enutrifoodService.getNutrifoodDivisions(null, this.themeData.administrative_divisions_type).subscribe((data: any) => {
      if (data && data.length) {
        this.hideFilter = setting.length == 1 ? data.length == 1 ? true : false : false;
      }
    }));
  }
  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
