import { Component, Input, OnInit } from '@angular/core';
import Utils from '../../../app/shared/utils/utils';

@Component({
  selector: 'app-app-agrimarket-preview',
  templateUrl: './app-agrimarket-preview.component.html',
  styleUrls: ['./app-agrimarket-preview.component.scss']
})
export class AppAgrimarketPreviewComponent implements OnInit {
  commodity = { name: '' };
  market = { name: '' };
  @Input() data: any;
  @Input() appName: any;
  constructor() { }

  ngOnInit(): void {
  }

  setMyStyles() {
    return {
      'direction': 'ltr',
      'text-align': 'left'
    };
  }
}
