import { FilterPipe } from './../shared/pipes/filter.pipe';
import { FormsModule } from '@angular/forms';
import { NutrifoodRoutingModule } from './nutrifood.routing';
import { SharedModule } from './../shared/shared.module';
import { NutrifoodComponent } from './nutrifood';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {CapitalizePipe}  from '../shared/pipes/capitalize.pipe';
import { NutrifoodCategoriesComponent } from './nutrifood-categories/nutrifood-categories.component';
import { NutrifoodSubjectsComponent } from './nutrifood-subjects/nutrifood-subjects.component';

@NgModule({
    imports: [
        SharedModule,
        FormsModule,
        NutrifoodRoutingModule,
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        })
    ],
    declarations: [
        NutrifoodComponent,
        NutrifoodCategoriesComponent,
        NutrifoodSubjectsComponent
    ],
    exports: [
        NutrifoodComponent,
        NutrifoodRoutingModule
    ]
})
export class NutrifoodModule { }
// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}